import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private http: HttpClient) {
  }

  getMembers(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.MEMBER_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  getMemberById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.MEMBER_URL + '/' + id)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveMember(memberId, payload): Observable<any> {
    // delete payload._id
    // delete payload.__v
    // delete payload.updatedAt

    if (memberId) {
      return this.http.put<any>(urls.BASE_URL + urls.MEMBER_URL + '/' + memberId, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.MEMBER_URL, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  updateMember(data): Observable<any> {
    delete data.__v
    delete data.updatedAt
    return this.http.put<any>(urls.BASE_URL + urls.MEMBER_URL + '/' + data._id, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  parseMemberFile(data): Observable<any> {
    const formData = new FormData();

    formData.append(`memberParse[companyType]`, data['companyType']);

    if (data.uploadExcel) {
      formData.append('uploadExcel', data.uploadExcel, data.uploadExcel.name);
    }
    return this.http.post<any>(urls.BASE_URL + urls.MEMBER_URL + `/parse/`, formData)
      .pipe(
        catchError(errorHandl)
      );
  }
}
