import { Component, OnDestroy, OnInit } from '@angular/core';
import { FleetService } from 'src/app/component/fleet/fleet-service/fleet.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { VehicleInspectionService } from '../vehicle-inspection-service/vehicle-inspection.service';
import { generatePdf, getCompanyLogo } from 'src/app/utils/utils.common';
import { vehicleInspection } from '../../pdf-reports/vehicle-inspection';
import { DatePipe } from 'src/app/pipe/date-pipe/date.pipe';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-vehicle-inspection',
  templateUrl: './vehicle-inspection.component.html',
  styleUrls: ['./vehicle-inspection.component.css']
})
export class VehicleInspectionComponent implements OnInit, OnDestroy {
  fleetList = [];
  user;
  vehicleInspectionList = [];
  selectedVehicle;
  search;

  constructor( private dateP: DatePipe, private fleetService: FleetService, private vehicleInspectionService: VehicleInspectionService, private sharedDataService: SharedDataService) { }


  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.getFleetList();
        }
      });
  }

  getFleetList() {
    this.fleetService
      .getFleetList({ 'isFleetSection': true, 'status': 'active' })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.fleetList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  onVehicleChange(event) {
    this.getAllVehicleInspection(event.target.value);
  }

  getAllVehicleInspection(vehicleId) {
    this.vehicleInspectionList = [];
    this.vehicleInspectionService.getVehicleInspection(vehicleId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.vehicleInspectionList = data;
    });
  }

  async downloadPdf(vInspection) {
    // this.ngxLoader.start();
    const detailsObject = await vehicleInspection(vInspection,this.dateP, this.user).catch(
      data=>{
        // this.ngxLoader.stop();
      }
    );

    let v = '';
    if(this.selectedVehicle && this.selectedVehicle.driverCarNumber) {
      v = this.selectedVehicle.driverCarNumber.replace(/\s/g,'');

    }
    // console.log("data -----> ",JSON.stringify(detailsObject));
    generatePdf(detailsObject, "download", v);
    // this.ngxLoader.stop();
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  onSelectVehicle(vehicle) {
    this.selectedVehicle = vehicle;
    this.getAllVehicleInspection(vehicle._id);
  }


  ngOnDestroy(): void {
  }
}
