import { Component, OnInit } from '@angular/core';
import { downloadFile } from 'src/app/utils/pdf-downloader';

@Component({
  selector: 'app-trfs-templates',
  templateUrl: './trfs-templates.component.html',
  styleUrls: ['./trfs-templates.component.css']
})
export class TrfsTemplatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  downloadFile() {
    downloadFile("assets/documents/form-cms1500.pdf", "pdf", "form-cms1500");
  }

}
