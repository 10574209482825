import { Component, OnInit, ViewChild, NgZone, OnDestroy, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { AgmMap, MapsAPILoader } from "@agm/core";
import { Observable } from "rxjs";
import { sweetAlert } from "../../../utils/swal";
import * as moment from "moment-timezone";
import { getBrokersList } from "src/app/utils/utils.common";
import { DriversService } from "../../drivers/drivers-service/drivers-service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { AssigTripsService } from "../assigntrips-service/assigntrips-service";
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
@Component({
  selector: 'app-driver-load',
  templateUrl: './driver-load.component.html',
  styleUrls: ['./driver-load.component.css']
})
export class DriverLoadComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  @ViewChild('timer', { static: false }) timer;
  @Output() clear = new EventEmitter<any>();
  duration = 10 * 1000; // 10 seconds
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;

  drivers = [];
  selectedDriverId = '';
  timeZone;
  todayDate;

  search = '';
  tripFare = 0;
  tripMiles = 0;
  tripDeadMiles = 0;

  standingOrders;
  unassignedTrips = [];
  nonSuggestedTrips = [];
  selectedTrips;
  row: any = {};
  assign = null;
  isResizing = false;

  getDriverListObserver: Observable<any>;
  getTripListObserver: Observable<any>;
  user;

  constructor(
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit() {
    this.getUser();
    this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();
    this.getDriverList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  ngAfterViewInit() {
    this.agmElement.mapReady
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((map) => {
      this.agmMap = map;
      this.googleMarkerBounds();
    });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  onDateChange() {
    this.todayDate = moment.tz(this.todayDate, this.timeZone).startOf('day').toISOString();
    if (this.selectedDriverId) {
      this.getStandingOrders();
    }
  }

  getStandingOrders() {
    // this.standingOrders = null;
    // this.tripFare = 0;
    // this.tripMiles = 0;
    // this.tripDeadMiles = 0;
    // this.nonSuggestedTrips = [];
    // this.row = {};
    // this.assign = null;
    if (!this.selectedDriverId) return;

    const object = {
      driver: this.selectedDriverId,
      scheduleTime: this.todayDate
    };
    this.assignTripService
      .getOutstandingTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {

          this.standingOrders = null;
          this.tripFare = 0;
          this.tripMiles = 0;
          this.tripDeadMiles = 0;
          this.nonSuggestedTrips = [];
          this.row = {};
          this.assign = null;

          this.standingOrders = data;
          this.filterTrips();
        }
        if (this.selectedDriverId) {
          this.timer.init();
          this.timer.start();
        }
      })
  }

  filterTrips() {
    this.tripFare = 0;
    this.tripMiles = 0;
    this.tripDeadMiles = 0;
    this.nonSuggestedTrips = [];
    this.row = {};
    this.assign = null;
    this.standingOrders.forEach(st => {
      if (st._id) {
        this.row = st;
      } else {
        this.nonSuggestedTrips = st.assigns;
      }
    });
    this.googleMarkerBounds();
  }

  sendToSpecificBucket(assign, index) {
    assign.status = 'offered';
    let findDriver = this.drivers.find(d => d._id === this.selectedDriverId);
    let tempBucket = {
      _id: { _id: findDriver._id, displayName: findDriver.displayName, profileImageURL: findDriver.profileImageURL, takeMeHome: findDriver.takeMeHome },
      assigns: [assign]
    };
    if (this.row && this.row._id) {
      this.row.assigns.push(assign);
    } else {
      this.row = tempBucket;
    }

    let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === assign._id);
    this.nonSuggestedTrips.splice(findTripIndex, 1);
    this.selectedTrips = [assign._id];
    this.processBucket({
      selectedTrips: this.selectedTrips,
      status: 'offered',
      driver: findDriver
    });
  };

  removeFromBucket(assign) {
    assign.status = 'unassigned';
    let findTripIndex = this.row.assigns.findIndex(t => t._id === assign._id);
    this.row.assigns.splice(findTripIndex, 1);
    this.nonSuggestedTrips.push(assign);
    this.selectedTrips = [assign._id];
    this.processBucket({
      selectedTrips: this.selectedTrips,
      status: 'unassigned'
    });
  };

  processBucket(data) {
    this.assignTripService
      .assignMultipleTrip(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Trip Processed Successfully', 'success', 'OK');
        } else if (data) {
          sweetAlert('Error', data, 'warning', 'OK');
        }
      })
  }

  // Map Functions Start
  googleMarkerBounds() {
    if (this.row && this.row.assigns && this.row.assigns.length) {
      this.tripMiles = 0;
      this.tripDeadMiles = 0;
      this.tripFare = 0;
      let driverHomeCoords = this.row._id.takeMeHome;

      this.bounds = new window["google"].maps.LatLngBounds();

      this.bounds.extend(
        new window["google"].maps.LatLng(
          this.row._id.latitude,
          this.row._id.longitude
        )
      );

      this.row.assigns.forEach((assign, index, assigns) => {
        let nextIndex = index + 1;
        if (index === 0) {
          let baseToTripDistance = this.distance(parseFloat(driverHomeCoords[1]), parseFloat(driverHomeCoords[0]), parseFloat(assign.jobOriginLatitude), parseFloat(assign.jobOriginLongitude));
          // this.tripDeadMiles += parseFloat(baseToTripDistance);
          this.tripDeadMiles += baseToTripDistance;
        }
        this.tripMiles += parseFloat(assign.milage);
        this.tripFare += parseFloat(assign.preSpecialRate);
        this.bounds.extend(
          new window["google"].maps.LatLng(
            parseFloat(assign.jobOriginLatitude),
            parseFloat(assign.jobOriginLongitude)
          )
        );

        this.bounds.extend(
          new window["google"].maps.LatLng(
            parseFloat(assign.jobDestinationLatitude),
            parseFloat(assign.jobDestinationLongitude)
          )
        );


        if (assigns.length > nextIndex) {
          let nextTrip = assigns[nextIndex];
          let distance = this.distance(parseFloat(assign.jobDestinationLatitude), parseFloat(assign.jobDestinationLongitude), parseFloat(nextTrip.jobOriginLatitude), parseFloat(nextTrip.jobOriginLongitude));
          // this.tripDeadMiles += parseFloat(distance);
          this.tripDeadMiles += distance;

        }

        if (assigns.length === nextIndex) {
          let tripToBase = this.distance(parseFloat(assign.jobDestinationLatitude), parseFloat(assign.jobDestinationLongitude), parseFloat(driverHomeCoords[1]), parseFloat(driverHomeCoords[0]));
          // this.tripDeadMiles += parseFloat(tripToBase);
          this.tripDeadMiles += tripToBase;

        }
      });


      this.agmMap.setCenter(this.bounds.getCenter());
      this.agmMap.fitBounds(this.bounds);
    }
  }

  distance(lat1, lon1, lat2, lon2) {

    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }

    return this.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d / 1.609; // divided by 1.609 to convert into miles
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  getTripRoute = function (assign) {
    this.assign = assign;
    this.bounds = new window["google"].maps.LatLngBounds();
    this.bounds.extend(
      new window["google"].maps.LatLng(
        parseFloat(this.assign.jobOriginLatitude),
        parseFloat(this.assign.jobOriginLongitude)
      )
    );
    this.bounds.extend(
      new window["google"].maps.LatLng(
        parseFloat(this.assign.jobDestinationLatitude),
        parseFloat(this.assign.jobDestinationLongitude)
      )
    );
    this.agmMap.setCenter(this.bounds.getCenter());
    this.agmMap.fitBounds(this.bounds);
  };
  // Map Functions End

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  onTimerComplete(): void {
    this.timer.init()
    if (this.selectedDriverId) {
      this.getStandingOrders();
    }
  }

onClearData(evt) {
  this.selectedDriverId = '';
  this.standingOrders = null;
  this.tripFare = 0;
  this.tripMiles = 0;
  this.tripDeadMiles = 0;
  this.nonSuggestedTrips = [];
  this.row = {};
  this.assign = null;
}

getBroker(key) {
  return getBrokersList[key];
}
  ngOnDestroy(): void {
  }

  startResizing(event: MouseEvent) {
    this.isResizing = true;
    const sidebar = document.getElementById('resizable-sidebar');

    if (sidebar) {
      const initialHeight = sidebar.offsetHeight;
      const startY = event.clientY;

      const mouseMoveHandler = (e: MouseEvent) => {
        if (this.isResizing) {
          const newHeight = initialHeight - (e.clientY - startY); // Subtract instead of add
          sidebar.style.height = newHeight + 'px';
          //comment added

        }
      };

      const mouseUpHandler = () => {
        this.isResizing = false;
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  }
}
