import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from '../../../utils/swal';
import { getBrokersList, getUserRole } from 'src/app/utils/utils.common';
import { ActivatedRoute, Router } from '@angular/router';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';
import { DynamicFareServiceService } from '../dynamic-fare-service/dynamic-fare-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-dynamic-fare-add',
  templateUrl: './dynamic-fare-add.component.html',
  styleUrls: ['./dynamic-fare-add.component.css']
})
export class DynamicFareAddComponent implements OnInit, OnDestroy {
  submitted = false;
  singleZoneFormSubmitted = false;
  addonServicesFormSubmitted = false;
  companies;
  corporateUsers;
  insuranceCompanies;
  drivers;
  addDynamicFareForm: FormGroup;
  zoneFareForm: FormGroup;
  user;
  userRole;
  dynamicFareId: any;

  constructor(
    private dynamicFareService: DynamicFareServiceService,
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.dynamicFareId = params["id"];
    });
    this.getUser();
    this.initForms();

    if (this.dynamicFareId) {
      this.addDynamicFareForm.patchValue({
        _id: this.dynamicFareId
      });
      this.getDynamicFareById();
    }

    this.selectFor('company');
    this.getDriverList();
    this.getCorporateUserList();
    this.getInsuranceBrokerList();
  }

  initForms() {
    this.addDynamicFareForm = this.fb.group({
      _id: [''],

      driver: [''],
      cooperate: [''],
      companyType: [''],
      company: [this.user._id],

      vehicleType: ['Standard', Validators.required],
      for: ['company', Validators.required],

      perMile: ['', Validators.required],
      perMin: ['', Validators.required],
      minimumFare: [''],
      cancelCharges: ['', Validators.required],
      additionalPassengerCharges: [''],

      pickupSurcharge: this.fb.group({
        fare: [''],
        per: [''],
        after: ['']
      }),

      baseFare: this.fb.group({
        fare: ['', Validators.required],
        for: ['', Validators.required],
      }),

      waitTime: this.fb.group({
        fare: ['', Validators.required],
        per: ['', Validators.required],
        after: ['', Validators.required],
      }),

      zones: [[]],
      isPerMileZone: [false],
      perMileCode: [""],
      dryRunCode: [""],
      baseFareCode: [""],
      waitTimeCode:[""]
    });

    this.zoneFareForm = this.fb.group({
      fare: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required]
    });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        this.user = user;
        this.userRole = getUserRole(this.user.roles);
      });
  }

  getDynamicFareById() {
    this.dynamicFareService
      .getDynamicFareById(this.addDynamicFareForm.value._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.addDynamicFareForm.patchValue({
            ...data
          });

          if (data.for === "driver" && !data.driver ) {
            this.addDynamicFareForm.patchValue({
            driver:''
            });
          }
          this.selectFor(data.for)
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data;
        }
      });
  }

  getInsuranceBrokerList() {
    this.insuranceCompanies = getBrokersList;
    return this.insuranceCompanies;
  }

  addZone() {
    this.singleZoneFormSubmitted = true;
    if (this.zoneFareForm.invalid) {
      return;
    }
    this.addDynamicFareForm.value.zones.push(this.zoneFareForm.value);
    this.zoneFareForm.reset();
    this.singleZoneFormSubmitted = false;
  }

  removeZone(index) {
    const z = this.addDynamicFareForm.value.zones;
    z.splice(index, 1);
    this.addDynamicFareForm.patchValue({
      zones: z
    });
  }

  setVehicle(vehicle: string) {
    this.addDynamicFareForm.patchValue({
      vehicleType: vehicle
    });
  }

  selectFor(selectFor: string) {
    console.log("selectFor", selectFor);
    this.addDynamicFareForm.patchValue({
      for: selectFor
    });
    let clearKeys = [];
    let setKeys = [];

    if (this.addDynamicFareForm.value.for === 'company') {
      clearKeys = ['companyType', 'cooperate', 'driver'];
      setKeys = ['company'];
    } else if (this.addDynamicFareForm.value.for === 'broker') {
      clearKeys = [, 'cooperate', 'driver', 'company'];
      setKeys = ['companyType', 'company'];
    } else if (this.addDynamicFareForm.value.for === 'driver') {
      clearKeys = ['companyType', 'cooperate'];
      setKeys = [ 'driver','company'];

    } else if (this.addDynamicFareForm.value.for === 'cooperate') {
      clearKeys = ['companyType', 'driver'];
      setKeys = ['company','cooperate'];
    } else {
      clearKeys = ['company', 'companyType', 'driver'];
      setKeys = ['cooperate', 'company'];
    }
    this.setFormValidation(selectFor, setKeys, clearKeys);
  }

  setFormValidation(selectFor, setKeys?, clearKeys?) {
    console.log('keys', {setKeys}, {clearKeys})
    clearKeys.forEach(element => {
      this.addDynamicFareForm.get(element).clearValidators();
      this.addDynamicFareForm.get(element).updateValueAndValidity();
      this.addDynamicFareForm.get(element).disable();
    });
    setKeys.forEach(element => {
      this.addDynamicFareForm.get(element).enable();
      if (selectFor === "driver" && element === "driver")
      return;
      this.addDynamicFareForm.get(element).setValidators([Validators.required]);

    });
  }

  onSubmit() {

    this.submitted = true;
    console.log('check on save', this.addDynamicFareForm.value, this.addDynamicFareForm)

    if (this.addDynamicFareForm.invalid) {
      return;
    }

    if (this.addDynamicFareForm.value.driver === "null" || this.addDynamicFareForm.value.driver === "") {
      this.addDynamicFareForm.patchValue({
        driver: null
      })
    }

    this.dynamicFareService
      .saveDynamicFare(this.addDynamicFareForm.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
         this.router.navigateByUrl('fares');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  toggleZonePerMile() {
    this.addDynamicFareForm.patchValue({
      isPerMileZone: !this.addDynamicFareForm.value.isPerMileZone
    });
  }

  get form() {
    return this.addDynamicFareForm.controls;
  }

  get zoneForm() {
    return this.zoneFareForm.controls;
  }

  ngOnDestroy(): void {
  }
}
