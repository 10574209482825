import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class DynamicFareServiceService {

  constructor(private http: HttpClient) {
  }

  getDynamicFareList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_DYNAMIC_FARE_LIST)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDynamicFareById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_DYNAMIC_FARE_LIST + '/' + id)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveDynamicFare(data): Observable<any> {

    if (data._id !== '') {
      return this.http.put<any>(urls.BASE_URL + urls.GET_DYNAMIC_FARE_LIST + '/' + data._id, data).pipe(
        catchError(errorHandl)
      );
    } else {
      delete data._id;
      return this.http.post<any>(urls.BASE_URL + urls.GET_DYNAMIC_FARE_LIST, data).pipe(
        catchError(errorHandl)
      );
    }

  }


}
