import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import Popper, { PopperOptions } from 'popper.js';
import { merge, fromEvent } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

@Directive({
  selector: '[appPopper]'
})
export class PopperDirective implements OnChanges {

  @Input() placement?: string;
  @Input() appPopper?: HTMLElement;
  @Input('toolTip') tooltipContent: string = 'Add Lable';
  private popper: Popper;
  private target;

  private readonly defaultConfig: PopperOptions = {
    placement: 'top-start',
    removeOnDestroy: true,
    modifiers: {
      arrow: {
        element: '.popper_arrow'
      }
    }
  };

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.popper) {
      this.popper.scheduleUpdate();
      this.updateTooltipContent();
    }
  }

  ngOnInit(): void {
    this.target = this.renderer.createElement('span');
    this.renderer.addClass( this.target , 'popper');
    this.renderer.setStyle(this.target, 'position', 'absolute');
    document.body.appendChild(this.target);
    const reference = this.appPopper ? this.appPopper : this.el.nativeElement;

    this.popper = new Popper(reference, this.target, this.defaultConfig);
    this.renderer.setStyle(this.target, 'display', 'none');
    this.updateTooltipContent();
    merge(
      fromEvent(reference, 'mouseenter'),
      fromEvent(reference, 'mouseleave')
    )
      .pipe(
        filter(() => this.popper != null),
        pluck('type')
      )
      .subscribe((e: any) => this.mouseHoverHandler(e));

  }

  private updateTooltipContent(): void {
    if (this.tooltipContent) {
      this.renderer.setProperty(this.target, 'innerHTML', `<span class="popper_arrow"></span>${this.tooltipContent}`);
    }
  }

  private mouseHoverHandler(e: string): void {
    if (e === 'mouseenter') {
      this.renderer.removeStyle(this.target, 'display');
      this.popper.enableEventListeners();
      this.popper.scheduleUpdate();
    } else {
      this.renderer.setStyle(this.target, 'display', 'none');
      this.popper.disableEventListeners();
    }
  }
  //   createToolTip(): HTMLElement {
  //   const tooltip = this.renderer.createElement('div');
  //   const text = this.renderer.createText(this.tooltipContetnt);
  //   this.renderer.appendChild(tooltip, text);
  //   this.renderer.addClass(tooltip, 'toolTipMy');
  //   this.renderer.setStyle(tooltip, 'position', 'absolute');
  //   return tooltip;
  // }
}
