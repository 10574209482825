import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByNumber'
})
export class SortByNumberPipe implements PipeTransform {

  transform(array: any[], propertyName: string): any[] {
    if (!Array.isArray(array) || !propertyName) {
      return array;
    }

    return array.slice().sort((a, b) => {
      const propA = a[propertyName];
      const propB = b[propertyName];

      if (typeof propA === 'number' && typeof propB === 'number') {
        return propB - propA; // Sorting in descending order
      } else {
        return 0; // If the property values are not numbers, return no change in order
      }
    });
  }

}
