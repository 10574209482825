import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Console } from 'console';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, takeUntil } from 'rxjs/operators';
import { setFormData, errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';
import { ProTipsServiceService } from 'src/app/component/helping-material/nemt-pro-tips/service/pro-tips-service.service';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { ImageSnippet, imageProcessing } from 'src/app/utils/image-processing-utils';
import { sweetAlert } from 'src/app/utils/swal';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-create-tips',
  templateUrl: './create-tips.component.html',
  styleUrls: ['./create-tips.component.css']
})
export class CreateTipsComponent implements OnInit, OnDestroy {
  createTipForm: FormGroup;
  submitted = false;
  selectedCoverImageFile: ImageSnippet;
  CoverImageUrl;
  userId = null;
  tipUserId = '';
  btnName = 'Create';
  images = [];
  proTips;
  constructor(private proTipsService: ProTipsServiceService,
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.tipUserId = params["id"];
    });

    this.createTipForm = this.fb.group({
      title: ['', Validators.required],
      status: ['', Validators.required],
      forUser: ['', Validators.required],
      images: [''],
      tipId: [''],
    });
    this.fillData();
  }

  fillData() {
    if (this.tipUserId) {

      this.proTipsService
        .getTipsProfile(this.tipUserId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.createTipForm.patchValue({
              ...data,
            });
            this.images = data.images;
          }
        });
    }
  }

  get form() {
    return this.createTipForm.controls;
  }


  processCoverImageFile(ImageInput: any) {
    console.log('Processing image file', ImageInput)
    imageProcessing(ImageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedCoverImageFile = result.file;
      this.CoverImageUrl = result.src;
    });
  }

  getAccountStatus() {
    return this.authService.accountStatus;
  }

  getProfileRole() {
    return this.authService.driverComponentProfileRoles;
  }

  onCreateTip() {
    console.log(this.createTipForm.value);
    this.submitted = true;

    if (this.createTipForm.invalid) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    // if(this.selectedCoverImageFile == null && this.CoverImageUrl == null) {
    //   return;
    // }
    // const image = {
    //   tipCoverImage: this.selectedCoverImageFile,
    // };
    this.createTipForm.patchValue({
      // images: image,
      tipId: this.tipUserId,
    });
    if (!this.tipUserId) {
      this.saveTip({...this.createTipForm.value, images: this.images});
    } else {
      this.saveTip({...this.createTipForm.value, images: this.images});
    }
  }

  saveTip(payload) {
    this.proTipsService
      .saveProTip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.submitted = false;
          console.log("check for images", this.images)
          if (payload.tipUserId) {
            // this.router.navigateByUrl('/drivers/' + data._id + '/profile');
          } else {
            this.router.navigateByUrl('nemtprotips');
          }
        }
      }, err => {
        console.log(err, 'check error')
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  removeImage() {
    console.log('Remove Image', this.CoverImageUrl)
    this.CoverImageUrl = "";
  }

  clearData() {
    this.createTipForm.reset();
  }

  uploadImage() {
    if (this.selectedCoverImageFile) {
      this.submitted = false;
      // this.store.dispatch(new UploadProfileImage(this.selectedFile));
      this.proTipsService
        .changeCoverImage(this.selectedCoverImageFile)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.images.push(data.location);
            this.CoverImageUrl = '';
            this.selectedCoverImageFile = null;
          }
        });
    }
  }

  changeImages(index, ImageInput){
   
    console.log(ImageInput, "file check")
    if (!ImageInput) return;
    imageProcessing(ImageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      console.log(result)
    //   // this.selectedCoverImageFile = result.file;
    //   // this.CoverImageUrl = result.src;
      this.proTipsService
      .changeCoverImage(result.file)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.images.splice(index, 1, data.location);
        }
      });
    });
  }

  removeImages(index){
    this.images.splice(index, 1);
  }

  ngOnDestroy(): void {
  }
}
