import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { ReportService } from '../reports-service/report.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.css']
})
export class EarningsComponent implements OnInit, OnDestroy {
  reportDetails = [];
  startDate = null;
  endDate = null;
  selectedDate = null;
  driversDetails = [];
  tripsDetailModalObj;
  imageModalUrl;
  mailCheck = false;
  selectedDriver = null;
  user;

  constructor(
    private reportService: ReportService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.getReportDetails();
        }
      });
  }

  getReportDetails(type?) {
    console.log(this.selectedDate);
    let data;
    if (type === 'reset') {
       data = {
        _id: this.user._id,
        startDate:  null,
        endDate:  null,
      };
    } else {
       data = {
        _id: this.user._id,
        startDate: this.selectedDate ? this.selectedDate[0].toISOString() : null,
        endDate: this.selectedDate ? this.selectedDate[1].endOf('day').toISOString() : null,
      };
    }
    this.reportService
      .getReportsWithNgrx(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.reportDetails = data;
          if (this.reportDetails.length > 0) {
            this.driversDetails = data[0].drivers;
            this.selectedDate = [new Date(this.reportDetails[0].dates.startDate) , new Date(this.reportDetails[0].dates.endDate)];
            this.startDate = this.reportDetails[0].dates.startDate;
            this.endDate = this.reportDetails[0].dates.endDate;
            console.log('Report Detail Check ' , this.reportDetails)
            console.log('Driver Detail Check ' , this.driversDetails)

          } else {
            this.driversDetails = null;
            this.startDate = this.selectedDate? this.selectedDate[0].toISOString(): '';
            this.endDate = this.selectedDate? this.selectedDate[1].endOf('day').toISOString(): '';
          }

        }
      })
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  openPdfDownloadModal(driverReport) {
    this.selectedDriver = driverReport._id._id;
  }

  downloadDriverEarningReport(driverId, mailCheck) {
    this.selectedDriver = null;
    this.mailCheck = false;
    console.log(driverId, mailCheck);
    const data = {
      mailCheck,
      driverId,
      startDate: this.startDate,
      endDate: this.endDate

    };
    this.reportService
      .getDriverPdfReport(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      });
  }

  downloaPaymentReport() {
    const data = {
      id: this.user._id,
      startDate: this.startDate,
      endDate: this.endDate

    };
    this.reportService
      .getPaymentPdfReport(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      });
  }

  setDriverTripsDetail(driver){
    // console.log("driversDetails ==>", driver)
    this.tripsDetailModalObj = driver
  }

  
ngOnDestroy(): void {
}
}
