import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { ServiceAreasService } from '../../service-areas/service-areas-service/service-areas.service';
import { sweetAlert } from 'src/app/utils/swal';
import { CorporateService } from '../../corporate-user/corporate-service/corporate-service';
@Component({
  selector: 'app-trip-activity-log',
  templateUrl: './trip-activity-log.component.html',
  styleUrls: ['./trip-activity-log.component.css']
})
export class TripActivityLogComponent implements OnInit {

  activityLog = [];
  search;
  @Input() tripData;
  @Output() onCloseModal = new EventEmitter<any>()

  constructor(
    private assignTripService: AssigTripsService,
    private serviceArea: ServiceAreasService,
    private corporateService: CorporateService
  ) { }

  ngOnInit() {
    console.log("Trip Data", this.tripData);
    this.getTripLog();
  }

  getTripLog() {
    this.assignTripService.getTripActivityLog(this.tripData._id)
      .pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
        console.log(data);
        if (data) {
          this.activityLog = data
        }
      })
  }

  onClose() {
    this.onCloseModal.emit()
  }

  ngOnDestroy(): void {

  }

}
