import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment-timezone';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';
import { exportToCsv, getBrokersList, getstandingOrderDaysAsObject } from 'src/app/utils/utils.common';
import { DatePipe } from 'src/app/pipe/date-pipe/date.pipe';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-standing-orders-v2',
  templateUrl: './standing-orders-v2.component.html',
  styleUrls: ['./standing-orders-v2.component.css']
})
export class StandingOrdersV2Component implements OnInit, OnDestroy {
  standingOrderDays = getstandingOrderDaysAsObject();
  search: any;
  todayDate;
  timeZone;
  user;
  dataList = [];
  editTripModalObj;
  trip;
  corporateUsers = [];
  cloneTripModalObj;
  standingDetailModalObj;
  status = 'active';
  isShowDaysDropdown = false;
  standingOrders;
  standingDays = [
    { label: 'Sun', value: 0, isChecked: true },
    { label: 'Mon', value: 1, isChecked: true },
    { label: 'Tue', value: 2, isChecked: true },
    { label: 'Wed', value: 3, isChecked: true },
    { label: 'Thu', value: 4, isChecked: true },
    { label: 'Fri', value: 5, isChecked: true },
    { label: 'Sat', value: 6, isChecked: true },
  ];

  constructor(
    private sharedDataService: SharedDataService,
    private assignTripService: AssigTripsService,
    private dateP: DatePipe) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getCorporateUserList();
          this.getTrips();
        }
      });
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }

  getTrips() {
    this.assignTripService.getAssignManifestList({
      status: 'standing',
      isRecurring: this.status === 'active' ? true : false
    })
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.dataList = [];
        if (data) {
          this.standingOrders = data;
          this.dataList = data;
          this.dataList.forEach(s => {
            s.diffFromToday = 10;
            if (s.recurringEndDate) {
              const end = moment(s.recurringEndDate, 'YYYY-MM-DD').tz(this.timeZone);
              const now = moment(this.todayDate).tz(this.timeZone);
              s.diffFromToday = end.diff(now, 'days');
            }
          });
          this.dataList.sort((a, b) => a.diffFromToday - b.diffFromToday);
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  editTrip(assign) {
    if (this.cloneTripModalObj) return;
    if (this.standingDetailModalObj) return;
    this.editTripModalObj = assign._id;
    this.trip = assign;

  }

  onEditTrip(event) {
    this.editTripModalObj = null;
    this.trip = null;
    this.getTrips();
  }

  getCorporateByID(id) {
    return this.corporateUsers[id];
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  openCloneModal(trip) {
    this.cloneTripModalObj = trip;
  }

  closeCloneTripModal(evt) {
    this.cloneTripModalObj = null;
  }

  openStandingDetailModal(trip) {
    this.standingDetailModalObj = trip;
  }

  closeStandingDetailModal(evt) {
    this.standingDetailModalObj = null;
  }

  onChangeDays(){
    let days = [];
    this.standingDays.forEach(d => {
      if (d.isChecked) {
        days.push(d.value);
      }
    });
    if (days.length === 0) {
      this.dataList = this.standingOrders;
      return;
    }

    this.dataList = this.standingOrders.filter(s => s.recurringDays.some(r => days.includes(r.day)))
  }

  onChangeStatus() {
    this.getTrips();
  }

  downloadReport() {
    const table = [
      [
        "Trip ID",
        "Member",
        "PU Time",
        "Apt Time",
        "Pickup",
        "Dropoff",
        "LOS",
        "Type",
        "Days",
        "Status",
        "End Date"
      ]
    ];

    for (let i = 0; i < this.dataList.length; i++) {
      const trip = this.dataList[i];

      let type = '';
      if(trip.jobType === 'cooperate') {
        if (trip.cooperate) {
          const corp = this.getCorporateByID(trip.cooperate);
          type = corp ? corp.displayName : "None"
        } else {
          type = "None";
        }
      } else {
        type = trip.companyType === "" ? "Cab Dispatch" : this.brokerList(trip.companyType);
      }

      let days = '';
      days = trip.recurringDays.map(r => {
        return this.standingOrderDays[r.day]
      });
      days = days && days.length ? days.toString() : '';

      const standingOrder = [
        trip.tripId,
        trip.priorityClient ? trip.priorityClient.displayName : "N/A",
        this.dateP.transform(trip.scheduleTime, "HH:mm"),
        this.dateP.transform(trip.appointmentTime, "HH:mm"),
        trip.jobOriginAddress,
        trip.jobDestinationAddress,
        trip.tripRequirement,
        type,
        days,
        trip.isRecurring ? "Active": "Inactive",
        trip.recurringEndDate ? this.dateP.transform(trip.recurringEndDate, "MM/DD/YYYY") : "N/A"
      ]
      table.push(standingOrder);
    }
    exportToCsv(`standing-orders-${ moment().format("MM-DD-YYYY")}.csv`, table);
  }

  toggleDaysDropdown() {
    this.isShowDaysDropdown = !this.isShowDaysDropdown
  }

  ngOnDestroy(): void {
  }
}
