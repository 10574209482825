import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { AgmMap, ControlPosition, MapsAPILoader, MapTypeControlOptions, MouseEvent } from "@agm/core";
import { Events } from "src/app/utils/event-utils";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { sweetAlert } from "../../../utils/swal";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment-timezone";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DispatchCenterService } from "../dispatch-center-service/dispatch-center-service";
import { AssigTripsService } from "../../assigned-trips/assigntrips-service/assigntrips-service";
import { mapStyles } from "src/app/utils/utils.common";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OfferedtripsService } from "../../offered-trips/offered-trips-service/offeredtrips.service";


declare var $;

@Component({
  selector: "app-main",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DispatchDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  duration = 5 * 1000; // 10 seconds
  // Map
  @ViewChild("AgmMap", { static: true }) agmElement: AgmMap;
  @ViewChild('timer', { static: true }) timer;

  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  agmMap;
  trafficLayer: google.maps.TrafficLayer;
  origin = { lat: 0.0, lng: 0.0 };
  destination = { lat: 0.0, lng: 0.0 };
  latitude: number;
  longitude: number;
  zoom: number = 12;
  styles = mapStyles();
  driverSearch = "";
  notificationSearch = "";
  address: string;
  geoCoder;
  public tripModalObject: any;
  public assignModalObj: any;
  public driverTripModalObj: any;
  public driverTripModalObject: any;
  live = true;
  isFilter = false;

  public drivers = [];
  public approachingTrips = [];
  public expiringSoonTrips = [];
  public markedTrips = [];
  notifications = [];
  companyDriverStatus;
  tripId = null;
  reDispatchTripDetail;
  selectedDriver;

  bounds;
  tripsInProgress;
  tripsInProgressObserver;
  selectedApproachingTripOption;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;

  onlineDrivers = 0;
  busyDrivers = 0;
  offlineDrivers = 0;

  isInprogressRoutes = false;
  isMarkedReadyTrips = false;
  isMarkedReadyRoutes = false;
  isExpiredTrips = false;
  isExpiredTripRoutes = false;
  isTraffic = false;
  isApproachingTrips = false;
  isAutoZoom = true;
  openPopup = false;
  showCreateTripModal = true;
  showInprogressTripsSlideout = false;
  driverDetailProfileModal;
  isCTCTrips = false;
  public ctcTripsList = [];
  timeZone;
  showCTCTrips;
  ctcTime = 0;

  constructor(
    private dispatchCenterService: DispatchCenterService,
    private assignTripService: AssigTripsService,
    private socketService: SocketService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private offeredTripsService: OfferedtripsService,
    private sharedDataService: SharedDataService

  ) { }

  ngOnInit() {
    this.listenSocketEvents();
  }


  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          // user.showCTCTrips = true;
          this.timeZone = user.timeZone;
          this.showCTCTrips = user.showCTCTrips;
          this.getNotificationsList();
          this.emitSocketEvents();
          this.getTripsInProgress();
        }
      });
  }


  ngAfterViewInit() {
    this.agmElement.mapReady
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((map) => {
        this.agmMap = map;
        this.geoCoder = new google.maps.Geocoder;
        this.getUser();
      });
  }
  onTimerComplete(): void {
    this.timer.init()
    this.emitSocketEvents();
    this.getTripsInProgress();
    this.ctcTime += 1;
    if (this.ctcTime >= 60) { // 5 mins interval for the ctc call 300s / 5s interval = 60
      this.ctcTime = 0;
      this.fetchCtcTrips();
    }
  }


  // Drivers Counts
  setDriverCounts() {
    this.onlineDrivers = 0;
    this.busyDrivers = 0;
    this.offlineDrivers = 0;

    this.drivers.forEach((driver) => {
      if (driver.status === "online") {
        this.onlineDrivers++;
      } else if (driver.status === "buzy") {
        this.busyDrivers++;
      } else if (driver.status === "offline") {
        this.offlineDrivers++;
      }
    });

  }

  getTripsInProgress() {

    // isShowAllTrips: false
    const object = {
      status: "inprogress",
      scheduleTime: moment().tz(this.timeZone).startOf('day').toISOString()
    };

    this.assignTripService
      .getAssignList(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          this.tripsInProgress = data;
        }
        this.timer.start();
      });

  }

  // Map Methods
  setBounds() {
    if (!this.isAutoZoom) return;

    if (this.agmMap) {
      let bounds = new google.maps.LatLngBounds();
      const markedReadyTrips = this.expiringSoonTrips.concat(this.markedTrips);
      console.log('TEST100: Marked Ready', markedReadyTrips);

      for (const driver of this.drivers) {
        bounds.extend(
          new window["google"].maps.LatLng(
            driver.latitude ? driver.latitude : 0,
            driver.longitude ? driver.longitude : 0
          )
        );
      }

      for (const trip of this.ctcTripsList) {
        bounds.extend(
          new window["google"].maps.LatLng(
            trip.PULatitude ? trip.PULatitude : 0,
            trip.PULongitude ? trip.PULongitude : 0
          )
        );
      }

      for (const trip of markedReadyTrips) {
        bounds.extend(
          new window["google"].maps.LatLng(
            trip.jobOriginLatitude,
            trip.jobOriginLongitude
          )
        );
      }

      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
    }
  }

  // Socket Events STARTS




  emitSocketEvents() {
    this.socketService.emit(Events.GET_COMPANY_DRIVER_STATUS);

    // Expired Trips
    if (this.isExpiredTrips) {
      this.socketService.emit(Events.SOON_EXPIRING_TRIPS);
    } else {
      this.expiringSoonTrips = [];
    }

    // Marked Ready Trips
    if (this.isMarkedReadyTrips) {
      this.socketService.emit(Events.GET_MARKED_TRIPS);
    } else {
      this.markedTrips = [];
    }
  }

  listenSocketEvents() {
    this.socketService
      .addListener(Events.GET_COMPANY_DRIVER_STATUS)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          this.drivers = [];
          this.drivers.push(...data.drivers);
          console.log("drivre information", this.drivers)
          this.setDriverCounts();
          this.setBounds();
        } else {
          this.drivers = [];
        }
      });

    this.socketService
      .addListener(Events.SOON_EXPIRING_TRIPS)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          if (this.isExpiredTrips) {
            console.log('TEST100:100:Expiring', data.assigns);
            this.expiringSoonTrips = data.assigns;
            // this.setBounds();
            this.setBounds();
          } else {
            this.expiringSoonTrips = [];
          }
        } else {
          this.expiringSoonTrips = [];
        }
      });

    this.socketService
      .addListener(Events.GET_MARKED_TRIPS)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          if (this.isMarkedReadyTrips) {
            this.markedTrips = data.assigns;
            // this.setBounds();
            this.setBounds()
          } else {
            this.markedTrips = [];
          }
        } else {
          this.markedTrips = [];
        }
      });

    this.socketService
      .addListener(Events.GET_APPROCHING_TRIPS)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          this.approachingTrips = [];
          this.approachingTrips.push(...data.assigns);
        } else {
          this.approachingTrips = [];
        }
      });

    this.socketService
      .addListener(Events.SEND_EXPIRE_DISPATCH)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.sucess) {
          this.getNotificationsList();
        }
      });
  }
  // Socket Events END

  // Utils Methods
  getFloatingValues(point) {
    // console.log(point);
    return parseFloat(point)
  }

  expiringSoonTime(trip) {
    // console.log('Expire trips ');
    const now = moment.utc(new Date()).local().format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  getNameChars(assign) {
    if (assign && assign.priorityClient && assign.priorityClient.displayName) {
      return assign.priorityClient.displayName
        .toLowerCase()
        .trim()
        .split(" ")[0];
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.companyDriverStatus);
    this.socketService.removeListener(Events.GET_APPROCHING_TRIPS);
    this.socketService.removeListener(Events.GET_COMPANY_DRIVER_STATUS);
    this.socketService.removeListener(Events.SEND_EXPIRE_DISPATCH);
    this.socketService.removeListener(Events.GET_MARKED_TRIPS);
    this.socketService.removeListener(Events.SOON_EXPIRING_TRIPS);
  }

  // =====================================
  // Unused (Now) & May be reuse later
  // Modals
  openDriverInfo(driver) {
    this.selectedDriver = driver;
  }

  getDriverAddress() {
    if (this.selectedDriver && this.selectedDriver.latitude && this.selectedDriver.longitude) {
      this.getAddress(this.selectedDriver.latitude, this.selectedDriver.longitude);
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results) {
          this.selectedDriver.address = results[0].formatted_address;
          // console.log('test-0:', this.selectedDriver.address);
        } else {
          sweetAlert('Alert', 'No Address Found', 'warning', 'Ok')
        }
      } else {
        sweetAlert('Alert', `Geocoder failed due to: ${status}`, 'warning', 'Ok')
      }
    });
  }

  closeDriverAddressWindow(evt) {
    this.selectedDriver = null;
  }

  openTripDetailModal(object) {
    this.assignModalObj = object;
  }

  openDriverTripModal(object) {
    this.driverTripModalObject = object;
  }

  tripAssignedResponse(tripStatus) {
    // if (
    //   tripStatus === "Trip Assigned" ||
    //   tripStatus === "Trip Un Assigned" ||
    //   tripStatus === "All Trips Assigned"
    // ) {
    //   // sweetAlert("Success", tripStatus, "success", "OK");
    // } else if (tripStatus !== null) {
    //   sweetAlert("Error", tripStatus, "error", "OK");
    // }
    this.assignModalObj = null;
  }

  onCloseModal(evt) {
    // console.log('Close Assign Modal', evt);
    // if (evt) {
    this.assignModalObj = null;
    // }
  }

  getNotificationsList() {
    this.dispatchCenterService
      .getNotifications()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.notifications = data;
          console.log('TEST100: Notifications', this.notifications);
        }
      });
  }

  onClickProfile(driver) {
    console.log('driver check', { driver })
    this.driverDetailProfileModal = driver;
  }


  toggleTraffic() {
    this.isTraffic = !this.isTraffic;
    if (this.isTraffic) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.agmMap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  toggleCTCTrips() {
    this.isCTCTrips = !this.isCTCTrips;
    this.ctcTime = 0;
    this.fetchCtcTrips();
  }
  fetchCtcTrips() {
    if (this.isCTCTrips && this.ctcTime === 0) {
      this.offeredTripsService
        .getCTCTripsList()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.ctcTripsList = [];
            data.forEach(i=>{
              const tripDate = moment.tz(i.DateOfService, this.timeZone).startOf('day');
              const currentDate = moment.tz(this.timeZone);
              if (tripDate.isSameOrBefore(currentDate.startOf('day'), 'day')) {
                i.class = 'today';
                this.ctcTripsList.push(i);
              } else if (tripDate.isSameOrBefore(currentDate.add(1, 'day').startOf('day'), 'day')) {
                i.class = 'tomorrow';
                this.ctcTripsList.push(i);
              }
            });


          }
        });

    }
  }

  ctcRequest(trip, index) {
    const payload = {
      TriplegId: trip.TripLegId,
    }
    this.offeredTripsService
      .putCTCTripClaim(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (data.message === 'Created') {
            console.log("index check");
            this.ctcTripsList.splice(index, 1);
          }
        }
      });
  }

  claimTable(trip, index) {
    if (!trip.StandingOrderDays) {
      sweetAlert(`<div class= 'text-main-danger'>Warning</div>`, 'If you reroute these trips once you have claimed them, your ability to use this feature may be suspended. Are you sure you want to claim these trips?', 'warning', 'Yes', 'No')
        .then((result: any) => {
          if (result.value) {
            this.ctcRequest(trip, index);
          }
        })
    }
    else {
      sweetAlert(`<div> <div class= 'text-main-danger'> You are claiming a standing order trip. By doing so, you are agreeing to take the standing order on all scheduled days which are: ${trip.sDays} </div> <div class= 'text-main'> Leg A: ${trip.PickupTime} - ${trip.PickupAddress}  Leg B: ${trip.PickupTime} - ${trip.DropoffAddress} </div> </div>`, `If you reroute these trips once you have claimed them, your ability to use this feature may be suspended. Are you sure you want to claim these trips?`, 'warning', 'Yes', 'No')
        .then((result: any) => {
          if (result.value) {
            this.ctcRequest(trip, index);
          }
        })
    }
  }

  ctcFilterByDate() {
  }

  openDriverTripDetailModal(id) {
    this.driverTripModalObj = id;
  }

  closeModal(evt) {
    this.driverTripModalObj = null;
  }


}
