import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspection-wrapper',
  templateUrl: './inspection-wrapper.component.html',
  styleUrls: ['./inspection-wrapper.component.css']
})
export class InspectionWrapperComponent implements OnInit {
  isInspectionDot = false;
  subMenu = 'dot';
  constructor() { }

  ngOnInit() {
  }

  onSelectSubMenu(menu) {
    this.subMenu = menu;
    if (this.subMenu === 'daily') {
      this.isInspectionDot = false;
    }
  }

}
