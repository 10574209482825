import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment-timezone';
import { getBillingBrokers, getBrokersList } from '../../../utils/utils.common';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { sweetAlert } from 'src/app/utils/swal';
import { DispatchCenterService } from '../../dispatch-center/dispatch-center-service/dispatch-center-service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { urls } from 'src/app/utils/url-utils';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { FleetService } from '../../fleet/fleet-service/fleet.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-adjustment-trip',
  templateUrl: './adjustment-trip.component.html',
  styleUrls: ['./adjustment-trip.component.css']
})
export class AdjustmentTripComponent implements OnInit, OnDestroy {
  search: any;
  todayDate;
  timeZone;
  user;
  lableList={};
  dataList;
  brokers;
  corporateUsers;
  driverList = [];
  selectedTrips = [];
  isShowFilter = false;
  selectedDriver = '';
  selectedMember = '';
  selectedCorporateUser = '';
  isMedical = false;
  selectedBroker = '';
  jobType = 'assigned';
  drivers = [];
  members = [];
  dateRange;
  status;
  tempCompanyImage = 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png';
  companyImage = this.tempCompanyImage;
  tempVin;
  projectedOdometer = '';
  driverFleet;


  constructor( private fleetService: FleetService, private toastr: ToastrService, private dispatchCenterService: DispatchCenterService, private sharedDataService: SharedDataService, private assignTripService: AssigTripsService, private driverService: DriversService) { }

  ngOnInit() {
    this.status = 'adjustedtriplogs';
    this.brokers = getBillingBrokers;
    this.getUser();

  }

  getCorporateUsers() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data;
        }
      });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.dateRange = [
            moment.tz(this.timeZone).startOf('day').toISOString(),
            moment.tz(this.timeZone).endOf('day').toISOString()
          ];
          // this.getDriverList();
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }


  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        // sweetAlert('Error', err, 'error', 'OK');
      });
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.getAdjustedTrips();
  }

  onDateRangeChange(event) {
    if (event && event.length) {
      this.dateRange = [
        event[0].toISOString(),
        event[1].endOf("day").toISOString()
      ];
    }
    this.getAdjustedTrips();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  getAdjustedTrips() {
    let queryParams = {
      status: this.status,
      scheduleTime: this.todayDate,
      jobType: this.jobType,
    };

    if (this.selectedBroker) {
      queryParams['companyType'] = this.selectedBroker;
    }

    if (this.isMedical) {
      queryParams['isMedical'] = this.isMedical;
    }

    if (this.selectedCorporateUser) {
      queryParams['cooperate'] = this.selectedCorporateUser;
    }


    if (this.jobType === 'cooperate' && (this.dateRange && this.dateRange.length)) {
      queryParams['scheduleTime'] = this.dateRange[0];
      queryParams['endDate'] = this.dateRange[1];
    }
    this.members = [];
    this.drivers = [];
    this.selectedTrips = [];
    this.dataList = [];
    this.lableList={};
    this.assignTripService
      .getAdjustedTrips(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {

          data.forEach((trip, i) => {
            const isFound = this.drivers.some(driver => driver._id === trip.driver._id)
            if (!isFound) {
              this.drivers.push(trip.driver);
            }
            this.members.push(trip.priorityClient);
            this.lableList[trip._id] = trip
            this.dataList.push(trip);

          });

        }
      });

  }


  setEscort(event, assign) {
    if (event.target.value === 'Helper') {
      assign.job.escortName = 'Helper';
      assign.job.escortRelation = 'Support';
    } else {
      assign.job.escortName = '';
      assign.job.escortRelation = '';
    }
    this.updateTrip(assign);
  }

  fetchCompletedTrips() {
    sweetAlert('Alert', 'Are you sure you want to proceed with pulling the trips? This action will overwrite the existing or adjusted trips with new data.', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.assignTripService
            .getCompletedTrips({ scheduleTime: this.todayDate })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.getAdjustedTrips();
              }
            });
        }
      })
  }

  patchCreatedTime(event, assign) {
    assign.job.created = event.toISOString();
    this.updateTrip(assign);
  }

  patchArrivedTime(event, assign) {
    assign.job.trackInfoJob.jobArrived.time = event.toISOString();
    this.updateTrip(assign);
  }

  patchStartedTime(event, assign) {
    assign.job.trackInfoJob.jobStarted.time = event.toISOString();
    this.updateTrip(assign);
  }

  patchFinishedTime(event, assign) {
    assign.job.trackInfoJob.jobCompleted.time = event.toISOString();
    assign.job.trackInfoJob.jobStarted.time = moment(assign.job.trackInfoJob.jobCompleted.time).subtract((assign.milage || 0) * 2, 'minutes').toISOString()
    assign.job.trackInfoJob.jobArrived.time = moment(assign.job.trackInfoJob.jobStarted.time).subtract(5, 'minutes').toISOString();
    assign.job.created = moment(assign.job.trackInfoJob.jobArrived.time).subtract(5, 'minutes').toISOString();
    this.updateTrip(assign);
  }


  updateTrip(assign) {
    assign.job.trackInfoJob.jobFinished = assign.job.trackInfoJob.jobCompleted;
    assign.job.driver = assign.driver._id;
    this.dispatchCenterService
      .saveTripAdjustment(assign.job._id, assign.job, true)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.getAdjustedTrips();
          this.toastr.success('Trip updated successfully!', 'Alert');
        }
      });

  }


  selectTrips(event, assign) {
    if (event.target.checked) {
      assign.isSelected = true;
      this.selectedTrips.push(assign._id);
    } else {
      assign.isSelected = false;
      _.filter(this.selectedTrips, (id, i) => {
        if (id === assign._id) {
          this.selectedTrips.splice(i, 1);
        }
        return id;
      });
    }
  }

  onValueChange(assign) {
    this.updateTrip(assign);
  }

  showFilter() {
    this.isShowFilter = !this.isShowFilter
  }


  onSelectBroker() {
    this.jobType = 'assigned';
    this.selectedCorporateUser = '';
    this.selectedDriver = '';

    if (this.selectedBroker) {
      const broker = this.brokers.find(b => b.name === this.selectedBroker);
      this.companyImage = broker ? broker.logoUrl : '';
    } else {
      this.companyImage = this.tempCompanyImage;
    }

    this.getAdjustedTrips();
  }

  onSelectCorporateUser() {
    if (this.selectedCorporateUser) {
      this.jobType = 'cooperate';
      this.isMedical = false;
      this.selectedBroker = '';
      const cUser = this.corporateUsers.find(user => user._id === this.selectedCorporateUser);
      if (cUser) {
        this.companyImage = cUser.profileImageURL;
      }
    } else {
      this.selectedCorporateUser = '';
      this.jobType = 'assigned';
      this.companyImage = this.tempCompanyImage;
    }
    this.getAdjustedTrips();
  }



  selectAll() {
    if (this.selectedTrips.length === this.dataList.length) {
      this.clearSelection();
    } else {
      this.selectedTrips = [];
      this.dataList.forEach((assign) => {
        assign.isSelected = true;
        this.selectedTrips.push(assign._id);
      });
    }
  }


  clearSelection() {
    this.selectedTrips = [];
    this.dataList.forEach(trip => {
      trip.isSelected = false;
    });
  }

  onChangeMedicalType(evt) {
    this.isMedical = evt.target.checked;
    if (!this.isMedical) {
      this.selectedBroker = '';
      this.companyImage = this.user.profileImageURL;
    }
    this.getAdjustedTrips();
  }

  billedtomodivcare() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .billedToModivcare(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.dataList.forEach(assign => {
            const assignIndex = this.selectedTrips.indexOf(assign._id.toString());
            if (assignIndex > -1) {
              assign.bankStatus = 'banked';
              assign.isSelected = false;
              this.selectedTrips.splice(assignIndex, 1);
            }
          });
        }
      });

  }

  callthecarInvoice() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }
    this.assignTripService
      .donwloadCallTheCarInvoice(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifest() {
    // United (Southeastrans)
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadSoutheastransManifest(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifestLogistic() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadLogisticareManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifestLcp() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadLcpManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });

  }

  manifestAmera() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadAmeraManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  corporateInvoiceReport() {
    let payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    if (this.dateRange && this.dateRange.length) {
      payload['startDate'] = this.dateRange[0];
      payload['endDate'] = this.dateRange[1];
    }

    this.assignTripService
      .donwloadCorporateInvoiceReport(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  corporateInvoice() {
    // Corporate TRF
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadCorporateInvoice(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });


  }

  nmnDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadMedtransDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmTRF() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadMtmTrf(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmClaim() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadMtmClaim(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmAllDriverLogs() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .donwloadAllDriverLog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  vapremiumDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .vapremiumDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  access2CareDriverLog() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .access2CareDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  veyoDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .veyoDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  tripsLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .tripsLog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  logistiCareTriplogs() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .logistiCareTriplogs(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });

  }

  signatureUpdate(isSignature) {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage,
      isSignature: isSignature
    }

    this.assignTripService
      .signatureUpdate(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Signature Updated Successfully', 'success', 'OK')
          this.getAdjustedTrips();
        }
      });
  }

  bankedTrips() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage,
      bankStatus: 'banked'
    }

    this.assignTripService
      .bankedTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Banked the Trip Successfully', 'success', 'OK')
          this.getAdjustedTrips();
        }
      });

  }

  claimFile(isPuDo) {
    const payload = {
      isPuDo: isPuDo,
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .claimFile(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });

  }

  cameraTripsLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .cameraTripslog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  corporateAeroInvoice() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .corporateAeroInvoice(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });

  }

  rnsCorporateInvoice1Report() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage
    }

    this.assignTripService
      .rnsCorporateInvoice1Report(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }


  onSelectDriver() {
    let trip;
    this.clearSelection();
    if (this.selectedDriver) {

      const checkDriverFleet = this.dataList
        .filter(trip => trip.driver._id === this.selectedDriver);

      if (checkDriverFleet.length) {
        trip = checkDriverFleet[0];
      }

      this.driverFleet = null;
      if (trip && trip.job && trip.job.fleet) {
        const fleetId = trip.job.fleet;
        this.projectedOdometer = null;
        this.fleetService
          .getFleetDataById(fleetId)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe(data => {
            if (data) {
              this.driverFleet = data;
              this.projectedOdometer = this.driverFleet[this.selectedBroker];
            }
          });
      }
    }
  }

  adjustOdometer() {
    const payload = {
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage,
      driver: this.selectedDriver,
      fleet: this.driverFleet._id,
      projectedOdometer: this.projectedOdometer,
      companyType: this.selectedBroker
    }
    this.assignTripService
      .adjustTripOdometer(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

  nextOdometer() {
    const payload = {
      _ids: this.selectedTrips,
      scheduleTime: this.todayDate,
      companyImage: this.companyImage,
      driver: this.selectedDriver,
      fleet: this.driverFleet._id,
      projectedOdometer: this.projectedOdometer,
      companyType: this.selectedBroker
    }

    this.assignTripService
      .adjustAllTripsOdometer(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

ngOnDestroy(): void {
}
}
