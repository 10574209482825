import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { getBrokersList } from '../../../utils/utils.common';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.css']
})
export class ManifestComponent implements OnInit, OnDestroy {
  @ViewChild('timer', { static: false }) timer;
  duration = 10 * 1000; // 10 seconds
  filterdataList = [];
  todayDate;
  isShowAllTrips = false;
  search: any;
  timeZone;
  user;
  completedTripModalObj;
  tripDetailModalObj;
  skip = 0;
  editTripModalObj;
  partialEditModalObj;
  clientId;
  vehicles;
  drivers;
  selectedDriver = [];
  driverListMain = [];
  filteredDrivers = [];
  isShowStatusDropdown = false;
  tripStatuses = [
    { label: 'Unassigned', status: 'pending', isChecked: true },
    { label: 'Assigned', status: 'hanging', isChecked: true },
    { label: 'En Route', status: 'onway', isChecked: true },
    { label: 'Arrived', status: 'arrived', isChecked: true },
    { label: 'In Progress', status: 'started', isChecked: true },
    { label: 'Completed', status: 'finished', isChecked: false },
    { label: 'Cancelled', status: 'cancelled', isChecked: false },
  ];
  selectedStatus: any = "active";
  driverList = [];
  corporateUsers = [];
  trip;
  payors = {
    medicaid: "Medicaid",
    medicare: "Medicare",
    medical: "MediCal",
    optum: "Optum",
    privatePay: "Private Pay",
    billFacility: "Bill Facility",
    tricare: "Tricare",
    champva: "CHAMPVA",
    federalemployees: "Federal Employees",
    fecaBlackLung: "FECA Black Lung",
    other: "Other"
  };
  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,
    private driverService: DriversService,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getDriverList();
          this.getCorporateUserList();
          this.getTrips();
        }
      });
  }

  getDriverList() {
    this.driverService.getDrivers(this.user._id, this.selectedStatus)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.driverListMain = data;
          this.driverList = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      },
    );
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }

  onChangeStatus() {
    this.getTrips();
  }

  toggleStatusDropdown() {
    this.isShowStatusDropdown = !this.isShowStatusDropdown
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.getTrips();
  }

  onTimerComplete(): void {
    this.timer.init();
    this.getTrips();
  }

  getTrips() {
    // this.filterdataList = [];
    // this.dataList = [];
    let jobStatuses = [];
    this.tripStatuses.forEach(t => {
      if (t.isChecked) {
        if (t.status === 'hanging') {
          jobStatuses.push('offered');
          jobStatuses.push('accepted');
        } else {
          jobStatuses.push(t.status);
        }
      }
    })
    const object = {
      isShowAllTrips: this.isShowAllTrips,
      status: 'manifest',
      date: this.todayDate,
      skip: this.skip,
      jobStatuses
    };
    // this.store.dispatch(new GetUnassignedList(object));
    this.assignTripService.getAssignManifestList(object)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.filterdataList = [];
        let drivers = [];
        if (data) {
          this.filterdataList = data;

          this.filterdataList.forEach(trip => {
            if (trip.driver) {
              drivers.push({ _id: trip.driver });
            }
          });

          drivers = _.uniq(drivers, '_id').sort();
          if(drivers && drivers.length){
            this.filteredDrivers = this.driverListMain.filter(driver => drivers.some(d => d._id === driver._id));
          }
          // this.dataList = [...data, ...this.dataList];
          // this.filterdataList = this.dataList;

          // if (data.length) {
          //   this.skip += data.length;
          //   this.getTrips();
          // } else {
          //   this.skip = 0;
          //   this.timer.init();
          //   this.timer.start();
          // }
        }
        this.timer.start();
      }, err => {
        this.timer.start();
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  // tripAction(trip) {
  //   if (trip.status === 'finished') {
  //     this.viewCompletedTripDetails(trip);
  //   } else if (['unassigned', 'offered', 'accepted'].includes(trip.status) || (trip.status === 'cancelled' && !trip.job)) {
  //     this.editTrip(trip);
  //   }
  // }

  editTrip(assign) {
    this.editTripModalObj = assign._id;
    this.trip = assign;
  }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex(t => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);

      if (moment(event.appointmentTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].appointmentTime).format("YYYY-MM-DD") || moment(event.scheduleTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].scheduleTime).format("YYYY-MM-DD")) {
        // this.clearTrips();
        // this.getTrips();
        this.filterdataList.splice(idx, 1);
      } else {
        this.filterdataList[idx] = event;
      }
      if (event.status === 'archived') {
        this.filterdataList.splice(idx, 1);
      }

      if (event.jobStatus === 'cancelled' || event.status === 'cancelled') {
        const isCancelledSelected = this.tripStatuses.find(ts => ts.status === 'cancelled' && ts.isChecked);
        if (!isCancelledSelected) {
          this.filterdataList.splice(idx, 1);
        }
      }
      this.editTripModalObj = null;
    }
  }

  viewCompletedTripDetails(trip) {
    this.clientId = trip.priorityClient.clientId;
    this.completedTripModalObj = trip;
  }

  getDriverByID(id) {
    return this.driverList[id];
  }

  getCorporateByID(id) {
    return this.corporateUsers[id];
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  viewTripDetails(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.driver = this.getDriverByID(trip.driver);
    this.tripDetailModalObj = obj;
  }

  updateTripData(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find((x) => x._id === evt._id);
      if (selectedTrip) {
        selectedTrip.attachment = evt.attachment;
        selectedTrip.companyNote = evt.companyNote;
        selectedTrip.dispatchNoteTwo = evt.dispatchNoteTwo;
      }
    }
  }

  setPartialEditModal(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.cooperate = this.getCorporateByID(trip.cooperate);
    this.partialEditModalObj = obj;
  }

  ngOnDestroy(): void {
  }
}
