import { Component, OnInit, OnDestroy } from '@angular/core';
// import { selectAccidentReportState, AccidentReportState } from '../../../ngrx/app.states';
// import { AccidentReportActionTypes, GetAccidentReport } from '../../../ngrx/store/actions/accident-report.actions';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';
import { AccidentalReportService } from '../accidental-service/accidental-report.service';
import { downloadBase64File, downloadFile } from 'src/app/utils/pdf-downloader';
import { urls } from 'src/app/utils/url-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { data } from 'jquery';
@Component({
  selector: 'app-accidental-report-list',
  templateUrl: './accidental-report-list.component.html',
  styleUrls: ['./accidental-report-list.component.css']
})
export class AccidentalReportListComponent implements OnInit, OnDestroy {
  accidentReportList = [];
  search;
  user: any;
  selectedReport;
  loader = {
    isloading: false
  }
  constructor(
    private sharedDataService: SharedDataService,
    private accidentReportService: AccidentalReportService

  ) { }


  ngOnInit() {
    this.getAccidentalReports();
    this.getUser()
  }

  getUser() {
    this.sharedDataService.getUser().subscribe(data => {
      if (data) {
        this.user = data;
      }
    })
  }

  getAccidentalReports() {
    this.accidentReportService.getAccidentReport()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.accidentReportList = data;
        } else if (data.errorMessage) {
          sweetAlert('Error', data.errorMessage, 'error', 'OK');
        }
      });
  }

  getLogistiCareReport(reportId) {
    const payload = {
      reportId,
      reportType: 'logisticare'
    }

    this.accidentReportService
      .getLogisticarePdfReport(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + 'pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  getNemtReport(reportId) {
    const payload = {
      reportId,
      reportType: 'nemt'
    }
    this.accidentReportService
      .getNemtPdfReport(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + 'pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }
  getAccidentReport(id) {
    this.selectedReport = id;
    this.loader.isloading = true;
    this.accidentReportService.getAccidentReportById(id).subscribe(data => {
      if (data) {
        this.accidentReportService.getAccidentReportPDF(data, this.user, this.loader);
      }
    });

  }

  ngOnDestroy(): void {
  }

}

