import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { DispatchCenterService } from 'src/app/component/dispatch-center/dispatch-center-service/dispatch-center-service';

@Component({
  selector: 'app-notifications-slideout',
  templateUrl: './notifications-slideout.component.html',
  styleUrls: ['./notifications-slideout.component.css']
})
export class NotificationsSlideoutComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<any>();

  notifications = [];

  constructor(
    private dispatchCenterService: DispatchCenterService
  ) { }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.dispatchCenterService
      .getNotifications()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.notifications = data;
        }
      });
  }

  ngOnDestroy(): void {
  }
}
