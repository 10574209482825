import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuarterService } from '../../service/quarter.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-quarter-list',
  templateUrl: './quarter-list.component.html',
  styleUrls: ['./quarter-list.component.css']
})
export class QuarterListComponent implements OnInit, OnDestroy {

  search;
  status = "active";
  quarterList = [];

  constructor(private quarterService: QuarterService) { }

  ngOnInit() {
    this.quarterListData()
  }


  quarterListData() {
    this.quarterService.getQuarterList()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.quarterList = data;
         }
      },);
  }




  onChangeStatus() {
    // this.quarterListData()
  }


  ngOnDestroy(): void {
  }

}

