import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { userManuals } from '../helping-material.util';
import { Router } from '@angular/router'
declare var $;
@Component({
  selector: 'app-helping-material-list',
  templateUrl: './helping-material-list.component.html',
  styleUrls: ['./helping-material-list.component.css']
})
export class HelpingMaterialListComponent implements OnInit {

  pdfUrl = null;
  ind = 0;
  userManuals;
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.userManuals = userManuals;
  }
  openPdfPopup(ind) {
    // this.router.navigate(['/helpingmaterial/pdf'], { state: { url: this.userManuals[ind].url } });
    if (this.userManuals[ind].url) {
      window.open('/helpingmaterial/pdf/'+ind, '_blank');
    } else {
      console.log('no preview available')
    }
    // console.log(url)
    // console.log($('#pdfModal'))

    // $('#pdfModal').removeData();
    // console.log($('#pdfModal'))
    // this.pdfUrl = null;
    // if (this.pdfUrl === null) {
    //   console.log('helo')
    //   this.set(ind)
    // }
  }
  set(ind) {
    this.pdfUrl = this.userManuals[ind].url;

  }

  getActivePdf() {
    console.log("Active ", this.pdfUrl)
    return this.pdfUrl;
    // return this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
  }

}
