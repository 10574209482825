import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class AdminkpisService {

  constructor(private http: HttpClient) { }

  getCompanyReportPdf(queryString, payload): Observable<any> {
    let qString = '';
    queryString.forEach((companyId) => {
      if(qString === ''){
      qString = qString +  'company=' + companyId
      }
     else {
     qString = qString +  '&company=' + companyId
    }
    });
    console.log(qString, "company");
    return this.http.post<any>(urls.BASE_URL + urls.ADMIN_COMPANY_REPORTS_URL + '?' + qString, payload)
    .pipe(
      catchError(errorHandl)
    );
  }

  getCompanyStatsByDate(startDate, endDate): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.ADMIN_NEMT_REPORTS_BY_DATE_URL + '?endDate=' + endDate + '&startDate=' + startDate)
    .pipe(
      catchError(errorHandl)
    );
  }

  getComapnyReports(data){
    return this.http.put<any>(urls.BASE_URL + urls.GET_ADMIN_EARNING_DETAILS_URL, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCompanyPdfReport(){
    // let params = new HttpParams();
    // params = params.append('check', data.mailCheck);
    // params = params.append('endDate', data.endDate);
    // params = params.append('startDate', data.startDate);

    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_ADMIN_EARNING_DETAILS_URL)
      .pipe(
        catchError(errorHandl)
      );
  }
  getCompaniesPdfReport(payload){
    // let params = new HttpParams();
    // params = params.append('check', data.mailCheck);
    // params = params.append('endDate', data.endDate);
    // params = params.append('startDate', data.startDate);
    console.log(payload);

    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_ADMIN_EARNING_DETAILS_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }
}

