import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

// Error handling
export function errorHandl(error: HttpErrorResponse) {
    let errorMessage = '';
    // console.log(error.instanceof());
    if (error.error instanceof ErrorEvent) {

        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

        if (error.error) {
            console.log("if error", error)
            if(error.error.message) {
                errorMessage = error.error.message;
            }
        }
    }
    return throwError(errorMessage);
}

// setting form data
export function setFormData(imgFiles, staffData, dataRef) {
    const formData = new FormData();
    if(imgFiles){
    Object.keys(imgFiles).forEach(key => {
            if (imgFiles[key] !== null) {
                formData.append(key, imgFiles[key], imgFiles[key].name);
            }
        });
    }
    if(staffData)
    Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
    return formData;
}

// Data Ref. like staff[name] wherein staff is data ref.
export function setFormDataV2(data, dataRef?) {
    const formData: FormData = new FormData();
    Object.keys(data).forEach((key) => {
        const name = dataRef ? `${dataRef}[${key}]` : key;
        const value = data[key];
        if (value instanceof File) {
            formData.append(key, value, value.name);
        } else if (value instanceof Array) {
            if (value.length) {
                value.forEach((v,index) => {
                    if (v instanceof Object) {
                        Object.keys(v).forEach(key => {
                            formData.append(`${name}[${index}][${key}]`, v[key]);
                        });
                    } else {
                        formData.append(name, v);
                    }
                });
            } else {
                formData.append(`${name}[]`, '');
            }
        } else if (value instanceof Object) {
            Object.keys(value).forEach(key1 => {
                formData.append(key + '[' + key1+']', value[key1]);
            });
        }
        else {
            formData.append(name, value);
        }
    });
    return formData;
}


export function setOnlyFieldFormData( staffData, dataRef) {
    const formData = new FormData();
    Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
    return formData;
}
