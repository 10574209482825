import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DispatchCenterService } from '../../dispatch-center/dispatch-center-service/dispatch-center-service';
import { urls } from 'src/app/utils/url-utils';
import { FormBuilder, Validators } from '@angular/forms';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-adjusted-trip-odomter-modal',
  templateUrl: './adjusted-trip-odomter-modal.component.html',
  styleUrls: ['./adjusted-trip-odomter-modal.component.css']
})
export class AdjustedTripOdomterModalComponent implements OnInit, OnDestroy {
  @Input() trip;
  @Output() saveOdometer = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  odometerForm;
  isSubmitted = false;
  
  constructor(
    private dispatchCenterService: DispatchCenterService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.odometerForm = this.fb.group({
      odometerBegin: ['', Validators.required],
      odometerStart: ['', Validators.required],
      odometerStop: ['', Validators.required],
    });

    this.odometerForm.patchValue({
      odometerBegin: this.trip.job.odometerBegin,
      odometerStart: this.trip.job.odometerStart,
      odometerStop: this.trip.job.odometerStop
    });
  }

  save() {
    this.isSubmitted = true
    if (this.odometerForm.invalid) {
      return;
    }

    const object = {
      _id: this.trip.job._id,
      odometerBegin: this.odometerForm.value.odometerBegin,
      odometerStart: this.odometerForm.value.odometerStart,
      odometerStop: this.odometerForm.value.odometerStop
    };
    
    this.dispatchCenterService
      .saveOdometer(urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL + this.trip.job._id, object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.saveOdometer.emit(data);
        }
      });
  }

  get form() {
    return this.odometerForm.controls;
  }
  
  ngOnDestroy(): void {
  }
}
