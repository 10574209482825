import { Component, OnInit, Input, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { sweetAlert } from 'src/app/utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
declare var $;

@Component({
  selector: 'app-create-new-trip',
  templateUrl: './create-new-trip.component.html',
  styleUrls: ['./create-new-trip.component.css']
})
export class CreateNewTripComponent implements OnInit, OnDestroy {
  @Input() tripDetail;
  @Input() driverDetail;
  createNewTripForm: FormGroup;
  submitted: boolean = false;
  carType = 'Standard';
  noOfPassengers: number;
  noOfPassengersSelected: number;
  todayDate;
  timeZone;
  _id: any;
  user;
  estimatedFare;

  constructor(
    private authService: AuthService,
    private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getUser();
    this.todayDate = moment().tz(this.timeZone).format();
    console.log(' this.todayDate', this.todayDate);
    if (this.activatedRoute.snapshot.url.length > 1) {
      $('#createNewTripModal').modal('show');
      // $('.modal-backdrop').addClass("blue");
    } else {
      this.tripDetail = null;
    }

    this.noOfPassengers = 4;
    // this.selectedTime = 'Now';
    this.createNewTripForm = this.fb.group({
      //company: this.authService.getUserData()._id,
      company: this.user._id,
      priorityClient: this.fb.group({
        displayName: ['', [Validators.required]],
        contactNumber: ['', [Validators.required]],
        cellPhone: ['']
      }),
      companyNote: [''],
      aptTime: [this.todayDate, [Validators.required]],
      pupTime: [this.todayDate, [Validators.required]],
      appointmentPickupHour: ['', Validators.required],
      appointmentPickupMinutes: ['', Validators.required],
      PickupHour: ['', Validators.required],
      PickupMinutes: ['', Validators.required],
      appointmentTime: [this.todayDate],
      scheduleTime: [this.todayDate],
      isSchedule: [false, Validators.required],
      jobOriginAddress: ['', [Validators.required]],
      jobOriginLatitude: [0.00],
      jobOriginLongitude: [0.00],
      jobDestinationAddress: ['', [Validators.required]],
      jobDestinationLatitude: [0.00],
      jobDestinationLongitude: [0.00],
      passengerNumber: [1, Validators.required],
      jobCarType: ['Standard', Validators.required],
      customerSpecialRate: ['']
    });

    if (this.tripDetail) {
      this.createNewTripForm.patchValue({
        ...this.tripDetail
      })

      this._id = this.tripDetail._id;

      if (this.tripDetail.appointmentTime) {
        // getting utc time from backend, it will convert the utc time to company timezone
        this.tripDetail.appointmentTime = moment.utc(this.tripDetail.appointmentTime).tz(this.timeZone).format();
        if (parseInt(this.tripDetail.appointmentTime.split('T')[1].substr(3, 2)) % 10 !== 0) {
          let min = parseInt(this.tripDetail.appointmentTime.split('T')[1].substr(3, 2)) + (10 - parseInt(this.tripDetail.appointmentTime.split('T')[1].substr(3, 2)) % 10)
          this.createNewTripForm.patchValue({
            appointmentPickupHour: this.tripDetail.appointmentTime.split('T')[1].substr(0, 2),
            appointmentPickupMinutes: min.toString(),
          })
        } else {
          this.createNewTripForm.patchValue({
            appointmentPickupHour: this.tripDetail.appointmentTime.split('T')[1].substr(0, 2),
            appointmentPickupMinutes: this.tripDetail.appointmentTime.split('T')[1].substr(3, 2),
          })
        }
      } else {
        this.createNewTripForm.patchValue({
          appointmentPickupHour: '05',
          appointmentPickupMinutes: '00'
        })
      }

      if (this.tripDetail.scheduleTime) {
        // getting utc time from backend, it will convert the utc time to company timezone
        this.tripDetail.scheduleTime = moment.utc(this.tripDetail.scheduleTime).tz(this.timeZone).format();
        if (parseInt(this.tripDetail.scheduleTime.split('T')[1].substr(3, 2)) % 10 !== 0) {
          let min = parseInt(this.tripDetail.scheduleTime.split('T')[1].substr(3, 2)) + (10 - parseInt(this.tripDetail.scheduleTime.split('T')[1].substr(3, 2)) % 10)

          this.createNewTripForm.patchValue({
            PickupHour: this.tripDetail.scheduleTime.split('T')[1].substr(0, 2),
            PickupMinutes: min.toString(),
          })
        } else {
          this.createNewTripForm.patchValue({
            PickupHour: this.tripDetail.scheduleTime.split('T')[1].substr(0, 2),
            PickupMinutes: this.tripDetail.scheduleTime.split('T')[1].substr(3, 2),
          })
        }
      } else {
        this.createNewTripForm.patchValue({
          PickupHour: '05',
          PickupMinutes: '00'
        })
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    if (this.driverDetail && this.driverDetail.driverCar) {
      this.selectedVehicle(this.driverDetail.driverCar);
    } else if (!this.driverDetail) {
      this.selectedVehicle(this.carType);
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      })
  }

  selectedVehicle(vehicle?: string): void {
    if (this.createNewTripForm) {
      this.createNewTripForm.patchValue({
        // jobCarType: vehicle,
        passengerNumber: 1
      });
      if (this.createNewTripForm.value.jobCarType === 'SUV') {
        this.noOfPassengers = 7;
      } else {
        this.noOfPassengers = 4;
      }
    }
  }

  passengerSelected(passengers: number): void {
    this.createNewTripForm.patchValue({
      passengerNumber: passengers
    });
  }

  selectTime(): void {
    // this.selectedTime = time;
    this.createNewTripForm.patchValue({
      isSchedule: !this.createNewTripForm.value.isSchedule
    });
    console.log(this.createNewTripForm.value.isSchedule)

  }

  onCreateNewTrip() {
    this.submitted = true;
    if (!this.createNewTripForm.value.isSchedule) {
      this.createNewTripForm.patchValue({
        scheduleTime: this.createNewTripForm.value.appointmentTime,
        PickupHour: this.createNewTripForm.value.appointmentPickupHour,
        PickupMinutes: this.createNewTripForm.value.appointmentPickupMinutes
      })
    }

    if (this.createNewTripForm.invalid) {
      return;
    }

    const keys = ['aptTime', 'pupTime', 'appointmentPickupHour', 'appointmentPickupMinutes', 'PickupHour', 'PickupMinutes'];
    this.removeExtraKeys(keys);
    this.setUpSocketListener();


    if (this.tripDetail) {
      const object = {
        ...this.createNewTripForm.value,
        _id: this._id
      }
      this.socketService.emit(Events.RE_DISPATCH_TRIP_CALL, object);
    } else {

      let object;
      if (this.driverDetail) {
        object = {
          ...this.createNewTripForm.value,
          selectDriver: this.driverDetail._id
        }
      } else {
        object = {
          ...this.createNewTripForm.value
        }
      }
      this.socketService.emit(Events.SEND_DISPATCH_TRIP_CALL, object);
    }
  }

  removeExtraKeys(keys) {
    keys.forEach(key => {
      delete this.createNewTripForm.value[key];
    });
  }

  onPickUpTimeChange(event) {
    if (event) {
      if (this.createNewTripForm.value.scheduleTime && this.createNewTripForm.value.PickupHour && this.createNewTripForm.value.PickupMinutes) {
        let scheduleTime = `${this.createNewTripForm.value.scheduleTime.split('T')[0]}T${this.createNewTripForm.value.PickupHour}:${this.createNewTripForm.value.PickupMinutes}:00`;
        this.createNewTripForm.patchValue({
          scheduleTime: moment.tz(scheduleTime, this.timeZone).toISOString()
        });
      }
    }
  }

  onPickUpDateChange(event) {
    if (event) {
      const date = moment.utc(event).local().format();
      this.createNewTripForm.patchValue({
        scheduleTime: date
      })

      if (this.createNewTripForm.value.scheduleTime && this.createNewTripForm.value.PickupHour && this.createNewTripForm.value.PickupMinutes) {
        let scheduleTime = `${this.createNewTripForm.value.scheduleTime.split('T')[0]}T${this.createNewTripForm.value.PickupHour}:${this.createNewTripForm.value.PickupMinutes}:00`;
        this.createNewTripForm.patchValue({
          scheduleTime: moment.tz(scheduleTime, this.timeZone).toISOString(),
        });
      }
    }
  }

  onAppointmentTimeChange(event) {
    if (event) {
      if (this.createNewTripForm.value.appointmentTime && this.createNewTripForm.value.appointmentPickupHour && this.createNewTripForm.value.appointmentPickupMinutes) {
        let appointmentTime = `${this.createNewTripForm.value.appointmentTime.split('T')[0]}T${this.createNewTripForm.value.appointmentPickupHour}:${this.createNewTripForm.value.appointmentPickupMinutes}:00`;
        this.createNewTripForm.patchValue({
          appointmentTime: moment.tz(appointmentTime, this.timeZone).toISOString(),
        });
      }
    }

  }

  onAppointmentDateChange(event) {
    if (event) {
      const date = moment.utc(event).local().format()
      this.createNewTripForm.patchValue({
        appointmentTime: date
      })

      if (this.createNewTripForm.value.appointmentTime && this.createNewTripForm.value.appointmentPickupHour && this.createNewTripForm.value.appointmentPickupMinutes) {
        let appointmentTime = `${this.createNewTripForm.value.appointmentTime.split('T')[0]}T${this.createNewTripForm.value.appointmentPickupHour}:${this.createNewTripForm.value.appointmentPickupMinutes}:00`;
        this.createNewTripForm.patchValue({
          appointmentTime: moment.tz(appointmentTime, this.timeZone).toISOString(),
        });
      }
    }
  }

  getPickupaddress(place) {
    this.createNewTripForm.patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng()
    });

  }

  getDropOffaddress(place) {
    this.createNewTripForm.patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng()
    });

  }

  setUpSocketListener() {
    this.socketService.addListener(Events.SEND_DISPATCH_TRIP_CALL)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          this.socketService.removeListener(Events.SEND_DISPATCH_TRIP_CALL);
          this.afterDispatchReponse();
        } else {
          sweetAlert('Error', data.message, 'error', 'OK')
            .then(() => {
              this.socketService.removeListener(Events.SEND_DISPATCH_TRIP_CALL);
              this.afterDispatchReponse();
            })
        }
      });
  }

  afterDispatchReponse() {
    console.log('TEST100: Response');
    this.submitted = false;
    // aptTime: moment.tz(new Date(new Date().setHours(0, 0, 0, 0)), this.timeZone),
    // pupTime: moment.tz(new Date(new Date().setHours(0, 0, 0, 0)), this.timeZone),
    if (!this.tripDetail) {
      this.createNewTripForm.reset();
      this.createNewTripForm.patchValue({
        company: this.user._id,
        aptTime: this.todayDate,
        pupTime: this.todayDate,
        appointmentTime: this.todayDate,
        scheduleTime: this.todayDate,
        passengerNumber: 1,
        jobCarType: 'Standard'
      })
    }
    $('#createNewTripModal .close').click();
    this.router.navigateByUrl('/')
  }

  resetForm() {
    this.submitted = false;
    this.createNewTripForm.reset();
    this.createNewTripForm.patchValue({
      company: this.user._id,
      aptTime: this.todayDate,
      pupTime: this.todayDate,
      appointmentTime: this.todayDate,
      scheduleTime: this.todayDate,
      passengerNumber: 1,
      jobCarType: 'Standard'
    })
  }

  calculateEstimatedFare() {
    this.estimatedFareListener();
    const payload =
    {
      company: this.user._id,
      jobCarType: this.createNewTripForm.value.jobCarType,
      jobDestinationLatitude: this.createNewTripForm.value.jobDestinationLatitude,
      jobDestinationLongitude: this.createNewTripForm.value.jobDestinationLongitude,
      jobOriginLatitude: this.createNewTripForm.value.jobOriginLatitude,
      jobOriginLongitude: this.createNewTripForm.value.jobOriginLongitude,
      passengerNumber: this.createNewTripForm.value.jobPassengerNumber
    };

    this.socketService.emit(Events.GET_ESTIMATED_FARE, payload);
  }

  estimatedFareListener() {
    this.socketService.addListener(Events.GET_ESTIMATED_FARE)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.estimatedFare = data.totalFare;
        }
        this.socketService.removeListener(Events.GET_ESTIMATED_FARE);
      });
  }

  get form() {
    return this.createNewTripForm.controls;
  }

  ngOnDestroy(): void {

  }
}
