import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsInitializer {
  private apiKeyUpdateInterval = 6 * 60 * 60 * 1000; // 6 hour in milliseconds

  constructor(private authService: AuthService) {
  }

  async initialize(config: LazyMapsAPILoaderConfigLiteral): Promise<void> {
  await this.setApiKey(config);
    // Set up an interval to refresh the key every hour
    setInterval(() => {
      this.reloadPage();
    }, this.apiKeyUpdateInterval);
  }

  async setApiKey(config: LazyMapsAPILoaderConfigLiteral) {
    const res = await this.authService.getGoogleKeys().toPromise();
    if (res && res.key) {
      console.log('Setting Google Maps API key:');
      config.apiKey = res.key;
    } else {
      console.error('Error setting Google Maps API key');
    }
  }


    private reloadPage(): void {
    console.log('Reloading page to apply new Google Maps API key');
    window.location.reload();
  }
}
