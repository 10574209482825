import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-standing-order-clone-modal',
  templateUrl: './standing-order-clone-modal.component.html',
  styleUrls: ['./standing-order-clone-modal.component.css']
})
export class StandingOrderCloneModalComponent implements OnInit, OnDestroy {
  @Input('trip') assign: any;
  @Output() onClose = new EventEmitter<any>();
  tripForm: FormGroup;
  user;
  timeZone;
  trip;
  submitted = false;
  message;
  
  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.trip = {...this.assign};

    this.tripForm = this.fb.group({
      preScheduleTime: ['', Validators.required],
      preAppointmentTime: ['', Validators.required],
      appointmentTime: ['', Validators.required],
      scheduleTime: ['', Validators.required]
    });
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;

          const now = moment.tz(this.timeZone);
          const puT = moment(this.trip.scheduleTime).tz(this.timeZone, false);
          this.trip.scheduleTime = puT.set({ 'year': now.year(), 'month': now.month(), 'date': now.date() }).toISOString();
          
          const apT = moment(this.trip.appointmentTime).tz(this.timeZone, false);
          this.trip.appointmentTime = apT.set({ 'year': now.year(), 'month': now.month(), 'date': now.date() }).toISOString();


          this.tripForm.patchValue(this.trip);
          this.tripForm.patchValue({
            preScheduleTime: moment(this.trip.scheduleTime).tz(this.timeZone),
            preAppointmentTime: moment(this.trip.appointmentTime).tz(this.timeZone),
          });
        }
      });
  }

  onScheduleDateChange(evt) {
    this.tripForm.patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });



    // this.tripForm.patchValue({
    //   appointmentTime: moment(evt).add(1, 'hours')
    // });

    // if (!this.unassignedTripDetailById) {
    //   this.tripForm.patchValue({
    //     preAppointmentTime: moment(evt).add(1, 'hours')
    //   });
    // }
  }

  onAppointmentDateChange(evt) {
    this.tripForm.patchValue({
      appointmentTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });


  }

  saveClone() {
    this.message = '';
    this.submitted = true;

    const schd = moment(this.tripForm.value.scheduleTime).tz(this.timeZone, false);
    const appt = moment(this.tripForm.value.appointmentTime).tz(this.timeZone, false);
    

    if (schd.diff(appt) >= 0) {
      this.message = 'Appointment Time must be greater than Schedule Time'
      return;
    }

    sweetAlert('Alert', 'Are you sure you want to clone this trip?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.submitted = true;
          this.cloneRequest();
        }
      });
  }

  cloneRequest() {
    const queryParams = {
      scheduleTime: this.tripForm.value.scheduleTime,
      appointmentTime: this.tripForm.value.appointmentTime
    }
    this.assignTripService
      .cloneStandingOrderTrip(this.trip._id, queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.onClose.emit(null);
          // sweetAlert('Success', 'Trip Cloned Successfully', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  close() {
    this.onClose.emit(null);
  }

  get form() {
    return this.tripForm.controls;
  }

  ngOnDestroy(): void {
  }
}
