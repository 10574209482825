import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-driver-earning-modal',
  templateUrl: './driver-earning-modal.component.html',
  styleUrls: ['./driver-earning-modal.component.css']
})
export class DriverEarningModalComponent implements OnInit {
  @Input() tripsDetailModalObj: any
  @Output() onCloseModal = new EventEmitter<any>();
  driverTrips;
  driverDetail;

  constructor() { }

  ngOnInit() {
    // console.log("Modal Check New" , this.tripsDetailModalObj)
    if (this.tripsDetailModalObj && this.tripsDetailModalObj.jobs) {
      this.driverTrips = this.tripsDetailModalObj;
      // this.driverDetail = this.tripsDetailModalObj
    console.log("Check Driver Name Modal", this.driverTrips)

    }
  }

  closeDetailModal(){
    this.onCloseModal.emit();
  }

}
