import { Component, OnInit, Renderer2, OnDestroy, AfterViewInit, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { AuthService } from "../../service/auth-service/auth.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { sweetAlert } from "../../utils/swal";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { environment } from "src/environments/environment";
import { AssigTripsService } from "../assigned-trips/assigntrips-service/assigntrips-service";


@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit, OnDestroy , AfterViewInit {
  success: boolean;
  passwordToggleFlag = true;
  signInForm: FormGroup;
  submitted = false;
  isAdmin = false;
  isCompany = true;
  isShowToggler = false;
  // fireworks;

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private assignTripService: AssigTripsService,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private socket: SocketService,
    private router: Router,
    private renderere: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    window.addEventListener("keyup", this.initializeTimer);
    this.signInForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
    });

    // if (!environment.production) {
    //   this.signInForm.patchValue({
    //     email: "medfastcab@yamsol.com",
    //     password: "123456"
    //   });
    // }
    // this.applyFirworks();
  }

  applyAnimation() {
    const duration = 15 * 1000; // Reduced duration for faster animation
    const animationEnd = Date.now() + duration;
    let skew = 1;

    const frame = () => {
      const timeLeft = animationEnd - Date.now();
      const ticks = Math.max(200, 1000 * (timeLeft / duration)); // Increased ticks for faster animation

      skew = Math.max(0.8, skew - 0.001);

      // @ts-ignore: confetti is not recognized by TypeScript
      confetti({
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        origin: {
          x: Math.random(),
          y: Math.random() * skew - 0.2,
        },
        colors: ["#ffffff"],
        shapes: ["circle"],
        gravity: this.randomInRange(0.4, 0.6),
        scalar: this.randomInRange(0.4, 1),
        drift: this.randomInRange(-0.4, 0.4),
      });

      if (timeLeft > 0) {
        // Throttle the animation by using setTimeout
        setTimeout(frame, 16); // 60 frames per second
      }
    };

    frame();
  }

  randomInRange(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  initializeTimer = (e) => {
    if (
      e.altKey &&
      (e.key.toLowerCase() === "v" || e.key.toLowerCase() === "√")
    ) {
      this.isShowToggler = !this.isShowToggler;
      if (this.isShowToggler) {
        this.isAdmin = true;
        this.isCompany = false;
      } else {
        this.isCompany = true;
        this.isAdmin = false;
      }
    }
  };

  get form() {
    return this.signInForm.controls;
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  onSignIn() {

    this.submitted = true;
    if (this.signInForm.invalid) {
      return;
    }

    let role;

    if (this.isAdmin) {
      role = 'admin';

    } else {
      if (this.isCompany) {
        role = 'company';
      } else {
        role = 'staff';
      }

    }



    this.authService
      .login(this.signInForm.value.email, this.signInForm.value.password, role)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.success) {
          this.authService.setToken(data.token);
          this.sharedDataService.saveUser(data.user);
          this.socket.connectSocket();
          this.getNemtCodes();
          // this.router.navigateByUrl('/');
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });
  }

  getNemtCodes() {
    this.assignTripService
      .getNemtCodes()
      .subscribe((data: any) => {
        if (data && data.length) {
          this.sharedDataService.saveNemtCodes(data);
        }
        this.router.navigateByUrl('/');
      }, err => {
        this.router.navigateByUrl('/');
      });
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderere.appendChild(document.body, script);
    return script;
  }

  changeAdminRole() {
    this.isAdmin = !this.isAdmin;
    this.isCompany = true;
  }
  ngOnDestroy(): void {
    window.removeEventListener("keyup", this.initializeTimer);
    // this.fireworks.waitStop(true);
  }


  ngAfterViewInit(){
    // this.applyAnimation();

    // @ts-ignore: confetti is not recognized by TypeScript
    // fireworks();
    // this.applyFirworks();
  }

  applyFirworks() {
    // const container = document.querySelector('.fireworks');
    // @ts-ignore
    // this.fireworks = new Fireworks.default(container);
    // this.fireworks.start();
  }

}
