import { Component, OnDestroy, OnInit } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { getBrokersList } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DynamicFareServiceService } from '../dynamic-fare-service/dynamic-fare-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-dynamic-fare-list',
  templateUrl: './dynamic-fare-list.component.html',
  styleUrls: ['./dynamic-fare-list.component.css']
})
export class DynamicFareListComponent implements OnInit, OnDestroy {
  dynamicfaresList = [];
  search;
  user;

  constructor(
    public authService: AuthService,
    private dynamicFareService: DynamicFareServiceService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
    this.getDynamicFareList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.user = data;
        }
      });
  }

  getDynamicFareList() {
    this.dynamicFareService
      .getDynamicFareList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.dynamicfaresList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  getBroker(broker){
    return getBrokersList[broker]
  }

  ngOnDestroy(): void {
  }
}
