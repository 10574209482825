import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlannerService } from '../planner-service/planner-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-broker-setting-list',
  templateUrl: './broker-setting-list.component.html',
  styleUrls: ['./broker-setting-list.component.css']
})
export class BrokerSettingListComponent implements OnInit, OnDestroy {
  brokersSettingList = [];
  search;


  constructor(private plannerService:PlannerService) { }

  ngOnInit() {
     this.plannerService.getBrokerSettingList()
     .pipe(takeUntil(componentDestroyed(this)))
     .subscribe(data=>{
      this.brokersSettingList = data;
      console.log('broker', this.brokersSettingList)
    })
  }

  brokerList(key) {
    if(key === 'uber'){
      return 'Uber'
    }else{
      return key ? getBrokersList[key] : 'N/A';
    }
  }

  ngOnDestroy(): void {
  }
}
