import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DriverDistributionsService } from '../driver-distributions-services/driver-distributions-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-driver-distributions-create',
  templateUrl: './driver-distributions-create.component.html',
  styleUrls: ['./driver-distributions-create.component.css']
})
export class DriverDistributionsCreateComponent implements OnInit, OnDestroy {
  addNewDistributions: FormGroup;
  submitted = false;
  distributionId = '';
  btnName = 'Create';
  driverList = [];
  user;

  constructor(
    private driverDistributionsService: DriverDistributionsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.distributionId = params["id"];
      this.btnName = 'Update';
      this.getDriverDistribution();
    });

    this.addNewDistributions = this.fb.group({
      driver: ['', Validators.required],
      driverInTake: ['0', Validators.required],
      companyInTake: ['', Validators.required],
      weeklyFee: ['', Validators.required],
      distributionId: [''],
      threshold: ['', Validators.required]
    });

    this.getUser();
    this.getDriverList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getDriverDistribution() {
    this.driverDistributionsService
      .getDistributionById(this.distributionId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.addNewDistributions.patchValue({ ...data });
        }
      });
  }

  onAddNewDistributions() {
    this.submitted = true;
    if (this.addNewDistributions.invalid) {
      return;
    } else {
      if (this.distributionId) {
        this.addNewDistributions.patchValue({
          distributionId: this.distributionId
        });
      }
      this.saveDriverDistribution(this.addNewDistributions.value);
    }
  }

  saveDriverDistribution(payload) {
    this.driverDistributionsService
      .addDriverDistribution(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.router.navigateByUrl('fares/distributions');
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
        this.router.navigateByUrl('fares/distributions');
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  percentage(partialValue) {
    return (100 - partialValue);
  }

  applyPercentageToCompanyInTake() {
    const companyInTake = this.percentage(this.addNewDistributions.value.driverInTake);
    this.addNewDistributions.patchValue({ companyInTake });
  }

  applyPercentageToDriverInTake() {
    if (!this.addNewDistributions.value.companyInTake) {
      this.addNewDistributions.patchValue({
        driverInTake: 0
      });
      return;
    }
    const driverInTake = this.percentage(this.addNewDistributions.value.companyInTake)
    this.addNewDistributions.patchValue({ driverInTake });
  }

  get form() {
    return this.addNewDistributions.controls;
  }

  ngOnDestroy(): void {
  }
}
