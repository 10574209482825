import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AerotransService } from 'src/app/service/aerotranscare/aerotrans.service';
import { getRandomString } from 'src/app/utils/utils.common';


@Component({
  selector: 'app-aerotrans-modal',
  templateUrl: './aerotrans-modal.component.html',
  styleUrls: ['./aerotrans-modal.component.css']
})
export class AerotransModalComponent implements OnInit {
  @Input() selectedTrips;
  @Input() user;
  @Input() date
  @Output() isClose = new EventEmitter<any>();
  @Input() loader = {
    isLoading: false,
    isModal: false,
    isBeachwoodModal: false,
  };
  aeroTransInvoiceForm: FormGroup
  constructor(
    private aeroTransService: AerotransService,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.aeroTransInvoiceForm = this.fb.group({
      invoiceNo: [this.generateString()],
      billingCompany: [''],
      billingAddress: [''],
      billingCompanyPhone: [''],
      billingCompanyEmail: [''],
      invoiceDate : [''],
      dueDate : ['']
    }) 
  }

  closeModal(evt) {
    this.isClose.emit(evt);
  }

  generateString() {
    const randomString = getRandomString(7)
    const dateTime = moment(this.date[0]).format('MMDDYY') + '-' + moment(this.date[1]).format('MMDDYY')
   return `${randomString}-${dateTime}`;
  }
  truncateName(name: string, maxLength: number): string {
    if (name.length <= maxLength) {
      return name;
    } else {
      return name.substring(0, maxLength) + '...';
    }
  }

 async getAeroReport() {
    this.loader.isLoading = true;
    await this.aeroTransService.getAerotranscareReport(this.selectedTrips, this.aeroTransInvoiceForm.value, this.user,  this.loader, this.date);
    this.aeroTransInvoiceForm.reset();
  }


  removeTrip(id) {
    this.selectedTrips = this.selectedTrips.filter(trip => trip._id !== id);

  }


}
