import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { getBrokersList } from 'src/app/utils/utils.common';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-outstanding-trips-modal',
  templateUrl: './outstanding-trips-modal.component.html',
  styleUrls: ['./outstanding-trips-modal.component.css']
})
export class OutstandingTripsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() bucket;
  @Input() nonSuggestedTrips;
  @Input() suggestedTrips;
  @Output() assignTrip = new EventEmitter<any>();
  @Output() removeTrip = new EventEmitter<any>();
  @Output() onCloseModal = new EventEmitter<string>();
  @Output() onDriverChange = new EventEmitter<string>();
  @Output() reFreshCall = new EventEmitter<string>();

  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  trip;
  tripDeadMiles = 0;
  tripMiles = 0;
  tripFare = 0;
  selectedTrips;
  drivers = [];
  user;
  timeZone;
  isFilter = false;
  unAssignedTrips = false;
  info = false;
  isUpForCreate = true;

  assignedMarker = {
    url: "assets/images/map/bucket_pin2.png",
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  baseMarker = {
    url: "assets/images/map/base_pin.png",
    scaledSize: {
      width: 60,
      height: 60
    }
  };
  unassignedMarker = {
    url: "assets/images/map/bucket_pin3.png",
    scaledSize: {
      width: 40,
      height: 40
    }
  };

  driverMarker = {
    url: "assets/images/map/driver_pin.png",
    scaledSize: {
      width: 40,
      height: 40
    }
  }

  constructor(private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService) { }

  ngOnInit() {
    if (this.bucket._id && !this.bucket._id.groupId) {
      this.unAssignedTrips = true;
    }

    if(this.bucket._id.groupId  && this.bucket.assigns && this.bucket.assigns[0] && this.bucket.assigns[0].isUp !== undefined){
      this.isUpForCreate = this.bucket.assigns[0].isUp  
    }
    this.getUser();
    this.getDriverList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  ngAfterViewInit() {
    this.agmElement.mapReady
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((map) => {
        this.agmMap = map;
      });
  }

  sendToSpecificBucket(trip) {
    if (this.bucket._id.groupId) {
      trip.gid = this.bucket._id.groupId;
      this.assignTrip.emit(trip);
    } else {
      let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === trip._id);

      this.bucket.assigns.push({ ...trip, priority: this.bucket.assigns.length+1 });
      this.nonSuggestedTrips.splice(findTripIndex, 1);
    }
  }

  removeFromBucket(trip) {
    if (this.bucket._id.groupId) {
      const evt = {
        trip: trip,
        bucket: this.bucket
      }
      this.removeTrip.emit(evt);
    } else {
      // remove from assign;
      let findTripIndex = this.bucket.assigns.findIndex(t => t._id === trip._id);
      this.nonSuggestedTrips.push({ ...trip, gid: "", priority: -1, isUp: undefined });
      this.bucket.assigns.splice(findTripIndex, 1);
    }
  }


  assignBucket(bucket) {
    sweetAlert('Alert', 'Are you sure you want to make bucket live?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.selectedTrips = bucket.assigns.map(a => a._id);
          const payload = {
            selectedTrips: this.selectedTrips,
            status: 'offered',
            driver: { _id: bucket._id.driver },
            note: ''
          }

          this.assignTripService
            .assignMultipleTrip(payload)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                //  let bIndex = this.suggestedTrips.findIndex(t => t._id === bucket._id);
                // this.suggestedTrips[bIndex].assigns.splice(bIndex, 1);
                this.reFreshCall.emit();
              }
            });
        }
      })
    // if (window.confirm('Are you sure you want to make bucket live?')) {
    //   this.selectedTrips = bucket.assigns.map(a => a._id);
    //   const payload = {
    //     selectedTrips: this.selectedTrips,
    //     status: 'offered',
    //     driver: bucket._id,
    //     note: ''
    //   }

    //   this.assignTripService
    //     .assignMultipleTrip(payload)
    //     .pipe(takeUntil(componentDestroyed(this)))
    //     .subscribe(data => {
    //       if (data) {
    //         this.suggestedTrips.splice(bIndex, 1);
    //       }
    //     });
    // }
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  onDriverSelect(evt) {
    this.onDriverChange.emit(evt);
  }


  checkTripStatus(assigns) {
    const trips = [];
    for (let trip of assigns) {
      if (!['offered', 'unassigned'].includes(trip.status)) {
        trips.push(trip);
      }
    }

    return !trips.length;
  }

  onClickPin(trip) {
    this.info = true;
  }

  ngOnDestroy(): void {
  }
  getDriverObj(driver) {
    return this.drivers.find(item => item._id === driver)
  }

  createBucket(bucket) {
    this.bucket._id.groupId = this.generateRandomString(8);
    const payload = [];

    this.bucket.assigns.forEach(element => {
      element.gid = this.bucket._id.groupId;
      element.isUp = this.isUpForCreate;

      let tripPayload: any = {
        gid: this.bucket._id.groupId,
        _id: element._id,
        priority: element.priority,
        isUp: element.isUp,
      };
      payload.push(tripPayload);

    });

    if (!payload || (payload && !payload.length))
      return

    this.assignTripService.getBulkWriteTrips({ data: payload })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.reFreshCall.emit();
          // refreshc call shere
        }
      });

  }


  generateRandomString(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

}
