import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { MaintenanceService } from '../maintenance-service/maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { getCompanyLogo } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-maintenance-stats',
  templateUrl: './maintenance-stats.component.html',
  styleUrls: ['./maintenance-stats.component.css']
})
export class MaintenanceStatsComponent implements OnInit {
  user;
  stats;
  vehicleStats;
  timeZone;
  constructor(
    private sharedDataService: SharedDataService,
    private maintenanceService: MaintenanceService) { }

    ngOnInit() {
      this.getUser();
      this.getStats();
    }
  
    getUser() {
      this.sharedDataService
        .getUser()
        .subscribe(user => {
          if (user) {
            this.user = user;
            this.timeZone = user.timeZone;
          }
        });
    }
    
    getStats(){
      this.maintenanceService.getMaintenanceStats()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if(data && data.maintenances && data.maintenances.length) {
          this.stats = data.maintenances[0];
          this.vehicleStats = this.stats.vehicleExpenses
        }
    });
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }
  
  
    ngOnDestroy(): void {
    }
  
}
