import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
@Component({
    selector: 'app-autocomplete-component',
    template: `
      <input class="input"
      class="form-control"
        type="text"
        placeholder="Address"

         [disabled]="isdisabled"
        [(ngModel)]="autocompleteInput"
        #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 100%"
        >


    `
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() adressType: string;
    @Input() isdisabled: boolean;
    @Input() address: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext', { static : true}) addresstext: any;

    autocompleteInput: string;
    queryWait: boolean;

    constructor( private mapsAPILoader: MapsAPILoader) {
    }

    ngOnInit() {
       this.autocompleteInput = this.address;
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        this.mapsAPILoader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                componentRestrictions: { country: 'US' },
                types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.invokeEvent(place);
        });
    });
    }

    invokeEvent(place) {
        this.setAddress.emit(place);
    }

}
