import { Component, OnInit } from '@angular/core';
import { ServiceAreasService } from '../service-areas-service/service-areas.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-service-areas-listing',
  templateUrl: './service-areas-listing.component.html',
  styleUrls: ['./service-areas-listing.component.css']
})
// export class ServiceAreasListingComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
export class ServiceAreasListingComponent implements OnInit {
  search;
  selectedStatus: any = "active";
  serviceAreas = [];

  constructor(
    private serviceArea: ServiceAreasService) {
  }

  ngOnInit() {
    this.getServiceAreas()
  }

  getServiceAreas(){
    this.serviceArea.getServiceAreas({status: this.selectedStatus})
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.serviceAreas = data
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
    }


  ngOnDestroy(): void {
  }

  onChangeStatus() {
    this.getServiceAreas();
  }

}
