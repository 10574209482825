import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DispatchCenterService } from 'src/app/component/dispatch-center/dispatch-center-service/dispatch-center-service';

@Component({
  selector: 'app-dispatch-driver-trips-modal',
  templateUrl: './dispatch-driver-trips-modal.component.html',
  styleUrls: ['./dispatch-driver-trips-modal.component.css']
})
export class DispatchDriverTripsModalComponent implements OnInit {
  @Input() driverId;
  @Output() close = new EventEmitter<any>();
  search;
  trips = [];
  constructor(private dispatchCenterService: DispatchCenterService) { }

  ngOnInit() {
    this.getDriverTrips();
  }

  closeModal() {
    this.close.emit();
  }

  getDriverTrips() {
    this.dispatchCenterService.getDriverTrips(this.driverId).subscribe(data => {
      if (data) {
        this.trips = data.assigns;
      }
    })
  }


}
