import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { PAYOR, getBrokersList, timeCalculation } from '../../../utils/utils.common';
import { KpisService } from '../../kpis/kpis-service/kpis.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-maintenance-detail-modal',
  templateUrl: './maintenance-detail-modal.component.html',
  styleUrls: ['./maintenance-detail-modal.component.css']
})
export class MaintenanceDetailModalComponent implements OnInit, OnDestroy {
  @Input('vehicleDetailModalObj') vehicle: any;
  @Input('driverDetailModalObj') driverDetail: any;
  @Output() onCloseModal = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }
  
  closeModal() {
    this.onCloseModal.emit();
  }
  ngOnDestroy(): void {
  }
}
