import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filterJobs, getBrokersList } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-dashboard-trips-details',
  templateUrl: './dashboard-trips-details.component.html',
  styleUrls: ['./dashboard-trips-details.component.css']
})
export class DashboardTripsDetailsComponent implements OnInit, OnDestroy {
 

  getAssignTripsState: Observable<any>;
  currentTime;
  tripsList = [];
  tripType;
  search = '';
  broker = '';
  assignModalObj;
  componentType;
  constructor(
    // private store: Store<AssignTripsState>, 
    private activatedRoute: ActivatedRoute, private service: AssigTripsService) { 
  }


  ngOnInit() {
    this.tripType = this.activatedRoute.snapshot.url[2].path
      this.getAllTrips();

  }

  
  getAllTrips() {
    this.service
        .getTripsForAssignDashboard()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if(data){
            this.currentTime = data.currentTime;
            this.tripsList = filterJobs(data.assigns, this.currentTime);
          }
        })
    // this.store.dispatch(new GetTripsForAssignDashboard());
  }

  getBrokersList() {
    return getBrokersList;
  }

  sortMethod(a, b) {
    return new Date(a.key) > new Date(b.key) ? 1 : -1;
  }


  countTrips(trips) {
    let count = 0;
    const todayDate = moment(this.currentTime).format('MM/DD/YYYY');
    for (var x in trips) {
      if (x === todayDate) {
        count += trips[x].length;
      }
    }
    return count;
  }

  
  setAssignModalObj(trip, type) {
    this.componentType = type;
    this.assignModalObj = trip;
  }

  tripAssignedResponse(tripStatus: string): void {
    if (tripStatus === "Trip Assigned" || tripStatus === 'Trip Un Assigned') {
      this.getAllTrips();
    } else if (tripStatus !== null) {
      sweetAlert('Error', tripStatus, 'error', 'OK');
    }
    this.assignModalObj = null;
    this.componentType = null;
  }


  ngOnDestroy(): void {
  }
}
