import { Component, OnInit, OnDestroy } from '@angular/core';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { MaintenanceService } from '../maintenance-service/maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-driver-expenses',
  templateUrl: './driver-expenses.component.html',
  styleUrls: ['./driver-expenses.component.css']
})
export class DriverExpensesComponent implements OnInit , OnDestroy {
  user;
  search;
  selectedDriver;
  driverList;
  driverExpenses;
  driverVehicleExpenses;
  timeZone;
  driverDetailModalObj;

  constructor(private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private maintenanceService: MaintenanceService) { }

  ngOnInit() {
    this.getUser();
    this.getDriverList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = user.timeZone;
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }
  
  getDriverExpense(id){
    this.maintenanceService.getDriverExpense(id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if(data && data.maintenances && data.maintenances.length) {
        this.driverExpenses = data.maintenances[0];
        this.driverVehicleExpenses = this.driverExpenses.vehicleExpenses
      }
  });
}

onSelectDriver(driver) {
  this.selectedDriver = driver;
  this.driverExpenses = null;
  this.driverVehicleExpenses = null;
  this.getDriverExpense(driver._id);
}

openDriverDetailModal(driverData){
  this.driverDetailModalObj = driverData;
}

  ngOnDestroy(): void {
  }

}
