export const userManuals =
  [{
    url: 'https://nemtplatform.notion.site/Vehicles-Fleet-63544b8ee5bf4d6caa922f90b4a9f0e5',
    name: 'Vehicles/Fleet',
    image: 'assets/images/manual/ambulance.png'
  }, {
    url: 'https://nemtplatform.notion.site/Drivers-9e50a475435648c39dbe19a42abc2fbe',
    name: 'Drivers',
    image: 'assets/images/manual/driver.png'
  },{
    url: 'https://nemtplatform.notion.site/Importing-trips-from-brokers-4508d72c026b41189d42eb979d17af2a',
    name: 'Importing trips from brokers',
    image: 'assets/images/manual/importFile.png'
  }, {
    url: 'https://nemtplatform.notion.site/Create-trips-manually-08117eb461c8445fbf5f1ac71bba0877',
    name: 'Create trips manually',
    image: 'assets/images/manual/addTrip.png'
  }, {
    url: 'https://nemtplatform.notion.site/Corporate-Private-Pay-470b0f14cf24459382ff6584a995bb3e',
    name: 'Corporate/Private Pay ',
    image: 'assets/images/manual/corporate.png'
  }, {
    url: 'https://nemtplatform.notion.site/Canceled-trips-section-f1e8a027071c4c90bf349ccc991f75ab',
    name: 'Cancelled trips section',
    image: 'assets/images/manual/cancelTrips.png'
  }, {
    url: 'https://nemtplatform.notion.site/Archived-trips-de9837d30a2542f89f0668e2c01c0985',
    name: 'Archived trips',
    image: 'assets/images/manual/archive.png'
  }, {
    url: 'https://nemtplatform.notion.site/Completed-trips-53663863be564fc18350dde14d66294f',
    name: 'Completed trips',
    image: 'assets/images/manual/completedTrip.png'
  }, {
    url: 'https://nemtplatform.notion.site/Trips-adjustment-Important-before-banking-39ce60d910b646098d71f7e3dd314414',
    name: 'Trips adjustment(Important before banking)',
    image: 'assets/images/manual/adjustment.png'
  }, {
    url: 'https://nemtplatform.notion.site/Cancel-trip-c84bdd6d093c4a8caec6b6920f773893',
    name: 'Cancel trip',
    image: 'assets/images/manual/cancel.png'
  }, {
    url: 'https://nemtplatform.notion.site/Mark-member-ready-aa485de960c84c399f62cbe0a0d1a170',
    name: 'Mark member ready',
    image: 'assets/images/manual/markReady.png'
  }, {
    url: 'https://nemtplatform.notion.site/Hanging-trips-Assigned-Trips-85ba07e624414ee4949c6a92f9575467',
    name: 'Assigned trips',
    image: 'assets/images/manual/hangingTrip.png'
  }, {
    url: 'https://nemtplatform.notion.site/Unassigned-trips-efabab76996f4fff8719662f1b41168a',
    name: 'Unassigned trips',
    image: 'assets/images/manual/unassignTrip.png'
  }]
;
