import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class CorporateAddonServiceService {

  constructor(private http: HttpClient) { }



  getCorporateAddonList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GETCORPORATEADDON)
  }


  addCorporateAddon(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.GETCORPORATEADDON, data)
  }

  getCorporateAddonListById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GETCORPORATEADDON + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  updateCorporateAddon(id, data){
    return this.http.put<any>(urls.BASE_URL + urls.GETCORPORATEADDON + `/${id}`, data)
// return this.http.put<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + '/' + driverId, formData)
  }

  removeCorporate(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-HTTP-Method-Override': 'DELETE',
        'Access-Control-Allow-Credentials':'true'
      })
    };
    return this.http.delete<any>(urls.BASE_URL + urls.GETCORPORATEADDON + `/${id}`, httpOptions)
  }


  getCorporateAddonServices(){
    return this.http.get<any>(urls.BASE_URL + urls.GET_CORPORATE_ADDON_SERVICES);
  }



}



