import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class InviteSmsService {

  constructor(private http: HttpClient) { }

  getPreviousInvitesList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.INVITES_LIST_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendSmsInvite(contact): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.INVITES_LIST_URL, contact).pipe(
      catchError(errorHandl)
    );
  }
}
