import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { MaintenanceService } from '../maintenance-service/maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FleetService } from '../../fleet/fleet-service/fleet.service';
import { getCompanyLogo } from 'src/app/utils/utils.common';
import { VmlService } from 'src/app/service/vml-service/vml.service';
import { CompaniesService } from '../../companies/companies.service';
@Component({
  selector: 'app-vehicle-expenses',
  templateUrl: './vehicle-expenses.component.html',
  styleUrls: ['./vehicle-expenses.component.css']
})
export class VehicleExpensesComponent implements OnInit, OnDestroy {
  user;
  search;
  selectedVehicle;
  fleetList;
  vehicleExpenses;
  timeZone;
  vehicleDetailModalObj;
  vehicles

  constructor(
    private fleetService: FleetService,
    private sharedDataService: SharedDataService,
    private maintenanceService: MaintenanceService,
    private vmlServices: VmlService,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = user.timeZone;
          this.getFleetList();

        }
      });
  }

  getFleetList() {
    this.fleetService
      .getFleetList({ 'isFleetSection': true, 'status': 'active' })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {

          if (data) {
            this.fleetList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }
  getFleetbyId(id) {
    this.fleetService.getFleetDataById(id).subscribe((data) => {
      if (data) {
        this.vehicles = data      }
    })
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  getVehicleExpense(id) {
    this.maintenanceService.getVehicleExpense(id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data && data.maintenances && data.maintenances.length) {
          this.vehicleExpenses = data.maintenances;
        }
      });
  }

  onSelectVehicle(vehicle) {
    this.selectedVehicle = vehicle;
    this.vehicleExpenses = null;
    this.getVehicleExpense(vehicle._id);
    this.getFleetbyId(vehicle._id)
  }

  openVehicleDetailModal(vehicleData) {
    this.vehicleDetailModalObj = vehicleData;
  }

  ngOnDestroy(): void {
  }

  getVmlReport(payload) {
    const temp = [
      {
          "_id": "654b7ad9d99d155d385c2ddf",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445464921-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "cost": 120,
          "notes": "Fainal check",
          "type": "expense",
          "__v": 0,
          "maintenanceservice": [],
          "created": "2023-11-08T12:11:05.097Z"
      },
      {
          "_id": "654b7abbd99d155d385c2dde",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445434718-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "perGallon": 1,
          "type": "gasoline",
          "cost": 65,
          "odometer": 1234,
          "gasQuantity": 65,
          "notes": "Refueling",
          "__v": 0,
          "maintenanceservice": [],
          "created": "2023-11-08T12:10:35.036Z"
      },
      {
          "_id": "654b7a9cd99d155d385c2ddd",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445403926-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "type": "service",
          "cost": 900,
          "odometer": 6000,
          "nextOdometer": 7000,
          "notes": "Full stop",
          "__v": 0,
          "maintenanceservice": [
              {
                  "_id": "5cb973169c57d73f5339ff7f",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657494170-tire_rotation.png",
                  "name": "Wheel Rotation"
              },
              {
                  "_id": "5cb972fc9c57d73f5339ff7e",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657468515-spark_plugs.png",
                  "name": "Plug"
              },
              {
                  "_id": "5cb972eb9c57d73f5339ff7d",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657451227-engine_oil.png",
                  "name": "Engine Oil"
              },
              {
                  "_id": "5cb972d99c57d73f5339ff7c",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
                  "name": "Engine Belt"
              },
              {
                  "_id": "5cb972a19c57d73f5339ff7b",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
                  "name": "Battery"
              }
          ],
          "created": "2023-11-08T12:10:04.426Z"
      },
      {
          "_id": "654b7a6ed99d155d385c2ddc",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "cost": 12,
          "type": "expense",
          "notes": "Oil change",
          "__v": 0,
          "maintenanceservice": [],
          "created": "2023-11-08T12:09:18.416Z"
      },
      {
          "_id": "654b7a59d99d155d385c2ddb",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "notes": "Pit stop",
          "odometer": 5574,
          "nextOdometer": 6000,
          "cost": 100,
          "type": "service",
          "__v": 0,
          "maintenanceservice": [
              {
                  "_id": "5cb972a19c57d73f5339ff7b",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
                  "name": "Battery"
              },
              {
                  "_id": "5cb972d99c57d73f5339ff7c",
                  "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
                  "name": "Engine Belt"
              }
          ],
          "created": "2023-11-08T12:08:57.119Z"
      },
      {
          "_id": "654b7a28d99d155d385c2dda",
          "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445288254-receiptImageURL",
          "fleet": {
              "_id": "650849d3266e48259089c10e",
              "driverCarNumber": "1234567",
              "driverCar": "Standard"
          },
          "company": "5a5da31a4772d13deb47c46b",
          "driver": {
              "_id": "5bd079c784001708afb7a145",
              "displayName": "Adnan javed",
              "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
          },
          "gasQuantity": 5,
          "perGallon": 2,
          "cost": 10,
          "type": "gasoline",
          "odometer": 1087,
          "notes": "New fuel",
          "__v": 0,
          "maintenanceservice": [],
          "created": "2023-11-08T12:08:08.477Z"
      },    
      {
        "_id": "654b7a9cd99d155d385c2ddd",
        "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445403926-receiptImageURL",
        "fleet": {
            "_id": "650849d3266e48259089c10e",
            "driverCarNumber": "1234567",
            "driverCar": "Standard"
        },
        "company": "5a5da31a4772d13deb47c46b",
        "driver": {
            "_id": "5bd079c784001708afb7a145",
            "displayName": "Adnan javed",
            "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
        },
        "type": "service",
        "cost": 900,
        "odometer": 6000,
        "nextOdometer": 7000,
        "notes": "Full stop",
        "__v": 0,
        "maintenanceservice": [
            {
                "_id": "5cb973169c57d73f5339ff7f",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657494170-tire_rotation.png",
                "name": "Wheel Rotation"
            },
            {
                "_id": "5cb972fc9c57d73f5339ff7e",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657468515-spark_plugs.png",
                "name": "Plug"
            },
            {
                "_id": "5cb972eb9c57d73f5339ff7d",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657451227-engine_oil.png",
                "name": "Engine Oil"
            },
            {
                "_id": "5cb972d99c57d73f5339ff7c",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
                "name": "Engine Belt"
            },
            {
                "_id": "5cb972a19c57d73f5339ff7b",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
                "name": "Battery"
            }
        ],
        "created": "2023-11-08T12:10:04.426Z"
    },
    {
        "_id": "654b7a6ed99d155d385c2ddc",
        "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
        "fleet": {
            "_id": "650849d3266e48259089c10e",
            "driverCarNumber": "1234567",
            "driverCar": "Standard"
        },
        "company": "5a5da31a4772d13deb47c46b",
        "driver": {
            "_id": "5bd079c784001708afb7a145",
            "displayName": "Adnan javed",
            "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
        },
        "cost": 12,
        "type": "expense",
        "notes": "Oil change",
        "__v": 0,
        "maintenanceservice": [],
        "created": "2023-11-08T12:09:18.416Z"
    },
    {
        "_id": "654b7a59d99d155d385c2ddb",
        "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
        "fleet": {
            "_id": "650849d3266e48259089c10e",
            "driverCarNumber": "1234567",
            "driverCar": "Standard"
        },
        "company": "5a5da31a4772d13deb47c46b",
        "driver": {
            "_id": "5bd079c784001708afb7a145",
            "displayName": "Adnan javed",
            "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
        },
        "notes": "Pit stop",
        "odometer": 5574,
        "nextOdometer": 6000,
        "cost": 100,
        "type": "service",
        "__v": 0,
        "maintenanceservice": [
            {
                "_id": "5cb972a19c57d73f5339ff7b",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
                "name": "Battery"
            },
            {
                "_id": "5cb972d99c57d73f5339ff7c",
                "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
                "name": "Engine Belt"
            }
        ],
        "created": "2023-11-08T12:08:57.119Z"
    },
    {
      "_id": "654b7a59d99d155d385c2ddb",
      "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
      "fleet": {
          "_id": "650849d3266e48259089c10e",
          "driverCarNumber": "1234567",
          "driverCar": "Standard"
      },
      "company": "5a5da31a4772d13deb47c46b",
      "driver": {
          "_id": "5bd079c784001708afb7a145",
          "displayName": "Adnan javed",
          "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
      },
      "notes": "Pit stop",
      "odometer": 5574,
      "nextOdometer": 6000,
      "cost": 100,
      "type": "service",
      "__v": 0,
      "maintenanceservice": [
          {
              "_id": "5cb972a19c57d73f5339ff7b",
              "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
              "name": "Battery"
          },
          {
              "_id": "5cb972d99c57d73f5339ff7c",
              "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
              "name": "Engine Belt"
          }
      ],
      "created": "2023-11-08T12:08:57.119Z"
    }, 
     {
      "_id": "654b7a6ed99d155d385c2ddc",
      "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
      "fleet": {
          "_id": "650849d3266e48259089c10e",
          "driverCarNumber": "1234567",
          "driverCar": "Standard"
      },
      "company": "5a5da31a4772d13deb47c46b",
      "driver": {
          "_id": "5bd079c784001708afb7a145",
          "displayName": "Adnan javed",
          "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
      },
      "cost": 12,
      "type": "expense",
      "notes": "Oil change",
      "__v": 0,
      "maintenanceservice": [],
      "created": "2023-11-08T12:09:18.416Z"
  },
  {
      "_id": "654b7a59d99d155d385c2ddb",
      "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
      "fleet": {
          "_id": "650849d3266e48259089c10e",
          "driverCarNumber": "1234567",
          "driverCar": "Standard"
      },
      "company": "5a5da31a4772d13deb47c46b",
      "driver": {
          "_id": "5bd079c784001708afb7a145",
          "displayName": "Adnan javed",
          "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
      },
      "notes": "Pit stop",
      "odometer": 5574,
      "nextOdometer": 6000,
      "cost": 100,
      "type": "service",
      "__v": 0,
      "maintenanceservice": [
          {
              "_id": "5cb972a19c57d73f5339ff7b",
              "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
              "name": "Battery"
          },
          {
              "_id": "5cb972d99c57d73f5339ff7c",
              "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
              "name": "Engine Belt"
          }
      ],
      "created": "2023-11-08T12:08:57.119Z"
  },
  {
    "_id": "654b7a59d99d155d385c2ddb",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "notes": "Pit stop",
    "odometer": 5574,
    "nextOdometer": 6000,
    "cost": 100,
    "type": "service",
    "__v": 0,
    "maintenanceservice": [
        {
            "_id": "5cb972a19c57d73f5339ff7b",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
            "name": "Battery"
        },
        {
            "_id": "5cb972d99c57d73f5339ff7c",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
            "name": "Engine Belt"
        }
    ],
    "created": "2023-11-08T12:08:57.119Z"
  },
  {
    "_id": "654b7ad9d99d155d385c2ddf",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445464921-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "cost": 120,
    "notes": "Fainal check",
    "type": "expense",
    "__v": 0,
    "maintenanceservice": [],
    "created": "2023-11-08T12:11:05.097Z"
},
{
    "_id": "654b7abbd99d155d385c2dde",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445434718-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "perGallon": 1,
    "type": "gasoline",
    "cost": 65,
    "odometer": 1234,
    "gasQuantity": 65,
    "notes": "Refueling",
    "__v": 0,
    "maintenanceservice": [],
    "created": "2023-11-08T12:10:35.036Z"
},
{
    "_id": "654b7a9cd99d155d385c2ddd",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445403926-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "type": "service",
    "cost": 900,
    "odometer": 6000,
    "nextOdometer": 7000,
    "notes": "Full stop",
    "__v": 0,
    "maintenanceservice": [
        {
            "_id": "5cb973169c57d73f5339ff7f",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657494170-tire_rotation.png",
            "name": "Wheel Rotation"
        },
        {
            "_id": "5cb972fc9c57d73f5339ff7e",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657468515-spark_plugs.png",
            "name": "Plug"
        },
        {
            "_id": "5cb972eb9c57d73f5339ff7d",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657451227-engine_oil.png",
            "name": "Engine Oil"
        },
        {
            "_id": "5cb972d99c57d73f5339ff7c",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
            "name": "Engine Belt"
        },
        {
            "_id": "5cb972a19c57d73f5339ff7b",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
            "name": "Battery"
        }
    ],
    "created": "2023-11-08T12:10:04.426Z"
},
{
    "_id": "654b7a6ed99d155d385c2ddc",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "cost": 12,
    "type": "expense",
    "notes": "Oil change",
    "__v": 0,
    "maintenanceservice": [],
    "created": "2023-11-08T12:09:18.416Z"
},
{
    "_id": "654b7a59d99d155d385c2ddb",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "notes": "Pit stop",
    "odometer": 5574,
    "nextOdometer": 6000,
    "cost": 100,
    "type": "service",
    "__v": 0,
    "maintenanceservice": [
        {
            "_id": "5cb972a19c57d73f5339ff7b",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
            "name": "Battery"
        },
        {
            "_id": "5cb972d99c57d73f5339ff7c",
            "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
            "name": "Engine Belt"
        }
    ],
    "created": "2023-11-08T12:08:57.119Z"
},
{
    "_id": "654b7a28d99d155d385c2dda",
    "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445288254-receiptImageURL",
    "fleet": {
        "_id": "650849d3266e48259089c10e",
        "driverCarNumber": "1234567",
        "driverCar": "Standard"
    },
    "company": "5a5da31a4772d13deb47c46b",
    "driver": {
        "_id": "5bd079c784001708afb7a145",
        "displayName": "Adnan javed",
        "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
    },
    "gasQuantity": 5,
    "perGallon": 2,
    "cost": 10,
    "type": "gasoline",
    "odometer": 1087,
    "notes": "New fuel",
    "__v": 0,
    "maintenanceservice": [],
    "created": "2023-11-08T12:08:08.477Z"
},    
{
  "_id": "654b7a9cd99d155d385c2ddd",
  "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445403926-receiptImageURL",
  "fleet": {
      "_id": "650849d3266e48259089c10e",
      "driverCarNumber": "1234567",
      "driverCar": "Standard"
  },
  "company": "5a5da31a4772d13deb47c46b",
  "driver": {
      "_id": "5bd079c784001708afb7a145",
      "displayName": "Adnan javed",
      "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
  },
  "type": "service",
  "cost": 900,
  "odometer": 6000,
  "nextOdometer": 7000,
  "notes": "Full stop",
  "__v": 0,
  "maintenanceservice": [
      {
          "_id": "5cb973169c57d73f5339ff7f",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657494170-tire_rotation.png",
          "name": "Wheel Rotation"
      },
      {
          "_id": "5cb972fc9c57d73f5339ff7e",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657468515-spark_plugs.png",
          "name": "Plug"
      },
      {
          "_id": "5cb972eb9c57d73f5339ff7d",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657451227-engine_oil.png",
          "name": "Engine Oil"
      },
      {
          "_id": "5cb972d99c57d73f5339ff7c",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
          "name": "Engine Belt"
      },
      {
          "_id": "5cb972a19c57d73f5339ff7b",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
          "name": "Battery"
      }
  ],
  "created": "2023-11-08T12:10:04.426Z"
},
{
  "_id": "654b7a6ed99d155d385c2ddc",
  "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
  "fleet": {
      "_id": "650849d3266e48259089c10e",
      "driverCarNumber": "1234567",
      "driverCar": "Standard"
  },
  "company": "5a5da31a4772d13deb47c46b",
  "driver": {
      "_id": "5bd079c784001708afb7a145",
      "displayName": "Adnan javed",
      "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
  },
  "cost": 12,
  "type": "expense",
  "notes": "Oil change",
  "__v": 0,
  "maintenanceservice": [],
  "created": "2023-11-08T12:09:18.416Z"
},
{
  "_id": "654b7a59d99d155d385c2ddb",
  "receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
  "fleet": {
      "_id": "650849d3266e48259089c10e",
      "driverCarNumber": "1234567",
      "driverCar": "Standard"
  },
  "company": "5a5da31a4772d13deb47c46b",
  "driver": {
      "_id": "5bd079c784001708afb7a145",
      "displayName": "Adnan javed",
      "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
  },
  "notes": "Pit stop",
  "odometer": 5574,
  "nextOdometer": 6000,
  "cost": 100,
  "type": "service",
  "__v": 0,
  "maintenanceservice": [
      {
          "_id": "5cb972a19c57d73f5339ff7b",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
          "name": "Battery"
      },
      {
          "_id": "5cb972d99c57d73f5339ff7c",
          "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
          "name": "Engine Belt"
      }
  ],
  "created": "2023-11-08T12:08:57.119Z"
},
{
"_id": "654b7a59d99d155d385c2ddb",
"receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
"fleet": {
    "_id": "650849d3266e48259089c10e",
    "driverCarNumber": "1234567",
    "driverCar": "Standard"
},
"company": "5a5da31a4772d13deb47c46b",
"driver": {
    "_id": "5bd079c784001708afb7a145",
    "displayName": "Adnan javed",
    "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
},
"notes": "Pit stop",
"odometer": 5574,
"nextOdometer": 6000,
"cost": 100,
"type": "service",
"__v": 0,
"maintenanceservice": [
    {
        "_id": "5cb972a19c57d73f5339ff7b",
        "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
        "name": "Battery"
    },
    {
        "_id": "5cb972d99c57d73f5339ff7c",
        "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
        "name": "Engine Belt"
    }
],
"created": "2023-11-08T12:08:57.119Z"
}, 
{
"_id": "654b7a6ed99d155d385c2ddc",
"receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445357988-receiptImageURL",
"fleet": {
    "_id": "650849d3266e48259089c10e",
    "driverCarNumber": "1234567",
    "driverCar": "Standard"
},
"company": "5a5da31a4772d13deb47c46b",
"driver": {
    "_id": "5bd079c784001708afb7a145",
    "displayName": "Adnan javed",
    "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
},
"cost": 12,
"type": "expense",
"notes": "Oil change",
"__v": 0,
"maintenanceservice": [],
"created": "2023-11-08T12:09:18.416Z"
},
{
"_id": "654b7a59d99d155d385c2ddb",
"receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
"fleet": {
    "_id": "650849d3266e48259089c10e",
    "driverCarNumber": "1234567",
    "driverCar": "Standard"
},
"company": "5a5da31a4772d13deb47c46b",
"driver": {
    "_id": "5bd079c784001708afb7a145",
    "displayName": "Adnan javed",
    "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
},
"notes": "Pit stop",
"odometer": 5574,
"nextOdometer": 6000,
"cost": 100,
"type": "service",
"__v": 0,
"maintenanceservice": [
    {
        "_id": "5cb972a19c57d73f5339ff7b",
        "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
        "name": "Battery"
    },
    {
        "_id": "5cb972d99c57d73f5339ff7c",
        "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
        "name": "Engine Belt"
    }
],
"created": "2023-11-08T12:08:57.119Z"
},
{
"_id": "654b7a59d99d155d385c2ddb",
"receiptImageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1699445336623-receiptImageURL",
"fleet": {
  "_id": "650849d3266e48259089c10e",
  "driverCarNumber": "1234567",
  "driverCar": "Standard"
},
"company": "5a5da31a4772d13deb47c46b",
"driver": {
  "_id": "5bd079c784001708afb7a145",
  "displayName": "Adnan javed",
  "profileImageURL": "https://neighborhood-dev.s3.amazonaws.com/drivers/1540389319558-8fb0b7ea-1c29-45f0-8a68-65d887387ebe.jpg"
},
"notes": "Pit stop",
"odometer": 5574,
"nextOdometer": 6000,
"cost": 100,
"type": "service",
"__v": 0,
"maintenanceservice": [
  {
      "_id": "5cb972a19c57d73f5339ff7b",
      "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657377326-battery.png",
      "name": "Battery"
  },
  {
      "_id": "5cb972d99c57d73f5339ff7c",
      "imageURL": "https://neighborhood-dev.s3.amazonaws.com/maintenance/1555657433365-timing_belt.png",
      "name": "Engine Belt"
  }
],
"created": "2023-11-08T12:08:57.119Z"
},
   
      
   
    
  ]
    this.vmlServices.getVmlReport(payload, this.user, this.vehicles)
  }


  getSingleVmlReport(item) {
  
    this.vmlServices.getVmlReport([item], this.user, this.vehicles)
  }
}
