import { Injectable } from '@angular/core';
import { HttpClient , HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setFormData } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class DispatchCenterService {

  constructor(private http: HttpClient) {
  }

  getDispatchHistoryList(id, endPoint): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DISPATCH_CENTER_HISTOR_URL + `/${id}/${endPoint}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getUnassignedTripDetail(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCameraImages(jobId,assignId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + `fwas?assign=${assignId}&job=${jobId}`)
      .pipe(
        catchError(errorHandl)
      );
  }


  getTripDetails(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_TRIP_DETAILS_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );

  }

  getAdjustmentDetail(jobId, isFromBilling) {
    const endpoint = isFromBilling ? urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL : urls.GET_ADJUSTMENT_DETAIL_URL;
      return this.http.get<any>(urls.BASE_URL + endpoint +  jobId)
        .pipe(
          catchError(errorHandl)
        );
  }

  // getAdjustmentDetail(endPoint, data) {

  //   if (data) {
  //     delete data['escort'];
  //     return this.http.put<any>(urls.BASE_URL + endPoint, data)
  //       .pipe(
  //         catchError(errorHandl)
  //       );
  //   } else {
  //     return this.http.get<any>(urls.BASE_URL + endPoint)
  //       .pipe(
  //         catchError(errorHandl)
  //       );
  //   }
  // }

  saveOdometer(endPoint, data) {
    // Previously getAdjustmentDetail
    if (data) {
      delete data['escort'];
      return this.http.put<any>(urls.BASE_URL + endPoint, data)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.get<any>(urls.BASE_URL + endPoint)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  saveTripAdjustment(jobId, payload, isFromBilling) {
    const endpoint = isFromBilling ? urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL : urls.GET_ADJUSTMENT_DETAIL_URL;
    delete payload['escort'];
    return this.http.put<any>(urls.BASE_URL + endpoint + jobId, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getBrokerRate(endPoint) {
    return this.http.get<any>(urls.BASE_URL + urls.GET_BROKER_RATE_URL + endPoint)
      .pipe(
        catchError(errorHandl)
      );
  }

  getNotifications(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NOTIFICATIONS_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveDriverFare(id, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TRIP_FARE_URL + '/' + id, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  getDriverTrips(driverId) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_MANIFEST_TRIPS, {'driverId': driverId} )
      .pipe(
        catchError(errorHandl)
        
      );
  }
}
