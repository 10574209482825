import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { TripPurposesService } from '../trip-purposes.service';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trip-purposes-add',
  templateUrl: './trip-purposes-add.component.html',
  styleUrls: ['./trip-purposes-add.component.css']
})
export class AddTripPurposesComponent implements OnInit, OnDestroy {
  tripPurposesForm: FormGroup;
  tripPurposesServicesForm: FormGroup;
  submitted = false;
  tripPurposesId;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private tripPurposesService: TripPurposesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.tripPurposesForm = this.fb.group({
      title: ['', Validators.required],
      status: ['', Validators.required],
    });

    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.tripPurposesId = params["id"];
      this.getTripPurposeById();
    });


  }

  getTripPurposeById() {
    this.tripPurposesService.getTripPurposesListById(this.tripPurposesId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.tripPurposesForm.patchValue(data)
    })
  }

  onCreateTripPurposes() {
    this.submitted = true;
    console.log('Form ',this.tripPurposesForm)
    if (this.tripPurposesForm.invalid) {
      return;
    }

    if (!this.tripPurposesId) {
      this.tripPurposesService.addTripPurposes(this.tripPurposesForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('/fares/trip-purposes')
          }
        }, (err) => {
          sweetAlert("Error", err.error.message, "error", "Ok");
        })
    } else {
      this.tripPurposesService.updateTripPurposes(this.tripPurposesId, this.tripPurposesForm.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.router.navigateByUrl('/fares/trip-purposes')
        }
      }, (err) => {
        sweetAlert("Error", err.error.message, "error", "Ok");
      })
    }
  }

  get form() {
    return this.tripPurposesForm.controls;
  }

  getAccountStatus() {
    return this.authService.accountStatus;
  }

  ngOnDestroy(): void {
  }

}
