import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PAYOR, getBrokersList } from '../../../utils/utils.common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { ProTipsServiceService } from 'src/app/component/helping-material/nemt-pro-tips/service/pro-tips-service.service';
import { ContentObserver } from '@angular/cdk/observers';



@Component({
  selector: 'app-partial-edit',
  templateUrl: './partial-edit.component.html',
  styleUrls: ['./partial-edit.component.css']
})
export class PartialEditComponent implements OnInit {
  @Input() trip: any;
  @Output() onCloseModal = new EventEmitter<string>();
  @Output() updateTripObj = new EventEmitter<any>();
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  payorList = PAYOR;
  isFileLoading = false;
  selectedFile = null;
  pdfAttachment;

  constructor(
    private proTipsService: ProTipsServiceService,
    private assignTripService: AssigTripsService,
    private fb: FormBuilder) { }

  updateTripModalForm: FormGroup;

  ngOnInit() {
    this.updateTripModalForm = this.fb.group({
      _id: [''],
      dispatchNoteTwo: [''],
      companyNote: [''],
      attachment: ['']
    });

    this.updateTripModalForm.patchValue({
      ...this.trip
    })
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  uploadFile(selectedPDFFile) {
    this.isFileLoading = true;
    this.proTipsService
      .changeCoverImage(selectedPDFFile)
      .subscribe(data => {
        console.log(data, "pdf file",data)
        if (data) {

          this.isFileLoading = false;
          // this.pdfAttachment = data.loaction
          console.log(data, "pdf file2")
          this.updateTripModalForm.patchValue({ attachment: data.location });
        }
      });
  }

  processCoverImageFile(pdfInput: any) {
    console.log('Processing image file', pdfInput)
    imageProcessing(pdfInput)
      .subscribe((result) => {
        this.fileInput.nativeElement.value = '';
        if (result && result.file && result.file.type === "application/pdf") {
          this.uploadFile(result.file);
        } else {
          // this.toastr.success("Invalid file uploaded", 'Alert');
        }
      });
  }


  onSubmit() {
    this.updateTrip(this.updateTripModalForm.value);
  }

  updateTrip(payload) {
    this.assignTripService
      .updateTrip(payload)
      .subscribe(data => {
        if (data) {
          this.updateTripObj.emit({_id: data._id, attachment: data.attachment , companyNote: data.companyNote, dispatchNoteTwo : data.dispatchNoteTwo});
          this.closeModal();
        }
      });

  }

  removeAttachment() {
    this.updateTripModalForm.patchValue({ attachment: '' });
  }

  get form() {
    return this.updateTripModalForm.controls;
  }



}
