import { Component, OnInit,Input,Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sweetAlert } from '../../../utils/swal';
import { DriversService } from '../drivers-service/drivers-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-driver-profile-dashboard',
  templateUrl: './driver-profile-dashboard.component.html',
  styleUrls: ['./driver-profile-dashboard.component.css']
})
export class DriverProfileDashboardComponent implements OnInit, OnDestroy {
  @Input() driverProfileModalObj;
  @Output() onCloseModal = new EventEmitter<any>();
  driverDetails = null;
  password = null;
  imageModalUrl = null;
  path = null;
  driverId;
  passwordType;
  showErrorMessage = false;


  constructor(
    private driverService: DriversService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
      this.driverService
      .getDriverProfile(this.driverProfileModalObj._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverDetails = data;
        }
      });
  
    
  }

  

  generatePassword(passwordType) {
    console.log('passwordType')
    this.passwordType = passwordType;
    
    if(passwordType === 'auto'){
      this.password = (new Date().getTime()).toString(36).substring(0, 9);
    }

     else if(passwordType === 'manual'){
      this.password = "";
     }
  }

  resetPassword() {
    console.log('password value', this.password);
    if(!this.password){
      console.log('here')
      this.showErrorMessage = true;
      return;
    }
    sweetAlert('Are you sure you want to Reset Password?', 'An Email with updated password will be send to driver\'s email.', 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          const newPassData = {
            driverId: this.driverProfileModalObj._id,
            newPassword: this.password
          };
          this.password = null;
          this.driverService
            .resetDriverPassword(newPassData)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                // sweetAlert('Success', data.message, 'success', 'Ok');
                this.passwordType = null;
              }
            }, err => {
              sweetAlert('Error', err, 'error', 'Ok');
            });
        } else {
          this.password = null;
        }
      });
  }
  modalClose(){
    this.onCloseModal.emit(false);
  }

  ngOnDestroy(): void {
  }
}
