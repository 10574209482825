import { Component, OnDestroy, OnInit } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { CorporateService } from '../corporate-service/corporate-service';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { urls } from 'src/app/utils/url-utils';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { requiredOdometers } from "../../../utils/utils.common";
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { CompaniesService } from '../../companies/companies.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-corporate-user',
  templateUrl: './corporate-user.component.html',
  styleUrls: ['./corporate-user.component.css']
})


export class CorporateuserComponent implements OnInit, OnDestroy {
  corporateUsers = [];
  search;
  imageModalUrl;
  status = "active";
  odometers;
  user;
  canView = false;
  viewAccess = false;

  constructor(
    private corporateService: CorporateService,
    private companyService: CompaniesService,
    private sharedDataService: SharedDataService
  ) { 
    document.addEventListener('keyup', e => {
      // e.ctrlKey &&
      if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
        this.canView = !this.canView;
      }
    });
  }

  ngOnInit() {
    this.getUser();
    this.getcorporateUsers();
    this.getOdometerValue();
  }

  getUser() {
    this.sharedDataService.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((user) => {
      if (user) {
        this.user = user;
        if (this.user.roles === "company") {
          this.viewAccess = true;
        }
      }
    });
  }

  getcorporateUsers() {
    this.corporateUsers = [];
    this.corporateService
      .getCorporateUsers(this.status)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          this.corporateUsers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  downloadExcel(corporateId) {
    const payload = {cooperateId : corporateId};
    this.corporateService
      .downloadExcel(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });
    // CooperatesService.cooperateExcel({ cooperateId: cooperateId }, function (response) {
    //   $rootScope.isLoading = false;
    //   var baseURL = window.location.origin;
    //   var name = response.excelName;
    //   var url = baseURL + '/excel/' + name;
    //   console.log(url, name);
    //   DownloadFile.downloadURI(name, url);
    // }, function (err) {
    //   $rootScope.isLoading = false;
    //   console.log('Corporate Excel Generation Error', err);
    //   Notification.warning({
    //     message: err.data.message,
    //     title: 'Notification'
    //   });
    // });

  }

  getOdometerValue() {
    this.odometers = requiredOdometers.reduce((obj, item) => (obj[item.id] = item, obj), {});;
  }

  onChangeStatus() {
    this.getcorporateUsers()
  }

  openPanel(id) {
    this.companyService.getCompanyToken(id,'cooperate')
      .subscribe((data: any) => {
        if(environment.production) {
          window.open(`${environment.CORPORATE_PANEL_URL}auth-token/${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.CORPORATE_PANEL_URL}auth-token/${data.companyToken}`, '_blank');
        }
      });
  }


ngOnDestroy(): void {
}
}
