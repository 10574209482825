import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { Cms1500Service } from 'src/app/service/cms-service/cms1500.service';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-add-cms-code',
  templateUrl: './add-cms-code.component.html',
  styleUrls: ['./add-cms-code.component.css']
})
export class AddCmsCodeComponent implements OnInit {
  isCodeFormSubmitted = false;
  addoncodeFormSubmitted = false;
  cms1500CodesForm: FormGroup;
  cmsCodesForm: FormGroup;
  codeId;

  constructor(private fb: FormBuilder, private cms1500Service: Cms1500Service, private activatedRoute: ActivatedRoute, private router:Router) {

  }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.codeId = params["id"];
      if (this.codeId) {
        this.getCodeDetails();
      }
    });

    this.cms1500CodesForm = this.fb.group({
      companyType: ['', Validators.required],
      status: ['', Validators.required],
      codes: [[]],
    });

    this.cmsCodesForm = this.fb.group({
      title: ['', Validators.required],
      fare: ['', Validators.required],
    });
  }

  getCodeDetails() {
    this.cms1500Service
      .getCmsCodesById(this.codeId)
      .subscribe(data => {
        if (data) {
          this.cms1500CodesForm.patchValue(data);
        }
      })
  }


  getAccountStatus() {
    return this.cms1500Service.accountStatus;
  }


  onCreateCMSCodes() {
    const payload = this.cms1500CodesForm.value;
    this.cms1500Service.addCmsCodes(payload, this.codeId).subscribe(data => {
      if(data){;
        this.router.navigateByUrl('/fares/cmscodes');
      }
    });
  }







  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  addCmsCode() {
    this.isCodeFormSubmitted = true;

    if (this.cmsCodesForm.invalid) {
      return;
    }

    this.cms1500CodesForm.value.codes.push(this.cmsCodesForm.value);
    this.cmsCodesForm.reset();
    this.isCodeFormSubmitted = false;
  }


  removeCmsCodes(index) {
    const z1 = this.cms1500CodesForm.value.codes;
    z1.splice(index, 1);
    this.cms1500CodesForm.patchValue({
      codes: z1
    });
  }

  get form() {
    return this.cms1500CodesForm.controls;
  }

}
