import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-additional-note-modal',
  templateUrl: './additional-note-modal.component.html',
  styleUrls: ['./additional-note-modal.component.css']
})
export class AdditionalNoteModalComponent implements OnInit, OnDestroy {
  @Input() trip;
  @Output() additionalNoteAdded = new EventEmitter<any>();
  reasonSubmitted:boolean = false;

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  addAdditionalNote() {
    this.reasonSubmitted = true;
    if(this.trip.note === ''){
      return
    }
    this.assignTripService
      .addAdditionalNote(this.trip)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.additionalNoteAdded.emit(data);
        }
      });
  }

  closeModal() {
    this.additionalNoteAdded.emit(null);
  }

  ngOnDestroy(): void {
  }
}
