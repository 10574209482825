import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-vehicle-detail-modal',
  templateUrl: './vehicle-detail-modal.component.html',
  styleUrls: ['./vehicle-detail-modal.component.css']
})
export class VehicleDetailModalComponent implements OnInit {
  @Input('vehicle') assign: any;
  @Output() onClose = new EventEmitter<any>();
  user;
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.onClose.emit(null);
  }
  ngOnDestroy(): void {
  }
}

