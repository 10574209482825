import { Component, OnInit } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { ModifierCodesService } from '../modifier-codes-service/modifier-codes.service';

@Component({
  selector: 'app-modifier-code-list',
  templateUrl: './modifier-code-list.component.html',
  styleUrls: ['./modifier-code-list.component.css']
})
export class ModifierCodeListComponent implements OnInit {
  search;
  modifierCodes =[];
  status = "active";

  constructor(
    private modifierCodesService: ModifierCodesService
  ) { }

  ngOnInit() {
    this.getModifierCodes();
  }

  getModifierCodes() {
      this.modifierCodesService
        .getModifierCodes()
        .subscribe(data => {
          if (data && data.length) {
            this.modifierCodes = data;
          }else{
            this.modifierCodes = [];
          }
        }, err => {
          sweetAlert("Error", err, "error", "Ok");
        });
  }
  
  ngOnDestroy(): void {
  }
}
