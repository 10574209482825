import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {userManuals} from '../helping-material.util'
@Component({
  selector: 'app-helping-material-pdf',
  templateUrl: './helping-material-pdf.component.html',
  styleUrls: ['./helping-material-pdf.component.css']
})
export class HelpingMaterialPdfComponent implements OnInit {

  url = null;
  ind;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.ind = this.activatedRoute.snapshot.url[2].path;
    this.url = userManuals[this.ind].url;
  }

}
