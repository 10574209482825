import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { ProTipsServiceService } from '../../helping-material/nemt-pro-tips/service/pro-tips-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-associate-image-modal',
  templateUrl: './associate-image-modal.component.html',
  styleUrls: ['./associate-image-modal.component.css']
})
export class AssociateImageModalComponent implements OnInit, OnDestroy {
  @Input() associateImageModalObj: any;
  @Output() onCloseModal = new EventEmitter<string>();
  @Output() updateAssociateImg = new EventEmitter<any>();
  @Output() updateCompImg = new EventEmitter<any>();
  associateForm: FormGroup;
  selectedFile = null;
  imageUrl;
  isFileLoading = false;
  submitted = false;
  constructor(private assignTripService: AssigTripsService,
    private proTipsService: ProTipsServiceService, private fb: FormBuilder) { }

  ngOnInit() {
    this.imageUrl = this.associateImageModalObj.associateImage;
    this.associateForm = this.fb.group({
      associateImageType: ['location', Validators.required]
    });

    if (this.associateImageModalObj.associateImageType){
      this.associateForm.patchValue({
        associateImageType: this.associateImageModalObj.associateImageType
      });
    }
  }


  closeModal() {
    this.onCloseModal.emit();
  }

  readURL(event: HTMLInputEvent): void {
    if (event.target && event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }

    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  submitFile() {
    if(!this.selectedFile) return;
    this.isFileLoading = true;
    this.proTipsService
      .changeCoverImage(this.selectedFile)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.isFileLoading = false;
          if(this.associateImageModalObj.status === 'finished' || this.associateImageModalObj.jobStatus === 'finished') {
            this.updateCompletedTrip(data.location);
          }  else {
          this.updateTrip(data.location);
          }
        }
      });
  }

  updateTrip(url){
    this.submitted = true;
    const payload = {
      associateImage: url,
      _id : this.associateImageModalObj._id,
      associateImageType : this.associateForm.value.associateImageType,
    };
    this.assignTripService
      .updateAssociateImg(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.updateAssociateImg.emit({tripId: this.associateImageModalObj._id , associateImage: data.associateImage, associateImageType : data.associateImageType});
          this.closeModal();
        }
      });
  }

  updateCompletedTrip(url){
    this.submitted = true;
    const payload = {
      associateImage: url,
      jobId : this.associateImageModalObj.job._id,
      associateImageType : this.associateForm.value.associateImageType,
    };
    this.assignTripService
      .updateCompTripImg(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.message) {
          this.updateCompImg.emit({jobId: this.associateImageModalObj.job._id , associateImage: payload.associateImage, associateImageType : payload.associateImageType});
          this.closeModal();
        }
      });
  }

  removeImage(){
    this.selectedFile = null;
    this.imageUrl = '';
  }

  get form() {
    return this.associateForm.controls;
  }

  ngOnDestroy(): void {
  }
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
