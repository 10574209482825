import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dispatch-inprogress-trips-slideout',
  templateUrl: './dispatch-inprogress-trips-slideout.component.html',
  styleUrls: ['./dispatch-inprogress-trips-slideout.component.css']
})
export class DispatchInprogressTripsSlideoutComponent implements OnInit {
  @Input() trips;
  @Output() close = new EventEmitter<any>();
  search;

  constructor() { }

  ngOnInit() {
  }

}
