import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ServiceAreasService {

  constructor(
    private http: HttpClient
  ) { }

  getServiceAreas(queryParams): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.BASE_URL + urls.SERVICE_AREAS, {params})
      .pipe(
        catchError(errorHandl)
      );
  }

  getServiceAreasbyId(id : any): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.SERVICE_AREAS + '/'+ id)
      .pipe(
        catchError(errorHandl)
      );
  }

  addServiceAreas(data : any, id?): Observable<any>{
    if(id){
      return this.http.put<any>(urls.BASE_URL + urls.SERVICE_AREAS + '/' + id ,data)
      .pipe(
        catchError(errorHandl)
      );
    }else{
      return this.http.post<any>(urls.BASE_URL + urls.SERVICE_AREAS ,data)
      .pipe(
        catchError(errorHandl)
      );
    }

  }
}

