import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
import { getImageAsEmbedNew, removeNonAllowedCharactersFromPDF, urlToArrayBuffer } from 'src/app/utils/utils.common';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AccidentalReportService {

  constructor(private http: HttpClient) { }

  addAccidentReport(data): Observable<any> {
    console.log(data);
    delete data.basicInformation.accidentDate;
    delete data.basicInformation.accidentHour;
    delete data.basicInformation.accidentMin;
    console.log(data);

    return this.http.post<any>(urls.BASE_URL + urls.ACCIDENT_REPORT_URL, data)
      .pipe(
        catchError(errorHandl)
      );

  }

  getAccidentReport(): Observable<any> {

    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.ACCIDENT_REPORT_URL)
      .pipe(
        catchError(errorHandl)
      );

  }

  getAccidentReportById(id): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.ACCIDENT_REPORT_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );

  }

  getLogisticarePdfReport(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append('reportType', payload.reportType);
    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.GENERATE_ACCIDENTAL_REPORT + `/${payload.reportId}?`, { params })
      .pipe(
        catchError(errorHandl)
      );

  }

  getNemtPdfReport(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append('reportType', payload.reportType);
    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.GENERATE_ACCIDENTAL_REPORT + `/${payload.reportId}?`, { params })
      .pipe(
        catchError(errorHandl)
      );

  }

  async fillPDF(pdfBuffer: ArrayBuffer, payload, user): Promise<ArrayBuffer> {
    console.log(payload)
    let pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();


    const ownerProfilePhoto: any = await urlToArrayBuffer(payload.fleet.ownerImage);
    const ownerPhoto = await getImageAsEmbedNew(pdfDoc, payload.fleet.ownerImage, ownerProfilePhoto);

    const profileLogo: any = await urlToArrayBuffer(user.profileImageURL);
    const companyLogo = await getImageAsEmbedNew(pdfDoc, user.profileImageURL, profileLogo);

    const jpgImageBytes: any = await urlToArrayBuffer(payload.driver.driverSignatureImage);
    const pngImage = await getImageAsEmbedNew(pdfDoc, payload.driver.driverSignatureImage, jpgImageBytes);

    const jpgDriverImageBytes: any = await urlToArrayBuffer(payload.driver.profileImageURL);
    const driverImage = await getImageAsEmbedNew(pdfDoc, payload.driver.profileImageURL, jpgDriverImageBytes);



    form.getTextField('c_name').setText(user.displayName);
    // form.getTextField('company_name').setText(user.displayName);
    if (companyLogo)
      form.getTextField('c_logo').setImage(companyLogo);
    form.getTextField('provider_company').setText(payload.company.displayName);
    form.getTextField('incident_date_time').setText(moment(payload.basicInformation.accidentAt).format('MM/DD/YY HH:mm') || '');
    form.getTextField('location_of_accident').setText(payload.basicInformation.location);
    form.getTextField('police_contacted').setText(payload.basicInformation.policeContact ? 'Yes' : 'No');
    form.getTextField('police_reported_filled').setText(payload.basicInformation.policeReport ? 'Yes' : 'No');
    form.getTextField('ticket_issued').setText(payload.basicInformation.policeTicketIssue ? 'Yes' : 'No');
    form.getTextField('ticket_received').setText(payload.basicInformation.policeTicketReceived);
    form.getTextField('police_agency').setText(payload.basicInformation.policeAgency);
    form.getTextField('officer_name').setText(payload.basicInformation.policeOfficerName);
    form.getTextField('is_ambulance_called').setText(payload.basicInformation.ambulanceCalled ? 'Yes' : 'No');

    //witness
    for (const [index, witnessess] of payload.witnessess.entries()) {
      if (index > 1) break;
      form.getTextField('witness' + (index + 1) + '_name').setText(witnessess.name);
      form.getTextField('witness' + (index + 1) + '_address').setText(witnessess.address);
      form.getTextField('witness' + (index + 1) + '_phone').setText(witnessess.phone);
    }

    //Driver
    form.getTextField('driver_name').setText(payload.driver.legalName);
    form.getTextField('driver_license_no').setText(payload.driver.driverLicenseNumber);
    form.getTextField('driver_address').setText(payload.driver.address);
    form.getTextField('driver_owner_home_no').setText(payload.driver.address);
    form.getTextField('driver_state').setText(payload.driver.state);
    form.getTextField('driver_reg_no').setText(payload.driver._id.substr(payload.driver._id.length - 5));

    //Passenger
    for (const [index, passenger] of payload.passengerInVehicle.entries()) {
      if (index > 1) break;
      form.getTextField('passenger' + (index + 1) + '_name').setText(passenger.name);
      form.getTextField('passenger' + (index + 1) + '_TripID').setText(passenger.tripId);
      form.getTextField('passenger' + (index + 1) + '_injured').setText(passenger.isInjured ? 'Yes' : 'No');
      form.getTextField('passenger' + (index + 1) + '_trustedby_ambulance').setText(passenger.transportedByAmbulance ? 'Yes' : 'No');
    }

    //Vehicle Information
    form.getTextField('vehicle_owner_name').setText(payload.fleet.ownerName);
    form.getTextField('vehicle_owner_address').setText(payload.fleet.ownerAddress);
    form.getTextField('vehicle_make_model').setText(payload.fleet.driverCarModel);
    form.getTextField('vehicle_color').setText(payload.fleet.driverCarColor);
    form.getTextField('vehicle_insurence_company').setText(payload.fleet.insuranceCompany);
    form.getTextField('vehicle_owner_business_no').setText(payload.fleet.ownerPhone);
    form.getTextField('vehicle_owner_home_no').setText(payload.fleet.ownerPhone);

    form.getTextField('vehicle_insurance_type').setText(payload.fleet.kindOfInsurance);
    form.getTextField('vehicle_vin').setText(payload.fleet.vin);
    form.getTextField('vehicle_license_no').setText(payload.fleet.licenseNo);
    form.getTextField('vehicle_reg_no').setText(payload.fleet.driverCarNumber);
    form.getTextField('vehicle_mileage').setText(payload.fleet.realOdometer.toString());


    //Vehicle Damage Information (Driver)
    form.getTextField('isVehicle_driveable').setText(payload.driverVehicleInformation.isDriveable ? 'Yes' : 'No');
    form.getTextField('vehicle_current_location_driver').setText(payload.driverVehicleInformation.vehicleCurrentLocation);
    form.getTextField('speedOfCollision').setText(payload.driverVehicleInformation.speed);
    form.getTextField('trafficSignal_violated').setText(payload.driverVehicleInformation.trafficSignalViolation ? 'Yes' : 'No');
    form.getTextField('weather_type').setText(payload.driverVehicleInformation.weather);
    form.getTextField('road_condition').setText(payload.driverVehicleInformation.roadContidion);
    form.getTextField('vehicle_mileage').setText(payload.otherVehicleInformation.ownerVehicleMileage);

    //driver car demage checkboxes
    if (payload.driverVehicleInformation.isFrontRightDamage) {

      form.getCheckBox('driver_car_indicate_1').check();
    }

    if (payload.driverVehicleInformation.isFrontDamage) {
      form.getCheckBox('driver_car_indicate_12').check();

    }

    if (payload.driverVehicleInformation.isFrontLeftDamage) {
      form.getCheckBox('driver_car_indicate_11').check();
    }

    if (payload.driverVehicleInformation.isLeftMirrorDamage) {
      form.getCheckBox('driver_car_indicate_9').check();
    }

    if (payload.driverVehicleInformation.isRightMirrorDamage) {
      form.getCheckBox('driver_car_indicate_3').check();
    }

    if (payload.driverVehicleInformation.isTopDamage) {
      form.getCheckBox('driver_car_indicate_13').check();
    }

    if (payload.driverVehicleInformation.isBackDamage) {
      form.getCheckBox('driver_car_indicate_6').check();
    }

    if (payload.driverVehicleInformation.isBackRightDamage) {
      form.getCheckBox('driver_car_indicate_5').check();
    }
    if (payload.driverVehicleInformation.isBackLeftDamage) {
      form.getCheckBox('driver_car_indicate_7').check();
    }

    if (payload.driverVehicleInformation.isRightRearDamage) {
      form.getCheckBox('driver_car_indicate_4').check();
    }

    if (payload.driverVehicleInformation.isLeftRearDamage) {
      form.getCheckBox('driver_car_indicate_8').check();
    }

    if (payload.driverVehicleInformation.isRightCenterDamage) {
      form.getCheckBox('driver_car_indicate_2').check();
    }

    if (payload.driverVehicleInformation.isLeftCenterDamage) {
      form.getCheckBox('driver_car_indicate_10').check();
    }

    //Vehicle Damage Information (other)
    form.getTextField('isVehicle_driveable_other').setText(payload.otherVehicleInformation.isDriveable ? 'Yes' : 'No');
    form.getTextField('other_vehicle_owner_name').setText(payload.otherVehicleInformation.ownerName);
    form.getTextField('other_vehicle_owner_address').setText(payload.otherVehicleInformation.ownerAddress);
    form.getTextField('other_vehicle_plate_state').setText(payload.otherVehicleInformation.licensePlateState);
    form.getTextField('other_vehicle_driver_name').setText(payload.otherVehicleInformation.driverName);
    form.getTextField('other_vehicle_license_no').setText(payload.otherVehicleInformation.driverLicenseNumber);
    form.getTextField('other_vehicle_plate_no').setText(payload.otherVehicleInformation.licensePlateNumber);

    form.getTextField('other_vehicle_license_state').setText(payload.otherVehicleInformation.driverLicenseState);
    form.getTextField('other_driver_phone').setText(payload.otherVehicleInformation.driverPhone);
    form.getTextField('other_vehicle_owner_phone').setText(payload.otherVehicleInformation.ownerPhone);
    form.getTextField('other_vehicle_make_model').setText(payload.otherVehicleInformation.makeModel);
    form.getTextField('other_vehicle_speed').setText(payload.otherVehicleInformation.speed);
    form.getTextField('other_driver_address').setText(payload.otherVehicleInformation.driverAddress);
    form.getTextField('vehicle_current_location').setText(payload.otherVehicleInformation.vehicleCurrentLocation);
    form.getTextField('owner_insurance_company').setText(payload.otherVehicleInformation.insuranceCompany);
    form.getTextField('owner_policy').setText(payload.otherVehicleInformation.policyNo);
    form.getTextField('owner_city').setText(payload.otherVehicleInformation.ownerCity);



    //other car demage checkboxes
    if (payload.otherVehicleInformation.isFrontRightDamage) {

      form.getCheckBox('other_car_indicate_1').check();
    }

    if (payload.otherVehicleInformation.isFrontDamage) {
      form.getCheckBox('other_car_indicate_12').check();

    }

    if (payload.otherVehicleInformation.isFrontLeftDamage) {
      form.getCheckBox('other_car_indicate_11').check();
    }

    if (payload.otherVehicleInformation.isLeftMirrorDamage) {
      form.getCheckBox('other_car_indicate_9').check();
    }

    if (payload.otherVehicleInformation.isRightMirrorDamage) {
      form.getCheckBox('other_car_indicate_3').check();
    }

    if (payload.otherVehicleInformation.isTopDamage) {
      form.getCheckBox('other_car_indicate_13').check();
    }

    if (payload.otherVehicleInformation.isBackDamage) {
      form.getCheckBox('other_car_indicate_6').check();
    }

    if (payload.otherVehicleInformation.isBackRightDamage) {
      form.getCheckBox('other_car_indicate_5').check();
    }
    if (payload.otherVehicleInformation.isBackLeftDamage) {
      form.getCheckBox('other_car_indicate_7').check();
    }

    if (payload.otherVehicleInformation.isRightRearDamage) {
      form.getCheckBox('other_car_indicate_4').check();
    }

    if (payload.otherVehicleInformation.isLeftRearDamage) {
      form.getCheckBox('other_car_indicate_8').check();
    }

    if (payload.otherVehicleInformation.isRightCenterDamage) {
      form.getCheckBox('other_car_indicate_2').check();
    }

    if (payload.otherVehicleInformation.isLeftCenterDamage) {
      form.getCheckBox('other_car_indicate_10').check();
    }



    // damage driver account
    form.getTextField('description_of_damage').setText(payload.driverVehicleInformation.damageDetails);
    form.getTextField('account_of_accident').setText(payload.driverDamageAccount.damageDescription);
    form.getTextField('accident_caused').setText(payload.driverDamageAccount.accidentCaused);
    form.getTextField('insured_vehicle_direction').setText(payload.driverDamageAccount.insuredVehicle);

    //footer
    //  form.getTextField('').setText(payload.driver.driverSignatureImage);
    if (payload.driver.driverSignatureImage && pngImage) {
      form.getTextField('driver_signature').setImage(pngImage);

    }
    if (driverImage)
      form.getTextField('driver_photo').setImage(driverImage);
    form.getTextField('reported_date').setText(moment(payload.created).format('MM/DD/YY') || '');

    //owner photo
    if (ownerPhoto)
      form.getTextField('owner_photo').setImage(ownerPhoto);

    //Accident Image
    for (const [index, otherItem] of payload.otherScenes.entries()) {
      if (index > 2) break;
      const accidentScenes: any = await urlToArrayBuffer(otherItem);
      const accidentImage1 = await getImageAsEmbedNew(pdfDoc, otherItem, accidentScenes);
      form.getTextField('image-' + (index + 1)).setImage(accidentImage1);
    }

    // //ehicle acccident
    for (const [index, accidentItem] of payload.accidentScenes.entries()) {
      if (index > 2) break;
      const accidentScenes: any = await urlToArrayBuffer(accidentItem);
      const accidentImage1 = await getImageAsEmbedNew(pdfDoc, accidentItem, accidentScenes);
      form.getTextField('image-' + (index + 4)).setImage(accidentImage1);
    }

    // //accidentScenes
    for (const [index, accidentItem] of payload.ownVehicle.entries()) {
      if (index > 2) break;
      const accidentScenes: any = await urlToArrayBuffer(accidentItem);
      const accidentImage1 = await getImageAsEmbedNew(pdfDoc, accidentItem, accidentScenes);
      form.getTextField('image-' + (index + 7)).setImage(accidentImage1);
    }
// remove non ASCII
   await removeNonAllowedCharactersFromPDF(form);
    try {
      const modifiedBuffer = await pdfDoc.save()
      return modifiedBuffer;

    } catch (error) {
      console.error('Error filling PDF:', error);
      return null; // Handle the error gracefully, e.g., by returning null
    }
  }


  async getAccidentReportPDF(payload, user: any, loader) {
    const response = await fetch('assets/documents/accidental_report_with_keys.pdf');
    let pdfBuffer = await response.arrayBuffer();

    pdfBuffer = await this.fillPDF(pdfBuffer, payload, user);

    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = payload.driver.legalName + '_accidental_report.pdf';
      loader.isloading = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }





}
