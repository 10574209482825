import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AssigTripsService } from "../../assigned-trips/assigntrips-service/assigntrips-service";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { ControlPosition, MapTypeControlOptions } from "@agm/core";

@Component({
  selector: "app-uber-trip-modal",
  templateUrl: "./uber-trip-modal.component.html",
  styleUrls: ["./uber-trip-modal.component.css"],
})
export class UberTripModalComponent implements OnInit {
  @Input() data;
  @Output() onClose = new EventEmitter<any>();
  uberDetail;
  uberReceipt;
  isDrawRoute = true;
  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };

  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  driverLocation;

  constructor(private assignTripService: AssigTripsService) {}

  ngOnInit() {
    this.uberDetails();
  }

  closeDetailModal(evt) {
    this.onClose.emit(evt);
  }

  uberDetails() {
    console.log("Assign Data", this.data);
    this.assignTripService
      .uberRideDetailsRequest(this.data.uber)
      .subscribe((data) => {
        if (data) {
          this.uberDetail = data;
          this.origin = {
            lat: this.uberDetail.pickup.latitude,
            lng: this.uberDetail.pickup.longitude
          };
          this.destination = {
            lat: this.uberDetail.destination.latitude,
            lng: this.uberDetail.destination.longitude
          };

          if (this.uberDetail.location) {
            this.driverLocation = {
              latitude: this.uberDetail.location.latitude,
              longitude: this.uberDetail.location.longitude
            }
          } else if (this.uberDetail.trip_end_location) {
            this.driverLocation = {
              latitude: this.uberDetail.trip_end_location.latitude,
              longitude: this.uberDetail.trip_end_location.longitude
            }
          }
        }
      });
    // this.assignTripService
    //   .uberRideReceiptRequest(this.data.uber)
    //   .subscribe((data) => {
    //     if (data) {
    //       console.log("Uber Receipt Data", data);
    //     }
    //   });
  }

  getUberReceipt() {
    this.assignTripService
      .uberRideReceiptRequest(this.uberDetail.request_id)
      .subscribe(data => {
        if (data) {
          this.uberReceipt = data;
        }
      });
  }
}
