import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { KpisService } from '../kpis-service/kpis.service';
import { Observable, from, Subscription } from "rxjs";
import { sweetAlert } from "src/app/utils/swal";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

declare var $;
@Component({
  selector: "app-driver-breakdown",
  templateUrl: "./driver-breakdown.component.html",
  styleUrls: ["./driver-breakdown.component.css"],
})
export class DriverBreakdownComponent implements OnInit, OnDestroy {
  dateForm: FormGroup;
  getKpisState: Observable<any>;
  kpisStats = null;
  drivers = [];
  searchDriver;
  scriptLoadCount = 0;
  serviceCharges = 0;
  perJobCharges = 0;
  totalPlatformCharges = 0;
  modalFlag:any
  
  

  constructor(private fb: FormBuilder,  private kpisService:KpisService) {
  }

  ngOnInit() {
    
    // this.getKpisState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     if (state.success) {
    //       if (
    //         state.type === KpisActionTypes.DRIVER_BREAKDOWN ||
    //         state.type === KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE
    //       ) {
    //         this.kpisStats =
    //           state.response.kpis.length > 0 ? state.response.kpis[0] : null;
    //         this.drivers =
    //           this.kpisStats && this.kpisStats.earnings.length > 0
    //             ? this.kpisStats.earnings
    //             : [];
    //         if (this.scriptLoadCount > 0) {
    //           var table = $("#dataTableExample").DataTable();
    //           table.destroy();
    //           removeDataTableScript();
    //         }

    //         addDataTableScript();
    //         this.scriptLoadCount++;

            // this.brokerKpis = state.response.brokerKpis;
            // this.corporateKpis = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].earnings : state.response.corporateKpis;
            // this.noCorporateTrips = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].totalJobs : 0;
            // this.driverKpis = state.response.driverKpis.length > 0 ? state.response.driverKpis[0].earnings : state.response.driverKpis;
            // this.brokerKpis.forEach(element => {
            //   this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
            // });
    //       }
    //     } else if (state.errorMessage) {
    //       sweetAlert("Error", state.errorMessage, "error", "Ok");
    //     }
    //   });

    this.dateForm = this.fb.group({
      dateRange: [""],
    });

    this.getDriverBreakDownDetails();
  }

  

  getDriverBreakDownDetails() {
    this.kpisService
                .getDriverBreakdownDetails()
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe(data => {
                  if(data){
                    this.kpisStats =
              data.kpis.length > 0 ? data.kpis[0] : null;
            this.drivers =
              this.kpisStats && this.kpisStats.earnings.length > 0
                ? this.kpisStats.earnings
                : [];

            this.scriptLoadCount++;
          }
                  
                })
    // this.store.dispatch(new GetDriverBreakdown());
  }

  getNemtStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].startOf('day').toISOString();
    const endDate = this.dateForm.value.dateRange[1].endOf('day').toISOString();
    this.kpisService
    .getDriverBreakdownDetailsByDate(startDate, endDate)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if(data){
        this.kpisStats =
        data.kpis.length > 0 ? data.kpis[0] : null;
      this.drivers =
        this.kpisStats && this.kpisStats.earnings.length > 0
          ? this.kpisStats.earnings
          : [];
      

      this.scriptLoadCount++;
      }
    })
    // this.store.dispatch(new DriverBreakdownByDateRange(startDate, endDate));
  }
  
   openAddExpenseModal(){
   this.modalFlag = !this.modalFlag
  }

  ngOnDestroy(): void {}

  // ------- NEMT Panel Driver expanse ----
  // Remove existing Expense
  // function remove() {
  //   if ($window.confirm('Are you sure you want to delete?')) {
  //     vm.expense.$remove($state.go('expenses.list'));
  //   }
  // }

  // // Save Expense
  // function save(isValid) {
  //   if (!isValid) {
  //     $scope.$broadcast('show-errors-check-validity', 'vm.form.expenseForm');
  //     return false;
  //   }

  //   // TODO: move create/update logic to service
  //   if (vm.expense._id) {
  //     vm.expense.$update(successCallback, errorCallback);
  //   } else {
  //     vm.expense.$save(successCallback, errorCallback);
  //   }

  //   function successCallback(res) {
  //     $state.go('expenses.view', {
  //       expenseId: res._id
  //     });
  //   }

  //   function errorCallback(res) {
  //     vm.error = res.data.message;
  //   }
  // }
  // ------- NEMT Panel Driver expanse ----

  
}
