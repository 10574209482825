import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DriversService } from "../drivers-service/drivers-service";
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { downloadBase64File } from "src/app/utils/pdf-downloader";
import { sweetAlert } from '../../../utils/swal';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { CsvService } from "src/app/service/csv-service/csv.service";

@Component({
  selector: "app-driver-timelines",
  templateUrl: "./driver-timelines.component.html",
  styleUrls: ["./driver-timelines.component.css"],
})
export class DriverTimelinesComponent implements OnInit, OnDestroy {
  user;
  search;
  drivers;
  selectedDriver;
  timeline;
  timelines;
  view = 'today';
  isShowBreakPopup = false;
  applyCaTimeSheet = false;
  currentDateTime = moment().startOf('day');
  preDateRange = [
    moment().startOf('day'),
    moment().endOf('day')
  ]
  dateRange = [
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString()
  ];
  break;

  // dates = {
  //   startDate: moment().startOf('day'),
  //   endDate: moment().endOf('day')
  // };
  timeZone;

  isHistoryData: boolean = false;
  isTodayData: boolean = false;
  isSelectedDriver: boolean = false;

  isBreakSubmitted = false;
  isTimelineSubmitted = false;
  breakDetail: boolean = false;
  editShift: boolean = false;
  selectedBreak = {
    time: moment().set({ 'hours': 0, 'minutes':5 }),
    min: moment().set({ 'hours': 0, 'minutes': 1 }),
    max: moment().set({ 'hours': 23, 'minutes': 59}),
  }
  preDrivers: any;


  @ViewChild('btnCloseTimelineModal', null) btnClose: ElementRef;

  constructor(
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private csvService : CsvService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.applyCaTimeSheet = this.user.applyCaTimeSheet;
          this.timeZone = this.user.timeZone;
          this.dateRange = [
            moment().tz(this.timeZone).startOf('day').toISOString(),
            moment().tz(this.timeZone).endOf('day').toISOString()
          ];
          this.getDrivers();
        }
      });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, "active")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.drivers = data;
          this.preDrivers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});

        }
      });
  }

  setView(view) {
    this.view = view;
    this.getDriverTimelines();
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.getDriverTimelines();
    this.closeView();
  }

  getDriverTimelines() {
    if (!this.selectedDriver) return;
    let queryString = {
      driver: this.selectedDriver._id
    };

    if (this.view === 'history') {
      queryString['dates'] = { "startDate": this.dateRange[0], "endDate": this.dateRange[1] };
    }

    this.driverService
      .getDriverTimelines(queryString)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (this.view === 'today') {
            this.timeline = data;
          } else if (this.view === 'history') {
            this.timelines = {};
            if (data.length) {
              this.timelines = data[0];
            }
          }
        } else {
          this.timeline = undefined;
          this.timelines = undefined;
        }
      });
  }

  showBreakPopup() {
    this.isShowBreakPopup = !this.isShowBreakPopup;
    if (this.isShowBreakPopup) {
      this.isBreakSubmitted = false;
      this.break = undefined;
    }
  }

  viewHourFormat(milli) {
    let duration = moment.duration(milli, 'milliseconds');
    return `${Math.floor(duration.asHours())}hr ${duration.minutes()}mins`;
  }

  onChangeDate(evt) {
    this.dateRange = [evt[0].tz(this.timeZone).startOf('day').toISOString(), evt[1].tz(this.timeZone).endOf('day').toISOString()];
    this.getDriverTimelines();
  }

  selectDuration(value) {
    this.break = value;
  }

  // saveBreak() {
  //   this.isBreakSubmitted = true;
  //   if (!this.break) return;
  // }

  saveBreak() {
    let totalMinutes = 0;
    if(this.break === 'custom'){
      const hours =  moment(this.selectedBreak.time).hours();
      const minutes = moment(this.selectedBreak.time).minutes();
      totalMinutes = hours * 60 + minutes;
    }
    this.isBreakSubmitted = true;
    if (!this.break) return;
    if (this.break === 10 || this.break === 15 || this.break === 30 || this.break === 'custom') {
      this.action('approved', { value: this.break === 'custom' ? totalMinutes : this.break })
    } else {
      this.action('open');
    }
  }
  checkBreak() {
    if (this.timeline.status === 'approved') {
      let isBreak = false;
      let lastBreakTimeEnd;
      let stop = moment().tz(this.timeZone);
      let lastBreak = this.timeline.breaks[this.timeline.breaks.length - 1];
      if (lastBreak) {
        lastBreakTimeEnd = moment(lastBreak.stop).tz(this.timeZone);
        if (moment.duration(lastBreakTimeEnd.diff(stop)).asSeconds() > 1) {
          isBreak = true;
        }
      }
      if (isBreak) {
        sweetAlert('Alert', 'Driver is already on a break. Break ends at ' + lastBreakTimeEnd.format('HHmm'), 'warning', 'Ok')
      }
      return isBreak;
    }
  }
  action(status, data?) {
    let payload: any = {
      driver: this.selectedDriver._id,
      currDriver: {
        _id: this.selectedDriver._id,
        deviceKey: this.selectedDriver._id.deviceKey,
        deviceType: this.selectedDriver._id.deviceType
      },
      status: status,
      break: {
        status: status,
        label: 'Your break request is ' + status
      }
    };

    if (status === 'none') {
      payload['checkin'] = moment().tz(this.timeZone).toISOString();
      delete payload.break;
    }

    if (status === 'approved') {
      if (this.checkBreak()) {
        return;
      }
      let start = moment().tz(this.timeZone);
      let stop = moment().tz(this.timeZone).add(data.value, 'minutes');
      let duration = moment.duration(stop.diff(start));
      payload.break.duration = duration.asMilliseconds();
      payload.break.value = data.value;
      payload.break.start = start.toISOString();
      payload.break.stop = stop.toISOString();
    }

    if (status === 'open') {
      if (this.checkBreak()) {
        return;
      }
      payload.break.label = 'Back office has sent you on a break';
      payload.break.value = -1;
      payload.break.start = moment().tz(this.timeZone).toISOString();
    }

    if (status === 'close') {
      let start = moment(data.start).tz(this.timeZone);
      let stop = moment().tz(this.timeZone);
      let value = stop.diff(start, 'minutes')
      payload.status = 'approved';
      payload.break = {
        source: 'dispatcher',
        label: 'The back office has ended your break',
        status: 'approved',
        start: data.start,
        stop: stop.toISOString(),
        value: value,
        duration: moment.duration(stop.diff(start)).asMilliseconds()
      }
    }

    if (status === 'completed') {
      let start = moment(this.timeline.checkin).tz(this.timeZone);
      let stop = moment().tz(this.timeZone);
      let duration = moment.duration(stop.diff(start));
      let breakTimeInMillis = 0;
      let isBreak = false;
      let lastBreakTimeEnd;
      this.timeline.breaks.forEach((b) => {
        isBreak = false;
        if (b.status === 'approved') {
          lastBreakTimeEnd = moment(b.stop).tz(this.timeZone);
          if (moment.duration(lastBreakTimeEnd.diff(stop)).asSeconds() > 1) {
            isBreak = true;
          }
          breakTimeInMillis += b.duration;
        }
      });
      if (isBreak) {
        return sweetAlert('Alert', 'Driver is already on a break. Break ends at ' + lastBreakTimeEnd.format('HHmm'), 'warning', 'Ok')
      }
      payload.checkin = start;
      payload.breakTimeInMillis = breakTimeInMillis || 0;
      payload.workTimeInMillis = (duration.asMilliseconds() - breakTimeInMillis) || 0;
      payload.checkout = stop;
      delete payload.break;
    }
    this.sendRequest(payload);
  }

  sendRequest(payload) {
    // ----
    sweetAlert('Alert', 'Are you sure you want to proceed?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.driverService
            .saveDriverBreak(payload)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.break = undefined;
                this.isShowBreakPopup = false;
                this.timeline = data;
              }
            });
        }
      })
    // ----
  }

  viewDetail(currentTimeline) {
    this.timeline = undefined;
    this.driverService
      .getDriverTimelineById(currentTimeline._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeline = data;
        }
      });
    this.breakHistory();
  }

  deleteDetail(currentTimeline) {
    console.log(currentTimeline)
    sweetAlert('Alert', 'Are you sure you want to delete?', 'warning', 'Yes', 'No')
    .then((result: any) => {
      if (result.value) {
        this.driverService
        .deleteDriverTimelineById(currentTimeline._id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.getDriverTimelines();
          }
        });
      }
    })
  }

  editDetail(currentTimeline) {
    this.timeline = undefined;
    this.isTimelineSubmitted = false;
    this.driverService
      .getDriverTimelineById(currentTimeline._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeline = data;
        }
      });
    this.shiftEdit();
  }

  saveTimeline() {
    this.isTimelineSubmitted = true;
    if (!this.timeline.checkin || !this.timeline.checkout) {
      return;
    }

    let start = moment(this.timeline.checkin).tz(this.timeZone);
    let stop = moment(this.timeline.checkout).tz(this.timeZone);
    let duration = moment.duration(stop.diff(start));
    let breakTimeInMillis = 0;
    let isBreak = false;
    let lastBreakTimeEnd;
    if (this.timeline && this.timeline.breaks) {
      this.timeline.breaks.forEach(function (b) {
        isBreak = false;
        if (b.status === 'approved') {
          lastBreakTimeEnd = moment(b.stop).tz(this.timeZone);
          if (moment.duration(lastBreakTimeEnd.diff(stop)).asSeconds() > 1) {
            isBreak = true;
          }
          let lastBreakTimeStart = moment(b.start).tz(this.timeZone);
          let duration = moment.duration(lastBreakTimeEnd.diff(lastBreakTimeStart));
          b.duration = duration.asMilliseconds();
          breakTimeInMillis += b.duration;
        }
      });
    }
    if (isBreak) {
      return sweetAlert('Alert', 'Driver is already on a break. Break ends in ' + lastBreakTimeEnd.format('HHmm'), 'warning', 'Ok')
      // alert('Driver is already on a break. Break ends in ' + lastBreakTimeEnd.format('HHmm'));
    }
    this.timeline.breakTimeInMillis = breakTimeInMillis || 0;
    this.timeline.workTimeInMillis = (duration.asMilliseconds() - breakTimeInMillis) || 0;
    this.timeline.checkin = start;
    this.timeline.checkout = stop;
    this.timeline.status = 'completed';
    if (this.timeline._id) {
      this.saveTimelineRequest(this.timeline);
    } else {
      this.timeline.driver = this.selectedDriver._id;
      this.saveTimelineRequest(this.timeline);
    }
    this.editShift = false;
  }

  saveTimelineRequest(payload) {
    this.driverService
      .saveDriverTimeline(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.timeline = data;
        if (this.timelines) {
          // this.btnClose.nativeElement.click();
          this.getDriverTimelines();
        }
      });
  }

  addTimeSheet() {
    this.shiftEdit();
    this.isTimelineSubmitted = false;
    this.timeline = {
      checkin: null,
      checkout: null
    };
  }

  generateReport(type?) {
    let queryString = {};
    queryString['dates'] = { "startDate": this.dateRange[0], "endDate": this.dateRange[1] };
    if (type === 'driver') {
      queryString['driver'] = this.selectedDriver._id;
    }
    this.driverService
      .getDriverTimelinesReport(queryString)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          const res = data[0];
          if (res.content && res.pdfName) {
            downloadBase64File(res.content, res.pdfName);
          }
        }
      });
  }

  generateCSVReport(){
    const data = this.timelines;
    const date = { "startDate": this.dateRange[0], "endDate": this.dateRange[1] }
    const selectedDriver = this.preDrivers[this.timelines._id.driver]
    this.csvService.multiSheetTimeline(data,date,selectedDriver)
   }

  breakHistory() {
    this.breakDetail = true
    this.editShift = false;
  }

  shiftEdit() {
    this.editShift = true;
    this.breakDetail = false;
  }

  closeView() {
    this.breakDetail = false;
    this.editShift = false;
  }


  ngOnDestroy(): void {
  }

}
