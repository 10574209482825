import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { urls } from 'src/app/utils/url-utils';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class ProTipsServiceService {

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) { }

  getProTips(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_NEMT_PRO_TIPS_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTipsProfile(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_NEMT_PRO_TIPS_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveProTip(tipData): Observable<any> {
    const images = tipData.images ? tipData.images :[] ;
    const tipId = tipData.tipId;
    delete tipData.tipId;
    // const formData = setFormData(imageFiles, tipData, 'tip');
    if (tipId) {
      return this.http.put<any>(urls.BASE_URL + urls.GET_NEMT_PRO_TIPS_URL + '/' + tipId, { ...tipData, images})
      .pipe(
        catchError(errorHandl)
      );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.GET_NEMT_PRO_TIPS_URL, { ...tipData, images})
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  changeCoverImage(data): Observable<any> {
    const formData = new FormData();
    formData.append('file', data, data.name);
    return this.http.put<any>(urls.BASE_URL + urls.GET_NEMT_PRO_TIPS_URL, formData)
      .pipe(
        catchError(errorHandl)
      );

  }
}
