import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { ProTipsServiceService } from '../service/pro-tips-service.service';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-nemt-pro-tips',
  templateUrl: './nemt-pro-tips.component.html',
  styleUrls: ['./nemt-pro-tips.component.css']
})
export class NemtProTipsComponent implements OnInit, OnDestroy {
  proTips = [];
  images = [];

  constructor(private proTipsService: ProTipsServiceService) { }

  ngOnInit() {
    this.getNemtProTips();
  }

  getNemtProTips() {
    this.proTipsService.getProTips()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.proTips = data;
        }
      },
    );
  }

  // validUrl(): boolean {
  //   const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  //   if(Validators.pattern(reg)){
  //     return true;
  //   }
  //   else return false;
  // }

  isValidHttpUrl(imgUrl) {
    // console.log("Image URL",imgUrl);
    // console.log("Validating", this.proTips)

    return imgUrl ? imgUrl : 'assets/images/placeholders/no_image.png'
  }

  ngOnDestroy(): void {
  }
}
