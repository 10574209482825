import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { downloadBase64File, downloadFile } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { KpisService } from '../kpis-service/kpis.service';
import { urls } from 'src/app/utils/url-utils';
import { state } from '@angular/animations';
@Component({
  selector: 'app-nemt-statistics',
  templateUrl: './nemt-statistics.component.html',
  styleUrls: ['./nemt-statistics.component.css']
})
export class NemtStatisticsComponent implements OnInit, OnDestroy {

  getKpisState: Observable<any>;
  kpisStats;
  dateForm: FormGroup;
  constructor( private kpisService: KpisService, private fb: FormBuilder, private router: Router) {
  }

  ngOnInit() {
    // this.getKpisState
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   if (state.success) {
    //     if (state.type === KpisActionTypes.NEMT_STATISTICS || state.type === KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE) {
    //       this.kpisStats = state.response.length > 0 ? state.response[0] : null;
    //     } else if (state.type === KpisActionTypes.NEMT_STAT_REPORT_PDF) {
    //       downloadBase64File(state.response.content, state.response.pdfName);
    //     }
    //   } else if (state.errorMessage) {
    //     sweetAlert('Error', state.errorMessage, 'error', 'Ok');
    //   }
    // });

    this.dateForm = this.fb.group({
      dateRange: ['']
    });
    this.getNemtStats();
  }

  getNemtStats() {
    this.kpisService
      .getNemtStatistics()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.kpisStats = data.length > 0 ? data[0] : null;
        }
      })
    // this.store.dispatch(new NemtStatistics());
  }

  getNemtStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].startOf('day').toISOString();
    const endDate = this.dateForm.value.dateRange[1].endOf('day').toISOString();
    this.kpisService
      .getNemtStatisticsByDate(startDate, endDate)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.kpisStats = data.length > 0 ? data[0] : null;
        }
      })
    // this.store.dispatch(new NemtStatisticsByDateRange(startDate, endDate));
  }

  statDetails(status) {
    if (this.dateForm.value.dateRange[0] && this.dateForm.value.dateRange[1]) {
      // tslint:disable-next-line: max-line-length
      // this.router.navigate(['reports/statisticsdetails'], { state: { status, startDate: this.dateForm.value.dateRange[0].toISOString(),
      //    endDate: this.dateForm.value.dateRange[1].toISOString() } });
         let w = window.open('reports/statisticsdetails');
         w['newData'] = { status, startDate: this.dateForm.value.dateRange[0].startOf('day').toISOString(), endDate: this.dateForm.value.dateRange[1].endOf('day').toISOString() }
    } else {
      sweetAlert('Error', 'Please Select Date Range First.', 'error', 'Ok');
    }
  }

  getStatReport() {
    if (this.dateForm.value.dateRange[0] && this.dateForm.value.dateRange[1]) {
      const data = {
        endDate: this.dateForm.value.dateRange[1].endOf('day').toISOString(),
        startDate: this.dateForm.value.dateRange[0].startOf('day').toISOString()
      };
      this.kpisService
        .getNemtStatisticsReportPdf(data)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            downloadBase64File(data.content, data.pdfName);
          }
        })
      // this.store.dispatch(new NemtStatisticsReportPdf(data));
    }
  }

  kpiTripsReport() {
    console.log('Check Download')
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
    };
    // DownloadFile.downloadURI(name, url);

    // console.log('check payload 1', payload)
    this.kpisService
      .getNemtStatsTripReportsXls(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      })
  }

  ngOnDestroy(): void {

  }
}
