import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setFormData, setFormDataV2 } from '../../../utils/network-utils';

@Injectable({
    providedIn: 'root'
})
export class CorporateService {

    constructor(private http: HttpClient) {
    }

    getCorporateUsers(status): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL + '?accountStatus=' + status)
            .pipe(
                catchError(errorHandl)
            );
    }

    getCorporateUserById(id): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL + '/' + id)
            .pipe(
                catchError(errorHandl)
            );
    }

    addCorporateUser(data): Observable<any> {
        // Check for empty
        if (!(data.payorTypes && data.payorTypes.length)) {
            delete data.payorTypes;
        }
        const imageFiles = data.images;
        const corporateUserId = data.corporateUserId;
        delete data.images;
        delete data.corporateUserId;
        if (!(data.payorTypes && data.payorTypes.length) && !corporateUserId) {
            delete data.payorTypes;
        }
        let formData;
        if (!imageFiles.newProfilePicture) {
            formData = setFormDataV2({...data}, 'cooperate');
        } else {
            formData = setFormDataV2({...data, ...imageFiles}, 'cooperate');
        }
        // const formData = setFormData(imageFiles, data, 'cooperate');
        if (corporateUserId) {
            return this.http.put<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL + '/' + corporateUserId, formData).pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL, formData).pipe(
                catchError(errorHandl)
            );
        }
    }

    downloadExcel(payload) {
        return this.http.post<any>(urls.BASE_URL + urls.GET_CORPORATE_EXCEL_URL, payload).pipe(
            catchError(errorHandl)
        );
    }
}
