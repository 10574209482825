import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class VehicleInspectionService {

  constructor(private http: HttpClient) { }



  addVehicleInspection(vehicleInspectionId, payload): Observable<any> {
    if (vehicleInspectionId) {
      return this.http.put<any>(urls.BASE_URL + urls.ADD_VEHICLE_INSPECTIONS + '/' + vehicleInspectionId, payload)
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.ADD_VEHICLE_INSPECTIONS, payload)
    }
  }

  getVehicleInspection(fleet): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.ADD_VEHICLE_INSPECTIONS + '?fleet=' + fleet)
  }


  getVehicleInspectionByID(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.ADD_VEHICLE_INSPECTIONS + '/' + id)
  }

  generateSignature(data): Observable<any> {
    const formData = new FormData();
    formData.append('file', data);
    return this.http.put<any>(urls.BASE_URL + urls.ADD_VEHICLE_INSPECTIONS , formData)
  }

  getDailyVehicleInspection(queryParams): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(urls.BASE_URL + urls.DAILY_VEHICLE_INSPECTIONS_URL , { params })
  }
}
