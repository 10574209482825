import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private http: HttpClient) { }

  getDriverExpense(driverId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_EXPENSE_URL + driverId).pipe(
      catchError(errorHandl)
    );
}

getVehicleExpense(vehicleId): Observable<any> {
  return this.http.get<any>(urls.BASE_URL + urls.VEHICLE_EXPENSE_URL + vehicleId).pipe(
    catchError(errorHandl)
  );
}

getMaintenanceStats(): Observable<any> {
  return this.http.get<any>(urls.BASE_URL + urls.MAINTENANCE_STATS_URL).pipe(
    catchError(errorHandl)
  );
}

}
