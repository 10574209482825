import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BeachwoodReportService } from 'src/app/service/beachwood-service/beachwood-report.service';
import { getRandomString } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-bt-facility-modal',
  templateUrl: './bt-facility-modal.component.html',
  styleUrls: ['./bt-facility-modal.component.css']
})
export class BtFacilityModalComponent implements OnInit {

  @Input() bwfLoader = {
    isLoading: false,
    isbwfModal: false,
  }
  isCalculate = false
  beachWoodFacilityForm : FormGroup
  @Input() company
  @Input() date
  @Input() selectedTrips
  @Input() users
  @Input() selectedUsers
  @Input() corporatePayor

  @Output() closeModal =new EventEmitter <any>();
  constructor(
    private fb: FormBuilder,
    private beachwoodReportService : BeachwoodReportService
    ) { }

  ngOnInit() {
    this.beachWoodFacilityForm = this.fb.group({
      invoiceNo: [this.generateString()],
      baseRate: ['0'],
      ambiMileage: ['0'],
      ambiPerMile: ['1'],
      wcMileage: ['0'],
      wcPerMile: ['1'],
      emptyMiles: ['0'],
      cancellations1: ['0'],
      cancellations2: ['0'],
      escortService: ['0'],
      discount: ['0'],
      tax: ['0'],
      totalDue: ['0'],
      dueDate: ['30'],
      totalMiles: ['0'],
      wcBaseFare : ['4'],
      ambiBaseFare : ['2'],
      totalAmbi: [''],
      totalWc: [''],
    })

  }

  btFacilityReport() {
    this.bwfLoader.isLoading = true;
    this.getBeachwoodFacilityReport(this.beachWoodFacilityForm.value)
  }

  closeBTModal(value) {
    this.closeModal.emit(value)
  }

  calculateFacilityData(){
    this.isCalculate = true
    let totalMiles = 0;
    let totalWaviMiles = 0;
    let totalAmbiMiles = 0;
    let totalWcBaseFare = 0;
    let totalAmbiBaseFare = 0;
    let totalBasefare = 0;
    let totaltempDue ;
    let data  = this.beachWoodFacilityForm.value;

    this.selectedTrips.forEach(trip => {
        if (trip.tripRequirement == "WAV") {

          totalWaviMiles =  totalWaviMiles +  parseFloat(trip.milage);
          totalWcBaseFare =  totalWcBaseFare +  parseFloat(data.wcBaseFare);

        } else if (trip.tripRequirement == "Standard") {

          totalAmbiMiles = totalAmbiMiles +  parseFloat(trip.milage);;
          totalAmbiBaseFare =  totalAmbiBaseFare +  Number(data.ambiBaseFare);

        }
        totalMiles =  totalWaviMiles +  totalAmbiMiles
    });

    totalAmbiBaseFare = (totalAmbiMiles * data.ambiPerMile) + totalAmbiBaseFare;
    totalWcBaseFare =  (totalWaviMiles * data.wcPerMile) + totalWcBaseFare
    totalBasefare =  totalWcBaseFare +  totalAmbiBaseFare;
    totaltempDue = totalBasefare + parseFloat(data.emptyMiles)
    let taxPercentageAmount = (parseFloat(data.tax) / 100) * totaltempDue;
    totaltempDue = totaltempDue + taxPercentageAmount
    totaltempDue = totaltempDue - parseFloat(data.discount)

    this.beachWoodFacilityForm.patchValue({
      baseRate : totalBasefare.toFixed(2),
      totalDue: totaltempDue.toFixed(2),
      totalMiles: totalMiles.toFixed(2),
      ambiMileage : totalAmbiMiles.toFixed(2),
      wcMileage : totalWaviMiles.toFixed(2),
      totalAmbi: (totalAmbiMiles * data.ambiPerMile).toFixed(2),
      totalWc : (totalWaviMiles * data.wcPerMile).toFixed(2),

    });

    // set total on base of data
    // const { totalAll } = this.beachwoodReportService.calculateTotalDue(this.beachWoodFacilityForm.value);
  }
  generateString() {
    const randomString = getRandomString(7)
    const dateTime = moment(this.date[0]).format('MMDDYY') + '-' + moment(this.date[1]).format('MMDDYY')
    return `${randomString}-${dateTime}`;
  }

  async getBeachwoodFacilityReport(data) {
    const selectedCorporate = this.users.find(corporate => corporate._id === this.selectedUsers);

   await this.beachwoodReportService.btFacilityReport(this.company, this.selectedTrips, selectedCorporate, this.date, data, this.bwfLoader ,  this.corporatePayor);
    this.beachWoodFacilityForm.reset()
  }
}
