import { Component, OnDestroy, OnInit } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-z-dates',
  templateUrl: './z-dates.component.html',
  styleUrls: ['./z-dates.component.css']
})
export class ZDatesComponent implements OnInit, OnDestroy {
  timeZone;

  constructor(
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.timeZone = user.timeZone;
        }
      })
  }

  show() {
    console.clear()
    console.log(this.timeZone);
    // moment.tz.setDefault(String);
    // This is used in app component and auth service.

    console.log('==== Moment Dates ====')
    console.log(moment())
    console.log(moment().format())
    console.log('UTC - ', moment().utc().format());
    console.log('ISO - ', moment().toISOString())
    
    
    console.log('==== JS Dates ====')
    console.log(new Date());
  }

  ngOnDestroy(): void {
  }
}
