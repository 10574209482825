import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KpisService } from '../kpis-service/kpis.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-add-expense-modal',
  templateUrl: './add-expense-modal.component.html',
  styleUrls: ['./add-expense-modal.component.css']
})
export class AddExpenseModalComponent implements OnInit, OnDestroy {
  modalFlagTest:any;
  addExpenseForm: FormGroup;
  @Input() addExpenseModal:boolean = false;
  @Output() onCloseExpenseModal =  new EventEmitter<any>();
  kpisExpense:any


  

  constructor(
    private kpiService:KpisService,
    private fb: FormBuilder) {

    }

  ngOnInit() {
    console.log('Modal Here', this.addExpenseModal)
    this.addExpenseForm = this.fb.group({
      amount: ['', Validators.required],
      description: ['', Validators.required],
     
  
    });
  }

  onSubmit(){
    this.kpiService.saveDriverBreakDownExpense(this.addExpenseForm)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data=>{
      if(data){
        this.kpisExpense = data;
        console.log('Expense', data)
      }
    })
    
  }


  onCloseModal(){
    this.onCloseExpenseModal.emit(false);
  }


  ngOnDestroy(): void {
  }
}
