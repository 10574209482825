import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Form } from "@angular/forms";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../service/auth-service/auth.service";
import { sweetAlert } from "../../../utils/swal";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ModifierCodesService } from "../modifier-codes-service/modifier-codes.service";

@Component({
  selector: 'app-add-modifier-code',
  templateUrl: './add-modifier-code.component.html',
  styleUrls: ['./add-modifier-code.component.css']
})
export class AddModifierCodeComponent implements OnInit {
  modifierCodesForm: FormGroup;
  submitted = false;
  userId = null;
  modifierCodesId;
 
  constructor(
    public authService: AuthService,
    private modifierCodesService: ModifierCodesService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.modifierCodesId = params["id"];
      if(this.modifierCodesId){
        this.getModifierCodebyId();
      }
    });

    this.modifierCodesForm = this.fb.group({
      title: ["", Validators.required],
      code: ["", Validators.required],
      status: ['active', Validators.required],
    });
  }

  getModifierCodebyId(){
    this.modifierCodesService.getModifierCodesById(this.modifierCodesId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.modifierCodesForm.patchValue(data)
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "Ok");
      }
    );
  }

  saveModifierCode() {
    this.submitted = true;
    if (this.modifierCodesForm.invalid) {
      return;
    }
      let payload = this.modifierCodesForm.value;
      this.modifierCodesService.saveModifierCode(this.modifierCodesId,payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.router.navigateByUrl("billing/modifier-codes");
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
  }

  get form() {
    return this.modifierCodesForm.controls;
  }

  ngOnDestroy(): void {
  }
}
