import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { LevelOfServiceService } from "../service/level-of-service.service";
import { imageProcessing } from "src/app/utils/image-processing-utils";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { sweetAlert } from "src/app/utils/swal";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";

@Component({
  selector: "app-add-level-of-service",
  templateUrl: "./add-level-of-service.component.html",
  styleUrls: ["./add-level-of-service.component.css"],
})
export class AddLevelOfServiceComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  vehicleId;
  vehicleImageFile;
  vehicle;
  vehicles=[];
  user;
  isAdmin = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private vehicleService: LevelOfServiceService,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.paramMap.get("id");

    this.formGroup = this.fb.group({
      name: ["", Validators.required], // disable  in tp case
      status: ["active", Validators.required],
      profileImageURL: ["", Validators.required],
      vehicle: ["", Validators.required],// disable  in admin case
    });


    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;

          if (this.user.roles && ((typeof this.user.roles) === 'object') && this.user.roles[0]) {
            this.isAdmin = this.user.roles[0] === "admin";
          }

          if (this.isAdmin) {
            this.formGroup.get('vehicle').disable();
            this.formGroup.get('vehicle').updateValueAndValidity();
          } else {
            this.formGroup.get('name').disable();
            this.formGroup.get('name').updateValueAndValidity();
            this.getVehicles();
          }

          if (this.vehicleId) {
            this.getVehicle();
          }
        }
      });

  }

  getVehicles() {
    const queryParams = {
      status: 'active'
    }
    this.vehicleService
      .getVehicles(true, queryParams)
      .subscribe(data => {
        if (data && data.length) {
          this.vehicles = data;
        } else {
          this.vehicles = [];
        }
      }, err => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }


  getVehicle() {
    this.vehicleService.getVehicleById(this.isAdmin, this.vehicleId).subscribe((data) => {
      if (data) {
        this.vehicle = data;
        this.formGroup.patchValue(data);
      }
    });
  }

  submit() {
    this.isSubmitted = true;
    console.log("Form: ", this.formGroup);
    console.log("Form Values: ", this.formGroup.value);
    if (this.formGroup.invalid) {
      return;
    }

    const payload = this.formGroup.value;
    if (this.vehicleImageFile) {
      payload['newProfilePicture'] = this.vehicleImageFile;
    }
    console.log("Valid Form: ", payload);
    this.vehicleService
      .saveVehicle(this.isAdmin, this.vehicleId, payload)
      .subscribe((data) => {
        if (data) {
          this.router.navigateByUrl("credentialing/levelofservice");
        }
      }, err => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        if (result) {
          this.vehicleImageFile = result.file;
          this.formGroup.patchValue({
            profileImageURL: result.src
          });
        } else {
          this.clearImage();
        }
      });
  }

  clearImage() {
    this.vehicleImageFile = null;
    this.formGroup.patchValue({
      profileImageURL: ""
    });

  }

  get form() {
    return this.formGroup.controls;
  }
  onSelectLOS(evt) {
    const selectedLOS = this.vehicles.find(data => data._id === evt);
    this.formGroup.patchValue({
      profileImageURL: selectedLOS.profileImageURL
    });
    this.vehicleImageFile = null;

  }

  ngOnDestroy(): void { }
}
