import { Component, OnDestroy, OnInit } from '@angular/core';
import { DriverDistributionsService } from '../driver-distributions-services/driver-distributions-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-driver-distributions',
  templateUrl: './driver-distributions.component.html',
  styleUrls: ['./driver-distributions.component.css']
})
export class DriverDistributionsComponent implements OnInit, OnDestroy {
  distributionList = [];
  search: any;

  constructor(
    private driverDistributionsService: DriverDistributionsService
  ) { }

  ngOnInit() {
    this.getDistributionList();
  }

  getDistributionList() {
    this.driverDistributionsService
      .getDistributionList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.distributionList = data;
        }
      });
  }

  ngOnDestroy(): void {
  }
}
