import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { DriversService } from '../../drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import * as moment from 'moment';

@Component({
  selector: 'app-driver-amendments',
  templateUrl: './driver-amendments.component.html',
  styleUrls: ['./driver-amendments.component.css']
})
export class DriverAmendmentsComponent implements OnInit, OnDestroy {
  user;
  search;
  isShowStatusDropdown = false;
  addAmendmentModalObj = false;
  amendmentDetails = [];
  status;

  constructor(private driverService: DriversService,
    private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.getUser();
    this.getAmendments();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
  }

  getAmendments(){

    this.driverService.getAmendments()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if(data){
        this.amendmentDetails = data;
        console.log(this.amendmentDetails, "details");
      }
    });
  }

  toggleStatusDropdown() {
    this.isShowStatusDropdown = !this.isShowStatusDropdown
    this.status = '';
  }

  onChangeStatus() {
    this.getAmendments();
  }


  addAmendments(data){
    this.addAmendmentModalObj = data;
  }

  
  ngOnDestroy(): void {
  }
}
