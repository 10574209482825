import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OfferedtripsService } from 'src/app/component/offered-trips/offered-trips-service/offeredtrips.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment';
import { DatePipe } from 'src/app/pipe/date-pipe/date.pipe';
import { exportToCsv } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-ctc-claimed-trips',
  templateUrl: './ctc-claimed-trips.component.html',
  styleUrls: ['./ctc-claimed-trips.component.css']
})
export class CtcClaimedTripsComponent implements OnInit, OnDestroy {
  ctcClaimedTripsList = [];
  search;
  todayDate;
  timeZone;
  formatedDate;
  user;
  dateForm: FormGroup;
  constructor(private fb: FormBuilder,
    private offeredTripsService: OfferedtripsService,
    private sharedDataService: SharedDataService,
    private dateP: DatePipe
  ) { }

  ngOnInit() {
    this.getUser();
    this.dateForm = this.fb.group({
      dateRange: ['']
    });
  }
  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = [moment.tz(this.timeZone).startOf('day').toISOString(), moment.tz(this.timeZone).endOf('day').toISOString()];
        }
      });
  }

  getClaimedTripsByDateRange() {
    // if (!(this.dateForm.value.dateRange && this.dateForm.value.dateRange.length)) {
    //   return;
    // }
    let startDate;
    let endDate;
    if (this.dateForm.value.dateRange && this.dateForm.value.dateRange.length) {
      startDate = this.dateForm.value.dateRange[0].startOf('day').toISOString();
      endDate = this.dateForm.value.dateRange[1].endOf('day').toISOString();
    } else {
      startDate = this.todayDate[0];
      endDate = this.todayDate[1];
    }

    this.offeredTripsService
      .getCTCClaimedTrips(startDate, endDate)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.ctcClaimedTripsList = data;
          this.ctcClaimedTripsList.forEach(i => {
            const tripDate = moment.tz(i.DateOfService, this.timeZone);
            const formattedDate = tripDate.format('MM/DD/YYYY HHmm');
            this.formatedDate = formattedDate;
            console.log(formattedDate, "date string");
          });
        }
      });
  }

  downloadReport() {
    const table = [
      [
        "Reservation No.",
        "DOS",
        "Standing Order",
        "Miles",
        "Claimed",
        "Status"
      ]
    ];

    for (let i = 0; i < this.ctcClaimedTripsList.length; i++) {
      const trip = this.ctcClaimedTripsList[i];

      const ctcTrip = [
        trip.ReservationNumber,
        this.formatedDate,
        trip.StandingOrderId ? trip.StandingOrderId : "N/A",
        trip.Miles,
        this.dateP.transform(trip.createdAt, "MM/DD/YYYY"),
        trip.message
      ]
      table.push(ctcTrip);
    }
    exportToCsv(`ctc-bot-trips-${moment().format("MM-DD-YYYY")}.csv`, table);
  }

  ngOnDestroy(): void {
  }
}
