import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({

  // <app-common-alert   class="modal fade bs-example-modal-center" id="alert"   [title]= "'Alert'" [btnPositive]="'Yes'" [btnNagtive]="'No'" [message]="'are you sure u wan do this'"
  // (btnPositiveResponse)="btnPositiveResponse()" (btnNagtiveResponse)="btnNagtiveResponse()"></app-common-alert>


  // data-toggle="modal"
  //             data-target="#commonAlertModal"

  selector: 'app-common-alert',
  templateUrl: './common-alert.component.html',
  styleUrls: ['./common-alert.component.css']
})
export class CommonAlertComponent implements OnInit {

  @Input() _id= 'commonAlertModal' ;
  @Input() message= '...' ;
  @Input() title = 'Alert';
  @Input()  btnPositive = 'Ok'
  @Input() btnNagtive = 'Cancel'

  @Output() btnPositiveResponse = new EventEmitter<any>();
  @Output() btnNagtiveResponse = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  btnPositiveAction(){
    this.btnPositiveResponse.emit();
    
  }
  btnNagtiveAction(){
    this.btnNagtiveResponse.emit();

  }

}
