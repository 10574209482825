import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { DriversService } from '../../drivers-service/drivers-service';
import { sweetAlert } from 'src/app/utils/swal';
import { extend } from 'lodash';
@Component({
  selector: 'app-add-amendment-modal',
  templateUrl: './add-amendment-modal.component.html',
  styleUrls: ['./add-amendment-modal.component.css']
})
export class AddAmendmentModalComponent implements OnInit, OnDestroy {
  @Input('addAmendmentModalObj') amendment: any;
  @Output() onClose = new EventEmitter<any>();
  user;
  timeZone;
  amendmentForm: FormGroup;
  timeline;
  isShowStatusDropdown = false;
  singleBreakFormSubmitted = false;
  constructor(private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.amendmentForm = this.fb.group({
      _id:[''],
      status: [''],
      currDriver:[null],
      preCheckin: ['', Validators.required],
      preCheckout: ['', Validators.required],
      checkin: ['', Validators.required],
      checkout: ['', Validators.required],
      notes: [''],
      breaks: this.fb.array([])
    });

    this.getUser();

  }

  getBreakForm() {
    return this.fb.group({
      // _id: [''],
      action: ['add'],
      dispatcherAction: ['approved'],
      preStart: ['', Validators.required],
      preStop: ['', Validators.required],
      start: ['', Validators.required],
      stop: ['', Validators.required],
    })
  }

  addBreak() {
    this.breakArrayForm.push(this.getBreakForm());
  }

  removeBreak(index) {
    this.breakArrayForm.removeAt(index);
  }


  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.amendmentDetails();
        }
      });
  }

  amendmentDetails() {
    this.driverService.getAmendmentsDetail(this.amendment._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeline = data.timeline;
          // patch top level values
          this.amendmentForm.patchValue({
            ...data,
            currDriver: data.driver,
            preCheckin: moment(data.checkin).tz(this.timeZone),
            preCheckout: moment(data.checkout).tz(this.timeZone)
          });
          // Add breaks
          for (let myBreak of data.breaks) {
            this.breakArrayForm.push(
              this.fb.group({
                _id: [myBreak._id],
                breakId: [myBreak.breakId],
                action: [myBreak.action],
                dispatcherAction: [myBreak.dispatcherAction],
                preStart: [moment(myBreak.start).tz(this.timeZone), Validators.required],
                preStop: [moment(myBreak.stop).tz(this.timeZone), Validators.required],
                start: [myBreak.start, Validators.required],
                stop: [myBreak.stop, Validators.required]
              })
            );
          }
        }
      });
  }

  toggleStatusDropdown() {
    this.isShowStatusDropdown = !this.isShowStatusDropdown
  }

  onCheckinDateChange(evt) {
    this.amendmentForm.patchValue({
      checkin: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onCheckoutDateChange(evt) {
    this.amendmentForm.patchValue({
      checkout: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onStartBreakChange(evt, index) {
    this.breakArrayForm.at(index).patchValue({
      start: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onStopBreakChange(evt, index) {
    this.breakArrayForm.at(index).patchValue({
      stop: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  amendmentRequest() {
    const payload = {
     ...this.amendmentForm.value
    };
    if (this.amendmentForm.value.status === 'approved') {
      payload['newTimeline'] = this.driverService.ammendmentCalculations(this.timeline, this.amendmentForm.value, this.timeZone);
    }
    this.driverService.updateAmendment(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.close();
        }
      },
        err => {
          sweetAlert('Error', err, 'error', 'OK');
        });
  }

  saveAmendment() {
    this.singleBreakFormSubmitted = true;
    if (this.amendmentForm.invalid) return;
    sweetAlert('Alert', 'Are you sure you want to update this amendment?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.amendmentRequest();
        }
      });
  }

  changeBreakStatus(dispatcherAction, breakObj) {
    breakObj.patchValue({
      dispatcherAction
    })
  }

  close() {
    this.onClose.emit(null);
  }

  closeStatusDropdown() {
    this.isShowStatusDropdown = false;
  }

  get form() {
    return this.amendmentForm.controls;
  }

  get breakArrayForm() {
    return this.amendmentForm.controls.breaks as FormArray;
  }

  ngOnDestroy(): void {
  }
}
