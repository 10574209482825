import { Injectable } from "@angular/core";
import { PDFDocument } from "pdf-lib";
import {
  removeSpecificfromPDF,
  removeNonAllowedCharactersFromPDF,
} from "../../utils/utils.common";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class CatService {
  constructor() {}

  async getMemberSummary(trips, user, date) {
    const pdfArray = [];
    let data = [];
    let fileName;
    let totalMiles = 0;
    let totalLegs = 0;
    let totalAmout = 0;

    let dateRange =
      moment(date[0]).tz(user.timeZone).format("MMMM") +
      moment(date[0]).tz(user.timeZone).format("DD") +
      "-" +
      moment(date[1]).tz(user.timeZone).format("DD") +
      "-" +
      moment(date[0]).tz(user.timeZone).format("YYYY");
    const arrayOfkeys = Object.keys(trips);
    for (const [index, key] of arrayOfkeys.entries()) {
      totalMiles = totalMiles + trips[key].miles;
      totalLegs = totalLegs + trips[key].days;
      totalAmout = totalAmout + trips[key].jobFee;
      data.push(trips[key]);

      fileName = `${
        data[0].trips[0].priorityClient.displayName.toLowerCase() +
        "-" +
        dateRange.toLowerCase()
      }-Summary Report.pdf`;
      if (index === 44) {
        const response = await fetch("assets/documents/cat.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillCatPDF(
          pdfBuffer,
          data,
          user,
          date,
          true,
          totalMiles,
          totalLegs,
          totalAmout,
          arrayOfkeys.length - 1 - index !== 0 && arrayOfkeys.length - index > 0
        );
        pdfArray.push(pdfBuffer);
        data = [];
      } else if (index > 44 && (index - 44) % 54 === 0) {
        const response = await fetch("assets/documents/cat-blank.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillCatPDF(
          pdfBuffer,
          data,
          user,
          date,
          false,
          totalMiles,
          totalLegs,
          totalAmout,
          arrayOfkeys.length - 1 - index !== 0 && arrayOfkeys.length - index > 0
        );
        pdfArray.push(pdfBuffer);
        data = [];
      }
    }
    if (!pdfArray.length && data.length) {
      const response = await fetch("assets/documents/cat.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillCatPDF(
        pdfBuffer,
        data,
        user,
        date,
        true,
        totalMiles,
        totalLegs,
        totalAmout,
        false
      );
      pdfArray.push(pdfBuffer);
      data = [];
    } else if (pdfArray.length && data.length) {
      const response = await fetch("assets/documents/cat-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillCatPDF(
        pdfBuffer,
        data,
        user,
        date,
        false,
        totalMiles,
        totalLegs,
        totalAmout,
        false
      );
      pdfArray.push(pdfBuffer);
    }
    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async fillCatPDF(
    pdfBuffer: ArrayBuffer,
    payload,
    user,
    dateRange,
    showHeader,
    miles,
    legs,
    amount,
    isShowTotal
  ): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;

    for (let [i, item] of payload.entries()) {
      if (showHeader) {
        if (user) {
          form.getTextField(`company_name`).setText(company.displayName || "");
        }

        form
          .getTextField(`date`)
          .setText(
            "(" +
              moment(dateRange[0]).tz(company.timeZone).format("MMMM") +
              " " +
              moment(dateRange[0]).tz(company.timeZone).format("DD") +
              "-" +
              moment(dateRange[1]).tz(company.timeZone).format("DD") +
              "," +
              moment(dateRange[0]).tz(company.timeZone).format("YYYY") +
              ")"
          );
        if (
          item.trips[0].priorityClient &&
          item.trips[0].priorityClient.displayName
        )
          form
            .getTextField(`member_name` + 0)
            .setText(item.trips[0].priorityClient.displayName);
        if (item.trips[0].memberId)
          form
            .getTextField(`w_number` + 0)
            .setText(item.trips[0].memberId || "");
        if (
          item.trips[0].priorityClient &&
          item.trips[0].priorityClient.clientId
        )
          form
            .getTextField(`medicaid_no` + 0)
            .setText(item.trips[0].priorityClient.clientId || "");
        if (
          item.trips[0].originAddressAdditional &&
          item.trips[0].originAddressAdditional.place
        )
          form
            .getTextField(`pu_facility_name`)
            .setText(item.trips[0].originAddressAdditional.place);
        if (
          item.trips[0].destinationAddressAdditional &&
          item.trips[0].destinationAddressAdditional.place
        )
          form
            .getTextField(`do_facility_name`)
            .setText(item.trips[0].destinationAddressAdditional.place);
        if (item.trips[0].jobOriginAddress) {
          const puAddress = this.splitAddressinTwo(
            item.trips[0].jobOriginAddress
          );

          form
            .getTextField(`initial_pu_address_one`)
            .setText(puAddress.firstAddress);
          form
            .getTextField(`initial_pu_address_two`)
            .setText(puAddress.secondAddress);
        }
        if (item.trips[0].jobDestinationAddress) {
          const doAddress = this.splitAddressinTwo(
            item.trips[0].jobDestinationAddress
          );

          form
            .getTextField(`initial_do_address_one`)
            .setText(doAddress.firstAddress);
          form
            .getTextField(`initial_do_address_two`)
            .setText(doAddress.secondAddress);
        }
        if (
          item.trips[0].originAddressAdditional &&
          item.trips[0].originAddressAdditional.phone
        )
          form
            .getTextField(`initial_phone` + 1)
            .setText(item.trips[0].originAddressAdditional.phone || "");
        if (
          item.trips[0].destinationAddressAdditional &&
          item.trips[0].destinationAddressAdditional
        )
          form
            .getTextField(`initial_phone` + 2)
            .setText(item.trips[0].destinationAddressAdditional.phone || "");
      }

      form.getTextField(`desc` + i).setText("" + item.trips[0].tripReason);
      if (item.miles)
        form.getTextField(`mileage` + i).setText("" + item.miles.toFixed(2));
      if (item.jobFee)
        form.getTextField(`amount` + i).setText("" + item.jobFee.toFixed(2));
      if (item.days) form.getTextField(`no_legs` + i).setText("" + item.days);
      form
        .getTextField(`dos` + i)
        .setText(
          moment(item.trips[0].scheduleTime)
            .tz(user.timeZone)
            .format("YYYY/MM/DD")
        );
      form
        .getTextField("legA_pu_time" + i)
        .setText(
          moment(item.trips[0].scheduleTime).tz(user.timeZone).format("HH:mm")
        );
      if (item.trips.length >= 2) {
        if (
          moment(item.trips[1].scheduleTime).tz(user.timeZone).hours() >= 22
        ) {
          if (
            item.trips[1].job.trackInfoJob.jobStarted.time &&
            item.trips[1].job.trackInfoJob &&
            item.trips[1].job.trackInfoJob.jobStarted &&
            item.trips[1].job.trackInfoJob.jobStarted.time
          )
            form
              .getTextField("legB_pu_time" + i)
              .setText(
                moment(item.trips[1].job.trackInfoJob.jobStarted.time)
                  .tz(user.timeZone)
                  .format("HH:mm")
              );
        } else {
          form
            .getTextField("legB_pu_time" + i)
            .setText(
              moment(item.trips[1].scheduleTime)
                .tz(user.timeZone)
                .format("HH:mm")
            );
        }
      }
    }
    if (miles)
      form.getTextField("total_miles").setText(miles.toFixed(2).toString());
    if (legs) form.getTextField("total_legs").setText(legs.toString());
    if (amount)
      form.getTextField("total_amount").setText("$" + amount.toFixed(2));
    form.getTextField("total").setText("Total");
    form.getTextField("no_miles").setText("# of Miles");
    form.getTextField("no_legs").setText("# of Legs");
    form.getTextField("charge").setText("Charge");

    // await this.removeSpecificfromPDF(form,['total', 'no_miles', 'no_legs','charge']);
    if (isShowTotal)
      await removeSpecificfromPDF(form, [
        "total_miles",
        "total_legs",
        "total_amount",
        "total",
        "no_miles",
        "no_legs",
        "charge",
      ]);

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save();
    return modifiedBuffer;
  }

  splitAddressinTwo(address) {
    let firstAddress = "";
    let secondAddress = "";
    address = address.split(", ");
    if (address && address.length > 3) {
      firstAddress = address.slice(0, -3).join(", ");
      secondAddress = address.slice(-3).join(", ");
    } else {
      firstAddress = address.toString();
    }
    return { firstAddress, secondAddress };
  }
}
