import { Component, OnInit, OnDestroy } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { InviteSmsService } from '../invite-sms-service/invite-sms.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-invite-sms',
  templateUrl: './invite-sms.component.html',
  styleUrls: ['./invite-sms.component.css']
})
export class InviteSmsComponent implements OnInit, OnDestroy {
  previousInvites = [];
  constructor(
    private inviteSmsService: InviteSmsService
  ) { }

  ngOnInit() {
    this.getPreviousInvites();
  }

  getPreviousInvites() {
    this.inviteSmsService
      .getPreviousInvitesList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.previousInvites = state.response;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'Ok');
      });
  }

  sendSms(contact) {
    const data = {
      contactNumber: contact
    };
    this.inviteSmsService
      .sendSmsInvite(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.getPreviousInvites();
        }
      });
  }

  ngOnDestroy(): void {
  }
}
