import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { downloadBase64File, downloadFile } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
// import { KpisService } from '../kpis-service/kpis.service';
import { urls } from 'src/app/utils/url-utils';
import { KpisService } from '../../kpis-service/kpis.service';

@Component({
  selector: 'app-statistics-details',
  templateUrl: './statistics-details.component.html',
  styleUrls: ['./statistics-details.component.css']
})
export class StatisticsDetailsComponent implements OnInit, OnDestroy {
  kpisDetails = [];
  search;
  dateForm;
  payload;
  vendorReturnedCount = 0;
  tripDetailModalObj;
  constructor(private kpisService: KpisService, private router: Router) {
    console.log('TEST100:RouteData:', this.router.getCurrentNavigation().extras.state);
    // const state = this.router.getCurrentNavigation().extras.state;

    // console.log("check for state", state)
    // this.payload = state;
    this.payload = window['newData']
    // if (state) {
    //   console.log("check for payload", this.payload)
    // }
  }
  ngOnInit() {
    this.getStatisticsDetails();
  }
  getStatisticsDetails() {
    this.kpisService.getNemtStatsDetailsByDate(this.payload)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.kpisDetails = data;
        if (this.kpisDetails) {
          for (let vendorReturned of this.kpisDetails) {
            if (!vendorReturned.changeReason) {
              this.vendorReturnedCount += 1;
              // console.log("check for count", this.vendorReturnedCount)
            }
          }
        }
      }
    })
  }

  setStatisticTripsDetail(trip){
    // console.log("driversDetails ==>", driver)
    this.tripDetailModalObj = trip;
  }

  ngOnDestroy(): void {
  }
}
