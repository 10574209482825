import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompaniesService } from '../companies.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent implements OnInit, OnDestroy {

  search;
  status = "active";
  portal = 'nemt'
  companiesList = [];
  user;
  timeZone;
  todayDate;

  constructor(private companiesService:CompaniesService, private sharedDataService: SharedDataService) { }

  ngOnInit() {

    this.sharedDataService
    .getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(user => {
      if (user) {
        this.user = user;
        this.timeZone = this.user.timeZone;
        this.todayDate = moment().tz(this.timeZone);
        this.companiesListData();
      }
    });

  }


  companiesListData() {
    this.companiesService.getCompaniesList(this.status)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.companiesList = data;
          this.companiesList.sort((a, b) => <any>new Date(b.created) - <any>new Date(a.created));
          // this.companiesList.forEach(company => {
          //   company.focalPerson = "";
          //   company.focalPersonNumber = ""
          // });
         }
      },);
  }




  onChangeStatus() {
    this.companiesListData()
  }

  onChangePlatform(){

  }
  getTrialStatus(date) {
    const trialEndDate = moment(date).tz(this.timeZone);
    return trialEndDate.diff(this.todayDate, 'days');
  }

  ngOnDestroy(): void {
  }
}
