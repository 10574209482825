import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class FleetService {

  public KEY_TOKEN: 'token';

  constructor(private http: HttpClient) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getFleetList(payload): Observable<any> {
    const params = new HttpParams({fromObject: payload});
    
    return this.http.get<any>(urls.BASE_URL + urls.FLEET_LIST_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getFleetDataById(fleetId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.ADD_FLEET_URL + '/' + fleetId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getSingleFleetQR(fleetId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.SINGLE_FLEET_QR_URL + fleetId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getAllFleetQR(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.ALL_FLEET_QR_URL, data)
      .pipe(
        catchError(errorHandl)
      );
  }


  addFleet(fleetData): Observable<any> {
    const imageFiles = fleetData.images;
    const fleetId = fleetData.FleetId;
    delete fleetData.images;
    delete fleetData.FleetId;
    const formData = setFormData(imageFiles, fleetData, 'fleet');
    if (fleetId) {
      return this.http.put<any>(urls.BASE_URL + urls.ADD_FLEET_URL + '/' + fleetId, formData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.ADD_FLEET_URL, formData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }
}
