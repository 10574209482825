import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ServiceAreasService } from '../../service-areas/service-areas-service/service-areas.service';

@Component({
  selector: 'app-add-trip-service-area-modal',
  templateUrl: './add-trip-service-area-modal.component.html',
  styleUrls: ['./add-trip-service-area-modal.component.css']
})
export class AddTripServiceAreaModalComponent implements OnInit, OnDestroy {
  @Input() trip;
  @Input() serviceAreas;
  @Input() preServiceAreas;
  @Output() serviceAreaAdded = new EventEmitter<any>();
  isSubmitted:boolean = false;
  selectedServiceArea = '';

  constructor(
    private serviceAreaService: ServiceAreasService,
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
    if (this.trip && this.trip.serviceArea) {
      this.selectedServiceArea = this.trip.serviceArea;
    }
  }

  addServiceArea() {
    // this.isSubmitted = true;
    // if(!this.selectedServiceArea){
    //   return;
    // }
    const payload = {
      _id: this.trip._id,
      serviceArea: this.selectedServiceArea ? this.selectedServiceArea : null
    }
    
    this.assignTripService
      .updateTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // For modifying local view
          this.trip.serviceArea = this.selectedServiceArea;
          this.serviceAreaAdded.emit(data);
        }
      });
  }

  closeModal() {
    this.serviceAreaAdded.emit(null);
  }

  ngOnDestroy(): void {
  }
}
