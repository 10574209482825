import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from 'src/app/utils/url-utils';
import { errorHandl, setFormData, setOnlyFieldFormData } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class InvoiceserviceService {

  constructor(private http: HttpClient,) { }


  getInvoiceList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_INVOICE_LIST)
    .pipe(
      catchError(errorHandl)
    );
  }

  saveInvoice(id, payload): Observable<any> {
    if (id) {
        return this.http.put<any>(urls.BASE_URL + urls.GET_COMPANY_DATA_URL + '/' + id, payload).pipe(
            catchError(errorHandl)
        );
    } 
}

getCompanyData(payload): Observable<any> {
  return this.http.post<any>(urls.BASE_URL + urls.GET_COMPANY_DATA_URL + '/' + 'company', payload)
    .pipe(
      catchError(errorHandl)
    );
}
}

