import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class TripPurposesService {

  constructor(private http: HttpClient) { }

  getTripPurposesList(status): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_TRIP_PURPOSES+`?status=${status}`)
  }


  addTripPurposes(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.GET_TRIP_PURPOSES, data)
  }

  getTripPurposesListById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_TRIP_PURPOSES + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  updateTripPurposes(id, data){
    return this.http.put<any>(urls.BASE_URL + urls.GET_TRIP_PURPOSES + `/${id}`, data)
  }


  removeTripPurpose(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-HTTP-Method-Override': 'DELETE',
        'Access-Control-Allow-Credentials':'true'
      })
    };
    return this.http.delete<any>(urls.BASE_URL + urls.GET_TRIP_PURPOSES + `/${id}`, httpOptions)
  }


}



