import { Component, OnDestroy, OnInit } from '@angular/core';
import { OfferedtripsService } from '../offered-trips-service/offeredtrips.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit, OnDestroy {
  search = '';
  logsList = [];

  constructor(
    private offeredTripsService: OfferedtripsService
  ) { }

  ngOnInit() {
    this.getLogs();
  }

  getLogs() {
    this.offeredTripsService
      .getTripsLogs()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.logsList = data;
        }
      });
  }

  ngOnDestroy(): void {
  }
}
