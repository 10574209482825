import { Component, OnDestroy, OnInit } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { ProTipsServiceService } from 'src/app/component/helping-material/nemt-pro-tips/service/pro-tips-service.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-nemt-super-pro-tips',
  templateUrl: './nemt-super-pro-tips.component.html',
  styleUrls: ['./nemt-super-pro-tips.component.css']
})
export class NemtSuperProTipsComponent implements OnInit, OnDestroy {
  search;
  proTips = [];
  images = [];
  user: any;

  constructor( private proTipsService: ProTipsServiceService, 
      private sharedDataService: SharedDataService,
    ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.user = data;
        this.getNemtProTips();
      }
    });
  }
  getNemtProTips() {
    this.proTipsService.getProTips()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.proTips = data;
        }
      },
    );
  }

  ngOnDestroy(): void {
  }
}
