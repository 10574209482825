import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment-timezone";

@Pipe({
  name: 'clockTime'
})
export class ClockTimePipe extends DatePipe  implements PipeTransform {

  transform(
    value: string | Date,
    format: string = "MM/dd/yyyy h:mm a",
    timezone: string = "America/New_York"
  ): string {
    // console.log(timezone);
    const timezoneOffset = moment(value)
      .tz(timezone)
      .format("Z");
    return super.transform(value, format, timezoneOffset);
  }

}
