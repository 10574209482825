import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilterbykeyPipe'
})
export class ArrayFilterbykeyPipePipe implements PipeTransform {
  public transform(value: any[], keys: string, terms: any[]): any[] {
    if (!terms || !terms.length || !value || !keys) { return value; }
    const splitArray = keys.split(',');
    let returnValue = [];

    value.forEach((item) => {
        let matches = false;

        if (typeof item === 'object') {
            splitArray.forEach((key) => {
                const splitArrayTwo = key.split('.');
                let tempObject = item;
                splitArrayTwo.forEach((d) => {
                    if (tempObject && tempObject.hasOwnProperty(d)) {
                        tempObject = tempObject[d];
                    }
                });

                if (terms.some(term => tempObject === term)) {
                    matches = true;
                }
            });
        } else if (typeof item === 'string' || typeof item === 'number') {
            if (terms.some(term => item === term)) {
                matches = true;
            }
        }

        if (matches) {
            returnValue.push(item);
        }
    });

    return returnValue;
}
}
