import { Component, OnInit, OnDestroy } from "@angular/core";
import * as moment from "moment-timezone";
import { sweetAlert } from "src/app/utils/swal";
import { MemberService } from "../member-service/member.service";
import { DriversService } from "../../drivers/drivers-service/drivers-service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: "app-member-list",
  templateUrl: "./member-list.component.html",
  styleUrls: ["./member-list.component.css"],
})
export class MemberListComponent implements OnInit, OnDestroy {
  members =[];
  drivers;
  user;
  assignDriverArray = [];
  selecetedMember;
  search;

  constructor(
    private memberService: MemberService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) {
  }

  ngOnInit() {
    this.getUser();
    this.getDrivers();
    this.getMembers();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getMembers() {
    this.memberService
      .getMembers()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.members = data;
        }
      });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, "active")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.drivers = data;
      }, err => {
        sweetAlert("Error", err, "warning", "OK");
      });
  }

  assignDriver(member) {
    this.selecetedMember = JSON.parse(JSON.stringify(member));
    this.assignDriverArray = [];
    let object = this.getAllDays();
    for (let i = 0; i < this.selecetedMember.scheduler.length; i++) {
      object = object.map((item) => {
        if (item.day === this.selecetedMember.scheduler[i].day) {
          item.driver = this.selecetedMember.scheduler[i].driver;
          item.isSelect = true;
        }
        return item;
      });
    }
    console.log(object);
    this.assignDriverArray = object;
  }

  saveModalData() {
    this.selecetedMember.scheduler = this.assignDriverArray.filter(
      (item) => item.isSelect && item.driver !== ""
    );

    this.memberService
      .updateMember(this.selecetedMember)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.members.filter((obj) => {
              if (obj._id === data._id) {
                obj.scheduler =  data.scheduler;
                obj.updatedAt =  data.updatedAt;
              }
            });
            console.log(this.members);
        }
      });
  }

  getDriverName(member) {
    let status = "-";
    if (member.scheduler && member.scheduler.length) {
      const records = member.scheduler.length;
      if (records === 1) {
        const driver = this.drivers.find(
          (item) => member.scheduler[0].driver === item._id
        );
        status = driver.displayName;
      } else {
        status = "Multiple Drivers";
      }
    }
    // if (member.scheduler.length === 1) {
    //   const driver = this.drivers.find(
    //     (item) => member.scheduler[0].driver === item._id
    //   );
    //   status = driver.displayName;
    // } else if (member.scheduler.length > 1) {
    //   status = "Multiple Drivers";
    // }
    return status;
  }

  getAllDays() {
    const weekdays = moment.weekdaysShort();
    let object = [];
    for (let i = 0; i < weekdays.length; i++) {
      console.log(moment().isoWeeks());
      const innerObject = {
        day: weekdays[i],
        driver: "",
        isSelect: false,
      };
      object.push(innerObject);
    }
    return object;
  }

  ngOnDestroy(): void {
  }
}
