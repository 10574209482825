import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DriversService } from '../../drivers-service/drivers-service';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import * as moment from 'moment';

@Component({
  selector: 'app-driver-timelines-v2',
  templateUrl: './driver-timelines-v2.component.html',
  styleUrls: ['./driver-timelines-v2.component.css']
})
export class DriverTimelinesV2Component implements OnInit, OnDestroy {
  duration = 60 * 1000; // 10 seconds
  @ViewChild('timer', { static: true }) timer;
  view = 'list';
  timelineDetails;
  user;
  timeZone;
  scheduleList = [];
  breakList = [];
  timeoutList = [];
  totalMealBreaks = 0;
  totalRestBreaks = 0;
  constructor(private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
    this.getDriverTimelines();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }
  
  isBreakDueNow(breakObj) {
    if (breakObj.status === 'open') {
      return false;
    } else if (['rest', 'approved'].includes(breakObj.status)) {
      const stop = moment(breakObj.stop).tz(this.timeZone, false);
      return moment.tz(this.timeZone).isAfter(stop)
        ;
    }
    return true;
  };

  getDriverTimelines() {
    this.scheduleList = [];
    this.breakList = [];
    this.timeoutList = [];
    this.driverService
      .getDriverData()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timer.start();
          this.timelineDetails = data;
          let now = moment().tz(this.timeZone);
          this.totalMealBreaks = 0;
          this.totalRestBreaks = 0;
          this.timelineDetails.forEach(time => {

            let breakTimeInMillis = 0;
            time.breaks.forEach(b => {
              if (b.status === 'approved') {
                this.totalMealBreaks++;
                breakTimeInMillis += b.duration;
              }
              if (b.status === 'rest') {
                this.totalRestBreaks++;
              }
            });

            if (time.status === 'completed') {
              this.timeoutList.push(time);
            } else {
              // Calculate working hours and break in milliseconds
              let checkIn = moment(time.checkin).tz(this.timeZone, false);
              let milliSeconds = now.diff(checkIn);
              time.workTimeInMillis = milliSeconds - breakTimeInMillis;
              if(time.workTimeInMillis < 0){
                time.workTimeInMillis = 0;
              }
              time.breakTimeInMillis = breakTimeInMillis;
              // END Calculate working hours and break in milliseconds
              if (time.breaks.length) {
                const latestBreak = time.breaks[time.breaks.length - 1];
                if (!this.isBreakDueNow(latestBreak)) {
                  this.breakList.push(time);
                } else {
                  this.scheduleList.push(time);
                }
              } else {
                this.scheduleList.push(time);
              }
            }
          });
        }
      });
  }

  viewHourFormat(milli) {
    let duration = moment.duration(milli, 'milliseconds');
    return `${Math.floor(duration.asHours())}hr ${duration.minutes()}mins`;
  }

  onTimerComplete(): void {
    this.timer.init();
    this.getDriverTimelines();
  }

  setView(type) {
    this.view = type;
  }

  getTripsCount() {
    let count = 0;
    if (this.scheduleList && this.scheduleList.length) {
      count = count + this.scheduleList.length;
    }

    if (this.breakList && this.breakList.length) {
      count = count + this.breakList.length;
    }

    if (this.timeoutList && this.timeoutList.length) {
      count = count + this.timeoutList.length;
    }

    return count;
  }

  ngOnDestroy(): void {
  }
}
