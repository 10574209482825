import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class PrimaryDiagnosisService {
  constructor(private http: HttpClient) { }


  getPrimaryDiagnoseById(id) {
    return this.http.get<any>(urls.BASE_URL + urls.PRIMARY_DIAGNOSE_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }


  saveprimaryDiagnose(id, payload) {

    if (id) {
      // Edit
      return this.http.put<any>(urls.BASE_URL + urls.PRIMARY_DIAGNOSE_URL + `/${id}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.BASE_URL + urls.PRIMARY_DIAGNOSE_URL, payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getPrimaryDiagnosis() {
    // const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + urls.PRIMARY_DIAGNOSE_URL)
      .pipe(
        catchError(errorHandl)
      );
  }
}
