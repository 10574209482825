import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MySocketWrapper } from './socket-wrapper';
import { urls } from 'src/app/utils/url-utils';
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: MySocketWrapper) {

    this.socket.on('connect', reason => {
      
      console.log(`**** Socket Connected (${reason}) ****$`)
		});

		this.socket.on('disconnect', reason => {
      console.log(`**** Socket Disconnected (${reason}) ****`)
		});
   }
  
  getSocket() {
    return this.socket;
  }

  emit(event, payload?) {
    console.group();
    console.log('>>>> Socket Request START');
    console.log(`Event: ${event}`);
    console.log('Payload:', payload);
    console.log('<<<< Socket Request END');
    console.groupEnd();
    this.socket.emit(event, payload);
  }

  addListener(event): Observable<any> {
    return this.socket.fromEvent(event).pipe(
      map(data => {
        console.group();
        console.log('>>>> Socket Response START');
        console.log(`Event: ${event}`);
        console.log('Payload: ', data);
        console.log('<<<< Socket Response END');
        console.groupEnd();
        return data;
      })

    );

  }

  removeListener(event) {
    this.socket.removeListener(event, ()=> {
      console.log(`**** Listener Removed (${event}) ****`)
    });
  }


  connectSocket() {
    if (!this.socket.ioSocket.connected ) {
      console.log('**** Socket Connect Called ****')
    this.socket.ioSocket.io.opts.query = { auth_token: JSON.parse(localStorage.getItem('token')) } //new options
    this.socket.ioSocket.io.uri = urls.SERVER_URL //new uri
    this.socket.connect(); //manually connection
  
    }
  }

  disconnectSocket() {
    if (this.socket.ioSocket.connected){
      console.log('**** Socket Disconnect Called ****')
      this.socket.removeAllListeners();
       this.socket.disconnect();
    }
  }
}
