import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-company-note-modal',
  templateUrl: './company-note-modal.component.html',
  styleUrls: ['./company-note-modal.component.css']
})
export class CompanyNoteModalComponent implements OnInit {

  @Input() trip: any;
  @Output() onCloseModal = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  breakString(str) {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }
  
  closeNotesModal(){
    this.onCloseModal.emit();
  }

}
