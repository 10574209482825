import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { getBrokersList } from '../../../utils/utils.common';
import { Observable, from } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
declare var $;
@Component({
  selector: 'app-sweep-trips-modal',
  templateUrl: './sweep-trips-modal.component.html',
  styleUrls: ['./sweep-trips-modal.component.css']
})
export class SweepTripsModalComponent implements OnInit, OnDestroy {

  @Input() parsedTrips;
  @Output() onCloseModal = new EventEmitter<any>();
  selectedBroker = null;
  getListObserver: Observable<any>;
  unparsedData;
  selectedBrokerKey;
  // sendEmailAndDownloadModal = false;
  removedTripsList = { created: null };
  constructor(
    private assignTripService: AssigTripsService) {
  }

  ngOnInit() {
  }

  getBrokerName(name) {
    return getBrokersList[name];
  }

  onSelectBroker() {
    // this.selectedBroker = brokerType;
    this.selectedBroker = this.parsedTrips.find(broker => broker._id === this.selectedBrokerKey);
  }

  viewUnparsedTrips(rawId) {
    this.unparsedData = null;
    // this.store.dispatch(new ViewUnparsedTripsDetail({ rawId }));
  }

  sortMethod(a, b) {
    return new Date(a.value.created) < new Date(b.value.created) ? 1 : -1;
  }

  unParsedData() {
    let unparsedData = '';
    // tslint:disable-next-line: forin
    for (const data in this.unparsedData) {
      unparsedData = unparsedData + this.unparsedData[data] + ',';
    }
    return unparsedData;

  }

  generateFile(data) {
    sweetAlert('Alert', 'Are you sure you want to delete this file?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.assignTripService
            .removeSweepTrips(data)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                // sweetAlert('Success', 'File Sweeped Successfully', 'success', 'OK')
                this.selectedBroker = null;
                this.removedTripsList = data.archive ? data.archive : null;
                this.closeDetailModal();
              }
            })
        }
      })
  }

  sendMail(tripList) {
    this.assignTripService
      .sendTripListEmail(tripList)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', 'Email Sent', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }
  download(tripList) {
    let url = 'https://qalbs.com/';
    const fileExtention = 'xlsx';
    const fileName = tripList.name;
    url = url + 'excel/' + fileName;
    downloadFile(url, fileExtention, fileName);
    // sweetAlert('Success', 'File Downloaded', 'success', 'OK');
  }

  closeDetailModal() {
    this.onCloseModal.emit();
  }

  ngOnDestroy(): void {
  }

}
