import { Component, OnDestroy, OnInit } from '@angular/core';
// import { FormGroup, FormBuilder } from '@angular/forms';
import { FleetService } from 'src/app/component/fleet/fleet-service/fleet.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { generatePdf, getCompanyLogo } from 'src/app/utils/utils.common';
import { DatePipe } from 'src/app/pipe/date-pipe/date.pipe';
import { VehicleInspectionService } from '../../vehicle-inspection-service/vehicle-inspection.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { dailyVehicleInspection } from '../../../pdf-reports/daily-vehicle-inspection';
import { cmsReport } from '../../../pdf-reports/cms-1500-report';

@Component({
  selector: 'app-daily-vehicle-inspection',
  templateUrl: './daily-vehicle-inspection.component.html',
  styleUrls: ['./daily-vehicle-inspection.component.css']
})
export class DailyVehicleInspectionComponent implements OnInit, OnDestroy {
  // dateForm: FormGroup;
  fleetList = [];
  user;
  vehicleInspectionList = [];
  selectedVehicle;
  search;
  dateRange;
  i = 0;
  j = 0;
  fromDate = moment().startOf('isoWeek');
  toDate = moment().endOf('isoWeek');
  weekNumber = moment().startOf('isoWeek').week();
  timeZone;
  vehicleDetailModalObj;

  constructor(private dateP: DatePipe, private fleetService: FleetService, private vehicleInspectionService: VehicleInspectionService, private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.timeZone = data.timeZone;
          this.getFleetList();
        }
      });
  }

  getFleetList() {
    this.fleetService
      .getFleetList({ 'isFleetSection': true, 'status': 'active' })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.fleetList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  onVehicleChange(event) {
    this.getAllVehicleInspection(event.target.value);
  }

  getAllVehicleInspection(vehicleId) {
    // this.todayDate = [moment.tz(this.timeZone).startOf('day').toISOString(), moment.tz(this.timeZone).endOf('day').toISOString()];
    this.vehicleInspectionList = [];
    let queryParams = {
      startDate: this.fromDate.tz(this.timeZone).startOf('day').toISOString(),
      endDate: this.toDate.tz(this.timeZone).endOf('day').toISOString(),
      fleet: this.selectedVehicle._id,
    };
    this.vehicleInspectionService.getDailyVehicleInspection(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.vehicleInspectionList = data;
      });
  }

  async downloadPdf() {
    // console.log(vInspection);
    // this.ngxLoader.start();
    const weekStart = this.fromDate.tz(this.timeZone).startOf('day').toISOString();
    const detailsObject = await dailyVehicleInspection(this.vehicleInspectionList, this.selectedVehicle, weekStart, this.dateP).catch(
      data => {
        // this.ngxLoader.stop();
      }
    );

    let v = '';
    if (this.selectedVehicle && this.selectedVehicle.driverCarNumber) {
      v = this.selectedVehicle.driverCarNumber.replace(/\s/g, '');

    }
    // console.log("data -----> ",JSON.stringify(detailsObject));
    generatePdf(detailsObject, "download", v);
    // this.ngxLoader.stop();
  }

  // async downloadCmsReport() {

  //   const report = await cmsReport().catch(
  //     data => {

  //     }
  //   )
  //   generatePdf(report, "download", "cms1500");
  // }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  onSelectVehicle(vehicle) {
    this.selectedVehicle = vehicle;
    console.log(this.selectedVehicle);
    this.getAllVehicleInspection(vehicle._id);
  }


  lastWeek() {
    this.i -= 1;
    this.j += 1;
    this.fromDate = moment().subtract(this.j, 'weeks').startOf('isoWeek');
    this.toDate = moment().subtract(this.j, 'weeks').endOf('isoWeek');
    this.weekNumber = moment().subtract(this.j, 'weeks').endOf('isoWeek').week();
    this.getAllVehicleInspection("");
  }

  nextWeek() {
    this.i += 1;
    this.j -= 1;
    this.fromDate = moment().add(this.i, 'weeks').startOf('isoWeek');
    this.toDate = moment().add(this.i, 'weeks').endOf('isoWeek');
    this.weekNumber = moment().add(this.i, 'weeks').endOf('isoWeek').week();
    this.getAllVehicleInspection("");
  }

  openVehicleDetailModal(vehicle) {
    this.vehicleDetailModalObj = vehicle;
  }

  closeVehicleDetailModal(evt) {
    this.vehicleDetailModalObj = null;
  }


  ngOnDestroy(): void {
  }
}

