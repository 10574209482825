import { Injectable } from "@angular/core";
import * as moment from "moment";
import { PDFDocument } from "pdf-lib";
import {
  urlToArrayBuffer,
  getImageAsEmbedNew,
  removeNonAllowedCharactersFromPDF,
} from "src/app/utils/utils.common";


@Injectable({
  providedIn: "root",
})
export class MemberLogService {
  constructor() { }

  async dailyMemberLog(trips, user, loader, corporate) {
    const pdfArray = [];
    let data = [];
    let filename;
    const arrayOfkeys = Object.keys(trips);


    for (const [index, key] of arrayOfkeys.entries()) {
      data.push(trips[key]);
      filename = `${
        data[0].trips[0].priorityClient.displayName + "-" + moment().format("YYYY-MM-DD")
      }-Log.pdf`;
      if (index === 8) {
        const response = await fetch(
          "assets/documents/member-log.pdf"
        );
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, data, user, corporate);
        pdfArray.push(pdfBuffer);
        data = [];
      } else if (index > 8 && (index - 8) % 16 === 0) {
        const response = await fetch(
          "assets/documents/member-log-blank.pdf"
        );
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, data, user, corporate);
        pdfArray.push(pdfBuffer);
        data = [];
      }
    }
    if (!pdfArray.length && data.length) {
      const response = await fetch("assets/documents/member-log.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, data, user, corporate);
      pdfArray.push(pdfBuffer);
      data = [];
    } else if (pdfArray.length && data.length) {
      const response = await fetch(
        "assets/documents/member-log-blank.pdf"
      );
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, data, user, corporate);
      pdfArray.push(pdfBuffer);
    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      loader.isLoading = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async fillPDF(pdfBuffer: ArrayBuffer, data, user, corporate): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;
    for (let [index, item] of data.entries()) {
      form
      .getTextField("name")
      .setText(item.trips[0].priorityClient.displayName);
      form
      .getTextField("member_id")
      .setText(item.trips[0].memberId);

      form
          .getTextField("adate_" + index)
          .setText(
            moment(item.trips[0].scheduleTime).tz(company.timeZone).format("MM/DD/YY")
          );

            form.getTextField("apickup_" + index).setText(item.trips[0].jobOriginAddress);

            form
              .getTextField("adropoff_" + index)
              .setText(item.trips[0].jobDestinationAddress);

              if(item.trips[1]){

                form
                .getTextField("bdate_" + index)
                .setText(
                  moment(item.trips[0].scheduleTime).tz(company.timeZone).format("MM/DD/YY")
                );

                form.getTextField("bpickup_" + index).setText(item.trips[1].jobOriginAddress);

                form
                .getTextField("bdropoff_" + index)
                .setText(item.trips[1].jobDestinationAddress);
              }



        if (item.trips[0].job.signatureImage) {
          const member = await urlToArrayBuffer(item.trips[0].job.signatureImage);
          const memberSignature = await getImageAsEmbedNew(
            pdfDoc,
            item.trips[0].job.signatureImage,
            member
          );
          if (memberSignature)
            await form
              .getTextField("signature_" + index)
              .setImage(memberSignature);
        }

    }
    form
    .getTextField("corporate")
    .setText(corporate.displayName);
    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }
}
