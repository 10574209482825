import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PAYOR, getBrokersList, getDistanceFromLatLon, timeCalculation } from '../../../utils/utils.common';
import { sweetAlert } from 'src/app/utils/swal';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { MapsAPILoader } from '@agm/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ServiceAreasService } from '../../service-areas/service-areas-service/service-areas.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  payorList = PAYOR;
  duration = 10 * 1000; // 10 seconds
  tripAssistanceTimer;
  user;
  search = '';
  selectedBroker = '';
  brokers = getBrokersList;
  currentTime;
  trips = [];
  proposedTrips = [];
  markedReadyTrips = [];
  alarmingTrips = [];
  inprogressTrips = [];
  scheduledTrips = [];
  cancelledTrips = [];
  settingInterval;

  componentType;
  notesModalObj;
  assignTripModalObj;
  tripDetailModalObj;
  live;
  counter: number = 10;
  tripAssistanceList;
  selectedTripSection;
  selectedTripId;

  @ViewChild('timer', { static: true }) timer;
  view = 'grid';
  directionMatrixService;
  timeZone;
  isGoogleApprox = false;
  showSlideFilter = false;
  isLocalApprox = false;
  subscription: Subscription;
  subMenu = 'v1';

  preServiceAreas;

  constructor(
    private assignTripService: AssigTripsService,
    private socketService: SocketService,
    private mapsAPILoader: MapsAPILoader,
    private sharedDataService: SharedDataService,
    private serviceAreasService: ServiceAreasService

  ) { }

  ngOnInit() {
    console.log('Test-1:Dashboard:ngOnInit:');
    this.mapsAPILoader.load().then(async () => {
      this.directionMatrixService = new google.maps.DistanceMatrixService()
      this.getUser();
    });

    this.socketService.addListener(Events.GET_ASSIGN_TRIP_ETA)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          if (data.success && this[this.selectedTripSection] && this[this.selectedTripSection].length) {
            const selectedTrip = this[this.selectedTripSection].find((x) => x._id === this.selectedTripId);
            if (selectedTrip) {
              selectedTrip.loadedMessage = data.message;
            }
            this.selectedTripSection = null;
            this.selectedTripId = null;
          }
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = user.timeZone;
          this.getAllTrips();
          this.listenSocket();
          const myUser = user.company ? user.company : user;
          if (myUser.isTripAssistance) {
            this.getTripAssistanceList();
            this.getTripAssistance();
            this.getServiceAreas();
          }
          if (this.user.hasOwnProperty('isListView') && this.user.isListView) {
            this.setView('list');
          }
        }
      });
  }

  getTripAssistanceList() {
    console.log('Test-1:Dashboard:Inside_Interval_API_Method:');
    this.assignTripService
      .getTripAssistanceList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log('Test-1:Dashboard:Inside_Interval_API_Method:Data_Received');
        if (data) {
          this.tripAssistanceList = data;
        }
      })
  }

  processTripAssistace(trip) {
    sweetAlert('Alert', `Are you sure you want to ${trip.isDone ? 'unmarked' : 'mark'} ready?`, 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          let payload = trip;

          if (trip.isDone) {
            payload.isDone = false;
          } else {
            payload.isDone = true;
          }
          this.assignTripService
            .processTripAssistance(trip._id, payload)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.tripAssistanceList.array.forEach(item => {
                  if (item._id === data._id) {
                    item = data;
                  }
                });


              }
            })
        }
      });
  }

  // incrementCounter() {
  //   // Increment the counter
  //   this.counter--;
  //    setTimeout(() => {
  //     if(this.counter == 0){
  //       this.getAllTrips();
  //       this.counter = 10;
  //     }
  //     this.incrementCounter();
  //   }, 1000);
  // }

  getAllTrips() {
    this.assignTripService
      .getTripsForAssignDashboard()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timer.start();
          this.currentTime = data.currentTime;
          this.trips = data.assigns;
          this.filterTrips();
        }
      });
  }

  filterTrips() {
    this.proposedTrips = [];
    this.markedReadyTrips = [];
    this.alarmingTrips = [];
    this.inprogressTrips = [];
    this.scheduledTrips = [];
    this.cancelledTrips = [];
    let onBoardingTrips = [];
    this.trips.forEach((trip) => {
      const time = timeCalculation(trip, this.currentTime);
      trip.time = time;

      if (trip.isReadyForPickup) {
        this.markedReadyTrips.push(trip);
      }

      if (trip.isProposedReadyTime) {
        this.proposedTrips.push(trip);
      }

      if (trip.status === 'cancelled') {
        this.cancelledTrips.push(trip);
      } else if (trip.status === 'inprogress') {
        if (trip.job && (trip.job.memberNotVisible || trip.job.isMemberLoading)) {
          onBoardingTrips.push(trip);
        } else {
          this.inprogressTrips.push(trip);
        }
        if (this.view === "list") {
          if (this.isGoogleApprox || this.isLocalApprox) {
            this.setApproximateTime(trip);
          } else {
            this.setDefaultApproximation(trip);
          }
        }
      } else if ((time <= 60) || trip.status === 'declined' || trip.status === 'bumped' || trip.status === 'cancelled') {
        // trip.filter = 'alarming';
        this.alarmingTrips.push(trip);
      }

      // else if (time <= 120 && time >= 30) {
      //   // trip.filter = 'upcoming';
      // }
      else if (time > 60) {
        // trip.filter = 'schedule';
        this.scheduledTrips.push(trip);
      }
    });
    onBoardingTrips.sort((a, b) => {
      if (a.scheduleTime < b.scheduleTime) return -1;
      if (a.scheduleTime < b.scheduleTime) return 1;
      return 0;
    });
    this.inprogressTrips.sort((a, b) => {
      if (a.scheduleTime < b.scheduleTime) return -1;
      if (a.scheduleTime < b.scheduleTime) return 1;
      return 0;
    });
    this.inprogressTrips = [...onBoardingTrips, ...this.inprogressTrips]
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          // sweetAlert('Success', data.message, 'success', 'OK');
          this.trips.filter((trip) => {
            if (data.assign._id === trip._id) {
              trip.isReadyForPickup = data.assign.isReadyForPickup;
              // this.getAllTrips();
            }
          });
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });

    this.socketService.addListener(Events.GET_MILES_CALL)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          // this.inprogressTrips.filter((trip) => {
          //   if (data.assign._id === trip._id) {
          //     trip.isReadyForPickup = data.assign.isReadyForPickup;
          //     this.getAllTrips();
          //   }
          // });
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });
  }

  markReadyTrip(assign) {
    const tempAssign = JSON.parse(JSON.stringify(assign));
    const payload = {
      assignId: tempAssign._id,
      isReadyForPickup: !tempAssign.isReadyForPickup
    }
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, payload);
  }

  getETA(trip, objectName) {
    this.assignTripService
      .getETA(trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        if (this[objectName] && this[objectName].length) {
          const selectedTrip = this[objectName].find((x) => x._id === data._id);
          if (selectedTrip) {
            selectedTrip.etaMessage = data.etaMessage;
          }
        }
      });
  }

  loadedMiles(trip, objectName) {
    this.selectedTripSection = objectName;
    this.selectedTripId = trip._id;
    const payload = {
      assignId: trip._id,
      isDriveTime: true
    }
    this.socketService.emit(Events.GET_ASSIGN_TRIP_ETA, payload);
  }

  assignTrip(trip) {
    this.assignTripModalObj = trip;
    this.componentType = 'dashboard';
  }

  onAssignTrip(tripStatus: string) {
    this.getAllTrips();
    this.assignTripModalObj = null;
    this.componentType = null;
  }

  viewNotes(trip) {
    this.notesModalObj = trip;
  }

  viewTripDetails(trip) {
    this.tripDetailModalObj = trip;
  }

  getBroker(key) {
    return getBrokersList[key];
  }

  sortMethod(a, b) {
    return new Date(a.key) > new Date(b.key) ? 1 : -1;
  }

  // ngAfterViewInit() {
  // }

  ngAfterViewInit(): void {
    this.timer.start();
    // this.getAllTrips();
  }

  onTimerComplete(): void {
    this.timer.init()
    this.getAllTrips();

  }

  getTripAssistance() {
    // if (this.tripAssistanceTimer) return;
    console.log('Test-1:Dashboard:Register_Interval1:', this.tripAssistanceTimer);
    if(this.tripAssistanceTimer){
      console.log('Test-1:Dashboard:Removed_Previous_Interval:', this.tripAssistanceTimer);
      clearInterval(this.tripAssistanceTimer);
    }

    this.tripAssistanceTimer = setInterval(() => {
      console.log('Test-1:Dashboard:Running_Interval2:', this.tripAssistanceTimer);
      this.getTripAssistanceList();
    }, 30000)
  }
  formatMyTimer(timer): void {
    if (timer)
      this.timer.formattedTimeLeft = timer.split(':')[1];
    else
      this.timer.formattedTimeLeft = '0'
  }

  async getDistance(origin, destination) {
    let seconds;

    const request: any = {
      origins: [new google.maps.LatLng(origin.lat, origin.lng)],
      destinations: [new google.maps.LatLng(destination.lat, destination.lng)],
      travelMode: google.maps.TravelMode.DRIVING
    }

    if (this.isGoogleApprox) {
      const res = await this.directionMatrixService.getDistanceMatrix(request, (response, status) => {
        if (status === 'OK' && response) {
          if (response.rows && response.rows.length) {
            const elements = response.rows[0].elements;
            if (elements && elements.length && elements[0].status === "OK") {
              const data = elements[0];
              seconds = data.duration.value;
              return seconds;
            }
          }
        }
        return null;
      });
    } else {
      const distance = getDistanceFromLatLon(request.origins[0].lat(), request.origins[0].lng(), request.destinations[0].lat(), request.destinations[0].lng());
      // converting per miles
      if (distance <= 5) {
        seconds = 5 * 60;
      } else {
        seconds = (distance + 5) * 60;
      }
    }

    return seconds;
  }

  async setApproximateTime(trip) {
    if (trip.job && trip.job.trackInfoJob && this.isEventExist(trip.job.trackInfoJob.jobAccepted)) {
      const res1 = await this.getDistance({ lat: trip.job.trackInfoJob.jobAccepted.coords[1], lng: trip.job.trackInfoJob.jobAccepted.coords[0] }, { lat: trip.jobOriginLatitude, lng: trip.jobOriginLongitude });
      if (res1) {
        // jobAccepted time
        let approxPU = moment(trip.job.trackInfoJob.jobAccepted.time).tz(this.timeZone).add(res1, 'seconds');
        trip.approxPU = approxPU.toISOString();
        let scheduleTime = moment(trip.scheduleTime).tz(this.timeZone);
        trip.puTimeDiff = scheduleTime.diff(approxPU, 'minutes');
        trip.puTimeMsg = trip.puTimeDiff < 0 ? 'Late' : 'Early';

        if (this.isEventExist(trip.job.trackInfoJob.jobStarted)) {
          const res = await this.getDistance({ lat: trip.job.trackInfoJob.jobStarted.coords[1], lng: trip.job.trackInfoJob.jobStarted.coords[0] }, { lat: trip.jobDestinationLatitude, lng: trip.jobDestinationLongitude });
          if (res) {
            let approxDO = moment(trip.job.trackInfoJob.jobStarted.time).tz(this.timeZone).add(res, 'seconds');
            trip.approxDO = approxDO.toISOString();
            let appointmentTime = moment(trip.appointmentTime).tz(this.timeZone);
            trip.doTimeDiff = appointmentTime.diff(approxDO, 'minutes');
            trip.doTimeMsg = trip.doTimeDiff < 0 ? 'Late' : 'Early';
          }
        } else {
          const res = await this.getDistance({ lat: trip.jobOriginLatitude, lng: trip.jobOriginLongitude }, { lat: trip.jobDestinationLatitude, lng: trip.jobDestinationLongitude });
          if (res) {
            let approxDO = moment(trip.approxPU).tz(this.timeZone).add(res, 'seconds');
            trip.approxDO = approxDO.toISOString();
            let appointmentTime = moment(trip.appointmentTime).tz(this.timeZone);
            trip.doTimeDiff = appointmentTime.diff(approxDO, 'minutes');
            trip.doTimeMsg = trip.doTimeDiff < 0 ? 'Late' : 'Early';
          }
        }
      }
    }
  }

  setDefaultApproximation(trip) {
    if (trip.job && trip.job.trackInfoJob) {
      if (this.isEventExist(trip.job.trackInfoJob.jobAccepted) && trip.job.trackInfoJob.jobAccepted.aproxTime) {
        // let approxPU = moment(trip.job.trackInfoJob.jobAccepted.aproxTime).tz(this.timeZone)
        // trip.approxPU = approxPU.toISOString();
        // let scheduleTime = moment(trip.scheduleTime).tz(this.timeZone);
        // trip.puTimeDiff = scheduleTime.diff(approxPU, 'minutes');
        // trip.puTimeMsg = trip.puTimeDiff < 0 ? 'Late' : 'Early';
        trip.approxPU = trip.job.trackInfoJob.jobAccepted.aproxTime;
        trip.puTimeDiff = moment(trip.scheduleTime).diff(moment(trip.approxPU), 'minutes');
      }
      if (this.isEventExist(trip.job.trackInfoJob.jobAproxDo) && trip.job.trackInfoJob.jobAproxDo.aproxTime) {
        // let approxDO = moment(trip.job.trackInfoJob.jobAproxDo.aproxTime).tz(this.timeZone);
        // trip.approxDO = approxDO.toISOString();
        // let appointmentTime = moment(trip.appointmentTime).tz(this.timeZone);
        // trip.doTimeDiff = appointmentTime.diff(approxDO, 'minutes');
        // trip.doTimeMsg = trip.doTimeDiff < 0 ? 'Late' : 'Early';
        trip.approxDO = trip.job.trackInfoJob.jobAproxDo.aproxTime;
        trip.doTimeDiff = moment(trip.appointmentTime).diff(moment(trip.approxDO), 'minutes');
      }

    }
  }

  isEventExist(event) {
    return event && event.coords.length === 2 && event.coords[0] && event.coords[1];
  }

  setView(type) {
    this.view = type;
    if (this.view === 'list') { // for reset timer and refetch data
      this.timer.init();
      this.timer.start();
      this.getAllTrips();
    }
  }

  toggleApprox(method) {
    if (method === 'google') {
      this.isGoogleApprox = !this.isGoogleApprox;
      if (this.isGoogleApprox) {
        this.isLocalApprox = false;
      }
    } else if (method === 'local') {
      this.isLocalApprox = !this.isLocalApprox;
      if (this.isLocalApprox) {
        this.isGoogleApprox = false;
      }
    }
  }

  showFilter() {
    this.showSlideFilter = !this.showSlideFilter
  }

  closeFilter() {
    this.showSlideFilter = false;
  }

  shortStatus(status, jobStatus) {
    let str = '';
    if (status === 'unassigned') {
      str = 'UN';
    } else if (status === 'offered' || status === 'offered') {
      str = 'AS';
    } else if (status === 'inprogress') {
      if (jobStatus === 'onway') {
        str = 'OW'
      } else if (jobStatus === 'arrived') {
        str = 'AR'
      } else if (jobStatus === 'started') {
        str = 'MO'
      } else if (jobStatus === 'completed') {
        str = 'CP'
      }
    } else if (status === 'finished') {
      str = 'FN';
    } else if (status === 'cancelled') {
      str = 'CN'
    }

    return str;
  }

  getTripsCount() {
    let count = 0;
    if (this.alarmingTrips && this.alarmingTrips.length) {
      count = count + this.alarmingTrips.length;
    }

    if (this.inprogressTrips && this.inprogressTrips.length) {
      count = count + this.inprogressTrips.length;
    }

    if (this.scheduledTrips && this.scheduledTrips.length) {
      count = count + this.scheduledTrips.length;
    }

    if (this.cancelledTrips && this.cancelledTrips.length) {
      count = count + this.cancelledTrips.length;
    }

    return count;
  }

  convert(num) {
    return Math.abs(num);
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  ngOnDestroy() {
    console.log('Test-1:Dashboard:ngOnDestroy:', this.tripAssistanceTimer);
    if(this.tripAssistanceTimer){
      clearInterval(this.tripAssistanceTimer);
    }
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
    this.socketService.removeListener(Events.GET_ASSIGN_TRIP_ETA);
  }

  getServiceAreas() {
    this.serviceAreasService
      .getServiceAreas({status: 'active'})
      .subscribe(data => {
        if (data && data.length) {
          this.preServiceAreas = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }
}
