import { downloadFile } from 'src/app/utils/pdf-downloader';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { sweetAlert } from 'src/app/utils/swal';
import { Observable, from, Subscription } from "rxjs";
import { getBrokersList } from '../../../utils/utils.common';
import { getBrokerImage } from '../../../utils/utils.common';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { KpisService } from '../kpis-service/kpis.service';
import { data } from 'jquery';
import { CsvService } from 'src/app/service/csv-service/csv.service';
@Component({
  selector: 'app-nemt-report',
  templateUrl: './nemt-report.component.html',
  styleUrls: ['./nemt-report.component.css']
})
export class NemtReportComponent implements OnInit, OnDestroy {

  getKpisState: Observable<any>;
  assignedKpis;
  brokerKpis = [];
  corporateKpis = [];
  driverKpis = [];
  nobrokerTrips = 0;
  noCorporateTrips;
  dateForm: FormGroup;
  isVisible = true; // for table coloumns
  businessReportData;
  constructor(private fb: FormBuilder, private kpisService: KpisService , private csvService: CsvService) {
  }

  ngOnInit() {
    // this.getKpisState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     if (state.success) {
    // if (state.type === KpisActionTypes.NEMT_REPORTS || state.type === KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE) {
    //         this.nobrokerTrips = 0;
    //         this.assignedKpis = state.response.assignedKpis.length > 0 ? state.response.assignedKpis[0] : null;
    //         this.brokerKpis = state.response.brokerKpis;
    //         // tslint:disable-next-line: max-line-length
    //         this.corporateKpis = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].earnings : state.response.corporateKpis;
    //         this.noCorporateTrips = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].totalJobs : 0;
    //         this.driverKpis = state.response.driverKpis.length > 0 ? state.response.driverKpis[0].earnings : state.response.driverKpis;
    //         this.brokerKpis.forEach(element => {
    //           this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
    //         });
    // } else if (state.type === KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF || state.type === KpisActionTypes.NEMT_BROKER_REPORT_PDF
    //         || state.type === KpisActionTypes.NEMT_CORPORATE_REPORT_PDF || state.type === KpisActionTypes.NEMT_DRIVER_REPORT_PDF
    // || state.type === KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF) {
    //         downloadBase64File(state.response.content, state.response.pdfName);
    //       }
    //     } else if (state.errorMessage) {
    //       sweetAlert('Error', state.errorMessage, 'error', 'Ok');
    //     }
    //   });
    this.dateForm = this.fb.group({
      dateRange: ['']
    });

    this.getNemtReports();
  }

  getNemtReports() {
    this.kpisService.getNemtReports()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.businessReportData = data;
        this.nobrokerTrips = 0;
        this.assignedKpis = data.assignedKpis.length > 0 ? data.assignedKpis[0] : null;
        this.brokerKpis = data.brokerKpis;
        // tslint:disable-next-line: max-line-length
        this.corporateKpis = data.corporateKpis.length > 0 ? data.corporateKpis[0].earnings : data.corporateKpis;
        this.noCorporateTrips = data.corporateKpis.length > 0 ? data.corporateKpis[0].totalJobs : 0;
        this.driverKpis = data.driverKpis.length > 0 ? data.driverKpis[0].earnings : data.driverKpis;
        this.brokerKpis.forEach(element => {
          this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
        });
      }
    })
    // this.store.dispatch(new NemtReports());
  }

  getNemtStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].startOf('day').toISOString();
    const endDate = this.dateForm.value.dateRange[1].endOf('day').toISOString();
    this.kpisService
      .getNemtReportsByDate(startDate, endDate)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.businessReportData = data;
          this.nobrokerTrips = 0;
          this.assignedKpis = data.assignedKpis.length > 0 ? data.assignedKpis[0] : null;
          this.brokerKpis = data.brokerKpis;
          // tslint:disable-next-line: max-line-length
          this.corporateKpis = data.corporateKpis.length > 0 ? data.corporateKpis[0].earnings : data.corporateKpis;
          this.noCorporateTrips = data.corporateKpis.length > 0 ? data.corporateKpis[0].totalJobs : 0;
          this.driverKpis = data.driverKpis.length > 0 ? data.driverKpis[0].earnings : data.driverKpis;
          this.brokerKpis.forEach(element => {
            this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
          });
        }
      })
    // this.store.dispatch(new NemtReportsByDateRange(startDate, endDate));

  }

  getBrokerName(brokerName) {
    if (brokerName.length === 0) {
      brokerName = 'default';
    }
    return getBrokersList[brokerName];
  }
  getBrokerImg(brokerName) {
    return getBrokerImage(brokerName);
  }


  onToggleClicked() {
    this.isVisible = !this.isVisible;
  }

  downloadBusinessReport() {
    console.log('Check Download')
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
      type: 'kpis'
    };
    this.kpisService
      .getNemtBusinessReportPdf(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log('Check Service', data)
        if(data){
          downloadBase64File(data.content, data.pdfName);
        }
      })

  }

  downloadMilesReport() {
    console.log('Check Download')
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
      type: 'miles'
    };
    this.kpisService
      .getNemtBusinessReportPdf(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log('Check Service', data)
        downloadBase64File(data.content, data.pdfName);
      })

  }

  downloadBrokerReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null
    };
    this.kpisService
      .getNemtBrokerReportPdf(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);

        }
      })
    // this.store.dispatch(new NemtBrokerReportPdf(data));
  }

  downloadCorporateReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null
    };
    this.kpisService
      .getNemtCorporateReportPdf(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      })
    // this.store.dispatch(new NemtCorporateReportPdf(data));
  }

  downloadCorporateTripReport(corporateId) {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null
    };
    this.kpisService
      .getNemtCorporateTripReportPdf(data, corporateId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      })
    // this.store.dispatch(new NemtCorporateTripReportPdf(data, corporateId));
  }

  downloadDriverReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null
    };
    this.kpisService
      .getNemtDriverReportPdf(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      })
    // this.store.dispatch(new NemtDriverReportPdf(data));
  }

  gmtKpiReport() {
    console.log('Check Download')
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
    };
    // DownloadFile.downloadURI(name, url);
    this.kpisService
      .getNemtGMTKpiReportPdf(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log('Check Service', data)
        downloadBase64File(data.content, data.pdfName);
      })
    // catchError((error) => {
    //     return of(new CommonFailure({ type: KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF, error }));
    // })    
  }

  driverkpiReport(driverId) {
    console.log('Check Download',driverId)
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
      driverId: driverId
    };
    // DownloadFile.downloadURI(name, url);
    console.log("check for single driver", payload)
    this.kpisService
      .getNemtDriverKpiReportPdf(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        console.log('Check Service', data)
        downloadBase64File(data.content, data.pdfName);
      })
    // catchError((error) => {
    //     return of(new CommonFailure({ type: KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF, error }));
    // })   
  }
  downloadMultiReport(){
    console.log("Data Check", this.businessReportData);
    const date = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].endOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].startOf('day').toISOString() : null,
    }
    this.csvService.multiSheet(this.businessReportData, date);

        
    
  }

  ngOnDestroy(): void {
  }
}
