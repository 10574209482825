import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LevelOfServiceService } from '../service/level-of-service.service';
import { sweetAlert } from 'src/app/utils/swal';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-level-of-service',
  templateUrl: './level-of-service-list.component.html',
  styleUrls: ['./level-of-service-list.component.css']
})
export class LevelOfServiceComponent implements OnInit {
  search;
  vehicles =[];
  user;
  status="active"

  constructor(
    private levelOfServiceService: LevelOfServiceService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit() {
      this.sharedDataService
        .getUser()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(user => {
          if (user) {
            this.user = user;
            this.getVehicles();
          }
        });
  }

  onChangeStatus(evt) {
    this.getVehicles();
  }
  getVehicles() {
      const queryParams = {
        status: this.status
      }

    let isAdmin = false;
      if (this.user.roles && ((typeof this.user.roles) === 'object') && this.user.roles[0] ) {
        isAdmin = this.user.roles[0] === "admin";
      }

      this.levelOfServiceService
        .getVehicles(isAdmin, queryParams)
        .subscribe(data => {
          if (data && data.length) {
            this.vehicles = data;
            console.log('Vehicles: ', this.vehicles);
          }else{
            this.vehicles = [];
          }
        }, err => {
          sweetAlert("Error", err, "error", "Ok");
        });
  }
  ngOnDestroy(): void {
  }
}
