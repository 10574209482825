import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceserviceService } from '../../service/invoiceservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-setting-list',
  templateUrl: './invoice-setting-list.component.html',
  styleUrls: ['./invoice-setting-list.component.css']
})
export class InvoiceSettingListComponent implements OnInit, OnDestroy {

  submitted = false;
  providerinvoicesettingForm: FormGroup;
  search: any;
  company = [];
  companyId;
  selectedCompany: any;
  invoiceId: any;
  invoiceData;
  user: any;
  timeZone: any;
  todayDate: any = [];
  constructor(
    private fb: FormBuilder,
    private invoiceService: InvoiceserviceService,
    private csvService : CsvService,
    private sharedDataService : SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();

    this.providerinvoicesettingForm = this.fb.group({
      billTo: ['', Validators.required],
      alias: ['', Validators.required],
      completedFee: ['', Validators.required],
      cancelledFee: ['', Validators.required],
      company: [''],
      pendingFee: ['', Validators.required],
      discountType: ['', Validators.required],
      discount: ['', Validators.required],
      arrearsType: ['', Validators.required],
      arrearsFee: ['', Validators.required],
      dispatchDate: ['', Validators.required],
      dueDay: ['', Validators.required],
      dateRange: [''],
      isCancelledWaived: [false],
      isCompletedWaived: [false],
      isPendingWaived: [false],
    });
  }

  getUser(){
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = [
            moment.tz(this.timeZone).startOf("day").toISOString(),
            moment.tz(this.timeZone).endOf("day").toISOString(),
          ];
        }
      });
    this.getCompanies()

  }
  getCompanies() {
    this.invoiceService
      .getInvoiceList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.company = data;
         }
      });
  }

  onSelectCompany(company) {
    this.selectedCompany = company;
    this.providerinvoicesettingForm.reset();
    this.companyData();
  }


  companyData() {
    this.invoiceId = null;
    this.submitted = true;
    const payload = {
      company: this.selectedCompany._id
    }
    this.invoiceService
      .getCompanyData(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.invoiceData = data
          this.invoiceId = data._id
          this.providerinvoicesettingForm.patchValue({
            ...data
        })
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }


  updateInvoice(){
    this.invoiceService
      .saveInvoice(this.invoiceId, this.providerinvoicesettingForm.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Invoice Updated Successfully', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  downloadInvoice(){
    this.csvService.downloadInvoice(this.invoiceData,this.selectedCompany, this.todayDate, this.timeZone)
  }

  onDateChange(event) {
    this.todayDate = [
      event[0].toISOString(),
      event[1].endOf("day").toISOString(),
    ];
  }

  ngOnDestroy(): void {
  }
}
