import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from 'src/app/utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { QuarterService } from '../../service/quarter.service';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-creat-quarter',
  templateUrl: './creat-quarter.component.html',
  styleUrls: ['./creat-quarter.component.css']
})
export class CreatQuarterComponent implements OnInit, OnDestroy {

  createCompanyForm: FormGroup;
  selectedProfileImageFile = null;
  newProfilePicture = null;
  submitted = false;
  quarterUserId = '';
  passwordToggleFlag = true;
  errorMessage: string;
  tzone = 'America/Los_Angeles';

  constructor(
    private router: Router,
    private quarterService: QuarterService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,

  ) {

  }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.quarterUserId = params["id"];
    });
    this.createCompanyForm = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      // ------login Information------
      timeZone: ['', Validators.required],
      roleType: ['', Validators.required],
      platform: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      images: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
    });

    this.fillData()
  }

  fillData() {
    if (this.quarterUserId) {

      // console.log('Driver id here ==>', this.driverUserId)

      this.quarterService
        .getQuarterProfile(this.quarterUserId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.createCompanyForm.patchValue({
              ...data,
              password: '******'
            });
            this.newProfilePicture = data.profileImageURL;
            this.tzone = data.timeZone;
          }
        });


    }
  }

  get form() {
    return this.createCompanyForm.controls;
  }

  onCreateQuarter() {
    // console.log(this.createCompanyForm.value);
    this.submitted = true;

    if (this.createCompanyForm.invalid) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    if ((this.selectedProfileImageFile == null && this.newProfilePicture == null)) {
      return;
    }
    const image = {
      newProfilePicture: this.selectedProfileImageFile
    };
    this.createCompanyForm.patchValue({
      images: image
    });
    if (!this.quarterUserId) {
      this.saveQuarterData(this.createCompanyForm.value);
    } else {
      delete this.createCompanyForm.value.password;
      this.saveQuarterData({...this.createCompanyForm.value, quarterId:this.quarterUserId});
    }
  }

  saveQuarterData(payload) {
    this.quarterService
      .saveQuarter(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
          console.log('error check', data)
          if(data.success){

          sweetAlert('Success', `Quarter ${payload.quarterId ? 'Updated' : 'Added'} Successfully`, 'success', 'Ok')
            .then(() => {
              this.submitted = false;
              if (payload.quarterUserId) {
                this.router.navigateByUrl('/quarterpanels/' + data._id + '/profile');
              } else {
                this.router.navigateByUrl('credentialing/quarterpanels');
              }
            });
          }
      }, err => {
        console.log(err, 'check error')
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }


  getAddress(place) {
    this.createCompanyForm.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  changeTimezone() {
    this.createCompanyForm.patchValue({ timeZone:  this.tzone });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  processProfileFile(ImageInput: any) {
    imageProcessing(ImageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedProfileImageFile = result.file;
      this.newProfilePicture = result.src;


    });

  }

  ngOnDestroy(): void {
  }
}
