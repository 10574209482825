import { sweetAlert } from "src/app/utils/swal";
import { Observable } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { getBrokersList } from "../../../utils/utils.common";
import * as _ from "underscore";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { PlannerService } from "../planner-service/planner-service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import * as moment from 'moment-timezone';

@Component({
  selector: "app-parse-trips",
  templateUrl: "./parse-trips.component.html",
  styleUrls: ["./parse-trips.component.css"],
})
export class ParseTripsComponent implements OnInit, OnDestroy {
  imageSrc;
  selectedFile = null;
  htmlText = null;
  submitted = false;
  selectedCompany = null;
  invalidFileType = false;
  getParseState: Observable<any>;
  brokerKeys;
  searchDate;
  show: any;
  buttonName: string;
  todayDate;
  isTripsLoading = false;
  isDissable = false;

  constructor(
    private plannerService: PlannerService,
    private router: Router,
    private sharedDataService: SharedDataService) { }

  selectedCompanyName = null;
  ngOnInit() {
    this.getUser();
    this.getBrokerKeys();

    // this.getParseState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     if (state.success) {
    //       sweetAlert("Success", state.responce.message, "success", "Ok").then(
    //         () => {
    //           this.router.navigateByUrl("/assignstrips/unassigned");
    //         }
    //       );
    //     } else if (state.errorMessage) {
    //       sweetAlert("Error", state.errorMessage, "error", "Ok");
    //     }
    //   });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.todayDate = moment.tz(data.timeZone).startOf('day').toISOString();
        }
      });
  }

  getBrokerKeys() {
    this.brokerKeys = getBrokersList;

  }

  readURL(event: HTMLInputEvent): void {
    if (event.target && event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
    console.log(this.selectedFile);
  }
  submitFile() {
    this.submitted = true;
    // console.log(this.selectedFile);
    // console.log(this.htmlText);
    // console.log(this.selectedCompanyName);
    if (!this.selectedCompanyName) {
      return;
    }
    if (this.isDissable) {
      return;
    }
    this.isDissable = true;
    const data = {
      companyType:
        this.selectedCompanyName === "lcp"
          ? "lcptrips"
          : this.selectedCompanyName,
      scrapData: this.selectedFile ? null : this.htmlText,
      isFile: this.selectedFile ? true : false,
    };
    if (this.selectedFile) {
      if (
        this.selectedFile.type === "application/vnd.ms-excel" ||
        this.selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        this.selectedFile.type === "text/csv"
      ) {
        // this.store.dispatch(new ScrapFile(this.selectedFile, data));
        this.scrapTrips(this.selectedFile, data);
      } else {
        this.invalidFileType = true;
      }
    } else if (this.htmlText) {
      // this.store.dispatch(new ScrapFile(this.selectedFile, data));
      this.scrapTrips(this.selectedFile, data);
    }
  }

  scrapTrips(file, payload) {
    this.plannerService
      .scrapTrips(file, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.router.navigateByUrl("/trips");
          // sweetAlert("Success", 'File parse successfully', "success", "Ok")
          // .then(
          //   () => {
          //     this.router.navigateByUrl("/trips");
          //   });
        }else{
          this.isDissable = false;
          sweetAlert("Error", 'Operation failed', "error", "Ok");
        }
      }, err => {
        this.isDissable = false;
        sweetAlert("Error", 'Operation failed', "error", "Ok");
      });
  }

  // parseMtmTrips(isValid: boolean): void {
  //   if (!isValid) {
  //     this.form.mtmTripForm.form.markAllAsTouched();
  //     return;
  //   }
  //   if (confirm('Are you sure you want to fetch mtm trips?')) {
  //     this.isTripLoaded = true;
  //     this.brokersettingsService.fetchMTMTrips({
  //       dos: this.dos
  //     }).subscribe(response => {
  //       this.isTripLoaded = false;
  //       this.message = `${response.total} trips fetched`;
  //       alert(this.message);
  //       if (response.total > 0) {
  //         setTimeout(() => {
  //           this.router.navigate(['/assigns/list'], { queryParams: { status: 'unassigned' } });
  //         }, 1000);
  //       }
  //     }, err => {
  //       this.isTripLoaded = false;
  //       this.message = err.message;
  //     });
  //   }
  // }


  ngOnDestroy(): void { }

  onSelectBroker(evt) {
    console.log(" ", evt)
    this.selectedCompanyName = evt;
    this.submitted = false;
    if (this.selectedCompanyName === "lcp") {
      this.selectedFile = null;
    } else {
      this.htmlText = null;
    }
  }

  onDateChange(event) {
    this.todayDate = event;
  }

  fetchTrips() {
    console.log('CallTheCar')
    if (this.selectedCompanyName === 'callthecar') {
      this.askForCTC();
    } else if (this.selectedCompanyName === 'first') {
      this.askForMTM();
    }
  }

  askForCTC() {
    sweetAlert('Alert', 'Are you sure you want to fetch CTC trips?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.isTripsLoading = true;
          this.fetchCTCTrips();
        }
      });
  }

  fetchCTCTrips() {
    console.log('ctc');
    const payload = { dos: moment(this.todayDate).format('YYYY-MM-DD') }
    this.plannerService.fetchCTCTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.isTripsLoading = false;
        if (data) {
          if (data.total > 0) {
            sweetAlert('Success', `${data.total} Trips Fetched Successfully`, 'success', 'OK')
              .then((result: any) => {
                if (result.value) {
                  this.router.navigateByUrl('trips');
                }
              });
          }
          else {
            sweetAlert('Alert', `${data.total} Trip Fetched`, 'warning', 'OK')
          }
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
        this.isTripsLoading = false;
      });
  }

  askForMTM() {
    sweetAlert('Alert', 'Are you sure you want to fetch MTM trips?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.isTripsLoading = true;
          this.fetchMTMTrips();
        }
      });
  }

  fetchMTMTrips() {
    console.log('mtm');
    const payload = { dos: moment(this.todayDate).format('YYYY-MM-DD') }
    this.plannerService.fetchMTMTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.isTripsLoading = false;
        if (data) {
          if (data.total > 0) {
            sweetAlert('Success', `${data.total} Trips Fetched Successfully`, 'success', 'OK')
              .then((result: any) => {
                if (result.value) {
                  this.router.navigateByUrl('trips');
                }
              });
          }
          else {
            sweetAlert('Alert', `${data.total} Trip Fetched`, 'warning', 'OK')
          }
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
        this.isTripsLoading = false;
      });
  }
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
