import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { sweetAlert } from 'src/app/utils/swal';
import { PrimaryDiagnosisService } from '../primary-diagnosis-service/primary-diagnosis.service';

@Component({
  selector: 'app-add-primary-diagnosis',
  templateUrl: './add-primary-diagnosis.component.html',
  styleUrls: ['./add-primary-diagnosis.component.css']
})
export class AddPrimaryDiagnosisComponent implements OnInit,OnDestroy {
  primaryDiagnosisForm: FormGroup;
  submitted = false;
  userId = null;
  primaryDiagnosisId;

  constructor(
    public authService: AuthService,
    private primaryDiagnosisService: PrimaryDiagnosisService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.primaryDiagnosisId = params["id"];
        if (this.primaryDiagnosisId) {
          this.getPrimaryDiagnosebyId();
        }
      });

    this.primaryDiagnosisForm = this.fb.group({
      title: ["", Validators.required],
      code: ["", Validators.required],
      status: ['active', Validators.required]
    });
  }

  getPrimaryDiagnosebyId() {
    this.primaryDiagnosisService.getPrimaryDiagnoseById(this.primaryDiagnosisId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.primaryDiagnosisForm.patchValue(data)
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
  }

  savePrimaryDiagnose() {
    this.submitted = true;
    if (this.primaryDiagnosisForm.invalid) {
      return;
    }
    let payload = this.primaryDiagnosisForm.value;
    this.primaryDiagnosisService.saveprimaryDiagnose(this.primaryDiagnosisId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.router.navigateByUrl("billing/primary-diagnosis");
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
  }

  get form() {
    return this.primaryDiagnosisForm.controls;
  }

  ngOnDestroy(): void {
  }
}
