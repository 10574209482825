import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-standing-orders',
  templateUrl: './standing-orders.component.html',
  styleUrls: ['./standing-orders.component.css']
})
export class StandingOrdersComponent implements OnInit, OnDestroy {
  cancelTripModalObj;
  cancelType;
  search;
  standingOrders;
  tripIndex = -1;
  drivers = [];
  driverList = [];
  filterdataList = [];
  editTripModalObj;

  constructor(
    private assignTripService: AssigTripsService,
  ) { }

  ngOnInit() {
    this.getStandingOrders();
  }

  getStandingOrders() {
    this.assignTripService
      .getStandingOrders()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.assigns && data.assigns.length) {
          this.standingOrders = data.assigns;
          this.drivers = data.drivers;
          this.driverList = this.drivers.reduce((obj, item) => (obj[item._id] = item, obj), {});
        } else {
          this.standingOrders = undefined;
        }
      });
  }

  cancelTrip(trip) {
    this.cancelType = 'Single';
    this.cancelTripModalObj = trip;
  }

  cancelAllTrips(trips) {
    this.cancelType = 'Multiple Assign';
    this.cancelTripModalObj = trips.map(trip => trip._id);
  }

  archiveAllTrips(trips) {
    sweetAlert('Alert', `Are you sure you want to archive all trips?`, 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          const selectedTrips = trips.map(trip => trip._id);
          this.archiveAllTripsCall(selectedTrips)
        }
      })
  }


  archiveTrip(trip) {
    sweetAlert('Alert', `Are you sure you want to archive trip?`, 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.archiveTripCall(trip)
        }
      })
  }

  archiveTripCall(trip) {
    this.assignTripService
      .removeTrip({ status: 'archive' }, trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', "Trip Cancelled Successfully", 'success', 'OK')
          this.getStandingOrders();
        }
      })
  }
  archiveAllTripsCall(selectedTrips) {
    this.assignTripService
      .assignMultipleTrip({
        selectedTrips,
        status: 'archived'
      })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.getStandingOrders();
        }
      });
  }

  // onCancelTrip(evt) {
  //   // this.cancelType = '';
  //   // this.cancelTripModalObj = undefined;
  //   // this.getStandingOrders();
  //   if (evt) {
  //     this.filterdataList.splice(this.tripIndex, 1);
  //   }
  //   this.cancelTripModalObj = null;
  // }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex(t => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);
      this.filterdataList[idx] = event;
      this.editTripModalObj = null;
    }
  }
  // onCloseModal(evt) {
  //   if (evt) {
  //     this.editTripModalObj = null;
  //   }
  // }

  editTrip(assign) {

    this.editTripModalObj = assign._id
  }

  onCloseCancelModal() {
    this.cancelTripModalObj = false;
    this.getStandingOrders();
  }

  ngOnDestroy(): void {
  }
}
