import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { FleetService } from "../../../fleet/fleet-service/fleet.service";
import { sweetAlert } from "src/app/utils/swal";
import { VehicleInspectionService } from "../vehicle-inspection-service/vehicle-inspection.service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { ActivatedRoute } from "@angular/router";
import { convertBase64ToFile } from "src/app/utils/utils.common";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
@Component({
  selector: "app-add-vehicle-inspection",
  templateUrl: "./add-vehicle-inspection.component.html",
  styleUrls: ["./add-vehicle-inspection.component.css"],
})
export class AddVehicleInspectionComponent implements OnInit, OnDestroy {
  vehicleInspectionForm: FormGroup;
  submitted = false;
  fleetList = [];
  user;
  vehicleInspectionId;
  signatureModalObj;
  signatureTag = '';

  constructor(private fb: FormBuilder, private fleetService: FleetService, private vehicleInspectionService: VehicleInspectionService, private sharedDataService: SharedDataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.vehicleInspectionForm = this.fb.group({
      fleet: ["", Validators.required],
      serviceName: ["", Validators.required],
      inspectionDate: ["", Validators.required],
      vehicleMake: ["", Validators.required],
      model: ["", Validators.required],
      vehicleDecalNumber: ["", Validators.required],
      vehicleServiceNumber: ["", Validators.required],
      vIdentificationNumber: ["", Validators.required],
      currentMileage: ["", Validators.required],
      lastInspectionDate: ["", Validators.required],
      serviceType: ["", Validators.required],

      driverPassengerCompartment: this.fb.group({
        ssFloor: [""],
        ssFloorDate: [""],
        sbOperational: [""],
        sbOperationalDate: [""],
        fintact: [""],
        fintactDate: [""],
        dpOperational: [""],
        dpOperationalDate: [""],
        cdClean: [""],
        cdCleanDate: [""],
        igWorking: [""],
        igWorkingDate: [""],
        hacOperational: [""],
        hacOperationalDate: [""],
        wbOperational: [""],
        wbOperationalDate: [""],
        wwOperational: [""],
        wwOperationalDate: [""],
        mdPassenger: [""],
        mdPassengerDate: [""],
        psOperational: [""],
        psOperationalDate: [""],
        ssSecure: [""],
        ssSecureDate: [""],
        hOperational: [""],
        hOperationalDate: [""],
        sirenOperational: [""],
        sirenOperationalDate: [""],
        airBags: [""],
        airBagsDate: [""],
        pbOperational: [""],
        pbOperationalDate: [""],
        backupAlarmOperational: [""],
        backupAlarmOperationalDate: [""],
        comment: [""],
      }),

      tireWheels: this.fb.group({
        msTires: [""],
        msTiresDate: [""],
        tPressure: [""],
        tPressureDate: [""],
        tireTread: [""],
        tireTreadDate: [""],
        wearTread: [""],
        wearTreadDate: [""],
        valveStems: [""],
        valveStemsDate: [""],
        wheelDamage: [""],
        wheelDamageDate: [""],
        tireMotion: [""],
        tireMotionDate: [""],
        rpLinkage: [""],
        rpLinkageDate: [""],
        abJoints: [""],
        abJointsDate: [""],
        slBushings: [""],
        slBushingsDate: [""],
        springs: [""],
        springsDate: [""],
        shockStruts: [""],
        shockStrutsDate: [""],
        powerSteering: [""],
        powerSteeringDate: [""],
        comment: [""],
      }),

      brakes: this.fb.group({
        rotorDrums: [""],
        rotorDrumsDate: [""],
        breakPads: [""],
        breakPadsDate: [""],
        breakLines: [""],
        breakLinesDate: [""],
        masterCylinder: [""],
        masterCylinderDate: [""],
        absOperational: [""],
        absOperationalDate: [""],
        comment: [""],
      }),

      bodyFrame: this.fb.group({
        metalProtruding: [""],
        metalProtrudingDate: [""],
        bumpers: [""],
        bumpersDate: [""],
        hood: [""],
        hoodDate: [""],
        fenders: [""],
        fendersDate: [""],
        frame: [""],
        frameDate: [""],
        comment: [""],
      }),

      fuelSystem: this.fb.group({
        fuelPump: [""],
        fuelPumpDate: [""],
        hoseFitting: [""],
        hoseFittingDate: [""],
        fuelLines: [""],
        fuelLinesDate: [""],
        fuelTank: [""],
        fuelTankDate: [""],
        bracketStraps: [""],
        bracketStrapsDate: [""],
        comment: [""],
      }),

      exhaustSystem: this.fb.group({
        maniFold: [""],
        maniFoldDate: [""],
        pipesFitting: [""],
        pipesFittingDate: [""],
        muffler: [""],
        mufflerDate: [""],
        bracketHanger: [""],
        bracketHangerDate: [""],
        ExhaustPipe: [""],
        ExhaustPipeDate: [""],
        engineEmission: [""],
        engineEmissionDate: [""],
        comment: [""],
      }),

      mechanicVerification: this.fb.group({
        isPassedInspection: ["", Validators.required],
        name: ["", Validators.required],
        signature: ["", Validators.required],
        verificationDate: ["", Validators.required],
        title: ["", Validators.required],
        certification: ["", Validators.required],
        employerName: ["", Validators.required],
        phoneNumber: ["", Validators.required],

      }),

      representative: this.fb.group({
        name: ["", Validators.required],
        title: ["", Validators.required],
        signature: ["", Validators.required],
        date: ["", Validators.required],
      }),
    });

    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.vehicleInspectionId = params["id"];
      if (this.vehicleInspectionId) {
        this.getVehicleInspectionByID();
      }
    });

    this.getUser();
  }

  get form() {
    return this.vehicleInspectionForm.controls;
  }


  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.getFleetList();
          if (!this.vehicleInspectionId)
            this.vehicleInspectionForm.patchValue({ serviceName: this.user.displayName })
        }
      });
  }

  getFleetList() {
    this.fleetService
      .getFleetList({ 'isFleetSection': true, 'status': 'active' })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.fleetList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  getVehicleInspectionByID() {
    this.vehicleInspectionService.getVehicleInspectionByID(this.vehicleInspectionId)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.vehicleInspectionForm.patchValue(data)
    });
  }
  onVehicleChange() {
    if (this.vehicleInspectionForm.value.fleet) {
      const selectedVehicle = this.fleetList.find(element => element._id === this.vehicleInspectionForm.value.fleet);
      this.vehicleInspectionForm.patchValue({
        vehicleMake: selectedVehicle.driverCarModel,
        model: selectedVehicle.driverCarModel,
        vehicleDecalNumber: selectedVehicle.setiDecall,
        vehicleServiceNumber: selectedVehicle.driverCarNumber,
        vIdentificationNumber: selectedVehicle.vin
      })
    }
  }

  loopKeys(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Do something with the key
        if (obj[key] instanceof moment) {
          obj[key] = obj[key].toISOString();
        } else if (typeof obj[key] === 'object') {
          this.loopKeys(obj[key]); // Recursively loop through nested keys
        }
      }
    }
  };

  onNewTripSubmit() {
    console.log(this.vehicleInspectionForm)
    this.submitted = true;
    if (this.vehicleInspectionForm.invalid) {
      return;
    }
    const payload = this.vehicleInspectionForm.value;
    this.loopKeys(payload);

    this.vehicleInspectionService.addVehicleInspection(this.vehicleInspectionId, payload)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (this.vehicleInspectionId)
        sweetAlert('Alert', "Updated Successfully", 'success', 'OK')
      else{
        sweetAlert('Alert', "Created Successfully", 'success', 'OK')
        this.vehicleInspectionId = data._id;
        this.getVehicleInspectionByID();
      }
    });

  }

  showSignatureModal(tag) {
    this.signatureTag = tag;
    this.signatureModalObj = true;
  }
  onClearVehicle() {
    this.vehicleInspectionForm.patchValue({
      vehicleMake: '',
      model: '',
      vehicleDecalNumber: '',
      vehicleServiceNumber: '',
      vIdentificationNumber: ''
    });
  }

  onSaveSignature(data) {
    if (data) {
      console.log(data, "signature data") ;
      const imageFile = convertBase64ToFile(data, Math.random() + '.png');
      this.vehicleInspectionService
        .generateSignature(imageFile)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            if (this.signatureTag === "0") {
              this.vehicleInspectionForm.controls.mechanicVerification.patchValue({
                signature: data.location
              })
            } else {
              this.vehicleInspectionForm.controls.representative.patchValue({
                signature: data.location
              })
            }
            this.signatureModalObj = null;

          }
        });;
    }
  }

  ngOnDestroy(): void {
  }
}
