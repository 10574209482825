import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData, setFormDataV2 } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ModifierCodesService {

  constructor(private http: HttpClient)  { }8

  getModifierCodesById(id) {
    return this.http.get<any>(urls.BASE_URL  + urls.MODIFIER_CODES_URL  + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }


  saveModifierCode(id, payload) {

    if (id) {
      // Edit
      return this.http.put<any>(urls.BASE_URL+ urls.MODIFIER_CODES_URL  + `/${id}`, payload)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      // Add
      return this.http.post<any>(urls.BASE_URL + urls.MODIFIER_CODES_URL  , payload)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  getModifierCodes() {
    // const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + urls.MODIFIER_CODES_URL )
      .pipe(
        catchError(errorHandl)
      );
  }


}
