import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from "moment-timezone";
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-driver-load-v2',
  templateUrl: './driver-load-v2.component.html',
  styleUrls: ['./driver-load-v2.component.css']
})
export class DriverLoadV2Component implements OnInit, OnDestroy {
  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  @ViewChild('timer', { static: false }) timer;
  @Output() clear = new EventEmitter<any>();
  duration = 10 * 1000; // 10 seconds
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  drivers = [];
  selectedDriverId = '';
  timeZone;
  todayDate;
  search = '';
  standingOrders;
  nonSuggestedTrips = [];
  user;
  driverTrips = [];
  tripsPickupPoints = [];
  isDragging = false;
  waypoints = [];
  renderOptions = {
    suppressMarkers: true,
      // polylineOptions: {
      //   strokeColor: '#FF5733', // Set the desired color for the path
      //   strokeOpacity: 1,       // Set the opacity (0 to 1)
      //   strokeWeight: 4,       // Set the desired width of the path
      // },
  };
  isResizing = false;


  constructor(
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit() {
    this.getUser();
    this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();
    this.getDriverList();
  }

  ngAfterViewInit() {
    this.agmElement.mapReady
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((map) => {
        this.agmMap = map;
      });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  onDateChange() {
    this.todayDate = moment.tz(this.todayDate, this.timeZone).startOf('day').toISOString();
    if (this.selectedDriverId) {
      this.getStandingOrders();
    }
  }

  getStandingOrders() {
    if (!this.selectedDriverId) return;
    const object = {
      driver: this.selectedDriverId,
      scheduleTime: this.todayDate
    };
    this.assignTripService
      .getOutstandingTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.standingOrders = null;
          this.nonSuggestedTrips = [];
          this.driverTrips = [];
          this.standingOrders = data;
          this.filterTrips();
        }
      })
  }

  filterTrips() {
    this.nonSuggestedTrips = [];
    this.driverTrips = [];
    this.standingOrders.forEach(st => {
      if (st._id) {
        this.driverTrips = st.assigns;
        this.driverTrips.sort((a, b) => a.scheduleTime - b.scheduleTime)
      } else {
        this.nonSuggestedTrips = st.assigns;
        this.nonSuggestedTrips.sort((a, b) => a.scheduleTime - b.scheduleTime)
      }
    });
    this.setupTripByPickup();
  }

  setupTripByPickup() {
    this.tripsPickupPoints = [];
    this.waypoints = [];
    let selectedDriver = this.drivers.find(d => d._id === this.selectedDriverId);


    if (selectedDriver) {
      selectedDriver.isPickup = true;
      selectedDriver.isDropOff = true;
      selectedDriver.jobOriginAddress = selectedDriver.address;
      selectedDriver.jobOriginLatitude = selectedDriver.takeMeHome[1];
      selectedDriver.jobOriginLongitude = selectedDriver.takeMeHome[0];

      selectedDriver.jobDestinationAddress = selectedDriver.address;
      selectedDriver.jobDestinationLatitude = selectedDriver.takeMeHome[1];
      selectedDriver.jobDestinationLongitude = selectedDriver.takeMeHome[0];


      if (selectedDriver.shift) {
        this.tripsPickupPoints.push({
          ...selectedDriver,
          appointmentTime: selectedDriver.shift.checkin,
          scheduleTime: selectedDriver.shift.checkin,
          eta: selectedDriver.shift.checkin,
          distance: "0",
          travel: moment().utc().set({ minute: 0, hour: 0, second: 0 }).toISOString()
        });

      } else {
        // removed eta from
        this.tripsPickupPoints.push({
          ...selectedDriver,
          distance: "0",
          travel: moment().utc().set({ minute: 0, hour: 0, second: 0 }).toISOString()
        });
      }
      // checkin,checkout
    }

    if (this.driverTrips && this.driverTrips.length) {
      for (let point of this.driverTrips) {
        // first Trip
        let firstETA = point.scheduleTime;
        let firstDistance = "0";
        let firstTravel = moment().utc().set({ minute: 0, hour: 0, second: 0 }).toISOString();

        if (this.tripsPickupPoints && this.tripsPickupPoints.length) {
          const previousTrip = this.tripsPickupPoints[this.tripsPickupPoints.length - 1];
          if (previousTrip.priorityClient) {
            const distance = this.getDistanceFromLatLonInKm(previousTrip.jobDestinationLatitude, previousTrip.jobDestinationLongitude, point.jobOriginLatitude, point.jobOriginLongitude);
            firstDistance = distance.toFixed(2);
            firstETA = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();
            firstTravel = moment(previousTrip.travel).utc().add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();
          } else {
            if (!previousTrip.eta) {
              previousTrip.eta = point.scheduleTime;
              previousTrip.appointmentTime = point.scheduleTime;
              previousTrip.scheduleTime = point.scheduleTime;
            }
            const distance = this.getDistanceFromLatLonInKm(previousTrip.takeMeHome[1], previousTrip.takeMeHome[0], point.jobOriginLatitude, point.jobOriginLongitude);
            firstDistance = distance.toFixed(2);
            firstETA = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();
            firstTravel = moment(previousTrip.travel).utc().add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();

          }

        }

        this.tripsPickupPoints.push({ ...point, eta: firstETA, distance: firstDistance, travel: firstTravel, isPickup: true, isDropOff: false });

        // second Trip
        const secondPreviousTrip = this.tripsPickupPoints[this.tripsPickupPoints.length - 1];
        const ckSecondDistance = this.getDistanceFromLatLonInKm(secondPreviousTrip.jobOriginLatitude, secondPreviousTrip.jobOriginLongitude, point.jobDestinationLatitude, point.jobDestinationLongitude);

        let secondDistance = ckSecondDistance.toFixed(2);
        let secondTravel = moment(secondPreviousTrip.travel).utc().add(Math.round(ckSecondDistance + (ckSecondDistance*0.2)), 'minutes').toISOString();
        let secondETA = moment(secondPreviousTrip.eta).add(Math.round(ckSecondDistance + (ckSecondDistance*0.2)), 'minutes').toISOString();;

        this.tripsPickupPoints.push({ ...point, eta: secondETA, distance: secondDistance, travel: secondTravel, isPickup: false, isDropOff: true });
      }
    }


    if (selectedDriver) {
      selectedDriver.isPickup = true;
      selectedDriver.isDropOff = true;
      const previousTrip = this.tripsPickupPoints[this.tripsPickupPoints.length - 1];
      const distance = this.getDistanceFromLatLonInKm(previousTrip.jobDestinationLatitude, previousTrip.jobDestinationLongitude, selectedDriver.takeMeHome[1], selectedDriver.takeMeHome[0]);

      if (selectedDriver.shift) {
        const eta = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();
        this.tripsPickupPoints.push({
          ...selectedDriver,
          appointmentTime: selectedDriver.shift.checkout,
          scheduleTime: selectedDriver.shift.checkout,
          eta: eta,
          distance: distance.toFixed(2),
          travel: moment(previousTrip.travel).utc().add(Math.round(distance + (distance*0.2)), 'minutes').toISOString()
        });

      } else {
        let eta = "0";
        let scheduleTime;
        let appointmentTime;

        if (previousTrip.appointmentTime)
          scheduleTime = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();

        if (previousTrip.scheduleTime)
          appointmentTime = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();

        if (previousTrip.eta)
          eta = moment(previousTrip.eta).add(Math.round(distance + (distance*0.2)), 'minutes').toISOString();
        // removed eta from
        this.tripsPickupPoints.push({
          ...selectedDriver,
          appointmentTime,
          scheduleTime,
          distance: distance.toFixed(2),
          eta,
          travel: moment(previousTrip.travel).utc().add(Math.round(distance + (distance*0.2)), 'minutes').toISOString()
        });
      }
      // checkin,checkout
    }
    console.log(this.tripsPickupPoints);
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d / 1.609; // divided by 1.609 to convert into miles
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  ngOnDestroy(): void {
  }

  getUTCTime(travel) {
    return moment.utc(travel).format("HH:mm:ss")
  }

  getTotalMiles() {
    let totalMiles = 0;
    for (let trip of this.driverTrips) {
      totalMiles = totalMiles + trip.milage;
    }
    return totalMiles.toFixed(2);
  }

  getDriverFromID() {
    const findDriver = this.drivers.find(driver => driver._id === this.selectedDriverId);
    return findDriver;
  }

  removeFromBucket(assign) {
    assign.status = 'unassigned';
    let findTripIndex = this.driverTrips.findIndex(t => t._id === assign._id);
    this.driverTrips.splice(findTripIndex, 1);
    this.nonSuggestedTrips.push(assign);
    this.processBucket({
      selectedTrips: [assign._id],
      status: 'unassigned'
    });

  };


  sendToSpecificBucket(assign) {
    assign.status = 'offered';
    let findDriver = this.drivers.find(d => d._id === this.selectedDriverId);
    this.driverTrips.push(assign);
    let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === assign._id);
    this.nonSuggestedTrips.splice(findTripIndex, 1);
    this.processBucket({
      selectedTrips: [assign._id],
      status: 'offered',
      driver: findDriver
    });
  };


  processBucket(data) {
    this.assignTripService
      .assignMultipleTrip(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.setupTripByPickup();
          // sweetAlert('Success', 'Trip Processed Successfully', 'success', 'OK');
        } else if (data) {
          sweetAlert('Error', data, 'warning', 'OK');
        }
      })
  }

  toggleSelection(item: any): void {
    if (item.polyline) {
      item.polyline = false
    } else {
      item.polyline = true
    }
    this.waypoints = [];
    for (let point of this.tripsPickupPoints) {
      if (point.polyline) {
        this.waypoints.push(
          {stopover: false,location: { lat: point.isPickup ? point.jobOriginLatitude : point.jobDestinationLatitude, lng: point.isPickup ? point.jobOriginLongitude : point.jobDestinationLongitude } }
        )
      }
    }

console.log(this.waypoints)

  }

  onMouseDown(item: any) {
    this.isDragging = true;
    this.toggleSelection(item);
  }

  onMouseUp() {
    this.isDragging = false;
  }

  onMouseOver(item: any) {
    if (this.isDragging) {
      this.toggleSelection(item);
    }
  }



  startResizing(event: MouseEvent) {
    this.isResizing = true;
    const sidebar = document.getElementById('resizable-sidebar');

    if (sidebar) {
      const initialHeight = sidebar.offsetHeight;
      const startY = event.clientY;

      const mouseMoveHandler = (e: MouseEvent) => {
        if (this.isResizing) {
          const newHeight = initialHeight - (e.clientY - startY); // Subtract instead of add
          sidebar.style.height = newHeight + 'px';
          //comment added

        }
      };

      const mouseUpHandler = () => {
        this.isResizing = false;
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  }

}
