import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-company-driver-earning-modal',
  templateUrl: './company-driver-earning-modal.component.html',
  styleUrls: ['./company-driver-earning-modal.component.css']
})
export class CompanyDriverEarningModalComponent implements OnInit {

  @Input() tripsDetailModalObj: any
  @Output() onCloseModal = new EventEmitter<any>();
  driverTrips;

  constructor() { }

  ngOnInit() {
    if (this.tripsDetailModalObj) {
      for (let driver of this.tripsDetailModalObj.drivers) {
        this.driverTrips = driver;
      }
    }
  }

  closeDetailModal(){
    this.onCloseModal.emit();
  }

}
