import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import * as moment from 'moment-timezone';
import { ReportService } from '../reports-service/report.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { getBrokersList, isDecimal } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-driver-score',
  templateUrl: './driver-score.component.html',
  styleUrls: ['./driver-score.component.css']
})
export class DriverScoreComponent implements OnInit, OnDestroy {
  user;
  timeZone;
  todayDate;
  scoreDetails;
  driversScore;
  isDropOffTime = false;
  viewScorewModalObj = false;
  score;
  search;
  selectedBroker = '';

  constructor(
    private reportService: ReportService,
    private csvService: CsvService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
    this.getDriversScore();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = [moment.tz(this.timeZone).startOf('day').toISOString(), moment.tz(this.timeZone).endOf('day').toISOString()];
        }
      });
  }

  getDriversScore() {
    this.scoreDetails = {};
    this.driversScore = [];

    const payload = {
      startDate: this.todayDate[0],
      endDate: this.todayDate[1],
      isDropOffTime: this.isDropOffTime,
      companyType: this.selectedBroker
    }
    this.reportService
      .getDriversScore(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          this.scoreDetails = data[0];
          this.driversScore = this.scoreDetails.drivers;
        }
      });
  }

  onDateChange(event) {
    this.todayDate = [event[0].toISOString(), event[1].endOf('day').toISOString()];
    this.getDriversScore();
  }

  downloadReport(dScores, type?) {
    const data = {
      date: moment(this.todayDate[0]).format("MM/DD/YYYY") + " - " + moment(this.todayDate[1]).format("MM/DD/YYYY"),
      scores: type === 'all' ? dScores : [dScores]
    };
    this.csvService.generateScoreReport(data);
  }

  isDecimal(number) {
    return isDecimal(number);
  }

  toggleDropOffTime(){
    this.isDropOffTime = !this.isDropOffTime;
    this.getDriversScore();
  }

  viewScore(score){
    console.log(score, "data score");
    this.score = score;
    this.viewScorewModalObj = true;
  }

  close(){
    this.viewScorewModalObj = false;
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  onSelectBroker(){
    this.getDriversScore();
  }

  ngOnDestroy(): void {
  }
}
