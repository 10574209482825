import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment';
import { DriversService } from 'src/app/component/drivers/drivers-service/drivers-service';
import { AssigTripsService } from '../../assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-trip-generate-modal',
  templateUrl: './trip-generate-modal.component.html',
  styleUrls: ['./trip-generate-modal.component.css']
})
export class TripGenerateModalComponent implements OnInit {
  @Input() tripData;
  @Input() componentType;
  @Output() onCloseModal = new EventEmitter<string>();
  @Output() upTrip = new EventEmitter<string>();
  @Output() downTrip = new EventEmitter<string>();
  @Output() onFormSubmit = new EventEmitter<string>();
  seats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  outStandingForm: FormGroup
  isSubmitted = false;
  user;
  timeZone;
  todayDate;
  drivers = [];
  corporateUserList;
  constructor(
    private fb: FormBuilder,
    private driverService: DriversService,
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.outStandingForm = this.fb.group({
      upAddress: ["", Validators.required],
      upLatitude: [0, Validators.required],
      upLongitude: [0, Validators.required],
      downAddress: ["", Validators.required],
      downLatitude: [0, Validators.required],
      downLongitude: [0, Validators.required],
      seats: ["3", Validators.required],
      driver: [""],
      cooperate: [""],
      routes: ["2", Validators.required],
    });
    this.getUser();
    this.getDriverList();
    this.getCorporateList();
  }

    getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();

          this.outStandingForm.patchValue({
            upAddress: this.user.completeAddress,
            upLlatitude: this.user.latitude,
            upLongitude: this.user.longitude,
            downAddress: this.user.completeAddress,
            downLlatitude: this.user.latitude,
            downLongitude: this.user.longitude
          })
        }
      });
  }

  getDriverList() {
    this.drivers = [''];
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getCorporateList() {

    this.assignTripService
    .getCorporateUserList()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.corporateUserList = data;
      }
    });
  }


  closegenerateTripModal(){
    this.onCloseModal.emit();
  }

  setUpAddress(place) {
    this.outStandingForm.patchValue({
      upAddress: place.formatted_address,
      upLatitude: place.geometry.location.lat(),
      upLongitude: place.geometry.location.lng(),
    });
  }

  setDownAddress(place) {
    this.outStandingForm.patchValue({
      downAddress: place.formatted_address,
      downLatitude: place.geometry.location.lat(),
      downLongitude: place.geometry.location.lng(),
    });
  }


  onSubmit() {
    console.log(this.outStandingForm)
    this.isSubmitted = true;
    if (this.outStandingForm.invalid) {
      return
    }
    this.onFormSubmit.emit(this.outStandingForm.value)
  }

  onOriginDriverChange() {
    const selected = this.drivers.find(data => data._id === this.outStandingForm.value.driver);
    if (selected) {
      this.outStandingForm.patchValue({
        upAddress: selected.address,
        upLatitude: selected.takeMeHome[1],
        upLongitude: selected.takeMeHome[0],
      })

    }
  }

  get form() {
    return this.outStandingForm.controls;
  }
  ngOnDestroy(): void {
  }
}
