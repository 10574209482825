import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { gantt } from 'dhtmlx-gantt';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'underscore';

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.css']
})
export class GanttChartComponent implements OnInit, OnDestroy {
  @ViewChild('ganttContainer', { static: true }) ganttContainer: ElementRef;
  user;
  timeZone;
  todayDate;
  driverList = [];
  filterdataList = [];
  isCollapse = false;
  selectedDriver;
  tripsMapModalObj;

  constructor(
    private sharedDataService: SharedDataService,
    private driverService: DriversService,
    private assignTripService: AssigTripsService

  ) { }

  ngOnInit() {
    this.getUser();
  }

  initializeGantt() {
    gantt.config.columns = [
      { name: "text", label: "Clients", width: 350, tree: true },
      { name: "route", label: "Route", align: "center", width: "*" },
      { name: "scheduleTime", label: "Sche Time", align: "center", width: "*" },
      { name: "appointmentTime", label: "Appt Time", align: "center", width: "*" }
    ];
    gantt.config.start_date = new Date(moment(this.todayDate).format("YYYY-MM-DDTHH:mm")); // Set the start date to today
    gantt.config.end_date = new Date(moment(this.todayDate).endOf('day').format("YYYY-MM-DDTHH:mm")); // Set the end date to today
    // gantt.config.start_date = new Date("2023-06-15T00:00:00"); // Set the start date to today
    // gantt.config.end_date = new Date("2023-06-15T23:59:59"); // Set the end date to today

    // gantt.config.scale_unit = "hour";   // Set the scale unit to 'minute'
    // gantt.config.step = 1;               // Set the number of units per step to 30
    // gantt.config.date_scale = "%H";    // Set the date scale format to display hours and minutes
    // gantt.config.subscales = [
    //   { unit: "minute", step: 15, date: "%i" }    // 30-minute sub-scale
    // ];

    gantt.config.scale_unit = "day";     // Set the base scale unit to 'day'
    gantt.config.date_scale = "%Y-%m-%d"; // Set the date scale format
    gantt.config.subscales = [
      { unit: "hour", step: 1, date: "%H:%i" },         // Hourly sub-scale
      { unit: "minute", step: 15, date: "%i" }    // 30-minute sub-scale
    ];
    gantt.config.drag_move = false; // Disable drag and drop for moving tasks
    gantt.config.drag_resize = false; // Disable drag and drop for resizing tasks
    gantt.config.drag_progress = false; // Disable drag and drop for changing task progress
    gantt.config.show_links = false; // Disable displaying task links
    // gantt.config.open_tree_initially = true; // Set all tasks to be opened initially

    gantt.init(this.ganttContainer.nativeElement);

    gantt.templates.task_class = (start, end, task) => {
      if (task.parent !== 0) {
        return 'gantt_project';
      } else {
        return 'gantt_subtask';
      }
    };


    gantt.attachEvent("onTaskClick", (id, e)=>{
      let task = gantt.getTask(id);
      this.selectedDriver = task;
      console.log("Main task : ",e,task);

      if (task.$level === 0) {
          // Check if it's a main task (parent task)
          // Your custom method or logic here
        this.getDriverTrips(id);
      }

      // Continue with the default behavior or prevent it based on your needs
      return true;
  });

  }

  getDriverTrips(id) {
    this.tripsMapModalObj = this.filterdataList.filter(trip => trip.driver === id);
  }

  destroyGantt() {
    gantt.clearAll();
    const ganttContainer = this.ganttContainer.nativeElement;
    ganttContainer.innerHTML = '';
  }
  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getDriverList();
          this.initializeGantt();
        }
      });
  }

  getDriverList() {
    this.driverService.getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.driverList = data;
            this.driverList = _.sortBy(this.driverList , function(dr) {
              return dr.displayName;
          });
            this.getTrips();
          }
        },
      );
  }

  getTrips() {
    const object = {
      isShowAllTrips: false,
      status: 'manifest',
      date: this.todayDate,
      jobStatuses: ['pending', 'offered', 'accepted', 'onway', 'arrived', 'started', 'finished', 'cancelled']
    };
    this.assignTripService.getAssignManifestList(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.filterdataList = [];
        if (data) {
          this.filterdataList = data;
          this.setupChart();
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  setupChart() {
    const tasks = [];
// unassign trips
    tasks.push({ id: "01a", text: "Unassign Trips", route: 'Click',start_date: new Date(moment(this.todayDate).format("YYYY-MM-DDTHH:mm")),end_date:new Date(moment(this.todayDate).add(5, 'minutes').format("YYYY-MM-DDTHH:mm")) });

    this.driverList.forEach(driver => {
      tasks.push({ id: driver._id, text: driver.displayName, route: 'Click', start_date: new Date(moment(this.todayDate).format("YYYY-MM-DDTHH:mm")),end_date:new Date(moment(this.todayDate).add(5, 'minutes').format("YYYY-MM-DDTHH:mm")) });
    });

    this.filterdataList.forEach(trip => {
      const driverTask = tasks.find(task => task.id === trip.driver);

      // lets add atleast 5 minutes which start and end  time same
      let duration = moment.duration(moment(trip.appointmentTime).diff(moment(trip.scheduleTime)));
      let minutes = duration.asMinutes();
      const expectedTime = (minutes < 5 ? (moment(trip.scheduleTime).add(5, 'minutes')) : (moment(trip.appointmentTime)));

      if (driverTask) {
        driverTask.end_date = new Date(expectedTime.format("YYYY-MM-DDTHH:mm"));
        // push in to tasks list
        tasks.push({
          id: trip._id,
          text: trip.priorityClient.displayName,
          start_date: new Date(moment(trip.scheduleTime).format("YYYY-MM-DDTHH:mm")),
          end_date: new Date(expectedTime.format("YYYY-MM-DDTHH:mm")),
          scheduleTime: moment(trip.scheduleTime).format("HH:mm"),
          appointmentTime: moment(trip.appointmentTime).format("HH:mm"),
          parent: trip.driver
        });

      } else {
        tasks[0].end_date = new Date(expectedTime.format("YYYY-MM-DDTHH:mm"));
        // push in to tasks list
        tasks.push({
          id: trip._id,
          text: trip.priorityClient.displayName,
          start_date: new Date(moment(trip.scheduleTime).format("YYYY-MM-DDTHH:mm")),
          end_date: new Date(expectedTime.format("YYYY-MM-DDTHH:mm")),
          scheduleTime: moment(trip.scheduleTime).format("HH:mm"),
          appointmentTime: moment(trip.appointmentTime).format("HH:mm"),
          parent: "01a"
        });

      }

      // // push in to tasks list
      // tasks.push({
      //   id: trip._id,
      //   text: trip.priorityClient.displayName,
      //   start_date: new Date(moment(trip.scheduleTime).format("YYYY-MM-DDTHH:mm")),
      //   end_date: new Date(expectedTime.format("YYYY-MM-DDTHH:mm")),
      //   scheduleTime: moment(trip.scheduleTime).format("HH:mm"),
      //   appointmentTime: moment(trip.appointmentTime).format("HH:mm")
      // });
    });

    gantt.parse({ data: tasks });
  }

  performSearch(searchValue: string) {
    if (searchValue.trim() !== '') {
      const tasks = gantt.getTaskBy((task) => {
        return task.text.includes(searchValue);
      });
      gantt.clearAll();
      gantt.parse({ data: tasks });
    } else {
      gantt.clearAll();
      this.setupChart();
    }
  }

  onDateChange(event) {
    this.destroyGantt();
    this.initializeGantt();
    this.todayDate = event.toISOString();
    this.getTrips();
  }

 async expendDrivers() {
    this.isCollapse = !this.isCollapse;
    await gantt.eachTask((task) => {
      if (task.$level === 0) {
        if (this.isCollapse) {
          gantt.open(task.id);
        } else {
          gantt.close(task.id);
        }
      }
  });

  }

  onSelectDriver(trips) {
    // selectedDriver
  }
  onClose(evt) {

  }

  ngOnDestroy(): void {
  }
}
