import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceAreasService } from '../service-areas-service/service-areas.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-service-areas-add',
  templateUrl: './service-areas-add.component.html',
  styleUrls: ['./service-areas-add.component.css']
})
// export class ServiceAreasAddComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
export class ServiceAreasAddComponent implements OnInit {
  serviceAreaForm: FormGroup;
  zipCodeForm: FormGroup
  submitted = false;
  zipCodeSubmitted = false;
  userId = null;
  serviceAreaId;
  zipCodeArray = [];

  colors = [
    { name: "Blue", color: "#007bff" },
    { name: "Green", color: "#28a745" },
    { name: "Red", color: "#dc3545" },
    { name: "Yellow", color: "#ffc107" },
    { name: "Purple", color: "#6f42c1" },
    { name: "Orange", color: "#fd7e14" },
    { name: "Cyan", color: "#17a2b8" },
    { name: "Pink", color: "#e83e8c" },
    { name: "Teal", color: "#20c997" },
    { name: "Indigo", color: "#6610f2" },
    { name: "Brown", color: "#856404" },
    { name: "Gray", color: "#6c757d" },
    { name: "Violet", color: "#8a2be2" },
    { name: "Turquoise", color: "#40e0d0" },
    { name: "Maroon", color: "#800000" },
    { name: "Gold", color: "#ffd700" },
    { name: "Lime", color: "#00ff00" },
    { name: "Slate", color: "#708090" },
    { name: "Fuchsia", color: "#ff00ff" },
    { name: "Olive", color: "#808000" }
  ];


  constructor(
    // public authService: AuthService,
    private serviceArea: ServiceAreasService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.serviceAreaId = params["id"];
        if (this.serviceAreaId) {
          this.getServiceAreabyId();
        }
      });

    this.serviceAreaForm = this.fb.group({
      name: ["", Validators.required],
      status: ['active', Validators.required],
      color: ['', Validators.required],
      zipCodes: [[]]
    });

    this.zipCodeForm = this.fb.group({
      zipCode: ['', Validators.required]
    })

  }

  addZipCode() {
    // this.submitted = true;
    this.zipCodeSubmitted = true;
    if (this.zipCodeForm.invalid) {
      return;
    }
    for (let item of this.zipCodeArray) {
      this.serviceAreaForm.value.zipCodes.push(item);
    }
    this.zipCodeArray = [];
    this.zipCodeForm.reset();
    this.zipCodeSubmitted = false;
    // this.submitted = false;
  }

  removeZipCode(index) {
    const z1 = this.serviceAreaForm.value.zipCodes;
    z1.splice(index, 1);
    this.serviceAreaForm.patchValue({
      zipCodes: z1
    });
  }

  getServiceAreabyId() {
    this.serviceArea.getServiceAreasbyId(this.serviceAreaId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.serviceAreaForm.patchValue(data)
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
  }

  onInputChange(event: any) {
    const inputValue = event.target.value;
    if (inputValue) {
      const values = inputValue.split(',');
      this.zipCodeArray = values.map(value => value.trim());
    }
  }


  saveServiceArea() {
    this.submitted = true;
    // || this.serviceAreaForm.value.zipCodes.length === 0
    if (this.serviceAreaForm.invalid) {
      return;
    }

    let payload = this.serviceAreaForm.value;
      this.serviceArea.addServiceAreas(payload, this.serviceAreaId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          (data) => {
            if (data) {
              this.router.navigateByUrl("credentialing/service-areas");
            }
          },
          (err) => {
            sweetAlert("Error", err, "error", "Ok");
          }
        );
  }

  get form() {
    return this.serviceAreaForm.controls;
  }
  get formControl() {
    return this.zipCodeForm.controls;
  }


  ngOnDestroy(): void {
  }

}
