import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-trips-in-progress-modal',
  templateUrl: './trips-in-progress-modal.component.html',
  styleUrls: ['./trips-in-progress-modal.component.css']
})
export class TripsInProgressModalComponent implements OnInit {

  search: any;
  @Input() object;
  constructor() { }

  ngOnInit() {
  }

}
