import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';

declare var $;
@Component({
  selector: 'app-trip-detail-dialog',
  templateUrl: './trip-detail-dialog.component.html',
  styleUrls: ['./trip-detail-dialog.component.css']
})
export class TripDetailDialogComponent implements OnInit, OnChanges {

  @Input() object: any;
  constructor() {
  }

  ngOnInit() {
    // if (this.object) {
    //   $('#detailModal').modal('show');
    // }
  }

  ngOnChanges(changes: SimpleChanges) {

    if(this.object) {
      $('#detailModal').modal('show');
    }
  }
}
