import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    const token = this.authService.getToken('token');

    // List of URLs where the Authorization header should be skipped
    const skipAuthorization = [
      '/api/get-server-key'
    ];

    // Check if the current URL is in the skip list
    const shouldSkip = skipAuthorization.some(url => req.url.includes(url));

    let tokenizedReq = req;

    if (!shouldSkip) {
      if (req.body instanceof FormData) {
        tokenizedReq = req.clone({
          setHeaders: {
            Authorization: `JWT ${token}`
          }
        });
      } else {
        tokenizedReq = req.clone({
          setHeaders: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
          }
        });
      }
    }

    return next.handle(tokenizedReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.responselogDetails(JSON.stringify(event.body));
          }
        },
        error => {
          this.logDetails('Request failed');
        }
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message =
          'HttpRequest >>>>>>>>\n\n\n'
          + req.method + ' ' + req.urlWithParams
          + ' in ' + elapsedTime + 'ms'
          + '\nbody: ' + JSON.stringify(req.body)
          + '\n\n\n<<<<<<<< HttpRequest';
        this.logDetails(message);
      })
    );
  }

  private logDetails(msg: string) {
    // console.log(msg); // Adjust this to your logging mechanism
  }

  private responselogDetails(msg: string) {
    // console.log('Response:', msg); // Adjust this to your logging mechanism
  }
}
