import { Component, OnDestroy, OnInit } from '@angular/core';
import { TripPurposesService } from '../trip-purposes.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trip-purposes-list',
  templateUrl: './trip-purposes-list.component.html',
  styleUrls: ['./trip-purposes-list.component.css']
})
export class TripPurposesListComponent implements OnInit, OnDestroy {
  selectedStatus: any = "active";
  tripPurposesList = [];
  search;

  constructor( private tripPurposesService: TripPurposesService) { }

  ngOnInit() {
    this.getTripPurpose();
  }


  changeStatus() {
    this.getTripPurpose();
  }


  getTripPurpose() {
    this.tripPurposesService.getTripPurposesList(this.selectedStatus)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.tripPurposesList = data;
    })
  }

  remove(id){

    sweetAlert('Alert', 'Are you sure you want to remove?', 'warning', 'Yes', 'No')
    .then((result: any) => {
      if (result.value) {
        this.tripPurposesService.removeTripPurpose(id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
        })
      }
    })

  }

  ngOnDestroy(): void {
  }
}
