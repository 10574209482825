import { Injectable } from "@angular/core";
import { PDFDocument } from "pdf-lib";
import {
  getImageAsEmbedNew,
  removeNonAllowedCharactersFromPDF,
  urlToArrayBuffer,
} from "../../utils/utils.common";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class AerotransService {
  async getAerotranscareReport(payload, data, user, loader,date) {

    payload.sort((a, b) => {
      const dateA = moment(a.job.created).tz(user.timeZone, false);
      const dateB = moment(b.job.created).tz(user.timeZone, false);
      return dateA.diff(dateB); // Use diff() to get the difference in milliseconds
    });
    let totalTripAmount = 0 


    totalTripAmount += payload.reduce((sum, currentItem) => {
      if(currentItem.job && currentItem.job.jobFareDetail && currentItem.job.jobFareDetail.jobFee){

        return sum + parseFloat(currentItem.job.jobFareDetail.jobFee);
      }
    }, 0);

    const pdfArray = [];
    let newTrips = [];
    for (const [index, item] of payload.entries()) {
      newTrips.push(item);
      if (index === 14) {
        const response = await fetch("assets/documents/aerotranscare.pdf");
        let pdfBuffer = await response.arrayBuffer();

        pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, data, user,true ,((payload.length-1) - index)!== 0 && (payload.length - index)> 0 , totalTripAmount) ;
        pdfArray.push(pdfBuffer);
        newTrips = [];
      } else if (index > 14 && (index - 14) % 24 === 0) {
        const response = await fetch(
          "assets/documents/aerotranscare-blank.pdf"
        );
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, data, user ,false , ((payload.length-1) - index)!== 0 && (payload.length - index)> 0, totalTripAmount);
        pdfArray.push(pdfBuffer);
        newTrips = [];
      }
    }
    if (!pdfArray.length && newTrips.length) {
      const response = await fetch("assets/documents/aerotranscare.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, data, user,true ,false , totalTripAmount);
      pdfArray.push(pdfBuffer);
      newTrips = [];
    } else if (pdfArray.length && newTrips.length) {
      const response = await fetch("assets/documents/aerotranscare-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, data, user , false, false,totalTripAmount);
      pdfArray.push(pdfBuffer);
    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }

    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const fileName = `AeroTranscare-${moment(date[0]).format('MM/DD/YY') + '-' + moment(date[1]).format('MM/DD/YY')}-Report.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =fileName;
      loader.isLoading = false;
      loader.isModal = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async fillPDF(
    pdfBuffer: ArrayBuffer,
    payload,
    data,
    user,
    showHeader,
    isShowTotal,
    isTotal

  ): Promise<ArrayBuffer> {
 
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;

    if (showHeader) {
      const jpgImageBytes: any = await urlToArrayBuffer(company.profileImageURL);
      const compnayLogo = await getImageAsEmbedNew(
        pdfDoc,
        company.profileImageURL,
        jpgImageBytes
      );

      if (compnayLogo) {
        form.getTextField("company_logo").setImage(compnayLogo || "");
        form.getTextField("company_name").setText(company.displayName || "");
        form
          .getTextField("company_name_footer")
          .setText(company.displayName || "");
        form.getTextField("company_address").setText(company.address || "");
        form.getTextField("company_phone").setText(company.contactNumber || "");
        form.getTextField("company_email").setText(company.email || "");

        form.getTextField("to_name").setText(data.billingCompany || "");
        form.getTextField("to_address").setText(data.billingAddress || "");
        form.getTextField("to_email").setText(data.billingCompanyEmail || "");
        form.getTextField("to_phone").setText(data.billingCompanyPhone || "");

        form.getTextField("invoice_no").setText(data.invoiceNo || "");
        form
          .getTextField("invoice_date")
          .setText(data.invoiceDate);
        form
          .getTextField("due_date")
          .setText(data.dueDate + " Days");
      }
    }
    form
    .getTextField("company_name_footer").setText(user.displayName || "");
    form.getTextField("company_phone").setText(user.contactNumber || "");
    form.getTextField("company_email").setText(user.email || "");


    payload.forEach((currentItem, index) => {
      form
        .getTextField("date" + index)
        .setText(
          moment(currentItem.job.created)
            .tz(user.timeZone, false)
            .format("MM/DD/YY")
        );
      form.getTextField("trip_id" + index).setText(currentItem.tripId);
      form
        .getTextField("name" + index)
        .setText(currentItem.priorityClient.displayName);
      form.getTextField("mot" + index).setText(currentItem.tripRequirement);
      if(currentItem.job && currentItem.job.jobFareDetail && currentItem.job.jobFareDetail.jobFee)
      form.getTextField("amount" + index).setText(`$${currentItem.job.jobFareDetail.jobFee}`);
    
    });

    form
      .getTextField("total_amount_label")
      .setText("Total Reimbursment Amount:");
    form.getTextField("total_amount").setText(`$${(isTotal.toFixed(2)).toString()}`);
    if(isShowTotal)
    await this.removeSpecificfromPDF(form,['total_amount_label', 'total_amount', 'abc']);

    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }

  removeSpecificfromPDF(form, fieldsToRemove) {
    // const fieldsToRemove = ["field1", "field2", "field3"];
    for (const fieldName of fieldsToRemove) {
      const field = form.getFields().find((x) => x.getName() === fieldName);
      if (field) {
        form.removeField(field);
      }
    }
  }
}
