import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BeachwoodReportService } from 'src/app/service/beachwood-service/beachwood-report.service';
import { getRandomString } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-bt-modal',
  templateUrl: './bt-modal.component.html',
  styleUrls: ['./bt-modal.component.css']
})
export class BtModalComponent implements OnInit {
  beachWoodForm : FormGroup
  dateRange = []

  totalMiles

  @Input() loader = {
    isLoading: false,
    isBeachwoodModal: false,
  };
  @Input() company
  @Input() date
  @Input() selectedTrips
  @Input() users
  @Input() selectedUsers
  @Output() closeModal =new EventEmitter <any>();
  constructor(
    private fb: FormBuilder,
    private beachwoodReportService: BeachwoodReportService
    ) { }

  ngOnInit() {
    this.beachWoodForm = this.fb.group({
      invoiceNo: [this.generateString()],
      baseRate: ['20'],
      mileageFee: ['5'],
      emptyMiles: ['0'],
      discount: ['0'],
      tax: ['0'],
      totalDue: ['0'],
      dueDate: ['30'],
      totalMiles : ['0']
    })

  }

  btReport() {
    this.loader.isLoading = true;
    this.getBeachwoodReport();
  }
  closeBTModal(value) {
    this.closeModal.emit(value)
  }
  calculate(){

    let baseRate = parseFloat(this.beachWoodForm.value.baseRate);
    let discount = parseFloat(this.beachWoodForm.value.discount);
    let tax = parseFloat(this.beachWoodForm.value.tax);
    let mileageFee = parseFloat(this.beachWoodForm.value.mileageFee);

    this.totalMiles = 0;
    let totalAll = 0;
    this.selectedTrips.forEach(trip => {
        const total = baseRate + (trip.milage * mileageFee)
        totalAll = totalAll + total;
        this.totalMiles = this.totalMiles + trip.milage;
    });
    let taxPercentageAmount = (tax / 100) * totalAll;
    // let discountPercentage = (discount / 100) * totalAll;
    totalAll = (totalAll + taxPercentageAmount) - discount;

    this.beachWoodForm.patchValue({
      baseRate: baseRate,
      discount : discount,
      totalMiles: this.totalMiles.toFixed(2),
      tax : tax,
      totalDue :totalAll.toFixed(2)
    });
  }
  generateString() {
    const randomString = getRandomString(7)
    const dateTime = moment(this.date[0]).format('MMDDYY') + '-' + moment(this.date[1]).format('MMDDYY')
   return `${randomString}-${dateTime}`;
  }

 async  getBeachwoodReport() {
    // this.isBeachwoodFile = true
    this.loader.isBeachwoodModal = true
    let users = {};
    let filterTripTimeWise = [];
    const selectedCorporate = this.users.find(corporate => corporate._id === this.selectedUsers);

    for (let trip of this.selectedTrips) {
      filterTripTimeWise.push(trip);
    }

    filterTripTimeWise = filterTripTimeWise.sort((a, b) => <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime));

    for (let trip of filterTripTimeWise) {
      if (users[trip.priorityClient.displayName]) {
        const tempuser = users[trip.priorityClient.displayName];
        users[trip.priorityClient.displayName] = {
          trip,
          miles: tempuser.miles + parseFloat(trip.milage ? trip.milage : 0),
          days: tempuser.days + 1,
        };
      } else {
        users[trip.priorityClient.displayName] = {
          trip,
          miles: parseFloat(trip.milage ? trip.milage : 0),
          days: 1,
        };
      }
    }
    await this.beachwoodReportService.beachwoodReport(this.company, selectedCorporate, users, this.dateRange, this.beachWoodForm.value, this.loader)
    this.beachWoodForm.reset()

  }
}
