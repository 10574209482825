import { LatLngLiteral } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trips-map-dashboard-modal',
  templateUrl: './trips-map-dashboard-modal.component.html',
  styleUrls: ['./trips-map-dashboard-modal.component.css']
})

export class TripsMapDashboardModalComponent implements OnInit {
  @Input() trips;
  @Input() driver;
  @Output() onCloseModal = new EventEmitter<any>();

  latitude = 35.125801;
  longitude = -117.9859038;
  centerLatitude = this.latitude;
  centerLongitude = this.longitude;
  zoom = 18;
  geoCoder;
  address;
  searchedLocation;

  constructor() { }

  ngOnInit() {
    // console.log('Trips :', this.driver);
    // this.geoCoder = new google.maps.Geocoder;
  }


  mapReady(map) {
    // map.addListener("dragend", () => {
    //   // console.log(this.centerLatitude, this.centerLongitude)
    //   this.getAddress(this.centerLatitude, this.centerLongitude);
    // });
  }

  onSelectAddress(place) {
    if (place) {
      this.searchedLocation = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      }
    }
    // place.formatted_address
  }


  centerChanged(coords: LatLngLiteral) {
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
  }

  setLocation() {
    console.log('ff');
    // this.onCloseModal.emit({
    //   reference: this.reference,
    //   address: this.address,
    //   lat: this.centerLatitude,
    //   lng: this.centerLongitude
    // });
  }


  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results) {
          this.address = results[0].formatted_address;
        } else {
          sweetAlert('Alert', 'No results found', 'warning', 'Ok')
        }
      } else {
        sweetAlert('Alert', `Geocoder failed due to: ${status}`, 'warning', 'Ok')
      }

    });
  }

  closeModal() {
    this.onCloseModal.emit();
  }
}
