import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-biller-note-modal',
  templateUrl: './biller-note-modal.component.html',
  styleUrls: ['./biller-note-modal.component.css']
})
export class BillerNoteModalComponent implements OnInit, OnDestroy {
  @Input() trip;
  @Output() billerNoteAdded = new EventEmitter<any>();
  reasonSubmitted:boolean = false;
  
  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  addBillerNote() {
    this.reasonSubmitted = true;

    if(this.trip.billerNote == ''){
      return
    }
    
    this.trip;
    this.assignTripService
      .addBillerNote(this.trip)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.billerNoteAdded.emit(data); 
        }
      });
  }

  closeModal() {
    this.billerNoteAdded.emit(null)
  }

  ngOnDestroy(): void {
  }
}
