import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trip-actions-dropdown',
  templateUrl: './trip-actions-dropdown.component.html',
  styleUrls: ['./trip-actions-dropdown.component.css']
})
export class TripActionsDropdownComponent implements OnInit, OnChanges {
  environment = environment;
  @Input() assign;
  @Input() status;

  @Output() recalculateFare = new EventEmitter<any>();
  @Output() calculateFare = new EventEmitter<any>();
  @Output() markReady = new EventEmitter<any>();
  @Output() markVip = new EventEmitter<any>();
  @Output() notAnswer = new EventEmitter<any>();
  @Output() badPhoneNumber = new EventEmitter<any>();
  @Output() dialysis = new EventEmitter<any>();

  @Output() markConfirmed = new EventEmitter<any>();
  @Output() markVoicemail = new EventEmitter<any>();
  @Output() clone = new EventEmitter<any>();
  @Output() sendForAuth = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() verifiedCancelled = new EventEmitter<any>();
  @Output() finishedCancelled = new EventEmitter<any>();
  @Output() sendToMarketplace = new EventEmitter<any>();
  @Output() addAdditionalNote = new EventEmitter<any>();
  @Output() addBillerNote = new EventEmitter<any>();
  @Output() tripNotes = new EventEmitter<any>();
  @Output() cancelInprogress = new EventEmitter<any>();
  @Output() markCompleted = new EventEmitter<any>();
  @Output() reroute = new EventEmitter<any>();
  @Output() markDvRequired = new EventEmitter<any>();
  @Output() isPaid = new EventEmitter<any>();
  @Output() addSpecialRates = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() assignTripAction = new EventEmitter<any>();
  @Output() tripFullDetail = new EventEmitter<any>();
  @Output() tripDetail = new EventEmitter<any>();
  @Output() markPaid = new EventEmitter<any>();
  @Output() dropTrip = new EventEmitter<any>();
  @Output() markRead = new EventEmitter<any>();
  @Output() memberVrText = new EventEmitter<any>();
  @Output() confirmationText = new EventEmitter<any>();
  @Output() associateImage = new EventEmitter<any>();
  @Output() partialEdit = new EventEmitter<any>();
  @Output() markMethadone = new EventEmitter<any>();
  @Output() addServiceArea = new EventEmitter<any>();
  @Output() tripLog = new EventEmitter<any>();

  // @Output() reassignTrip = new EventEmitter<any>();
  // @Output() edit = new EventEmitter<any>();
  // @Output() edit = new EventEmitter<any>();
  isClose = false;

  constructor() { }

  ngOnInit() {
    console.log('onssave', this.assign);
    console.log('boolean variable onit', this.isClose);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('boolean variable onachnage', this.isClose);
  }

  isStringExist(substrings, str) {
    if (substrings.some(v => str.toLowerCase().includes(v))) {
      return true;
    }
    return false;
  }

}
