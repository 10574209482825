import { downloadFile } from 'src/app/utils/pdf-downloader';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, from, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from '../../../utils/utils.common';
import { getBrokerImage } from '../../../utils/utils.common';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { data } from 'jquery';
import { AdminkpisService } from '../service/adminkpis.service';
import { urls } from 'src/app/utils/url-utils';
import { ReportService } from '../../reports/reports-service/report.service';
import { CompaniesService } from '../../companies/companies.service';

@Component({
  selector: 'app-admin-earning-report',
  templateUrl: './admin-earning-report.component.html',
  styleUrls: ['./admin-earning-report.component.css']
})
export class AdminEarningReportComponent implements OnInit {
  reportDetails = [];
  startDate = null;
  endDate = null;
  selectedDate = null;
  comapnyDetails = [];
  tripsDetailModalObj;
  mailCheck = false;
  selectedDriver = null;
  companies;
  status = "active";
 
  constructor(private companiesService: CompaniesService, private adminKpisService: AdminkpisService) { }

  ngOnInit() {
    this.getCompaniesList();
    this.getReportDetails();
  }

  getReportDetails(type?) {
    let data;
    if (type === 'reset') {
       data = {
        // _id: this.user._id,
        startDate:  null,
        endDate:  null,
      };
    } else {
       data = {
        // _id: this.user._id,
        startDate: this.selectedDate ? this.selectedDate[0].startOf('day').toISOString() : null,
        endDate: this.selectedDate ? this.selectedDate[1].endOf('day').toISOString() : null,
      };
    }
    this.adminKpisService
      .getComapnyReports(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.reportDetails = data;
          if (this.reportDetails.length > 0) {
            this.comapnyDetails = data[0].companies;
            // this.selectedDate = [new Date(this.reportDetails[0].dates.startDate) , new Date(this.reportDetails[0].dates.endDate)];
            // this.startDate = this.reportDetails[0].dates.startDate;
            // this.endDate = this.reportDetails[0].dates.endDate;
          } else {
              this.comapnyDetails = null;
              this.startDate = this.selectedDate? this.selectedDate[0].toISOString(): '';
              this.endDate = this.selectedDate? this.selectedDate[1].endOf('day').toISOString(): '';
            }
        }
      })
  }

  downloadCompanyEarningReport() {
    // this.selectedDriver = null;
    // this.mailCheck = false;
    // console.log(companyId, mailCheck);
    // const data = {
    //   mailCheck,
    //   companyId,
    //   startDate: this.startDate,
    //   endDate: this.endDate

    // };
    this.adminKpisService
      .getCompanyPdfReport()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      });
  }

  downloaPaymentReport() {
    const payload = {
      startDate: this.selectedDate ? this.selectedDate[0].startOf('day').toISOString() : null,
      endDate: this.selectedDate ? this.selectedDate[1].endOf('day').toISOString() : null,
    };
    this.adminKpisService
      .getCompaniesPdfReport(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      });
  }

  setDriverTripsDetail(driver){
    this.tripsDetailModalObj = driver
  }


  getCompaniesList() {
    this.companiesService.getCompaniesList(this.status)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.companiesService = data;
        this.companies = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
      }
    })
  }

  getCompanyById(companyId) {
    if (this.companies.hasOwnProperty(companyId)) {
      return this.companies[companyId];
    }
  }

  
ngOnDestroy(): void {
}

}
