import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { getBrokersList } from '../../.././utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-dirt-road',
  templateUrl: './dirt-road.component.html',
  styleUrls: ['./dirt-road.component.css']
})
export class DirtRoadComponent implements OnInit, OnDestroy {
  user;
  timeZone;
  trips: any;
  filteredTrips = [];
  brokerList = getBrokersList;
  search: any;

  isMarketPlace = false;
  isMedical = false;
  isCorporate = false;

  // todayDate;
  searchDate;
  selectedBroker = '';

  notesModalObj;
  showSlideFilter = false;
  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.searchDate = moment().tz(this.timeZone).startOf('day').toISOString();
          this.getTrips();
        }
      });
  }

  getTrips() {
    const queryString = {
      isShowAllTrips: 'false',
      scheduleTime: this.searchDate,
      // scheduleTime: '2023-01-12T05:00:00.000Z',
      skip: "0",
      status: 'dirt',
    }

    this.assignTripService
      .getDirtRoadTrips({ params: queryString })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data: any) => {
        this.trips = data;
        this.filteredTrips = data;
      });
  }

  getImage(lat, lng, color) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:http://maps.google.com/mapfiles/ms/icons/${color === 'red' ? 'red' : 'green'}.png%7C${lat},${lng}&size=500x500&maptype=satellite&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`
  }

  onDateChange(evt) {
    this.searchDate = evt.tz(this.timeZone).toISOString();;
    this.getTrips();
  }

  setTripFilter(tripType) {
    if (tripType === 'marketplace') {
      this.isMarketPlace = !this.isMarketPlace;
    } else if (tripType === 'medical') {
      this.isMedical = !this.isMedical;
    } else if (tripType === 'corporate') {
      this.isCorporate = !this.isCorporate;
    }

    this.filteredTrips = this.trips.filter(trip => {
      let checks = [];
      let v = true;

      if (this.isMarketPlace) {
        trip.jobStatus === 'marketplaced' ? checks.push(true) : checks.push(false);
      }

      if (this.isMedical) {
        trip.isMedical ? checks.push(true) : checks.push(false);
      }

      if (this.isCorporate) {
        trip.jobType === 'cooperate' ? checks.push(true) : checks.push(false)
      }

      checks.forEach(c => {
        if (!c) {
          v = false;
          return;
        }
      });

      return v;
    });

    
  }

  viewNotes(assign) {
    this.notesModalObj = assign;
  }
  
  showFilter(){
    this.showSlideFilter = !this.showSlideFilter
  }

  closeFilter(){
    this.showSlideFilter = false;
  }


  ngOnDestroy(): void {
  }
}
