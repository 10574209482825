import { Injectable } from '@angular/core';
// import * as FileSaver from 'file-saver';
// import * as ExcelJS from 'exceljs';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  setCombinedFirstRowWithFontSize(date: string, data: any[], filename: string): void {


    // const workbook = new ExcelJS.Workbook();
    // const worksheet = workbook.addWorksheet('Sheet 1');

    // // Combine all columns in the first row
    // worksheet.mergeCells('A1:Q1');
    // const firstRow = worksheet.getRow(1);

    // // Set the date in front of the number 22, change font size to 22, and center it
    // firstRow.getCell(1).value = '24'; // Number 22
    // firstRow.getCell(2).value = date; // Your desired date
    // firstRow.getCell(2).alignment = { horizontal: 'center' };

    // // Set font size to 22 for the date
    // firstRow.getCell(2).font = { size: 24, bold: true }; // Change font size as needed



    // // Define an array of predefined background colors for data rows
    // const rowColors = ["F6D8D8", "FFEAD7", "E8E2DA", "FFEA9F", "C5FF6C", "99FFEB", "99E5FF", "B1C5FF", "E4AEFF", "EFD3EF", "FF99FF", "FFD9E0", "E9D7D8",]; // Example colors
    // const textToColorMap = new Map<string, string>();

    // for (let i = 0; i < data.length; i++) {
    //   const textInSecondColumn = data[i][0]; // Assuming the second column is at index 1

    //   // Check if the text exists in the Map, and if not, assign a background color
    //   if (!textToColorMap.has(textInSecondColumn)) {
    //   // Use a color from the predefined array based on the current index, but reset after every 10th index
    //   const colorIndex = i % rowColors.length; // Reset color picking every 10th index
    //   const color = rowColors[colorIndex];
    //   textToColorMap.set(textInSecondColumn, color);
    //   }

    //   const rowColor = textToColorMap.get(textInSecondColumn);

    //   // Add the row data to the worksheet
    //   worksheet.addRow(data[i]);

    //   // Set the background color for each cell in the added row
    //   const addedRow = worksheet.getRow(i + 2); // i + 3 to skip the header row and the first row
    //   addedRow.eachCell((cell) => {
    //     cell.fill = {
    //       type: 'pattern',
    //       pattern: 'solid',
    //       fgColor: { argb: rowColor },
    //     };
    //   });
    // }


    // const secondRow = worksheet.getRow(2);
    // // Set a blue background color for the header row
    // secondRow.eachCell((cell) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '4E81BD' }, // Blue color code
    //   };
    //   cell.font= { color: { argb: 'FFFFFF' } };
    // });

    // // Create a blob from the workbook and save it
    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   this.saveExcelFile(buffer, filename);
    // });
  }

  // private saveExcelFile(buffer: any, fileName: string): void {
  //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   FileSaver.saveAs(blob, fileName);
  // }
}
