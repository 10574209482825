import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesService } from '../companies.service';
import { sweetAlert } from 'src/app/utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { states } from 'src/app/utils/utils.common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css']
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
  states = states;
  companyForm: FormGroup;
  companyId;
  timeZone = 'America/Los_Angeles';
  selectedFile = null;
  profileImageUrl = null;
  isSubmitted = false;
  selectedItems;
  companies = [];
  status = "active";
  dropdownList = [];

  options: any[] = [
    { id: 'assign-completed', label: 'Assign-Completed' },
    { id: 'trip-logs', label: 'Trip-logs' },
    { id: 'verified', label: 'Verified' },
  ];

  // dropdownSettings: IDropdownSettings = {
  //   singleSelection: false, // Set to true if you want single selection
  //   idField: 'id',
  //   textField: 'label',
  // };

  dropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'displayName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private companyService: CompaniesService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(params => {
        if (params && params['id']) {
          this.companyId = params['id'];
          this.getCompanyInformation();
        }
      });

    this.companyForm = this.fb.group({
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      extension: [''],
      reviewURL: [''],
      address: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      faxNumber: [''],

      focalPerson: this.fb.group({
        displayName: ['', Validators.required],
        contactNumber: ['', Validators.required]
      }),

      timeZone: [this.timeZone, Validators.required],
      // template: ['', Validators.required],
      platform: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      state: ['', Validators.required],
      stateCode: ['', Validators.required],
      timeinAt: [''],
      note: [''],

      calculateLocalMiles: [false],
      isRoute: [false],
      isShowDeadMiles: [false],
      isMedicalType: [false],
      isTime: [false],
      isCamera: [false],
      isAttendee: [false],
      isTripAssistance: [false],
      isNearBy: [false],
      isSms: [false],
      isAlcTrip: [false],
      forcedAcceptTrips: [false],
      iaal: [false],
      isCheckListOdometer: [false],
      isCorporateOdometer: [false],
      isListView: [false],
      skipSignature: [false],
      showCTCTrips: [false],
      isShowCMS1500: [false],
      applyCaTimeSheet: [false],
      takeTripEndImage: [false],
      hideContactNumber:[false],
      onboardStatus: ['trial', Validators.required],
      npi: [''],
      taxId: [''],
      companies: [''],
      trialExpireDate: [moment().toISOString(), Validators.required],
      preTrialExpireDate: [moment()],
      dateFormat: ['', Validators.required],
      timeFormat: ['', Validators.required],
      caTimeCaution: [0],
      isUber: [false]
    });

    this.companyForm.controls['isTime'].valueChanges.subscribe(value => {
      console.log(value);
      if (value) {
        this.companyForm.get('timeinAt').setValidators([Validators.required]);
      } else {
        this.companyForm.get("timeinAt").clearValidators();
      }
      this.companyForm.get('timeinAt').updateValueAndValidity();
    });

    this.companyForm.controls['onboardStatus'].valueChanges.subscribe(value => {
      if (value === 'trial') {
        this.companyForm.get('trialExpireDate').setValidators([Validators.required]);
      } else {
        this.companyForm.get("trialExpireDate").clearValidators();
      }
      this.companyForm.get('trialExpireDate').updateValueAndValidity();
    });

    this.companiesListData();
  }

  getCompanyInformation() {
    this.companyService
      .getCompanyById(this.companyId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.companyForm.patchValue({
            ...data,
            latitude: data.latitude,
            longitude: data.longitude,
            address: data.address,
            preTrialExpireDate: data.trialExpireDate === "null" || data.trialExpireDate === ""? moment().tz(this.timeZone, true): data.trialExpireDate,
            trialExpireDate: data.trialExpireDate === "" || data.trialExpireDate === ""? moment().tz(this.timeZone, true).toISOString: data.trialExpireDate,
            caTimeCaution: data.caTimeCaution ? data.caTimeCaution : 0
          })
          this.profileImageUrl = data.profileImageURL;

          if (data.companies) {
            const savedCompanies = data.companies;
            this.selectedItems = this.companies.filter(c => savedCompanies.includes(c._id));
          }
          this.timeZone = data.timeZone;

          this.companyForm.get('password').clearValidators();
          this.companyForm.get('password').updateValueAndValidity();
        }
      });
  }

  save() {
    console.log(this.companyForm)
    this.isSubmitted = true;
    if (this.companyForm.invalid) {
      return;
    }

    if (!this.companyId && !this.selectedFile) {
      return;
    }

    if (this.selectedItems && this.selectedItems.length){
      const companiesIds = this.selectedItems.map(c => c._id);
      this.companyForm.patchValue({
        companies: companiesIds
      })
    } else {
      this.companyForm.patchValue({
        companies: []
      });
    }

    let payload = {
      ...this.companyForm.value
    };


    if (this.companyId && !payload.password) {
      delete payload.password;
    }

    if (this.selectedFile) {
      payload['newProfilePicture'] = this.selectedFile
    }
    console.log(payload, "Company");
    this.companyService
      .savecompany(this.companyId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', `Company ${this.companyId ? 'Updated' : 'Added'} Successfully`, 'success', 'Ok')
          this.router.navigateByUrl('credentialing/companies');
        }
      });
  }

  getAddress(place) {
    this.companyForm.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  changeTimezone() {
    this.companyForm.patchValue({ timeZone: this.timeZone });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.selectedFile = result.file;
        this.profileImageUrl = result.src;
      });
  }

  companiesListData() {
    let queryParams = {
      status: this.status,
      isRemoveIAAL: true,
    };
    this.companyService.getMultiselecetCompanies(queryParams)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data && data.length) {
          this.companies = data;
          this.dropdownList = this.companies.map(c => {
            return {_id: c._id, displayName: c.displayName}
          });
         }
      },);
  }

  onSelectCompnay(vehicle: any) {
  }

  onDeSelectCompnay(vehilce: any) {
  }

  onTrialDateChange(evt){
    this.companyForm.patchValue({
      trialExpireDate: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  get form() {
    return this.companyForm.controls;
  }

  ngOnDestroy(): void {
  }
}
