import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getBrokersList } from 'src/app/utils/utils.common';
import { PlannerService } from '../planner-service/planner-service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-broker-setting',
  templateUrl: './broker-setting.component.html',
  styleUrls: ['./broker-setting.component.css']
})
export class BrokerSettingComponent implements OnInit, OnDestroy {
  brokerSettingForm: FormGroup;

  brokers = getBrokersList;
  brokerId;
  passwordToggleFlag = true;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private brokerService: PlannerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.brokerId = params["id"];

      });
    this.getDetails();

    this.brokerSettingForm = this.fb.group({
      name: ['', Validators.required],
      orgId: [''],
      username: ['', Validators.required],
      email: [''],
      password: ['', Validators.required],

      mailer: this.fb.group({
        username: [''],
        password: [''],
        secureType: [''],
        host: [''],
        port: ['']
      }),

      uber: this.fb.group({
        clientId:[''],
        clientSecret:[''],
        scope:[''],
        driver:[''],
      })
    });

    this.brokerSettingForm.get('name').valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(val => {
        if (val === "first") {
          this.brokerSettingForm.get('orgId').setValidators([Validators.required]);
        } else {
          this.brokerSettingForm.get('orgId').clearValidators();
        }
        this.brokerSettingForm.get('orgId').updateValueAndValidity();

      });
      this.brokerSettingForm.get('name').valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(val => {
        if (val === "uber") {
           this.brokerSettingForm.get('uber.clientId').setValidators([Validators.required]);
            this.brokerSettingForm.get('uber.clientSecret').setValidators([Validators.required]);
            this.brokerSettingForm.get('uber.scope').setValidators([Validators.required]);
            this.brokerSettingForm.get('uber.driver').setValidators([Validators.required]);
        } else {
            this.brokerSettingForm.get('uber.clientId').clearValidators();
            this.brokerSettingForm.get('uber.clientSecret').clearValidators();
            this.brokerSettingForm.get('uber.scope').clearValidators();
            this.brokerSettingForm.get('uber.driver').clearValidators();
        }
          this.brokerSettingForm.get('uber.clientId').updateValueAndValidity();
          this.brokerSettingForm.get('uber.clientSecret').updateValueAndValidity();
          this.brokerSettingForm.get('uber.scope').updateValueAndValidity();
          this.brokerSettingForm.get('uber.driver').updateValueAndValidity();

      });



  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  getDetails() {
    if (this.brokerId) {
      this.brokerService
        .getBrokerSettingById(this.brokerId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.brokerSettingForm.patchValue(data);
          }
        });
    }
  }


  addBrokerSetting() {

    this.submitted = true;
    if (this.brokerSettingForm.invalid) {
      return
    }
    
      
    let payload = this.brokerSettingForm.value;
    console.log('payload',payload)

    if (this.brokerId) {
      payload["_id"] = this.brokerId
      this.brokerService.updataBrokerSetting(payload)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('/operations/broker-configuration');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
        });
    } else {

      this.brokerService.addBrokerSetting(payload)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('/operations/broker-configuration');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
        });
    }
    this.submitted = false;

  }

  get form() {
    return this.brokerSettingForm.controls;
  }

  uploadProfilePic(input) {
    console.log(input.files[0].name)
  }

  ngOnDestroy(): void {
  }
}
