import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AuthService } from '../../service/auth-service/auth.service';
@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  timeZone;
  dateFormat = '';

  constructor(
    private sharedDataService: SharedDataService
  ) {
    if (!this.timeZone) {
      this.sharedDataService
        .getUser()
        .subscribe(user => {
          if (user && user.timeZone) {
            this.timeZone = user.timeZone;
            if (user.hasOwnProperty('dateFormat') && user.hasOwnProperty('timeFormat')) {
              this.dateFormat = user.dateFormat + ' ' + user.timeFormat;   
            }
          }
        });
    }
  }
  transform(value: any, format: any): any {
    if (value) {
      if (format) {
        value = moment.utc(value).tz(this.timeZone).format(format);
      } else {
        value = moment.utc(value).tz(this.timeZone).format(this.dateFormat ? this.dateFormat : 'MM/DD/YYYY HHmm');
      }
    }
    return value;
  }

}
