import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { DriversService } from 'src/app/component/drivers/drivers-service/drivers-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-driver-shift-modal',
  templateUrl: './driver-shift-modal.component.html',
  styleUrls: ['./driver-shift-modal.component.css']
})
export class DriverShiftModalComponent implements OnInit, OnDestroy {
  createDriverShift: FormGroup;
  @Input() driverShiftModalObj
  @Output() onCloseModal = new EventEmitter<any>();
  @Output() updateTime = new EventEmitter<string>();
  isTimelineSubmitted = false;
  timeZone;
  shift;
  user;
  driverUserId = '';

  constructor(private driverService: DriversService, private fb: FormBuilder, private sharedDataService: SharedDataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.createDriverShift = this.fb.group({
      preCheckin: [''],
      checkin: [''],
      preCheckout: [''],
      checkout: ['']
    })
    if(this.driverShiftModalObj && this.driverShiftModalObj.shift){
    this.createDriverShift.patchValue({
      preCheckin : this.driverShiftModalObj.shift.checkin,
      checkin : this.driverShiftModalObj.shift.checkin,
      preCheckout : this.driverShiftModalObj.shift.checkout,
      checkout : this.driverShiftModalObj.shift.checkout
    });
  }
    this.getUser();
  }


  getUser() {
    this.sharedDataService.getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  onUpdate() {
    this.isTimelineSubmitted = true;

    if (this.createDriverShift.invalid) {
      return;
    }
    const payload = this.createDriverShift.value;
    delete this.createDriverShift.value.preCheckin;
    delete this.createDriverShift.value.preCheckout;
    this.driverService
      .saveDriver({... payload, driverId: this.driverShiftModalObj._id }, 'shift')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.isTimelineSubmitted = false;
          this.modalClose();
          this.updateTime.emit(data);
        }
      });

  }


  modalClose() {
    this.onCloseModal.emit(false);
  }

  onCheckInChange(evt) {
    this.createDriverShift.patchValue({
      checkin: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onCheckOutChange(evt) {
    this.createDriverShift.patchValue({
      checkout: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }
  get form() {
    return this.createDriverShift.controls;
  }

  ngOnDestroy(): void {
  }
}
