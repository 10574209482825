import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing } from '../../../utils/image-processing-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { sweetAlert } from '../../../utils/swal';
import * as moment from 'moment-timezone';
import { FleetService } from '../fleet-service/fleet.service';
import { create } from 'domain';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
@Component({
  selector: 'app-create-fleet',
  templateUrl: './create-fleet.component.html',
  styleUrls: ['./create-fleet.component.css']
})
export class CreateFleetComponent implements OnInit, OnDestroy {
  createFleetForm: FormGroup;
  btnName = 'Create';
  fleetId = null;
  carRegistrationImageUrl = null;
  carRegistrationSelectedFile = null;
  insuranceCardImageUrl = null;
  insuranceCardSelectedFile = null;
  stateInspectionCardImageUrl = null;
  stateInspectionCardSelectedFile = null;
  ownerImageUrl = null;
  ownerSelectedFile = null;
  submitted = false;
  imageModalUrl = null;
  user;
  fleet;
  expiryDate = moment().set({ 'year': 2036, 'month': 11, 'date': 31 }).toISOString();

  constructor(
    private fleetService: FleetService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.fleetId = params["id"];
      });

    this.createFleetForm = this.fb.group({
      driverCarNumber: ['', Validators.required],
      driverCarModel: ['', Validators.required],
      driverCarColor: ['', Validators.required],
      vin: ['', Validators.required],
      setiDecall: ['', Validators.required],
      status: ['', Validators.required],
      realOdometer: ['000000', Validators.required],
      lcp: ['000000', Validators.required],
      united: ['000000', Validators.required],
      logisticscare: ['000000', Validators.required],
      first: ['000000', Validators.required],
      driverCar: ['Standard', Validators.required],
      images: [''],
      carRegistrationExpiry: ['', Validators.required],
      insuranceCardExpiry: ['', Validators.required],
      stateInspectionCardExpiry: ['', Validators.required],
      FleetId: ['123'],
      carRegistrationExpiryDate: [''],
      insuranceCardExpiryDate: [''],
      stateInspectionCardExpiryDate: [''],
      ownerName: ['', Validators.required],
      ownerAddress: ['', Validators.required],
      ownerPhone: ['', Validators.required],
      ownerBusiness: ['', Validators.required],
      licenseNo: ['', Validators.required],
      insuranceCompany: ['', Validators.required],
      kindOfInsurance: ['', Validators.required],
      capacity: ['', Validators.required],
      imei: ['']

    });
    this.getUser();
    this.fillData();
  }

  getUser() {
    if (this.fleetId) return;
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.createFleetForm.patchValue({
            ownerName: data.displayName,
            ownerBusiness: data.focalPerson ? data.focalPerson.contactNumber : '',
            ownerPhone: data.contactNumber,
            ownerAddress: data.address,
            driverCarColor: 'Black',
            capacity: '4',
            licenseNo: 'N/A',
            insuranceCompany: 'N/A',
            kindOfInsurance: 'N/A',
            carRegistrationExpiryDate: this.expiryDate,
            carRegistrationExpiry: this.expiryDate,
            insuranceCardExpiryDate: this.expiryDate,
            insuranceCardExpiry: this.expiryDate,
            stateInspectionCardExpiryDate: this.expiryDate,
            stateInspectionCardExpiry: this.expiryDate
          });
        }
      });
  }

 async fillData() {

    if (this.fleetId) {

      this.fleetService
        .getFleetDataById(this.fleetId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.fleet = data;
            this.createFleetForm.patchValue({
              ...data,
              carRegistrationExpiryDate: data.carRegistrationExpiry,
              insuranceCardExpiryDate: data.insuranceCardExpiry,
              stateInspectionCardExpiryDate: data.stateInspectionCardExpiry,
            });
            this.carRegistrationImageUrl = data.driverCarRegistrationImage;
            this.insuranceCardImageUrl = data.driverInsuranceCardImage;
            this.stateInspectionCardImageUrl = data.driverStateInspectionImage;
            this.ownerImageUrl = data.ownerImage;
          }
        });
    } else {
      // load dummy images
      const selectedFile = await this.autoSelectImage();

      if (selectedFile) {
        this.carRegistrationSelectedFile = selectedFile.file;
        this.carRegistrationImageUrl = selectedFile.src;

        this.stateInspectionCardSelectedFile = selectedFile.file;
        this.stateInspectionCardImageUrl = selectedFile.src;

        this.insuranceCardSelectedFile = selectedFile.file;
        this.insuranceCardImageUrl = selectedFile.src;
      }


    }

  }

  getAccountStatus() {
    return this.authService.fleetAccountStatus;
  }

  selectedVehicle(vehicle: string): void {
    this.createFleetForm.patchValue({
      driverCar: vehicle
    });
  }

  processCarRegFile(ImageInput: any) {
    imageProcessing(ImageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {

        this.carRegistrationSelectedFile = result.file;
        this.carRegistrationImageUrl = result.src;
      });
  }

  processOwnerFile(ImageInput: any) {
    imageProcessing(ImageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {

        this.ownerSelectedFile = result.file;
        this.ownerImageUrl = result.src;
      });
  }

  processStateInspectionFile(ImageInput: any) {
    imageProcessing(ImageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {

        this.stateInspectionCardSelectedFile = result.file;
        this.stateInspectionCardImageUrl = result.src;
      });
  }

  processInsuranceFile(ImageInput: any) {
    imageProcessing(ImageInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {

        this.insuranceCardSelectedFile = result.file;
        this.insuranceCardImageUrl = result.src;
      });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  onFleetCreate() {
    this.submitted = true;
    if (this.createFleetForm.invalid) {
      return;
    }
    if(this.fleetId && this.createFleetForm.value.status === 'inactive' && this.fleet.driver){
      sweetAlert('Alert', `Fleet can not be marked inactive until you unassign the driver.`, 'warning', 'Ok')


      return
    }

    if ((this.carRegistrationSelectedFile == null && this.carRegistrationImageUrl == null) ||
      (this.insuranceCardSelectedFile == null && this.insuranceCardImageUrl == null) ||
      (this.stateInspectionCardSelectedFile == null && this.stateInspectionCardImageUrl == null)) {
      return;
    }

    const image = {
      driverCarRegistrationImage: this.carRegistrationSelectedFile,
      driverInsuranceCardImage: this.insuranceCardSelectedFile,
      driverStateInspectionImage: this.stateInspectionCardSelectedFile,
      ownerImage: this.ownerSelectedFile
    };

    this.createFleetForm.patchValue({
      images: image,
      FleetId: this.fleetId
    });
    delete this.createFleetForm.value.carRegistrationExpiryDate;
    delete this.createFleetForm.value.insuranceCardExpiryDate;
    delete this.createFleetForm.value.stateInspectionCardExpiryDate;
    if (!this.fleetId) {
      this.fleetService
        .addFleet(this.createFleetForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('credentialing/fleet');
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK')
            .then(() => {
              this.router.navigateByUrl('credentialing/fleet');
            });
        });
    } else {
      this.fleetService
        .addFleet(this.createFleetForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('credentialing/fleet');
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK')
            .then(() => {
              this.router.navigateByUrl('credentialing/fleet');
            });
        });
    }
  }


  fillDummyData() {
    this.createFleetForm.patchValue({
      driverCarNumber: 'Les-123',
      driverCarModel: 'Honda Civic',
      driverCarColor: 'Red',
      vin: 'Test',
      setiDecall: 'Test',
      status: 'active',
      realOdometer: '0',
      driverCar: 'SUV'
    });
  }

  clearData() {
    this.createFleetForm.reset();
  }

  oncarRegistrationExpiryChange(event) {
    if (event) {
      this.createFleetForm.patchValue({
        carRegistrationExpiry: moment(event).toISOString()
      });
    }
  }

  onInsuranceCardExpiryChange(event) {

    if (event) {
      this.createFleetForm.patchValue({
        insuranceCardExpiry: moment(event).toISOString()
      });
    }
  }

  // }
  onStateInspectionCardExpiryChange(event) {
    if (event) {
      this.createFleetForm.patchValue({
        stateInspectionCardExpiry: moment(event).toISOString()
      });
    }
  }

  getOwnerAddress(place) {
    this.createFleetForm.patchValue({
      ownerAddress: place.formatted_address
    });
  }

  get form() {
    return this.createFleetForm.controls;
  }


  setVehicle(vehicleType) {
    this.createFleetForm.patchValue({
      driverCar: vehicleType
    })
  }
  removeImage(docImage) {
    console.log(docImage)

    switch (docImage) {
      case 'carReg':
        this.carRegistrationImageUrl = "";
        break;

      case 'incReg':
        this.insuranceCardImageUrl = "";
        break;

      case 'stateReg':
        this.stateInspectionCardImageUrl = "";
        break;

    }

  }

  async autoSelectImage() {
    const path = location.origin + "/assets/images/placeholders/dummy-file.png";
    const splitArry = path.split("/");
    const fileName = splitArry[splitArry.length - 1].split(".")[0];
    try {
      const { file, src } = await this.fetchImageAndCreateFile(path, fileName);
      if (file && src)
        return { file, src };
    } catch (error) {
      console.error("Error:", error);
    }
    return null;
  }

  async fetchImageAndCreateFile(src, fileName) {
    try {
      const response = await fetch(src);
      const blob = await response.blob();

      // Create a File object
      const file = new File([blob], fileName, { type: response.headers.get("content-type") });

      // Return both the File object and the source URL
      return { file, src };
    } catch (error) {
      console.error("Error fetching image:", error);
      // You might want to handle the error in an appropriate way
      return null;
    }
  }

  ngOnDestroy(): void {
  }
}
