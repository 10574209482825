import { Injectable } from "@angular/core";
import * as moment from "moment";
import { PDFDocument } from "pdf-lib";
import {
  urlToArrayBuffer,
  getImageAsEmbedNew,
  removeNonAllowedCharactersFromPDF,
} from "src/app/utils/utils.common";

@Injectable({
  providedIn: "root",
})
export class DailyTripLogService {
  constructor() {}
  async dailyDriverLog(data, user, loader) {
    const pdfArray = [];
    let newTrips = [];
    const tripEntries = [...data.entries()];
    // Sorting the entries based on scheduleTime
    tripEntries.sort((a, b) => a.scheduleTime - b.scheduleTime)

    for (const [index, item] of tripEntries) {
      newTrips.push(item);
      if (index === 5) {
        const response = await fetch(
          "assets/documents/daily-driver-report.pdf"
        );
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, user);
        pdfArray.push(pdfBuffer);
        newTrips = [];
      } else if (index > 5 && (index - 5) % 6 === 0) {
        const response = await fetch(
          "assets/documents/daily-driver-report-blank.pdf"
        );
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, user);
        pdfArray.push(pdfBuffer);
        newTrips = [];
      }
    }
    if (!pdfArray.length && newTrips.length) {
      const response = await fetch("assets/documents/daily-driver-report.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, user);
      pdfArray.push(pdfBuffer);
      newTrips = [];
    } else if (pdfArray.length && newTrips.length) {
      const response = await fetch(
        "assets/documents/daily-driver-report-blank.pdf"
      );
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newTrips, user);
      pdfArray.push(pdfBuffer);
    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${
        data[0].driver.displayName + "-" + moment().format("YYYY-MM-DD")
      }-Log.pdf`;
      loader.isLoading = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async fillPDF(pdfBuffer: ArrayBuffer, data, user): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;

    // Get Fleet Info on base of id
    form.getTextField("company_name").setText(company.displayName);
    form.getTextField("company_name2").setText(company.displayName);
    form
      .getTextField("confirmation")
      .setText(
        `By my signature I hereby attest to the information collected on this page and certify this is true to the best of my knowledge. I understand ${company.displayName} will verify the accuracy of this information.`
      );

    for (const [index, item] of data.entries()) {
      if (item) {
        form
          .getTextField("date" + index)
          .setText(
            moment(item.scheduleTime).tz(company.timeZone).format("MM/DD/YY")
          );
        form
          .getTextField("member_name" + index)
          .setText(item.priorityClient.displayName);
        form
          .getTextField("medicaid" + index)
          .setText(item.priorityClient.clientId);
        form.getTextField("pu_address" + index).setText(item.jobOriginAddress);
        form
          .getTextField("pu_time" + index)
          .setText(
            moment(item.job.trackInfoJob.jobArrived.time).tz(company.timeZone).format("HH:mm")
          );
        form
          .getTextField("do_address" + index)
          .setText(item.jobDestinationAddress);
        form
          .getTextField("do_time" + index)
          .setText(
            moment(item.job.trackInfoJob.jobFinished.time).tz(company.timeZone).format("HH:mm")
        );

        if (item.milage) {
          form
            .getTextField("miles_" + index)
            .setText(item.milage + "");
        }

        form.getTextField("identity_verified" + index).setText("Yes");
        if (item.job.signatureImage) {
          const member = await urlToArrayBuffer(item.job.signatureImage);
          const memberSignature = await getImageAsEmbedNew(
            pdfDoc,
            item.job.signatureImage,
            member
          );
          if (memberSignature)
            await form
              .getTextField("member_signature" + index)
              .setImage(memberSignature);
        }
      }
    }
    form.getTextField("driver_name").setText(data[0].driver.displayName);
    if (data[0].driver.driverSignatureImage) {
      const jpgImageBytes: any = await urlToArrayBuffer(
        data[0].driver.driverSignatureImage
      );
      const driverSignature = await getImageAsEmbedNew(
        pdfDoc,
        data[0].driver.driverSignatureImage,
        jpgImageBytes
      );
      if (driverSignature)
        form.getTextField("driver_signature").setImage(driverSignature || "");
    }
    if (data[0].driver.vin)
      form.getTextField("vehicle_id").setText(data[0].driver.vin);
    if (company.email) form.getTextField("email").setText(company.email);
    if (company.contactNumber)
      form.getTextField("phone").setText(company.contactNumber);

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }
}
