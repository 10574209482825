import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../utils/url-utils';
import { errorHandl, setFormDataV2 } from 'src/app/utils/network-utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient,) { }


  getCompaniesList(status): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + "?accountStatus=" + status)
  }

  getMultiselecetCompanies(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append('accountStatus', endPoint.status);
    params = params.append('isRemoveIAAL', endPoint.isRemoveIAAL);
    return this.http.get<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST , {params});
  }


  savecompany(id, companyData): Observable<any> {
    console.log('Saving company', companyData)
    const formData = setFormDataV2(companyData);

    if (id) {
      return this.http.put<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + '/' + id, formData).pipe(
        catchError(errorHandl)
      );
    }
    else {
      return this.http.post<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST, formData).pipe(
        catchError(errorHandl)
      );
    }
  }


  getCompanyById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_COMPANIES_LIST + `/${id}`)
  }

  getCompanyToken(Id,type?) {
    if (type) {
      const params = new HttpParams({ fromObject: { roleType: type } });
      return this.http.get(urls.BASE_URL + urls.GET_COMPANY_TOKEN + '/' + Id, { params });
    } else {
      return this.http.get(urls.BASE_URL + urls.GET_COMPANY_TOKEN + '/' + Id);
    }
  }


}


