import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from 'src/app/utils/url-utils';
import { errorHandl, setFormData } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class QuarterService {

  constructor(private http: HttpClient,) { }

  getQuarterList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_QUARTER_LIST)
  }

  saveQuarter(quarterData: any): Observable<any> {
    const imageFiles = quarterData.images;
    const quarterId = quarterData.quarterId;
    delete quarterData.images;
    const formData = setFormData({ newProfilePicture: imageFiles['newProfilePicture'] }, quarterData, 'quarterpanel');
    if (quarterId) {
      console.log('check ID' , quarterId)
      return this.http.put<any>(urls.BASE_URL + urls.GET_QUARTER_LIST + '/' + quarterId, formData).pipe(
          catchError(errorHandl)
      );
  } else {
      return this.http.post<any>(urls.BASE_URL + urls.GET_QUARTER_LIST, formData)
          .pipe(
              catchError(errorHandl)
          );
  }
    return this.http.post<any>(urls.BASE_URL + urls.GET_QUARTER_LIST + '/', formData);
  }


  getQuarterProfile(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_QUARTER_LIST + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }




}
