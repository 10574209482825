import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageReloadService {

  constructor() { }

  startReloading(intervalInMinutes: number) {
    const intervalInMilliseconds = intervalInMinutes * 60 * 1000;
    setInterval(() => {
      console.log("PageReloadService");
      window.location.reload();
    }, intervalInMilliseconds);
  }
}
