import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class StaffService {
  public KEY_TOKEN: 'token';
  constructor(private http: HttpClient) {
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  getStaffList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.STAFF_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  getStaffById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.STAFF_URL + '/' + id)
      .pipe(
        catchError(errorHandl)
      );
  }

  saveStaff(staffData): Observable<any> {
    const imageFiles = staffData.images;
    const staffId = staffData.staffId;
    delete staffData.images;
    delete staffData.staffId;
    const formData = setFormData(imageFiles, staffData, 'staff');
    if (staffId) {
      return this.http.put<any>(urls.BASE_URL + urls.STAFF_URL + '/' + staffId, formData).pipe(
        catchError(errorHandl)
      );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.STAFF_URL, formData).pipe(
        catchError(errorHandl)
      );
    }
  }


}
