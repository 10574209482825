import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedDataService } from './service/shared-service/shared-data.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PageReloadService } from './service/page-reload/page-reload.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;



  constructor(
    private sharedDataService: SharedDataService,
    private reloadService: PageReloadService
  ) {
  }

  ngOnInit() {
    this.reloadService.startReloading(12 * 60); // Reload every 24 mins
    this.sharedDataService
    .getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(user => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnDestroy(): void {
  }

}




