import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
import { getImageAsEmbedNew, removeNonAllowedCharactersFromPDF, urlToArrayBuffer } from '../../../utils/utils.common';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }


  async getDriverGrivenceReport(payload, user) {

    const response = await fetch('assets/documents/driver-grievance-report.pdf');
    let pdfBuffer = await response.arrayBuffer();

    pdfBuffer = await this.fillPDF(pdfBuffer, payload , user);

    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'driver-grievance-report.pdf'
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }


  async fillPDF(pdfBuffer: ArrayBuffer, payload , user): Promise<ArrayBuffer> {
    console.log(payload)
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const profileLogo: any = await urlToArrayBuffer(user.profileImageURL);
    const companyLogo = await getImageAsEmbedNew(pdfDoc, user.profileImageURL, profileLogo);
    //Reporting Details
    form.getTextField('company_name').setText(payload.createdBy.toString());
   if(companyLogo)
    form.getTextField('company_logo').setImage(companyLogo);
    form.getTextField('dos').setText(moment(payload.appointmentTime).format('MM/DD/YY HH:mm') || ''.toString());
    form.getTextField('reporting_time').setText('Reporting time');
    form.getTextField('complaint_id').setText("Client Id");
    form.getTextField('reported_driver').setText(payload.driver.displayName.toString());
    // //temp address on incident_address
    form.getTextField('incident_address').setText(payload.jobOriginAddress.toString());
    // //Member
    form.getTextField('member_name').setText(payload.priorityClient.displayName.toString());
    form.getTextField('member_cellphone').setText(payload.priorityClient.cellPhone.toString());
    form.getTextField('member_contactNo').setText(payload.priorityClient.contactNumber.toString());
    form.getTextField('client_id').setText(payload.memberId.toString());
    form.getTextField('member_weight').setText("Weight Missing" || '0');
    form.getTextField('member_gender').setText('Male' || '');
    form.getTextField('insurance_id').setText(payload.priorityClient.clientId.toString());
    form.getTextField('member_dob').setText(payload.dob.toString());
    form.getTextField('member_escort').setText(payload.noOfEscorts ? payload.noOfEscorts.toString():'');
    // //Trip Infomation
    form.getTextField('pu_address').setText(payload.jobOriginAddress.toString() );
    form.getTextField('do_address').setText(payload.jobDestinationAddress.toString());
    form.getTextField('trip_status').setText(payload.status.toString());
    form.getTextField('trip_notes').setText(payload.companyNote.slice(0,50));
    form.getTextField('trip_miles').setText(payload.milage.toString());
    form.getTextField('pu_time').setText(moment(payload.scheduleTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('do_time').setText(moment(payload.appointmentTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('act_pu_time').setText(moment(payload.scheduleTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('act_do_time').setText(moment(payload.appointmentTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('cancel_reason').setText(payload.changeReason.toString());
    form.getTextField('broker_name').setText(payload.payor.toString());
    form.getTextField('trip_id').setText(payload.tripId.toString());
    form.getTextField('los').setText(payload.tripRequirement.toString());
    // // Vin missing
    form.getTextField('vin').setText("HardCoded" || '0');

    // //timestamps
    form.getTextField('accepted_time').setText("HardCode" || "0");
    form.getTextField('onscene_time').setText(moment(payload.job.trackInfoJob.jobArrived.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('started_time').setText(moment(payload.job.trackInfoJob.jobStarted.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('ended_time').setText(moment(payload.job.trackInfoJob.jobFinished.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('accepted_coordinate').setText("Coordinates Missing");
    form.getTextField('onscene_coordinate').setText(`${payload.job.trackInfoJob.jobArrived.coords[0]} - ${payload.job.trackInfoJob.jobArrived.coords[1]}` );
    form.getTextField('started_coordinate').setText(`${payload.job.trackInfoJob.jobStarted.coords[0]} - ${payload.job.trackInfoJob.jobStarted.coords[1]}` );
    form.getTextField('ended_coordinate').setText(`${payload.job.trackInfoJob.jobFinished.coords[0]} - ${payload.job.trackInfoJob.jobFinished.coords[1]}` );

    form.getTextField('driver_grievance').setText(payload.driverNote.toString());
    form.getTextField('driver_signature').setText(payload.signatureImage || "N/A");


      // form.getTextField('provider_company').setText('test');
// remove non ASCII
await removeNonAllowedCharactersFromPDF(form);
      const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
      return modifiedBuffer;


  }




  getReportsWithNgrx(data): Observable<any> {
    const userId = data._id;
    delete data._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_REPORTS_URL + `${userId}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDriverPdfReport(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('check', data.mailCheck);
    params = params.append('endDate', data.endDate);
    params = params.append('startDate', data.startDate);

    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_DRIVER_PDF_REPORT_URL + `${data.driverId}?`, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getPaymentPdfReport(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('endDate', data.endDate);
    params = params.append('startDate', data.startDate);

    return this.http.get<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_PAYMENT_PDF_REPORT_URL + `${data.id}?`, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getDriversScore(payload): Observable<any> {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_KPI_SCORE, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  async getCoTripReport(payload, company, date, loader) {
    const pdfArray = [];
    let newtemp = []
    const arrayOfkeys = Object.keys(payload);
    for (const [index, key] of arrayOfkeys.entries()) {
      newtemp.push(payload[key])
      if (index === 2) {
        const response = await fetch("assets/documents/co-trip-report.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillCoTripReport(pdfBuffer, newtemp, company, date);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      } else if (index > 2 && (index - 2) % 2 === 0) {
        const response = await fetch("assets/documents/co-trip-report-blank.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillCoTripReport(pdfBuffer, newtemp, company, date);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      }
    }

    if (!pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/co-trip-report.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillCoTripReport(pdfBuffer, newtemp, company, date);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    } else if (pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/co-trip-report-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillCoTripReport(pdfBuffer, newtemp, company, date);
      pdfArray.push(pdfBuffer);

    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]);
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const currentDate = moment().format("MM-DD-YYYY");
      const fileName = `co_trip_report_${currentDate}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      loader.isLoading = false;
      window.URL.revokeObjectURL(url);
    }
  }

  async fillCoTripReport(pdfBuffer: ArrayBuffer, trips, user, date): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();

    const company = user.company ? user.company : user;
    const lastIndex = (trips.length) - 1
    const lastTrip = trips[lastIndex]
    const weekendDate = moment(lastTrip.scheduleTime).tz(company.timeZone).endOf('week').format("MM/DD/YYYY")

    if (company) {
      form.getTextField("company_name").setText(company.displayName);
      form.getTextField("npi").setText(company.npi);

    }
    if (date) {
      form.getTextField("date").setText(moment(date).tz(company.timeZone).format('MM/DD/YYYY'));
    }
    for (const [index, trip] of trips.entries()) {
      if (trip.tripRequirement){
        if(trip.tripRequirement ==='Standard'){
          form.getRadioGroup("Group4").select('Choice6');
        } else if (trip.tripRequirement === 'Premium'){
          form.getRadioGroup("Group4").select('Choice7');
        }
        else if (trip.tripRequirement === 'SUV') {
          form.getRadioGroup("Group4").select('Choice7');
        }
        else if (trip.tripRequirement === 'WAV') {
          form.getRadioGroup("Group4").select('Choice3');
        }
        else if (trip.tripRequirement === 'Stretcher') {
          form.getRadioGroup("Group4").select('Choice4');
        }
      }


      if (trip) {
        if (trip.priorityClient) {
          form.getTextField("member_name").setText(trip.priorityClient.displayName);
          form.getTextField("medicaid_id").setText(trip.priorityClient.clientId);
        }
        if (trip.driver) {
          form.getTextField("driver_name").setText(trip.driver.displayName);
          form.getTextField("Vin").setText(trip.driver.vin);
          form.getTextField("facility_representative_name").setText(trip.driver.displayName);
          form.getTextField("facility_representative_title").setText('Driver');
         
        }

        form.getTextField("pu_mon").setText(moment(trip.scheduleTime).tz(company.timeZone).format('mm'));
        form.getTextField("pu_day").setText(moment(trip.scheduleTime).tz(company.timeZone).format('DD'));
        form.getTextField("pu_year").setText(moment(trip.scheduleTime).tz(company.timeZone).format('yyyy'));
        form.getTextField("pu_hour").setText(moment(trip.scheduleTime).tz(company.timeZone).format('HH'));
        form.getTextField("pu_min").setText(moment(trip.scheduleTime).tz(company.timeZone).format('mm'));
        const dayTime = moment(trip.scheduleTime).tz(company.timeZone).format('A');
        console.log("AM PM CHECK", dayTime);
        if (dayTime === 'AM') {
          form.getRadioGroup("Group1").select('Choice1');
        } else {
          form.getRadioGroup("Group1").select('Choice2');
        }
        form.getRadioGroup("Group2").select('Choice1');
        form.getRadioGroup("Group3").select('Choice1');
        if(trips.length > 0){
          form.getRadioGroup("Group5").select('Choice2');
        }
        else{
          form.getRadioGroup("Group5").select('Choic1');
        }

        const brokenPuAddress = this.parseAddress(trip.jobOriginAddress)
        form.getTextField("pu_address" + index).setText(brokenPuAddress.street);
        form.getTextField("city" + index).setText(brokenPuAddress.city);
        form.getTextField("state" + index).setText(brokenPuAddress.state);
        form.getTextField("zip" + index).setText(brokenPuAddress.zip);

        const brokenDoAddress = this.parseAddress(trip.jobDestinationAddress)
        form.getTextField("do_address" + index).setText(brokenDoAddress.street);
        form.getTextField("city" + index).setText(brokenDoAddress.city);
        form.getTextField("state" + index).setText(brokenDoAddress.state);
        form.getTextField("zip" + index).setText(brokenDoAddress.zip);
        if(trip.scheduleTime){
          const dayTime = moment(trip.scheduleTime).tz(company.timeZone).format('A');
          if (dayTime === 'AM') {
            form.getRadioGroup("pu_group"+index).select('Choice1');
          } else {
            form.getRadioGroup("pu_group"+index).select('Choice2');
          }
        }
        if (trip.appointmentTime) {
          const dayTime = moment(trip.appointmentTime).tz(company.timeZone).format('A');
          if (dayTime === 'AM') {
            form.getRadioGroup("do_group" + index).select('Choice1');
          } else {
            form.getRadioGroup("do_group" + index).select('Choice2');
          }
        }
        form.getTextField("miles" + index).setText(trip.milage + '');

        if(trip.job){
          form.getTextField("pu_odometer" + index).setText(trip.job.odometerStart + '');
          form.getTextField("do_odometer" + index).setText(trip.job.odometerStop + '');
        
        if(trip.driver){
          const profileLogo: any = await urlToArrayBuffer(trip.driver.driverSignatureImage);
          const driverSignature = await getImageAsEmbedNew(pdfDoc, trip.driver.driverSignatureImage, profileLogo);
          form.getTextField("driver_signature").setImage(driverSignature);
        }
        if (trip.job){
          const profileLogo: any = await urlToArrayBuffer(trip.job.signatureImage);
          const memberSignature = await getImageAsEmbedNew(pdfDoc, trip.job.signatureImage, profileLogo);
          form.getTextField("member_signature").setImage(memberSignature);
        }

        }

      
      }

    }

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }

  parseAddress(address) {
  const regex = /(.*),\s*([^,]*),\s*([A-Z]{2})\s*(\d{5})?,/;
  const match = address.match(regex);

  if (!match)
    return {
      street: "",
      city: "",
      state: "",
      zip: "",
    };

  return {
    street: match[1] ? match[1].trim() : "",
    city: match[2] ? match[2].trim() : "",
    state: match[3] ? match[3].trim() : "",
    zip: match[4] ? match[4].trim() : "",
  };
}
}
