import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Form } from "@angular/forms";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { data } from "jquery";
import { AuthService } from "../../../service/auth-service/auth.service";
import {
  imageProcessing,
  ImageSnippet,
} from "../../../utils/image-processing-utils";
import { sweetAlert } from "../../../utils/swal";
import { StaffService } from "../staff-service/staff.service";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
@Component({
  selector: "app-staffs-create",
  templateUrl: "./staffs-create.component.html",
  styleUrls: ["./staffs-create.component.css"],
})
export class StaffsCreateComponent implements OnInit {
  addNewStaff: FormGroup;
  submitted = false;
  btnName = "Create";
  selectedFile: ImageSnippet = null;
  profileImageURL = null;
  userId = null;
  staffUserId = "";
  passwordToggleFlag = true;

  constructor(
    public authService: AuthService,
    private staffService: StaffService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.staffUserId = params["id"];
    });

    this.addNewStaff = this.fb.group({
      displayName: ["", [Validators.required, Validators.minLength(3)]],
      contactNumber: ["", Validators.required],
      address: ["", Validators.required],
      roles: ["", Validators.required],
      accountStatus: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(16)]],
      latitude: ["", Validators.required],
      longitude: ["", Validators.required],
      staffId: [""],
      images: [""],
    });

    this.fillForm();
  }

  fillForm() {
    if (this.staffUserId) {
      this.addNewStaff.get('password').clearValidators();

      this.staffService.getStaffById(this.staffUserId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.addNewStaff.patchValue({ ...data, password: "" });
            this.profileImageURL = data.profileImageURL;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
          this.router.navigateByUrl("staffs");
        }
      );
    } else {
      this.addNewStaff.get('password').setValidators([Validators.required,Validators.minLength(6),Validators.maxLength(16)],);
    }
    this.addNewStaff.get('password').updateValueAndValidity();
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedFile = result.file;
      this.profileImageURL = result.src;
    });
  }

  getAddress(place) {
    this.addNewStaff.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  saveStaff() {
    this.submitted = true;
    if (this.addNewStaff.invalid) {
      return;
    } else if (this.selectedFile == null && this.profileImageURL == null) {
      return;
    } else {
      const image = {
        newProfilePicture: this.selectedFile,
      };

      if (this.staffUserId) {
        this.addNewStaff.patchValue({
          staffId: this.staffUserId,
          images: image,
        });

      } else {
        this.addNewStaff.patchValue({
          images: image,
        });
      }

      const payload = this.addNewStaff.value;

      if (this.staffUserId) {
        if (this.addNewStaff.value.password === '') {
          delete payload.password;
        }
      }


      this.staffService.saveStaff(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.router.navigateByUrl("credentialing/staff");
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "Ok");
        }
      );
    }
  }

  get form() {
    return this.addNewStaff.controls;
  }


ngOnDestroy(): void {
}
}
