import { Component, OnInit } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { PrimaryDiagnosisService } from '../primary-diagnosis-service/primary-diagnosis.service';

@Component({
  selector: 'app-primary-diagnosis-list',
  templateUrl: './primary-diagnosis-list.component.html',
  styleUrls: ['./primary-diagnosis-list.component.css']
})
export class PrimaryDiagnosisListComponent implements OnInit {

  search;
  primaryDiagnosis = [];
  status = "active";

  constructor(
    private primaryDiagnosisService: PrimaryDiagnosisService
  ) { }

  ngOnInit() {
    this.getPrimaryDiagnosis();
  }

  getPrimaryDiagnosis() {
    this.primaryDiagnosisService
      .getPrimaryDiagnosis()
      .subscribe(data => {
        if (data && data.length) {
          this.primaryDiagnosis = data;
        } else {
          this.primaryDiagnosis = [];
        }
      }, err => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }

  ngOnDestroy(): void {
  }
}
