import { Component, OnInit } from '@angular/core';
import { DriverGrievanceService } from '../driver-grievance.service';
import { data } from 'jquery';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';


@Component({
  selector: 'app-driver-grievance',
  templateUrl: './driver-grievance.component.html',
  styleUrls: ['./driver-grievance.component.css']
})
export class DriverGrievanceComponent implements OnInit {
  grievancelist = [];
  selectedType = 'grievance';
  search;
  user: any;
  itemLostCount = 0;
  grievanceCount = 0;
  grievanceId;any;
  loader = {
    isloading: false,
  };




  constructor(
    private sharedDataService: SharedDataService,
    private driverGrievance: DriverGrievanceService
  ) { }

  ngOnInit() {
    this.getUser();
    this.getDriverGrievance();
  }

  getUser() {
    this.sharedDataService.getUser().subscribe(data => {
      if (data) {
        this.user = data;
      }
    })
  }

  getDriverGrievance() {
    this.driverGrievance.getDriverGrievance().subscribe(data => {
      if (data) {
        this.grievancelist = data;

        this.grievancelist.forEach(item => {
          if (item.type == 'itemlost') {
            this.itemLostCount++
            
          }
          else if (item.type == 'grievance') {
            this.grievanceCount++
            
          }

        });

      }
    })
  }






  getGrivenceReport(id) {
      this.grievanceId = id;
     this.loader.isloading =true;
    this.driverGrievance.getDriverGrievanceById(id).subscribe(data => {
      if (data) {
       
        this.driverGrievance.getDriverGrivenceReport(data, this.user , this.loader)
      }
    })
  }

}
