import { P } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { urlToArrayBuffer, getImageAsEmbedNew, removeNonAllowedCharactersFromPDF } from 'src/app/utils/utils.common';

@Injectable({
  providedIn: 'root'
})
export class BeachwoodReportService {

  constructor() { }


  async beachwoodReport(user, corporate, users, dateRange, reportData, loader) {
    const pdfArray = [];
    let newUsers = [];
    const arrayOfkeys = Object.keys(users);
    for (const [index, key] of arrayOfkeys.entries()) {
      newUsers.push(users[key]);
      if (index === 19) {
        const response = await fetch('assets/documents/beachwood-report.pdf');
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillBTPDF(user, corporate, pdfBuffer, newUsers, dateRange, reportData);
        pdfArray.push(pdfBuffer);
        newUsers = [];
      } else if (index > 19 && (index - 19) % 27 === 0) {
        const response = await fetch('assets/documents/beachwood-report-blank.pdf');
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillBTPDF(user, null, pdfBuffer, newUsers, null, null);
        pdfArray.push(pdfBuffer);
        newUsers = [];
      }
    }

    if (!pdfArray.length && newUsers.length) {
      const response = await fetch('assets/documents/beachwood-report.pdf');
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillBTPDF(user, corporate, pdfBuffer, newUsers, dateRange, reportData);
      pdfArray.push(pdfBuffer);
      newUsers = [];
    } else if (pdfArray.length && newUsers.length) {
      const response = await fetch('assets/documents/beachwood-report-blank.pdf');
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillBTPDF(user, null, pdfBuffer, newUsers, null, null);
      pdfArray.push(pdfBuffer);
    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }

    // download
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const fileName = `beachwood_report_${moment(dateRange[0]).format('MM/DD/YY') + '-' + moment(dateRange[1]).format('MM/DD/YY')}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      loader.isLoading = false;
      loader.isBeachwoodModal = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }



  async btFacilityReport(user, trips, corporate, dateRange, reportData, bwfLoader , payor) {
    const pdfArray = [];
    let newTrips = []
    for (const [index, item] of trips.entries()) {
      newTrips.push(item);

      if (index === 18) { // Change 17 to 18 if you want to include the 18th entry on the first page
        const response = await fetch('assets/documents/bt-facility-report.pdf');
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(user, newTrips, corporate, dateRange, pdfBuffer, reportData ,payor);
        pdfArray.push(pdfBuffer);
        newTrips = [];
      } else if ( index > 18 &&(index - 18) % 25 === 0) {
        const response = await fetch('assets/documents/bt-facility-report-blank.pdf');
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(user, newTrips, null, null, pdfBuffer, null , null);
        pdfArray.push(pdfBuffer);
        newTrips = [];
      }
    }

    if (!pdfArray.length && newTrips.length) {
      const response = await fetch('assets/documents/bt-facility-report.pdf');
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(user, newTrips, corporate, dateRange, pdfBuffer, reportData, payor);
      pdfArray.push(pdfBuffer);
      newTrips = [];
    }
    else if (pdfArray.length && newTrips.length) {
      const response = await fetch('assets/documents/bt-facility-report-blank.pdf');
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(user, newTrips, null, null, pdfBuffer, null, null);
      pdfArray.push(pdfBuffer);
    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
      pdfDoc.addPage(page);
    }

    // download
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const fileName = `beachwood_facility_report_${moment(dateRange[0]).format('MM/DD/YY') + '-' + moment(dateRange[1]).format('MM/DD/YY')}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      bwfLoader.isLoading = false;
      bwfLoader.isbwfModal = false;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }



  async fillBTPDF(user, corporate, pdfBuffer: ArrayBuffer, payload, dateRange, reportData): Promise<ArrayBuffer> {

    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;
    if (corporate) {
      form.getTextField("school_title").setText(corporate.displayName);
      form.getTextField("to_school_name").setText(corporate.displayName);
      form.getTextField("to_address").setText(corporate.address);
      form.getTextField("to_phone").setText(corporate.contactNumber);
      form.getTextField("email").setText(corporate.email);

      form.getTextField("company_name").setText(company.displayName);
      form.getTextField("bt_company_name").setText(company.displayName);
      form.getTextField("from_address").setText(company.address);
      form.getTextField("due_date").setText(reportData.dueDate + " Days");
      form.getTextField("company_email").setText(company.email);


      if (dateRange) {
        form.getTextField('date').setText(moment(dateRange[0]).format('MM/DD/YY') + ' - ' + moment(dateRange[1]).format('MM/DD/YY'));
      }
      //   // Fetch JPEG image
      const jpgImageBytes: any = await urlToArrayBuffer(company.profileImageURL);
      const pngImage = await getImageAsEmbedNew(pdfDoc, company.profileImageURL, jpgImageBytes);
      if (pngImage)
        form.getTextField('bt_logo').setImage(pngImage);
    }

    if (reportData) {
      form.getTextField("invoice_number").setText(reportData.invoiceNo);
      form.getTextField("base_rate").setText("$" + reportData.baseRate);
      form.getTextField("mileage_fee").setText("$" + reportData.mileageFee);
      form.getTextField("empty_miles").setText("$" + reportData.emptyMiles);
      form.getTextField("total_discount").setText("$" + reportData.discount);
      form.getTextField("tax").setText(reportData.tax + "%");
      form.getTextField("total_due").setText("$" + reportData.totalDue);


    }


    // const temp = Object.keys(payload)
    // temp.sort();

    // let totalMiles = 0;
    for (const [i, individualUser] of payload.entries()) {
      // totalMiles = totalMiles + individualUser.miles ;

      const index = i + 1;
      form.getTextField("member_name" + index).setText(individualUser.trip.priorityClient.displayName);
      form.getTextField("days" + index).setText(individualUser.days.toString());
      form.getTextField("loa" + index).setText(individualUser.trip.tripRequirement);
      form.getTextField("miles" + index).setText(individualUser.miles.toFixed(2));
      if (individualUser.trip.destinationAddressAdditional && individualUser.trip.destinationAddressAdditional.place)
        form.getTextField("school_name" + index).setText(individualUser.trip.destinationAddressAdditional.place);
    }
    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save();
    return modifiedBuffer;
  }

  async fillPDF(user, trip, corporate, dateRange, pdfBuffer: ArrayBuffer, reportData , payor): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;
    //to fields
    if (corporate) {
      form.getTextField('company_title').setText(corporate.displayName);
      form.getTextField('to_name').setText(corporate.displayName);
      form.getTextField('to_address').setText(corporate.address);
      form.getTextField('to_phone').setText(corporate.contactNumber);
      form.getTextField('to_email').setText(corporate.email);
      form.getTextField('from_name').setText(company.displayName);
      form.getTextField('from_address').setText(company.address);
      form.getTextField('from_phone').setText(company.contactNumber);
      form.getTextField('from_email').setText(company.email);
      if(payor){
        // let payorName = payor.toUpperCase();
        form.getTextField('payor_name').setText(payor.toUpperCase());
      }

    }



    //   Base-Rate fields
    if (reportData) {
      form.getTextField("invoice_number").setText(reportData.invoiceNo);
      form.getTextField('base_rate').setText("$" + reportData.baseRate);
      form.getTextField('ambi_mileage2').setText(reportData.ambiMileage.toString());

      form.getTextField('ambi_mileage1').setText("$" + reportData.ambiPerMile);

      //formula & calculation here

      // let discountPercentage = (discount / 100) * totalAll;


      form.getTextField('ambi_mileage').setText("$" + reportData.totalAmbi);

      if(reportData.totalWc){
        form.getTextField('wc_mileage').setText("$" + reportData.totalWc);
      }
      form.getTextField('wc_mileage1').setText("$" + reportData.wcPerMile);
      form.getTextField('wc_mileage2').setText(reportData.wcMileage.toString());

      form.getTextField('empty_miles').setText("$" + reportData.emptyMiles);
      // form.getTextField('empty_miles1').setText(reportData.baseRate);
      form.getTextField('cancellations').setText("$" + reportData.cancellations1);
      form.getTextField('cancellations1').setText("$" + reportData.cancellations2);
      form.getTextField('escort_service').setText("$" + reportData.escortService);
      form.getTextField('volume_discount').setText("$" + reportData.discount);
      form.getTextField('tax').setText(reportData.tax.toString());
      form.getTextField('total_due').setText("$" + reportData.totalDue);
      form.getTextField("due_date").setText(reportData.dueDate + " Days");
    }

    //Date Fields
    if (dateRange) {
      form.getTextField('date').setText(moment(dateRange[0]).format('MM/DD/YY') + ' - ' + moment(dateRange[1]).format('MM/DD/YY'));
    }

    //table fields
    trip.forEach((item, index) => {
      form.getTextField("date_time" + index).setText(moment(item.scheduleTime).format('MM/DD/YY HH:mm'));
      form.getTextField("loa" + index).setText(item.tripRequirement);
      form.getTextField("member_name" + index).setText(item.priorityClient.displayName);
      form.getTextField("pu_address" + index).setText(item.jobOriginAddress);
      form.getTextField("do_address" + index).setText(item.jobDestinationAddress);
      form.getTextField("miles" + index).setText(item.milage.toString());
      if(reportData)
      form.getTextField("base" + index).setText((item.tripRequirement === "WAV" ? reportData.wcBaseFare  : reportData.ambiBaseFare).toString());
      if (item.status === "finished") {
        form.getTextField("status" + index).setText("Rode");
      }
      else {
        form.getTextField("status" + index).setText("RiderCancel");
      }
    });

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save();
    return modifiedBuffer;
  }

}

