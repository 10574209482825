import { Component, OnDestroy, OnInit } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';
import { CorporateAddonServiceService } from '../corporate-addon-service.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-corporate-addon-list',
  templateUrl: './corporate-addon-list.component.html',
  styleUrls: ['./corporate-addon-list.component.css']
})
export class CorporateAddonListComponent implements OnInit, OnDestroy {
  search;
  corporateAddonList = [];
  corporateUsers;
  corporateAddonId;
  selectedStatus: any = "active";
  constructor(
    private corporateAddonService: CorporateAddonServiceService,
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
    this.getCorporateAddon();
    this.getCorporateUserList();
  }


  getCorporateAddon() {
    this.corporateAddonService.getCorporateAddonList()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      this.corporateAddonList = data;
      console.log('corporateAddonList', this.corporateAddonList)

    })
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.corporateUsers = data;
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj) ,{});
        }
      });
  }

  getCorporateUser(corporateId) {
    return this.corporateUsers[corporateId];
  }

  removeCrporateAddon(id){
     
    sweetAlert('Alert', 'Are you sure you want to remove?', 'warning', 'Yes', 'No')
    .then((result: any) => {
      if (result.value) {
        this.corporateAddonService.removeCorporate(id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          data;
          
        })      
      }
    })
   
  }


  onChangeStatus(){
    console.log(this.selectedStatus)
  }

  ngOnDestroy(): void {
  }
}
