import { Injectable } from "@angular/core";
import { PDFDocument } from "pdf-lib";
import { getImageAsEmbedNew, removeNonAllowedCharactersFromPDF, urlToArrayBuffer } from "../../utils/utils.common";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class VmlService {
  constructor() {}

  accountStatus = {
    active: "Active",
    inactive: "InActive",
  };

  async fillPDF(pdfBuffer: ArrayBuffer,data,company,vehicle): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const index = 0;
    
    if(company){
      const jpgImageBytes: any = await urlToArrayBuffer(company.profileImageURL); 
    const pngImage = await getImageAsEmbedNew(pdfDoc,company.profileImageURL,jpgImageBytes);
    form.getTextField("company_name").setText(company.displayName);
    form.getTextField("company_logo").setImage(pngImage);
    }
    if(vehicle){
      form.getTextField("make_model").setText((vehicle.driverCarModel));
      form.getTextField("vin").setText(vehicle.vin.toString());
      form.getTextField("seti_decal").setText(vehicle.setiDecall.toString());
      if (data && Array.isArray(data) && data.length) {
        const slicedData = data.slice(0, data.length);
  
        const totalCost = slicedData.reduce((sum, item) => sum + (item.cost || 0), 0);
  
        form.getTextField("total_cost").setText(totalCost.toString());
      } else {
        form.getTextField("total_cost").setText(data[0].cost.toString());
      }
      
    if (data.length > 1) {
      const firstDate = moment(data[0].created).format("YYYY-MM-DD");
      const lastDate = moment(data[data.length-1].created).format("YYYY-MM-DD");


      const displayText = `${firstDate} - ${lastDate}`;
      form.getTextField("log_duration").setText(displayText);
    } else {
      form.getTextField("log_duration").setText(moment(data[0].created).format("MM/DD/YY") || "");
    }

    }
    
    // Download in Bulk
    if (data && data.length > 1) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item) {
          form.getTextField("dos" + i).setText(moment(item.created).format("MM/DD/YY") || "");
          form.getTextField("driver" + i).setText((item.driver.displayName));
          form.getTextField("cost" + i).setText(item.cost.toString());
          if (
            data[i].maintenanceservice &&
            Array.isArray(data[i].maintenanceservice)
          ) {
            let descriptions = "";
            for (let service of data[i].maintenanceservice) {
              descriptions += service.name + ",";
            }
            form.getTextField("description" + i).setText(descriptions);
          }

          if (item && typeof item.odometer !== 'undefined' && typeof item.nextOdometer !== 'undefined') {
            form.getTextField("odo_atservice" + i).setText(item.odometer.toString());
            form.getTextField("odo_nextservice" + i).setText(item.nextOdometer.toString());
          }
          form.getTextField("mechanic_workshop" + i).setText(item.mechanicWorkshop);
          form.getTextField("notes" + i).setText((item.notes));
        }
      }
    }
    // Single Download
    else {
      form.getTextField("dos" + index).setText(moment(data[0].created).format("MM/DD/YY") || "");

      form.getTextField("driver" + index).setText((data[0].driver.displayName));
      form.getTextField("cost" + index).setText(data[0].cost.toString());
      if (data[0].type === "service") {
        let descriptions = "";
        for (let service of data[0].maintenanceservice) {
          descriptions += service.name + ",";
        }
        form.getTextField("description" + index).setText(descriptions);
      } else {
        form.getTextField("description" + index).setText(data[0].type);
      }
      if (data[0] && typeof data[0].odometer !== 'undefined' && typeof data[0].nextOdometer !== 'undefined') {
      form
        .getTextField("odo_atservice" + index).setText(data[0].odometer.toString());
      form
        .getTextField("odo_nextservice" + index).setText(data[0].nextOdometer.toString());
      }
      form.getTextField("mechanic_workshop" + index).setText(data[0].mechanicWorkshop);
      form
        .getTextField("notes" + index)
        .setText((data[0].notes));
    }
// remove non ASCII
await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }

  async getVmlReport(payload, company, vehicle) {
    const pdfArray = [];
    let newtemp = []
    for(const [index , item] of payload.entries()){
      newtemp.push(item)
      if(index === 9){
        const response = await fetch("assets/documents/vehicle-maintenance-report.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, vehicle);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      }else if(index > 9 && (index - 9) % 16 === 0){
        const response = await fetch("assets/documents/vehicle-maintenance-report-blank.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, null, null);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      }
    }

    if (!pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/vehicle-maintenance-report.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, vehicle);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    } else if (pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/vehicle-maintenance-report-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, null, null);
      pdfArray.push(pdfBuffer);
    
    }
    
    const pdfDoc = await PDFDocument.create();
  for (const pdfBytesData of pdfArray) {
    const sourcePDF = await PDFDocument.load(pdfBytesData);
    const [page] = await pdfDoc.copyPages(sourcePDF, [0]); 
    pdfDoc.addPage(page);
  }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const currentDate = moment().format("YYYY-MM-DD");
      const fileName = `vehicle_maintenance_report_${currentDate}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

}


