import { Component, OnDestroy, OnInit } from "@angular/core";
import { sweetAlert } from "../../../utils/swal";
import { ActivatedRoute } from "@angular/router";
import {
  getCompanyLogo,
} from "../../../utils/utils.common";
import { FleetService } from "../../fleet/fleet-service/fleet.service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DriversService } from "../drivers-service/drivers-service";
import { downloadFile } from "src/app/utils/pdf-downloader";
import { urls } from "src/app/utils/url-utils";
import { animate, style, transition, trigger } from "@angular/animations";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import * as moment from "moment";

@Component({
  selector: "app-driver-list",
  templateUrl: "./driver-list.component.html",
  styleUrls: ["./driver-list.component.css"],
  animations: [
    trigger("fleetAssignModalAnimation", [

      transition(":enter", [
        style({ opacity: 0, transform: "translateX(100%)" }),
        animate(
          "750ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        )
      ]),

      transition(":leave", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate(
          "750ms ease-in-out",
          style({ opacity: 0, transform: "translateX(-100%)" })
        )
      ])

    ])
  ]
})
export class DriverListComponent implements OnInit, OnDestroy {
  driverList = [];
  search;
  fleetsList = [];
  selectedDriver: any;
  isSelectedFleet = false;
  path = "";
  user;
  drivers: void[];
  selectedStatus: any = "active";
  isModal: boolean = false;
  btnName: string = "Assign Fleet";
  driverDetailProfileModal;
  driverShiftTimeModal;
  isShiftModal: boolean = false;
  isTimelineSubmitted = false;
  timeZone;
  shift;
  updatedData;

  constructor(
    private driverService: DriversService,
    private fleetService: FleetService,
    private sharedDataService: SharedDataService,
    private actvatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getUser();
  }




  getUser() {
    this.sharedDataService.getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.timeZone = this.user.timeZone;
          this.getDriverList();
        }
      });
  }

  getDriverList() {
    this.driverService.getDrivers(this.user._id, this.selectedStatus)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.driverList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  onChangeStatus() {
    this.getDriverList();
  }

  onClickProfile(driver) {
    console.log({ driver })
    this.driverDetailProfileModal = driver;
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  currentSelectedFleet(driver) {
    this.isModal = true;
    this.selectedDriver = driver;
    this.fleetsList.length = 0;

    if (driver.isFleet) {
      this.isSelectedFleet = true;
      this.getFleetList({ driver: this.selectedDriver._id, source: this.selectedDriver.source });
    } else {
      this.isSelectedFleet = false;
      this.getFleetList({ status: 'active', source: this.selectedDriver.source });
    }
  }

  getFleetList(payload) {
    this.fleetService.getFleetList(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.fleetsList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  // updateFleet() {
  //   const newFleet = this.currentFleet;
  //   newFleet.fleetId = newFleet._id;
  //   delete newFleet._id;
  //   delete newFleet.__v;
  //   newFleet.isFleet = true;
  //   $("#fleetListModal .close").click();
  //   this.driverService
  //     .assignFleet(newFleet, this.selectedDriver._id)
  //     .subscribe((data) => {
  //       if (data) {
  //         const itemIndex = this.driverList.findIndex(
  //           (item) => item._id === data._id
  //         );
  //         this.driverList[itemIndex] = data;
  //         this.isModal = false;
  //         sweetAlert("Success", "Fleet Updated Successfully", "success", "OK");
  //       }
  //     });
  //   this.assignFleet(newFleet, this.selectedDriver._id);
  // }

  updateFleet(fleet) {
    const newFleet = fleet;
    newFleet.fleetId = newFleet._id;
    delete newFleet._id;
    delete newFleet.__v;
    newFleet.isFleet = true;
    $("#fleetListModal .close").click();
    this.driverService
      .assignFleet(newFleet, this.selectedDriver._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          const itemIndex = this.driverList.findIndex(
            (item) => item._id === data._id
          );
          this.driverList[itemIndex] = data;
          this.getDriverList();
          // sweetAlert("Success", "Fleet Updated Successfully", "success", "OK");
        }
      });
    this.assignFleet(newFleet, this.selectedDriver._id);
  }

  clearFleet(fleet) {
    this.assignFleet(fleet._id, this.selectedDriver._id);
  }

  assignFleet(fleetId, driverId) {
    this.driverService.assignFleet(fleetId, driverId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          const itemIndex = this.driverList.findIndex(
            (item) => item._id === data._id
          );
          this.driverList[itemIndex] = data;
          this.getDriverList();
          // sweetAlert("Success", "Fleet Updated Successfully", "success", "OK");
          this.isModal = false;
        }
      });
  }

  generateQRCode(driver) {
    this.driverService.generateDriverQR(driver._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data && data.pdfName) {
          const name = data.pdfName;
          const url = urls.SERVER_URL + "pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  driverShiftModal(driver) {
    this.isShiftModal = true;
    this.driverShiftTimeModal = driver;
  }

  shiftUpdate(evt) {
    if (this.driverList && this.driverList.length) {
      let selectedDriver = this.driverList.find((driver) => driver._id === evt._id);
      if (selectedDriver) {
        if (selectedDriver.shift) {
          selectedDriver.shift.checkin = evt.shift.checkin;
          selectedDriver.shift.checkout = evt.shift.checkout;
        } else {
          selectedDriver["shift"] =  { checkin: evt.shift.checkin, checkout: evt.shift.checkout };
        }
      }
    }
  }

  ngOnDestroy(): void {
  }
}
