import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-routing-wrapper',
  templateUrl: './routing-wrapper.component.html',
  styleUrls: ['./routing-wrapper.component.css']
})
export class RoutingWrapperComponent implements OnInit {
  isRoutingLine = false;
  subMenu = 'line';
  constructor() { }

  ngOnInit() {
  }

  onSelectSubMenu(menu) {
    this.subMenu = menu;
    if (this.subMenu === 'manual') {
      this.isRoutingLine = false;
    }
  }
}
