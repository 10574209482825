import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from '../../../utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { UserProfileService } from '../user-profile-service/user-profile.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from '../../shared/layout/menu-service/menu.service';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit, OnDestroy {

  editProfileForm: FormGroup;
  submitted = false;
  selectedFile: ImageSnippet;
  userData = null;


  image = null;

  tzone = 'America/Los_Angeles';

  placeholderString = 'Select timezone';


  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  toggleCurrentPassword = true;
  toggleNewPassword = true;
  toggleConfirmPassword = true;
  user;
  forcedAcceptTrips = false;
  isTripAssistance = false;
  isListView = false;
  isTime = false;
  userRole;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private userProfileService: UserProfileService,
    private menuService: MenuService,
  ) {
  }



  /*
    changes in CSS of select2 node module
    In select2.css file

    comment line height in the following selector
    .select2-container--default .select2-selection--single .select2-selection__rendered

    comment border style in the following selector
    .select2-container--default .select2-selection--single .select2-selection__arrow b
  */

  get form() {
    return this.editProfileForm.controls;
  }

  ngOnInit() {
    this.getUser();

    this.editProfileForm = this.fb.group({
      displayName: [{ value: '', disabled: true }, Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      contactNumber: [{ value: '', disabled: true }, Validators.required],
      address: [{ value: '', disabled: true }, Validators.required],
      timeZone: [{ value: this.tzone, disabled: true }, Validators.required],
      currentPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      latitude: [{ value: '', disabled: true }, Validators.required],
      longitude: [{ value: '', disabled: true }, Validators.required],
      focalPerson: this.fb.group({
        displayName: [{ value: '', disabled: true }],
        contactNumber: [{ value: '', disabled: true }]
      }),
      faxNumber: [{ value: '', disabled: true }]
    });
    // this.userData = this.authService.getUserData();
    // console.log('service data here', this.authService.getUserData())
    this.setUpView();

  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.tzone = this.user.timeZone
          this.userData = user;
          this.forcedAcceptTrips = this.user.forcedAcceptTrips;
          this.isTripAssistance = this.user.isTripAssistance;
          this.isListView = this.user.isListView;
          this.isTime = this.user.isTime;

          if (this.user && this.user.roles) {
            if ((typeof this.user.roles) === 'string') {
              if(this.user.sRoles) {
                this.userRole = this.menuService.getUserRoleTitle(this.user.sRoles);
              } else {
                this.userRole = this.menuService.getUserRoleTitle(this.user.roles);
              }
            } else if ((typeof this.user.roles) === 'object') {
              this.userRole = this.menuService.getUserRoleTitle(this.user.roles[0]);
            }
          }
        }
      });
  }

  afterEditSuccess() {
    this.selectedFile = null;
    // this.userData = this.authService.getUserData();
    this.editProfileForm.reset();
    this.submitted = false;
    this.setUpView();
  }

  setUpView() {

    console.log('user data here ==>', this.userData)
    if (this.userData != null) {
      // console.log('in setup view function', this.userData)

      this.editProfileForm.patchValue({
        ...this.userData
      });
      this.image = this.userData.profileImageURL;
    }

  }

  togglePassword(fieldName: string) {
    switch (fieldName) {
      case 'current':
        this.toggleCurrentPassword = !this.toggleCurrentPassword;
        break;
      case 'new':
          this.toggleNewPassword = !this.toggleNewPassword;
          break;
      case 'confirm':
          this.toggleConfirmPassword = !this.toggleConfirmPassword;
          break;
    }
  }

  processFile(imageInput: any) {
    this.submitted = false;
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedFile = result.file;
      this.image = result.src;
      this.uploadImage();
    });
  }

  changeTimezone() {
    this.editProfileForm.patchValue({ timeZone: this.tzone });
  }

  onEditProfileSubmit() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    } else {

      this.userProfileService
        .ChangePassword(this.editProfileForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data.success) {
            sweetAlert('Success', 'Password Updated Successfully', 'success', 'OK');
            this.afterEditSuccess();
          } else {
            sweetAlert('Error', data.message, 'warning', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
    }
  }


  uploadImage() {
    if (this.selectedFile) {
      this.submitted = false;
      this.userProfileService
        .changeProfileImage(this.selectedFile)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data: any) => {
          if (data && data.user) {
            this.sharedDataService.saveUser({...this.user, profileImageURL: data.user.profileImageURL});
            this.afterEditSuccess();
            this.image = data.user.profileImageURL;
            sweetAlert('Success', 'Image Uploaded Successfully', 'success', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
    }
  }

  clearImage() {
    this.selectedFile = null;
    this.image = this.userData.profileImageURL;
  }
  getAddress(place) {
    this.editProfileForm.patchValue({
      currentAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });

  }

  onToggleConfiguration() {
    const payload = {
      forcedAcceptTrips : this.forcedAcceptTrips,
      isTime: this.isTime,
      isTripAssistance : this.isTripAssistance,
      isListView : this.isListView
    }
    this.userProfileService
      .updateUser(payload)
      .subscribe(data => {
        if (data) {
        }
      })
  }

  ngOnDestroy(): void {
  }
}
