import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Events } from 'src/app/utils/event-utils';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { urls } from 'src/app/utils/url-utils';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DispatchCenterService } from '../dispatch-center-service/dispatch-center-service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FleetService } from '../../fleet/fleet-service/fleet.service';
@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.css']
})

export class AdjustmentComponent implements OnInit, OnDestroy {
  adjustmentForm: FormGroup;
  submitted = false;
  getDriverListState: Observable<any>;
  getAdjustmentState: Observable<any>;
  adjustmentDetails;
  driverList = [];
  jobId;
  timeZone;
  scheduleTime;
  appointmentTime;
  brokerRate;
  estimationData;
  escort = 'escort';
  update = false;
  isFromBilling = false;
  jobCarType = '';
  user;
  tripID;
  created;
  arrivedTime;
  startedTime;
  completedTime
  fleet;
  tripDetailsModalObj;
  clientId;

  constructor(
    private driverService: DriversService,
    private fleetService: FleetService,
    private dispatchCenterService: DispatchCenterService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.jobId = params["id"];
    });

    this.activatedRoute.queryParams
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      if (params.isFromBilling) {
        this.isFromBilling = Boolean(params['isFromBilling']);
        console.log('TEST100:RouteData:', params['isFromBilling']);
      }
    });

    this.socketService.addListener(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          this.estimationData = data.results.googleFare ? data.results.googleFare.additionalInfoJob : null;
        }
      });

    this.adjustmentForm = this.fb.group({
      jobFareDetail: this.fb.group({
        jobFee: ['', Validators.required]
      }),
      driver: ['', Validators.required],
      jobCarType: [this.jobCarType],
      odometerStart: [''],
      odometerStop: [''],
      escortName: [''],
      escortRelation: [''],
      additionalInfoJob: this.fb.group({
        jobTollFee: ['', Validators.required],
        totalMiles: ['', Validators.required],
        additionalWaitTime: ['', Validators.required],
      }),
      tripId: ['', Validators.required],
      created: ['', Validators.required],
      trackInfoJob: this.fb.group({
        jobAccepted: this.fb.group({
          coords:[[]],
          time: [''],
        }),

        jobArrived: this.fb.group({
          coords:[[]],
          time: ['', Validators.required],
        }),
        jobStarted: this.fb.group({
          coords:[[]],
          time: ['', Validators.required],
        }),
        jobCompleted: this.fb.group({
          coords:[[]],
          time: ['', Validators.required],
        }),
        jobFinished: this.fb.group({
          coords:[[]],
          time: ['', Validators.required],
        }),
        jobCancelled: this.fb.group({
          coords:[[]],
          time: [''],
        }),

      }),
      fleet: ['', Validators.required]
    });

    this.getUser();
    this.getDriverList();
    this.getFleet();
    this.getBrokerRate();
    this.getAdjustmentRequest();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          // this.searchDate = moment().tz(this.timeZone);
          // this.getTrips();
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  getFleet() {
    const payload = {
      isFleetSection: true,
      status: 'active'
    }
    this.fleetService
      .getFleetList(payload)
      .subscribe(data => {
        if (data && data.length) {
          this.fleet = data;
        }
      });
  }

  getBrokerRate() {
    this.dispatchCenterService
      .getBrokerRate(this.jobId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.brokerRate = data.preSpecialRate;
        }
      });
  }

  getAdjustmentRequest() {
    this.dispatchCenterService
      .getAdjustmentDetail(this.jobId, this.isFromBilling)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          console.log('TST00: Adjustment: ', data);
          this.adjustmentDetails = data;
          this.adjustmentForm.patchValue({
            ...this.adjustmentDetails,
            driver: this.adjustmentDetails.driver._id ? this.adjustmentDetails.driver._id : this.adjustmentDetails.driver,
            additionalWaitTime: this.adjustmentDetails.additionalInfoJob.additionalWaitTime,
          });
          this.escort = this.adjustmentDetails.escortName;
          this.jobCarType = this.adjustmentDetails.jobCarType;
          this.scheduleTime = new Date(this.adjustmentDetails.scheduleTime);
          this.appointmentTime = new Date(this.adjustmentDetails.appointmentTime);
          this.tripID = this.adjustmentDetails.tripId;
          this.created = this.adjustmentDetails.created;
          this.arrivedTime = this.adjustmentDetails.trackInfoJob.jobArrived.time;
          this.startedTime = this.adjustmentDetails.trackInfoJob.jobStarted.time;
          this.completedTime = this.adjustmentDetails.trackInfoJob.jobCompleted.time;

          console.log( this.adjustmentForm.value)
          this.getAdjustmentEstimations();
          this.update = false;
          this.submitted = false;
        }
      });
  }

  getAdjustmentEstimations() {
    const adjustmentEstimationPayload = {
      _id: this.isFromBilling ? this.adjustmentDetails.job : this.adjustmentDetails._id,
      jobFee: this.adjustmentDetails.jobFareDetail.jobFee,
      totalMiles: this.adjustmentDetails.additionalInfoJob.totalMiles,
      jobArrived: this.adjustmentDetails.trackInfoJob.jobArrived,
      jobStarted: this.adjustmentDetails.trackInfoJob.jobStarted,
      jobCompleted: this.adjustmentDetails.trackInfoJob.jobCompleted,
      jobTollFee: this.adjustmentDetails.additionalInfoJob.jobTollFee,
      created: this.adjustmentDetails.created
    };
    console.log(adjustmentEstimationPayload);
    this.socketService.emit(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL, adjustmentEstimationPayload);
  }

  onCreatedTimeChange() {
    if (this.adjustmentForm.value.created._d) {
      console.log(this.adjustmentForm.value.created);
      this.adjustmentForm.patchValue({
        created: this.adjustmentForm.value.created.toISOString()
      });
    }
  }

  onArrivedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobArrived.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobArrived.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobArrived.time.toISOString()
      });
    }
  }

  onStartedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobStarted.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobStarted.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobStarted.time.toISOString()
      });
    }
  }

  onCompletedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobCompleted.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobCompleted.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobCompleted.time.toISOString()
      });
      this.adjustmentForm.controls.trackInfoJob['controls'].jobStarted.patchValue({
        // tslint:disable-next-line: max-line-length
        time: moment(this.adjustmentForm.value.trackInfoJob.jobCompleted.time).subtract(this.estimationData.jobMinutes, 'minutes').toISOString()
      });
      this.adjustmentForm.controls.trackInfoJob['controls'].jobArrived.patchValue({
        time: moment(this.adjustmentForm.value.trackInfoJob.jobStarted.time).subtract(5, 'minutes').toISOString()
      });
      this.adjustmentForm.patchValue({
        created: moment(this.adjustmentForm.value.trackInfoJob.jobArrived.time).subtract(5, 'minutes').toISOString()
      });
    }
  }

  setEscort(event) {
    console.log(event);
    if (event.target.value === 'Helper') {
      this.adjustmentForm.patchValue({
        escortName: 'Helper',
        escortRelation: 'Support'
      });
      this.escort = 'Helper';
    } else if (event.target.value === 'escort') {
      this.adjustmentForm.patchValue({
        escortName: '',
        escortRelation: ''
      });
      this.escort = 'escort';
    } else {
      this.adjustmentForm.patchValue({
        escortName: '',
        escortRelation: ''
      });
      this.escort = '';
    }
  }

  setVehicle(event) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.driverList.length; i++) {
      if (event.target.value === this.driverList[i]._id) {
        this.jobCarType = this.driverList[i].driverCar;
        this.adjustmentForm.patchValue({
          jobCarType: this.jobCarType
        });
      }
    }
  }

  onAdjustment() {
    this.submitted = true;
    console.log(this.adjustmentForm.value);
    if (this.adjustmentForm.invalid) {
      return;
    }
    if (this.escort === 'escort') {
      return;
    }
    this.update = true;
    let payload = this.adjustmentForm.value;
    payload.trackInfoJob.jobFinished = payload.trackInfoJob.jobCompleted;

    this.dispatchCenterService
      .saveTripAdjustment(this.jobId, payload, this.isFromBilling)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (this.isFromBilling) {
            this.adjustmentDetails = data;
            this.adjustmentForm.patchValue({
              ...this.adjustmentDetails,
              driver: this.adjustmentDetails.driver._id ? this.adjustmentDetails.driver._id : this.adjustmentDetails.driver,
              additionalWaitTime: this.adjustmentDetails.additionalInfoJob.additionalWaitTime,
            });
            this.escort = this.adjustmentDetails.escortName;
            this.jobCarType = this.adjustmentDetails.jobCarType;
            this.scheduleTime = new Date(this.adjustmentDetails.scheduleTime);
            this.appointmentTime = new Date(this.adjustmentDetails.appointmentTime);
            this.tripID = this.adjustmentDetails.tripId;
            this.created = this.adjustmentDetails.created;
            this.arrivedTime = this.adjustmentDetails.trackInfoJob.jobArrived.time;
            this.startedTime = this.adjustmentDetails.trackInfoJob.jobStarted.time;
            this.completedTime = this.adjustmentDetails.trackInfoJob.jobCompleted.time;
          }
          this.toastr.success('Trip updated successfully!', 'Alert');
        }
      });

  }

  viewTripDetails() {
    this.clientId = this.adjustmentDetails.priorityClient.clientId;
    this.tripDetailsModalObj = this.adjustmentDetails;
  }

  get form() {
    return this.adjustmentForm.controls;
  }
  ngOnDestroy(): void {
    this.socketService.removeListener(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL);
  }
}
