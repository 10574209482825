import { Component, OnInit, NgZone, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { LatLngLiteral } from '@agm/core';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.css']
})
export class AddressPickerComponent implements OnInit {
  @Input() reference: any
  @Output() onCloseModal = new EventEmitter<any>();

  latitude = 35.125801;
  longitude = -117.9859038;
  centerLatitude = this.latitude;
  centerLongitude = this.longitude;
  initialZoom = 18;
  geoCoder;
  address;

  constructor() { }

  ngOnInit() {
    console.log(this.reference)
    this.geoCoder = new google.maps.Geocoder;
    if (this.reference.address && this.reference.lat !==0 && this.reference.lng !==0 ) {
      this.address = this.reference.address;
      this.latitude = this.reference.lat;
      this.longitude =this.reference.lng;
      this.centerLatitude = this.latitude;
      this.centerLongitude = this.longitude;
    }
  }


  mapReady(map) {
    map.addListener("dragend", () => {
      // console.log(this.centerLatitude, this.centerLongitude)
      this.getAddress(this.centerLatitude, this.centerLongitude);
    });
  }


  centerChanged(coords: LatLngLiteral) {
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
  }

  closeAddressPickerModal() {
    this.onCloseModal.emit();
  }


  setLocation() {
    this.onCloseModal.emit({
      reference: this.reference,
      address: this.address,
      lat: this.centerLatitude,
      lng: this.centerLongitude
    });
  }



  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results) {
          this.address = results[0].formatted_address;
        } else {
          sweetAlert('Alert', 'No results found', 'warning', 'Ok')
        }
      } else {
        sweetAlert('Alert', `Geocoder failed due to: ${status}`, 'warning', 'Ok')
      }

    });
  }
}
