import { AgmMap } from '@agm/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import * as moment from "moment-timezone";
import { getBrokersList } from 'src/app/utils/utils.common';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { takeUntil, filter } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PlanningService } from '../../shared/planning/planning-service';

@Component({
  selector: 'app-outstanding',
  templateUrl: './outstanding.component.html',
  styleUrls: ['./outstanding.component.css']
})
export class OutstandingComponent implements OnInit, OnDestroy {
  getTripListObserver: Observable<any>;
  drivers = [];
  timeZone;
  todayDate;
  search;
  standingOrders;
  outstandingTrips = [];
  selectedDriverId;
  suggestedTrips = [];
  nonSuggestedTrips = [];
  bucketTrips;
  row;
  user;
  selectedBucket;
  tempDriver = '';
  seats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  isSubmitted = false;
  corporateUsers;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  generateTripModalObj;
  isTripLoading = false;
  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;

  constructor(
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private planningService: PlanningService,
  ) { }

  ngOnInit() {
    this.getUser();
    this.getDriverList();
    this.getOutstandingTrips();
    this.getCorporateList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();
        }
      });
  }

  getDriverList() {
    this.drivers = [''];
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getCorporateList() {

    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }


  getOutstandingTrips() {
    this.assignTripService
      .getBulkwriteTripsForBucket({ scheduleTime: this.todayDate })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.standingOrders = data;
          this.filterTrips();
        }
      });
  }

  filterTrips() {
    this.suggestedTrips = [];
    this.nonSuggestedTrips = [];
    this.bucketTrips = 0;
    let isContain = false;
    this.standingOrders.forEach(st => {
      if (st._id.groupId) {
        this.bucketTrips += st.assigns.length;
        st.assigns.sort(this.compare);
        if (st._id && !st._id.driver) {
          st._id.driver = '';
        }
        if (this.selectedBucket && (st._id.groupId === this.selectedBucket._id.groupId)) {
          isContain = true;
          this.selectedBucket = st;
        }
        this.suggestedTrips.push(st);
      } else {
        // this.nonSuggestedTrips = st.assigns;
        // temp additon
        st.assigns.forEach(s => {
          if (s.status !== "unassigned") {
            return
          };
          this.nonSuggestedTrips.push({...s, gid:''});
        });

      }
    });

    if (!isContain) {
      this.selectedBucket = null;
    }

  }

  onDateChange() {
    this.todayDate = moment.tz(this.todayDate, this.timeZone).startOf('day').toISOString();
    this.getOutstandingTrips();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  async sendToBucket(assign) {
    if (!assign.gid) return;

    // Remove the trip from bucket
    let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === assign._id);
    this.nonSuggestedTrips.splice(findTripIndex, 1);

    let suggestedTripIndex = this.suggestedTrips.findIndex(t => t._id.groupId === assign.gid);
    this.suggestedTrips[suggestedTripIndex].assigns.push(assign);

    let preData: any = {
      upAddress: this.user.completeAddress,
      upLatitude: this.user.latitude,
      upLongitude: this.user.longitude,

      downAddress: this.user.completeAddress,
      downLatitude: this.user.latitude,
      downLongitude: this.user.longitude,

      seats: 111,
      routes: 111
    };

    if (this.suggestedTrips[suggestedTripIndex] && this.suggestedTrips[suggestedTripIndex]._id && this.suggestedTrips[suggestedTripIndex]._id.driver) {
      const selected = this.drivers.find(data => data._id === this.suggestedTrips[suggestedTripIndex]._id.driver);
      preData = {
        ...preData,
        upAddress: selected.address,
        upLatitude: selected.takeMeHome[1],
        upLongitude: selected.takeMeHome[0],
      }
    }

    const allRoutes = await this.planningService.getAllRoutes(this.suggestedTrips[suggestedTripIndex].assigns, preData, this.suggestedTrips[suggestedTripIndex].assigns[0].isUp);
    const payload = [];
    if (allRoutes) {
      for (let group of allRoutes.aRoutes) {
        for (let trip of group) {
          payload.push({
            gid: assign.gid,
            _id: trip._id,
            priority: trip.priority,
            standingorder: this.suggestedTrips[suggestedTripIndex]._id.driver,
            isUp: trip.isUp,
          })
        }
      }

      for (let group of allRoutes.bRoutes) {
        for (let trip of group) {
          payload.push({
            gid: assign.gid,
            _id: trip._id,
            priority: trip.priority,
            standingorder: this.suggestedTrips[suggestedTripIndex]._id.driver,
            isUp: trip.isUp,
          })
        }
      }
    }

    this.assignTripService.getBulkWriteTrips({
      data: payload
    })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.suggestedTrips[suggestedTripIndex].assigns.push(assign);
          this.getOutstandingTrips();
        }
      });

  }

  compare(a, b) {
    if (a.scheduleTime < b.scheduleTime) {
      return -1;
    }
    if (a.scheduleTime > b.scheduleTime) {
      return 1;
    }
    return 0;
  }

  assignBucket(bucket) {
    sweetAlert('Alert', 'Are you sure you want to make bucket live?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          const selectedTrips = [];

          for (let a of bucket.assigns) {
            if (['offered', 'unassigned'].includes(a.status)) {
              selectedTrips.push(a._id)
            }
          }

          if (!selectedTrips.length) return;

          const payload = {
            selectedTrips,
            status: 'offered',
            driver: { _id: bucket._id.driver },
            note: ''
          }

          this.assignTripService
            .assignMultipleTrip(payload)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                // this.suggestedTrips.splice(bIndex, 1);
                this.getOutstandingTrips();
              }
            });
        }
      })
  }
  getBucketData(bucket) {

    this.selectedBucket = bucket;
  };

  removeTrip(evt) {
    let trip = evt.trip;
    let bucket = evt.bucket;
    this.removeFromBucket(trip, bucket);
    this.getOutstandingTrips();
  }
  removeFromBucket(assign, bucket) {

    let bIndex = this.suggestedTrips.findIndex(t => t._id === bucket._id);

    sweetAlert('Alert', 'Are you sure you want to remove trip?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {

          // Remove the trip from bucket
          let findTripIndex = bucket.assigns.findIndex(t => t._id === assign._id);
          bucket.assigns.splice(findTripIndex, 1);


          // Remove the bucket if there is no trip left
          if (bucket.assigns.length === 0) {
            this.suggestedTrips.splice(bIndex, 1);
          }

          // Push the trip back to non-suggested trips
          assign.gid = '';
          this.nonSuggestedTrips.push(assign);
          this.nonSuggestedTrips.sort(this.compare);
          this.bucketTrips -= 1;

          let payload: any = {
            gid: "",
            _id: assign._id,
            priority: -1,
            standingorder: null
            // isUp: trip.isUp,
          };

          if (assign.status === "offered") {
            payload = { ...payload, status: "unassigned", jobStatus: "pending" }
          }

          this.assignTripService.getBulkWriteTrips({ data: [payload] })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.getOutstandingTrips();
              }
            });

        }
      });
  }



  removeBucket(bucket) {
    sweetAlert('Alert', 'Are you sure you want to delete this bucket?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {

          const payload = [];
          for (let trip of bucket.assigns) {
            if (trip.status === "offered") {
              payload.push({
                gid: "",
                _id: trip._id,
                priority: -1,
                standingorder: null,
                status: "unassigned", jobStatus: "pending"
              });
            } else {
              payload.push({
                gid: "",
                _id: trip._id,
                priority: -1,
                standingorder: null
                // isUp: trip.isUp,
              })

            }
          };

          if (!payload || (payload && !payload.length))
            return

          this.assignTripService.getBulkWriteTrips({ data: payload })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.getOutstandingTrips();
              }
            });
        }
      });
  }

  deleteAllBuckets() {
    sweetAlert('Alert', 'Are you sure you want to delete all bucket?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          const payload = [];

          for (let buckets of this.suggestedTrips) {
            for (let trip of buckets.assigns) {
              if (trip.status === "offered") {
                payload.push({
                  gid: "",
                  _id: trip._id,
                  priority: -1,
                  standingorder: null,
                  status: "unassigned", jobStatus: "pending"
                  // isUp: trip.isUp,
                })
              } else {
                payload.push({
                  gid: "",
                  _id: trip._id,
                  priority: -1,
                  standingorder: null
                  // isUp: trip.isUp,
                })
              }
            }
          }
          if (!payload || (payload && !payload.length))
            return

          this.assignTripService.getBulkWriteTrips({ data: payload })
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.getOutstandingTrips();
              }
            });





        }
      });
  }

  onSubmit(data) {
    this.isTripLoading = true;
    this.generateTripModalObj = null;
    this.setUpRouteCall(this.nonSuggestedTrips, data)
  }

  async setUpRouteCall(nonSuggestedTrips, outStandingForm) {

    let filterTrips = [];

    if (outStandingForm.cooperate) {
      for (let item of nonSuggestedTrips) {
        if (item.cooperate && ([item.cooperate].includes(outStandingForm.cooperate)))
          filterTrips.push(item);
      }
    } else {
      filterTrips = nonSuggestedTrips;
    }

    const allRoutes = await this.planningService.getAllRoutes(filterTrips, outStandingForm);
    const payload = [];
    if (allRoutes) {
      // set all A legs parameter
      for (let group of allRoutes.aRoutes) {
        for (let trip of group) {

          let tripPayload: any = {
            gid: trip.groupId,
            _id: trip._id,
            priority: trip.priority,
            isUp: trip.isUp,
          };

          if (trip.driver) {
            tripPayload = { ...tripPayload, standingorder: trip.driver }
          }

          payload.push(tripPayload)
        }
      }

      // set all B legs parameter
      for (let group of allRoutes.bRoutes) {
        for (let trip of group) {

          let tripPayload: any = {
            gid: trip.groupId,
            _id: trip._id,
            priority: trip.priority,
            isUp: trip.isUp,
          };

          if (trip.driver) {
            tripPayload = { ...tripPayload, standingorder: trip.driver }
          }

          payload.push(tripPayload)
        }
      }
    }
    if (!payload || (payload && !payload.length))
      return

    this.assignTripService.getBulkWriteTrips({ data: payload })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.isTripLoading = false;
          this.getOutstandingTrips();
        }
      });
  }


  onDriverChange(bucket) {

    const payload = [];
    for (let trip of bucket.assigns) {
      if (['offered', 'unassigned'].includes(trip.status)) {
        payload.push({
          _id: trip._id,
          standingorder: bucket._id.driver === "null" ? null : bucket._id.driver,
          jobStatus: "pending",
          status: "unassigned"
        })
      }
    }

    if (!payload || (payload && !payload.length))
      return

    this.assignTripService.getBulkWriteTrips({ data: payload })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.getOutstandingTrips();
        }
      });

  }

  reFreshCall(evt) {
    this.getOutstandingTrips();
  }
  ngOnDestroy(): void {
  }

  checkTripStatus(assigns) {
    const trips = [];
    for (let trip of assigns) {
      if (!['offered', 'unassigned'].includes(trip.status)) {
        trips.push(trip);
      }
    }

    return !trips.length;
  }

  generateTrip() {
    this.generateTripModalObj = true;
  }

  onCloseModal(evt) {
    this.generateTripModalObj = null;
  }

}
