import { downloadFile } from 'src/app/utils/pdf-downloader';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, from, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from '../../../utils/utils.common';
import { getBrokerImage } from '../../../utils/utils.common';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { data } from 'jquery';
import { AdminkpisService } from '../service/adminkpis.service';
import { urls } from 'src/app/utils/url-utils';
@Component({
  selector: 'app-admin-nemt-report',
  templateUrl: './admin-nemt-report.component.html',
  styleUrls: ['./admin-nemt-report.component.css']
})
export class AdminNemtReportComponent implements OnInit, OnDestroy {

  getKpisState: Observable<any>;
  assignedKpis;
  nobrokerTrips = 0;
  adminCompany = [];
  selectedCompany = [];
  dateForm: FormGroup;
  constructor(private fb: FormBuilder, private adminKpisService: AdminkpisService) {
  }

  ngOnInit() {
    this.dateForm = this.fb.group({
      dateRange: ['']
    });
  }

  getCompanyStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].startOf('day').toISOString();
    const endDate = this.dateForm.value.dateRange[1].endOf('day').toISOString();
    this.adminKpisService
      .getCompanyStatsByDate(startDate, endDate)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.nobrokerTrips = 0;
          this.assignedKpis = data.length > 0 ? data[0] : null;
          this.adminCompany = this.assignedKpis.data
        }
      })

  }

  downloadCompanyReport() {
    const payload = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].startOf('day').toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].endOf('day').toISOString() : null
    };
    this.adminKpisService
      .getCompanyReportPdf(this.selectedCompany, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          var name = data.pdfName;
          var url = urls.SERVER_URL  + '/pdf/' + name;
          downloadFile(url, '', 'Company-Trip-Report.pdf');
        }
      })
  }

  selectCompany(company) {
    const index = this.selectedCompany.indexOf(company.company._id);
    if (index > -1) {
      this.selectedCompany.splice(index, 1);
    } else {
      this.selectedCompany.push(company.company._id);
    }
  }


  selectAllCompanies(evt) {
    if (this.selectedCompany.length === this.adminCompany.length) {
      this.unSelectAllCompanies();
    } else {
      this.selectedCompany = [];
      this.adminCompany.forEach(company => {
        company.isSelected = true;
        this.selectedCompany.push(company.company._id);
      });
    }
  }

  unSelectAllCompanies() {
    this.selectedCompany = [];
    for (const company in this.adminCompany) {
      this.adminCompany[company].isSelected = false;
    }
  }

  ngOnDestroy(): void {
  }
}

