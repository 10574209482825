import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from "@w11k/ngx-componentdestroyed";
import { CorporateService } from '../corporate-service/corporate-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { noWhitespaceValidator, requiredOdometers } from "../../../utils/utils.common";

@Component({
  selector: "app-corporate-add-user",
  templateUrl: "./corporate-add-user.component.html",
  styleUrls: ["./corporate-add-user.component.css"],
})
export class CorporateAddUserComponent implements OnInit, OnDestroy {
  addNewCorporateUser: FormGroup;
  payorTypesForm: FormGroup;
  submitted = false;
  payorTypesFormSubmitted = false;
  isPayorSubmitted = false;
  btnName = "Create";
  selectedFile: ImageSnippet = null;
  profileImageURL = null;
  corporateUserId = null;
  passwordType = "password";
  passwordToggleFlag = true;
  user;
  corporateUsers = [];
  status = "active"
  odometers;
  vehicles = [];
  selectedVehicles = [];
  selectedOdometers = [];
  dropdownSettings = {};

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private corporateService: CorporateService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.corporateUserId = params["id"];
    });

    this.addNewCorporateUser = this.fb.group({
      displayName: ["", [Validators.required, Validators.minLength(3)]],
      contactNumber: ["", Validators.required],
      address: ["", Validators.required],
      accountStatus: ["", Validators.required],
      // associateTo: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.minLength(6), Validators.maxLength(16),],],
      latitude: ["", Validators.required],
      longitude: ["", Validators.required],
      corporateUserId: [""],
      images: [""],
      company: [""],
      selfPay: [false],
      isOdometer: [false],
      showScheduleTime: [false],
      showStatus: [false],
      vehicles: [],
      requiredOdometers: [{ value: "0", disabled: true }],
      payorTypes: [[]]
    });

    this.payorTypesForm = this.fb.group ({
      title: ['', Validators.required, noWhitespaceValidator()],
      value: [''] 
    });

    // this.payorServicesForm = this.fb.group({
    //   title: ['', Validators.required],
    //   fare: ['', Validators.required],
    // });


    this.addNewCorporateUser.controls['isOdometer'].valueChanges.subscribe(value => {
      if (value) {
        this.addNewCorporateUser.get("requiredOdometers").enable();
      } else {
        this.addNewCorporateUser.get('requiredOdometers').disable();
      }
      this.addNewCorporateUser.get('requiredOdometers').updateValueAndValidity();
    });

    this.getUser();
    this.getOdometerValue();
    this.fillForm();
    this.getcorporateUsers();

    this.vehicles = [
      { id: 1, text: 'Standard' },
      { id: 2, text: 'Premium' },
      { id: 3, text: 'SUV' },
      { id: 4, text: 'WAV' },
      { id: 5, text: 'Stretcher' }
    ];

    // this.requiredOdometers = [
    //   { id: 0, text: 'Onscene/Completed' },
    //   { id: 5, text: 'Onway/Onscene/Completed/Cancelled' },
    //   { id: 4, text: 'Onscene/Completed/Cancelled' },
    //   { id: 3, text: 'Onway/Onscene/Completed' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      enableCheckAll: false
      // allowSearchFilter: true
    };
  }

  getUser() {
    this.sharedDataService.getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((user) => {

      if (user) {

        this.user = user;
        this.addNewCorporateUser.patchValue({
          company:this.user._id
        })
      }
    });
  }

  get form() {
    return this.addNewCorporateUser.controls;
  }
  fillForm() {
    if (this.corporateUserId) {
      this.addNewCorporateUser.get('password').clearValidators();
      this.getCorporateUserById(this.corporateUserId);
      this.addNewCorporateUser.patchValue({
        company: this.user._id,
      });
      this.btnName = "Update";
    }
    else {
      this.addNewCorporateUser.get('password').setValidators([Validators.required]);
    }

    this.addNewCorporateUser.get('password').updateValueAndValidity();
  }

  getCorporateUserById(id) {
    this.corporateService.getCorporateUserById(id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((data) => {
      if (data) {
        this.addNewCorporateUser.patchValue({
          ...data,
          password: ""
        });

        this.profileImageURL = data.profileImageURL;
        if (data.vehicles && data.vehicles.length) {
          const savedVehicles = data.vehicles;
          this.selectedVehicles = this.vehicles.filter(v => savedVehicles.includes(v.text));
          console.log('selected vehicles: ', this.selectedVehicles);
        }

        if(data.payorTypes && data.payorTypes.length ){
          if (data.payorTypes[0] && data.payorTypes[0].title) {
            this.addNewCorporateUser.patchValue({
              payorTypes: data.payorTypes
            })
          }
          else {
            this.addNewCorporateUser.patchValue({
              payorTypes: []
            });
          }
        }
      }
    });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedFile = result.file;
      this.profileImageURL = result.src;
    });
  }

  getAddress(place) {
    this.addNewCorporateUser.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  }

  togglePasswordType() {
    this.passwordType = this.passwordToggleFlag ? "text" : "password";
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  newCorporateUser() {
    console.log('form: ', this.addNewCorporateUser.value)
    this.submitted = true;
    if (this.addNewCorporateUser.invalid) {
      return;
    } else if (this.selectedFile == null && this.profileImageURL == null) {
      return;
    } else {
      const image = {
        newProfilePicture: this.selectedFile,
      };

      if (this.selectedVehicles && this.selectedVehicles.length) {
        const vehicles = this.selectedVehicles.map(v => v.text);
        this.addNewCorporateUser.patchValue({
          vehicles: vehicles
        });
      } else {
        this.addNewCorporateUser.patchValue({
          vehicles: []
        });
      }
      // console.clear();
      // console.log(this.addNewCorporateUser.value);
      // return;

      if (!this.corporateUserId) {
        this.addNewCorporateUser.patchValue({
          images: image,
        });
        this.saveCorporateUser(this.addNewCorporateUser.value);
        // this.store.dispatch(new AddCorporateUser(this.addNewCorporateUser.value));
      } else {
        // delete this.addNewCorporateUser.value.password;
        this.addNewCorporateUser.patchValue({
          corporateUserId: this.corporateUserId,
          images: image,
        });
        const payload = this.addNewCorporateUser.value;

        if (this.corporateUserId) {
          if (this.addNewCorporateUser.value.password === '') {
            delete payload.password;
          }
          // payload.payorTypes = payload.payorTypes.filter(p => !p._id);
          this.saveCorporateUser(payload);
        }


      }
    }
  }

  saveCorporateUser(payload) {
    this.corporateService.addCorporateUser(payload)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(
      (data) => {
        if (data) {
          this.submitted = false;
          this.router.navigateByUrl('credentialing/corporates');
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "Ok");
      }
    );
  }

  toggleSelfPaid() {
    this.addNewCorporateUser.patchValue({
      selfPay: !this.addNewCorporateUser.value.selfPay
    });
  }

  toggleisOdometer() {
    this.addNewCorporateUser.patchValue({
      isOdometer: !this.addNewCorporateUser.value.isOdometer
    });
  }

  toggleisAllowPickup() {
    this.addNewCorporateUser.patchValue({
      showScheduleTime: !this.addNewCorporateUser.value.showScheduleTime
    });
  }

  toggleShowStatus() {
    this.addNewCorporateUser.patchValue({
      showStatus: !this.addNewCorporateUser.value.showStatus
    });
  }

  getcorporateUsers() {
    this.corporateService
      .getCorporateUsers(this.status)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.length) {
          this.corporateUsers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getOdometerValue() {
    this.odometers = requiredOdometers;
  }


  onSelectVehicle(vehicle: any) {
  }

  onDeSelectVehicle(vehilce: any) {
  }

  onSelectOdometer(vehicle: any) {
  }

  onDeSelectOdometer(vehilce: any) {
  }

  payorTypes() {
    this.isPayorSubmitted = true;
    this.payorTypesFormSubmitted = true;
    if (this.payorTypesForm.invalid) {
      return;
    }
    this.payorTypesForm.patchValue({ title:this.convertToTitleCase(this.payorTypesForm.value.title)  ,value: this.formatNameToKey(this.payorTypesForm.value.title)})
    this.addNewCorporateUser.value.payorTypes.push(this.payorTypesForm.value);
    this.payorTypesForm.reset();
    this.payorTypesFormSubmitted = false;
    this.isPayorSubmitted = false;
  }
   convertToTitleCase(inputString) {
    const words = inputString.split(' ');
  
    const titleCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    return titleCaseWords.join(' ');
  }
  
  formatNameToKey(inputName) {
  const words = inputName.split(' ');
  const formattedName = words.reduce((acc, word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    } else {
      return acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  }, '');

  return formattedName;
  }
  removePayorType(index) {
    const z1 = this.addNewCorporateUser.value.payorTypes;
    z1.splice(index, 1);
    this.addNewCorporateUser.patchValue({
      payorTypes: z1
    });
  }

  get formControl(){
    return this.payorTypesForm.controls;
  }

ngOnDestroy(): void {
}
}
