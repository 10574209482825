import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { AssigTripsService } from '../../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.css']
})
export class SignatureModalComponent implements OnInit, AfterViewInit{
  @Input() id;
  @Output() saveSignature = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  // tslint:disable-next-line: ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 460,
    canvasHeight: 300
  };
  isSubmitted = false;
  isEmpty = false;

  constructor(
    private assignTripService: AssigTripsService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.signaturePad) {
      this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }

  save() {
    this.isSubmitted = true;
    this.isEmpty = false;
    if (this.signaturePad.isEmpty()) {
      this.isEmpty = true;
      return;
    }
    this.saveSignature.emit(this.signaturePad.toDataURL('image/png'));
  }

  clearSignature() {
    this.signaturePad.clear();
  }
}
