import { Component, OnInit } from '@angular/core';
import { Cms1500Service } from 'src/app/service/cms-service/cms1500.service';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-list-cms-codes',
  templateUrl: './list-cms-codes.component.html',
  styleUrls: ['./list-cms-codes.component.css']
})
export class ListCmsCodesComponent implements OnInit {
  cmsCodes = [];
  search ;

  constructor(private cms1500Service:Cms1500Service) { }

  ngOnInit() {
    this.getCodes();
  }

  getCodes(){
    this.cms1500Service.getCmsCodes().subscribe(data=>{
      if(data){
        this.cmsCodes = data;
        // console.log('cmsCodes', this.cmsCodes)
      }
    })
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }



}
