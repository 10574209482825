import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../../assigntrips-service/assigntrips-service';
import { getBrokersList, getstandingOrderDaysAsObject } from 'src/app/utils/utils.common';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-standingorder-detail-modal',
  templateUrl: './standingorder-detail-modal.component.html',
  styleUrls: ['./standingorder-detail-modal.component.css']
})
export class StandingorderDetailModalComponent implements OnInit, OnDestroy {
  @Input('trip') assign: any;
  @Output() onClose = new EventEmitter<any>();
  standingOrderDays = getstandingOrderDaysAsObject();
  tripsList = [];
  status = 'active';
  search: any;
  todayDate;
  timeZone;
  corporateUsers = [];
  user;

  constructor( private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getTrips();
        }
      });
  }

  getTrips() {
    this.assignTripService.getAssignManifestList({
      status: 'associatestandingorder',
      so: this.assign._id,
      date: moment.tz(this.timeZone).startOf('day').toISOString(),
    })
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripsList = data;
          this.tripsList.forEach(s => {
            s.diffFromToday = 10;
            if (s.recurringEndDate) {
              const end = moment(s.recurringEndDate, 'YYYY-MM-DD').tz(this.timeZone);
              const now = moment(this.todayDate).tz(this.timeZone);
              s.diffFromToday = end.diff(now, 'days');
            }
          });
          this.tripsList.sort((a, b) => a.diffFromToday - b.diffFromToday);
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }
  // editTrip(assign) {
  //   if (this.cloneTripModalObj) return;
  //   this.editTripModalObj = assign._id;
  //   this.trip = assign;

  // }

  // onEditTrip(event) {
  //   this.editTripModalObj = null;
  //   this.trip = null;
  //   this.getTrips();
  // }

  getCorporateByID(id) {
    return this.corporateUsers[id];
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }
  close() {
    this.onClose.emit(null);
  }

  ngOnDestroy(): void {
  }
}
