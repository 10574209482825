import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-load-wrapper',
  templateUrl: './driver-load-wrapper.component.html',
  styleUrls: ['./driver-load-wrapper.component.css']
})
export class DriverLoadWrapperComponent implements OnInit {
  isDriverLoadV1 = false;
  subMenu = 'V1';
  constructor() { }

  ngOnInit() {
  }

  onSelectSubMenu(menu) {
    this.subMenu = menu;
    if (this.subMenu === 'V2') {
      this.isDriverLoadV1 = false;
    }
  }

}
