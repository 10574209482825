import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  constructor() { }

  async getAllRoutes(studentAddresses, payload, isUp?) {
console.log({studentAddresses, payload, isUp})

    // check all trips A legs
    const aLegTrips = [];
    const bLegTrips = [];
    const tripIdentitiesProcessed = [];

    const sortedTrips = studentAddresses.sort((a, b) => moment(a.scheduleTime).diff(moment(b.scheduleTime)));

    if (isUp != null) {
      if (isUp) {
        sortedTrips.forEach(trip => {
          aLegTrips.push(trip);
        })
      } else {
        sortedTrips.forEach(trip => {
          bLegTrips.push(trip);
        })
      }

    } else {
      sortedTrips.forEach(trip => {
        const tripIdentity = trip.tripIdentity;

        if (!tripIdentity || !tripIdentitiesProcessed.includes(tripIdentity)) {
          console.log(trip.tripIdentity, "A Leg ", trip.tripId, !tripIdentity, !tripIdentitiesProcessed.includes(tripIdentity)
          )

          aLegTrips.push(trip);
          tripIdentitiesProcessed.push(tripIdentity);
        } else {
          console.log("B Leg ", trip.tripId)
          bLegTrips.push(trip);
        }
      });
    }
    console.log("A Leg Trips:", aLegTrips);
    console.log("B Leg Trips:", bLegTrips);

    const aRoutes = await this.initialRoute(payload, true, aLegTrips)
    const bRoutes = await this.initialRoute(payload, false, bLegTrips)

    return { aRoutes, bRoutes };

  }

  async initialRoute(preData, isUp, studentAddresses) {
    const unvisited = [...studentAddresses];
    const allRoutes = [];
    let numberOfRoutes;
    if (typeof preData.routes === "string") {
      numberOfRoutes = Number(preData.routes);
    } else {
      numberOfRoutes = preData.routes;
    }
    while (unvisited.length > 0 && (allRoutes.length < numberOfRoutes)) {
      const groupId = await this.generateRandomString(8);
      const route = [];
      let currentLocation;
      if (isUp) {
        currentLocation = { latitude: preData.upLatitude, longitude: preData.upLongitude };
      } else {
        currentLocation = { latitude: preData.downLatitude, longitude: preData.downLongitude };
      }
      let remainingSeats;
      if (typeof preData.seats === "string") {
        remainingSeats = Number(preData.seats);
      } else {
        remainingSeats = preData.seats;
      }
      while (remainingSeats > 0 && unvisited.length > 0) {
        let nearestStudent = null;
        let minDistance = Infinity;

        for (let i = 0; i < unvisited.length; i++) {
          // const student = unvisited[i];
          const student = { latitude: unvisited[i].isUp ? unvisited[i].jobOriginLatitude : unvisited[i].jobDestinationLatitude, longitude: unvisited[i].isUp ? unvisited[i].jobOriginLongitude : unvisited[i].jobDestinationLongitude };
          const distance = await this.calculateDistance(currentLocation, student);
          if (distance < minDistance) {
            minDistance = distance;
            nearestStudent = unvisited[i];
          }
        }

        if (nearestStudent) {
          const priority = route.length + 1;
          // If there is a nearest student within remaining seats, add them to the route
          route.push({ ...nearestStudent, seatsAvailable: remainingSeats - 1, isUp, groupId, priority, driver: preData.driver });
          currentLocation = { latitude: nearestStudent.isUp ? nearestStudent.jobOriginLatitude : nearestStudent.jobDestinationLatitude, longitude: nearestStudent.isUp ? nearestStudent.jobOriginLongitude : nearestStudent.jobDestinationLongitude };
          remainingSeats -= 1;

          // check index of trip
          const indexOfnest = unvisited.findIndex(t => t._id === nearestStudent._id)
          unvisited.splice(indexOfnest, 1);
        } else {
          // No suitable student found within remaining seats, end the route
          console.log("No suitable student found within remaining seats, end the route")
          break;
        }
      }

      allRoutes.push(route);
    }

    return allRoutes;
  }

  calculateDistance(coord1, coord2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const lat1Rad = (coord1.latitude * Math.PI) / 180;
    const lon1Rad = (coord1.longitude * Math.PI) / 180;
    const lat2Rad = (coord2.latitude * Math.PI) / 180;
    const lon2Rad = (coord2.longitude * Math.PI) / 180;

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers
    return distance;
  }

  generateRandomString(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

}
