import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { errorHandl } from '../../utils/network-utils';
import { urls } from '../../utils/url-utils';
import { PDFDocument } from 'pdf-lib';
import { getImageAsEmbedNew, removeNonAllowedCharactersFromPDF, urlToArrayBuffer } from '../../utils/utils.common';
import * as moment from 'moment';
import { loadStaticMap } from 'src/app/utils/utils.common';

@Injectable({
  providedIn: 'root'
})
export class DriverGrievanceService {

  constructor(private http: HttpClient) { }

  getDriverGrievance(): Observable<any> {
    let params = new HttpParams();
    params = params.append('type', 'grievance');
    params = params.append('type', 'itemlost');
    return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_GRIEVANCE, { params })

  }


  getDriverGrievanceById(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_GRIEVANCE + '/' + id)
  }


  async getDriverGrivenceReport(payload, user, loader) {
    console.log('payload', payload)

    const response = await fetch('assets/documents/driver-grievance-report.pdf');
    let pdfBuffer = await response.arrayBuffer();

    pdfBuffer = await this.fillPDF(pdfBuffer, payload, user);

    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // payload.grievance.type == 'grievance' ? 'DRIVER GRIEVANCE REPORT' : 'DRIVER ITEM LOST REPORT'
      if(payload.grievance.type == 'grievance'){
        a.download = `driver-grievance-${payload.grievance._id.slice(0, 6)}.pdf`
        loader.isloading = false;
      }
      if(payload.grievance.type == 'itemlost'){
        a.download = `itemlost-${payload.grievance._id.slice(0, 6)}.pdf`
        loader.isloading = false;
      }
      
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }


  async fillPDF(pdfBuffer: ArrayBuffer, payload, user): Promise<ArrayBuffer> {
    let jobAcceptedUrl;
    let jobOnSceneUrl;
    let jobStartedUrl;
    let jobEndedUrl;

    jobAcceptedUrl = loadStaticMap(payload.job.trackInfoJob.jobAccepted.coords[1],
      payload.job.trackInfoJob.jobAccepted.coords[0]);

      console.log('jobAcceptedUrl', jobAcceptedUrl)

    jobOnSceneUrl = loadStaticMap(payload.job.trackInfoJob.jobArrived.coords[1],
      payload.job.trackInfoJob.jobArrived.coords[0]);

    jobStartedUrl = loadStaticMap(payload.job.trackInfoJob.jobStarted.coords[1],
      payload.job.trackInfoJob.jobStarted.coords[0]);

    jobEndedUrl = loadStaticMap(payload.job.trackInfoJob.jobCompleted.coords[1],
      payload.job.trackInfoJob.jobCompleted.coords[0]);

    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();

    const profileLogo: any = await urlToArrayBuffer(user.profileImageURL);
    const companyLogo = await getImageAsEmbedNew(pdfDoc, user.profileImageURL, profileLogo);

    const signature: any = await urlToArrayBuffer(payload.driver.driverSignatureImage);
    const driverSignature = await getImageAsEmbedNew(pdfDoc, payload.driver.driverSignatureImage, signature);

    // const jobAcceptedUrlTemp: any = await urlToArrayBuffer(jobAcceptedUrl);
    // const jobAcceptedImage = await this.getImageAsEmbedNew(pdfDoc, jobAcceptedUrl, jobAcceptedUrlTemp);

    //Reporting Details
    form.getTextField('company_name').setText(user.displayName);
    form.getTextField('report_name').setText(payload.grievance.type == 'grievance' ? 'DRIVER GRIEVANCE REPORT' : 'DRIVER ITEM LOST REPORT');
   if(companyLogo)
    form.getTextField('company_logo').setImage(companyLogo);
    form.getTextField('dos').setText(moment(payload.job.scheduleTime).format('MM/DD/YY HH:mm') || ''.toString());
    form.getTextField('reporting_time').setText(moment(payload.grievance.updatedAt).format('MM/DD/YY HH:mm') || ''.toString())
    form.getTextField('complaint_id').setText(payload.grievance._id);
    form.getTextField('reported_driver').setText(payload.driver.displayName.toString());

    if (payload.grievance.incidentAddress) {
      form.getTextField('incident_address').setText(payload.grievance.incidentAddress.toString());
    }

    // // // //Member
    form.getTextField('member_name').setText(payload.assign.priorityClient.displayName.toString());
    form.getTextField('member_cellphone').setText(payload.assign.priorityClient.cellPhone.toString());
    form.getTextField('member_contactNo').setText(payload.assign.priorityClient.contactNumber.toString());
    form.getTextField('client_id').setText(payload.assign.priorityClient.clientId.toString());
    if (payload.assign.weight) {
      form.getTextField('member_weight').setText(payload.assign.weight.toString());
    }
    form.getTextField('member_gender').setText(payload.assign.gender);
    form.getTextField('insurance_id').setText(payload.assign.memberId.toString());
    form.getTextField('member_dob').setText(payload.assign.dob.toString());
    form.getTextField('member_escort').setText(payload.job.escortName ? payload.job.escortName.toString() : '');
    // // // //Trip Infomation
    form.getTextField('pu_address').setText(payload.job.jobOriginAddress.toString());
    form.getTextField('do_address').setText(payload.job.jobDestinationAddress.toString());
    form.getTextField('trip_status').setText(payload.job.jobStatus.toString());
    form.getTextField('trip_notes').setText(payload.job.noteInfoJob.companyNote.slice(0, 50));
    form.getTextField('trip_miles').setText(payload.job.milage.toString());
    form.getTextField('pu_time').setText(moment(payload.job.scheduleTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('do_time').setText(moment(payload.job.appointmentTime).format('MM/DD/YY HH:mm') || '');
    form.getTextField('act_pu_time').setText(moment(payload.job.trackInfoJob.jobArrived.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('act_do_time').setText(moment(payload.job.trackInfoJob.jobFinished.time).format('MM/DD/YY HH:mm') || '');
    // // form.getTextField('cancel_reason').setText(payload.changeReason.toString());
    if(payload.assign.payor)
    form.getTextField('broker_name').setText(payload.assign.payor.toString());
  
    form.getTextField('trip_id').setText(payload.assign.tripId.toString());
    form.getTextField('los').setText(payload.job.tripRequirement.toString());
    // // // // Vin missing
    // // form.getTextField('vin').setText("HardCoded" || '0');

    // // // //timestamps

    // timeStamp Images
    // form.getTextField('accepted_time_stamp').setImage(jobAcceptedImage);

    form.getTextField('accepted_time').setText(payload.job.trackInfoJob.jobAccepted.coords[0] === 0 ? '' : moment(payload.job.trackInfoJob.jobAccepted.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('onscene_time').setText(payload.job.trackInfoJob.jobArrived.coords[0] === 0 ? '' : moment(payload.job.trackInfoJob.jobArrived.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('started_time').setText(payload.job.trackInfoJob.jobStarted.coords[0] === 0 ? '' : moment(payload.job.trackInfoJob.jobStarted.time).format('MM/DD/YY HH:mm') || '');
    form.getTextField('ended_time').setText(payload.job.trackInfoJob.jobFinished.coords[0] === 0 ? '' : moment(payload.job.trackInfoJob.jobFinished.time).format('MM/DD/YY HH:mm') || '');
    
    form.getTextField('accepted_coordinate').setText(`${payload.job.trackInfoJob.jobAccepted.coords[0] === 0 ? '' : payload.job.trackInfoJob.jobAccepted.coords[0] + ' - ' + payload.job.trackInfoJob.jobAccepted.coords[1]}`);
    form.getTextField('onscene_coordinate').setText(`${payload.job.trackInfoJob.jobArrived.coords[0] === 0 ? '' : payload.job.trackInfoJob.jobArrived.coords[0] +' - '+ payload.job.trackInfoJob.jobArrived.coords[1]}`);
    form.getTextField('started_coordinate').setText(`${payload.job.trackInfoJob.jobStarted.coords[0] === 0 ? '' : payload.job.trackInfoJob.jobStarted.coords[0] +' - '+ payload.job.trackInfoJob.jobStarted.coords[1]} `);
    form.getTextField('ended_coordinate').setText(`${payload.job.trackInfoJob.jobFinished.coords[0] === 0 ? '' : payload.job.trackInfoJob.jobFinished.coords[0] + ' - '+ payload.job.trackInfoJob.jobFinished.coords[1]}`);
    form.getTextField('driver_grievance').setText(payload.grievance.comments.toString());
    if (driverSignature) {
      form.getTextField('driver_signature').setImage(driverSignature);
    }


    // form.getTextField('provider_company').setText('test');
// remove non ASCII
await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;


  }





}
