import { Component, OnInit, OnDestroy } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { DispatchCenterService } from 'src/app/component/dispatch-center/dispatch-center-service/dispatch-center-service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.css']
})
export class NotificationDetailsComponent implements OnInit, OnDestroy {
  search = '';
  notifications = [];
  constructor(
    private dispatchCenterService: DispatchCenterService
  ) {
  }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.dispatchCenterService
      .getNotifications()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.notifications = data;
        }
      }, err => {
        // sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  ngOnDestroy(): void {
  }
}
