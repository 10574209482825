import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { sweetAlert } from "../../../utils/swal";
import {
  downloadFile,
  downloadFileAsBlob,
  getArrayBuffer,
} from "src/app/utils/pdf-downloader";
import * as moment from "moment-timezone";
import { ActivatedRoute, Router } from "@angular/router";
import { urls } from "src/app/utils/url-utils";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import * as _ from "underscore";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { AssigTripsService } from "../assigntrips-service/assigntrips-service";
import { DispatchCenterService } from "../../dispatch-center/dispatch-center-service/dispatch-center-service";
import { FleetService } from "../../fleet/fleet-service/fleet.service";
import {
  PAYOR,
  exportToCsv,
  getBillingBrokers,
  getBrokersList,
  urlToBase64,
} from "src/app/utils/utils.common";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { generatePdf, getCompanyLogo } from "src/app/utils/utils.common";
import { cmsReport } from "../../reports/pdf-reports/cms-1500-report";
import { Cms1500Service } from "src/app/service/cms-service/cms1500.service";
import { PDFDocument } from "pdf-lib";
import { BeachwoodReportService } from "src/app/service/beachwood-service/beachwood-report.service";
import { isArray } from "util";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CatService } from "src/app/service/capital-area-transport/cat.service";
import { FilterPipe } from "src/app/pipe/list-filter-pipe/list-filter-pipe";
import { FilterByKeyPipe } from "src/app/pipe/list-filterbykey-pipe/list-filterbykey-pipe";
import { DailyTripLogService } from "src/app/service/driver-trip-log/daily-trip-log.service";
import { MemberLogService } from "src/app/service/member-log-service/member-log.service";
import { ModivcareReportService } from "src/app/service/modivcare-report-service/modivcare-report.service";
import { environment } from "src/environments/environment";
import { CsvService } from "src/app/service/csv-service/csv.service";
import { ReportService } from "../../reports/reports-service/report.service";
@Component({
  selector: "app-verified-trip-logs",
  templateUrl: "./verified-trip-logs.component.html",
  styleUrls: ["./verified-trip-logs.component.css"],
})
export class VerifiedTripLogsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  // tslint:disable-next-line: ban-types
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 750,
    canvasHeight: 300,
  };

  payorList = PAYOR;

  status;
  user;
  timeZone;
  searchDate;
  dateRange;
  search = "";
  dataList = [];
  filteredAdjustedTrips = [];
  selectedTrips = [];
  selectedTripJobIds = [];
  brokers;
  selectedBroker = "";
  selectedBrokerLogo;

  drivers = [];
  members = [];
  selectedDriver = "";
  selectedMember = "";
  corporateUsers;
  selectedCorporateUser = "";
  selectedCorporatePayor = "";
  isMedical = false;

  projectedOdometer = "";
  tempVin;
  odometerBegin;
  odometerStart;
  odometerStop;

  isOdometerRequest = false;
  isAllTripsSelected = false;
  brockerType = "";

  popupAssignTrip: any;
  bankedType;
  alertMessage = "";
  corporatePayors;

  jobType = "assigned";
  type = null;
  tempCompanyImage =
    "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png";
  companyImage = this.tempCompanyImage;
  fleet;
  isShowFilter = false;
  odomterModalObj;
  signatureModalObj;
  cmsReport: any;
  isShowCms1500 = false;
  isTripLogFile = false;
  isMemberFile = false;
  isAHCCCSFile = false;
  isMedicaidReport = false;
  isBeachwoodFile = false;
  isModiveCarev2File = false;
  isMemberTripsLogDownloading = false;
  // isBeachwoodModal = false
  isBeachwoodFacilityModal = false;
  beachWoodForm: FormGroup;
  beachWoodFacilityForm: FormGroup;
  beachWoodReportData;
  beachWoodFacilityData;
  corporatesByKey;

  totalMiles = 0;
  isProduction = environment.production;


  aeroModal = {
    isLoading: false,
    isModal: false,
  };
  loader = {
    isLoading: false,
    isBeachwoodModal: false,
  };

  bwfLoader = {
    isLoading: false,
    isbwfModal: false,
  };
  driverLoader = {
    isLoading: false,
  };
  memberLoader = {
    isLoading: false,
  };
  coReportLoader = {
    isLoading : false
  };

  constructor(
    private  memberLogService: MemberLogService,
    private catService: CatService,
    private driverLogService: DailyTripLogService,
    private cms1500Service: Cms1500Service,
    private assignTripService: AssigTripsService,
    private fleetService: FleetService,
    private sharedDataService: SharedDataService,
    private modivcareService: ModivcareReportService,
    private csvService: CsvService,
    private router: Router,
    private reportService : ReportService
  ) {}

  ngOnInit() {
    this.status = "adjustedtriplogs";
    this.brokers = getBillingBrokers;
    this.getUser();
    this.getCorporateUsers();
    // this.getCmsReport(trip);
  }

  ngAfterViewInit() {
    if (this.signaturePad) {
      // this.signaturePad is now available
      this.signaturePad.set("minWidth", 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((user) => {
        if (user) {
          this.timeZone = user.timeZone;
          this.user = user;
          // this.searchDate = moment().startOf('day').toISOString();
          this.searchDate = moment
            .tz(this.timeZone)
            .startOf("day")
            .toISOString();
          this.dateRange = [
            moment.tz(this.timeZone).startOf("day").toISOString(),
            moment.tz(this.timeZone).endOf("day").toISOString(),
          ];
          this.getAdjustedTrips();
        }

        if (this.user.hasOwnProperty("isShowCMS1500")) {
          this.isShowCms1500 = this.user.isShowCMS1500;
        }
      });
  }

  getCorporateUsers() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.corporateUsers = data;
          this.corporatesByKey = data.reduce(
            (obj, item) => ((obj[item._id] = item), obj),
            {}
          );
        }
      });
  }

  getCorporateByID(id) {
    return this.corporatesByKey[id];
  }

  getAdjustedTrips() {
    let queryParams = {
      status: this.status,
      scheduleTime: this.searchDate,
      // scheduleTime: '2023-04-04T04:00:00.000Z',
      jobType: this.jobType,
    };

    if (this.selectedBroker) {
      queryParams["companyType"] = this.selectedBroker;
    }

    if (this.isMedical) {
      queryParams["isMedical"] = this.isMedical;
    }

    if (this.selectedCorporateUser) {
      queryParams["cooperate"] = this.selectedCorporateUser;
    }

    if (
      this.jobType === "cooperate" &&
      this.dateRange &&
      this.dateRange.length
    ) {
      queryParams["scheduleTime"] = this.dateRange[0];
      queryParams["endDate"] = this.dateRange[1];
    }

    // if (this.jobType === 'cooperate') {
    //   delete queryParams.scheduleTime;
    // }

    this.members = [];
    this.drivers = [];
    this.selectedTrips = [];
    this.filteredAdjustedTrips = [];
    this.dataList = [];

    this.search = "";
    this.selectedMember = "";
    this.selectedCorporatePayor = "";
    this.selectedDriver = "";

    this.assignTripService
      .getAdjustedTrips(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.dataList = data;
          this.filteredAdjustedTrips = data;

          this.dataList.forEach((trip, i) => {
            const isFound = this.drivers.some(
              (driver) => driver._id === trip.driver._id
            );
            if (!isFound) {
              this.drivers.push(trip.driver);
            }
            this.members.push(trip.priorityClient);
          });

          this.members = _.uniq(this.members, (obj) => obj.displayName);

          this.filterTripsByModel();
        }
      });
  }

  onSearchDateChange(event) {
    this.searchDate = event.toISOString();
    this.getAdjustedTrips();
  }

  onDateRangeChange(event) {
    if (event && event.length) {
      this.dateRange = [
        event[0].toISOString(),
        event[1].endOf("day").toISOString(),
      ];
    }

    this.getAdjustedTrips();
  }

  onSelectBroker() {
    this.jobType = "assigned";
    this.selectedCorporateUser = "";
    this.selectedCorporatePayor = "";
    this.selectedDriver = "";

    if (!this.selectedBroker) {
      this.companyImage = this.tempCompanyImage;
      this.getAdjustedTrips();
      return false;
    }

    const broker = this.brokers.find((b) => b.name === this.selectedBroker);
    this.companyImage = broker ? broker.logoUrl : "";
    this.getAdjustedTrips();
  }

  onSelectCorporateUser() {
    this.corporatePayors = null;
    this.selectedCorporatePayor = "";
    if (this.selectedCorporateUser) {
      this.jobType = "cooperate";
      this.isMedical = false;
      this.selectedBroker = "";
      const cUser = this.corporateUsers.find(
        (user) => user._id === this.selectedCorporateUser
      );
      if (cUser) {
        this.companyImage = cUser.profileImageURL;

        if (cUser.payorTypes) {
          if (cUser.payorTypes[0] !== null) {
            this.corporatePayors = cUser.payorTypes;
          }
        }
      }
      this.getAdjustedTrips();
    } else {
      this.selectedCorporateUser = "";
      this.jobType = "assigned";
      this.companyImage = this.tempCompanyImage;
      this.getAdjustedTrips();
    }
  }

  onSelectDriver() {
    this.filterTripsByModel();
    if (this.filteredAdjustedTrips.length) {
      const trip = this.filteredAdjustedTrips[0];
      if (trip && trip.job && trip.job.fleet) {
        const fleetId = trip.job.fleet;
        this.projectedOdometer = null;
        this.fleetService
          .getFleetDataById(fleetId)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            if (data) {
              this.fleet = data;
              this.projectedOdometer = this.fleet[this.selectedBroker];
            }
          });
      }
    }
  }

  onChangeMedicalType(evt) {
    this.isMedical = evt.target.checked;
    if (!this.isMedical) {
      this.selectedBroker = "";
      this.companyImage = this.user.profileImageURL;
    }
    this.getAdjustedTrips();
  }

  // Trip Selection Starts
  selectTrips(assign) {
    const index = this.selectedTrips.indexOf(assign._id);
    if (index > -1) {
      assign.isSelected = false;
      this.selectedTrips.splice(index, 1);
    } else {
      assign.isSelected = true;
      this.selectedTrips.push(assign._id);
    }
  }

  selectAll() {
    // if (this.selectedTrips.length === this.filteredAdjustedTrips.length) {
    //   this.unSelectAllTrips();
    // } else {
    //   this.selectedTrips = [];
    //   this.filteredAdjustedTrips.forEach((assign) => {
    //     assign.isSelected = true;
    //     this.selectedTrips.push(assign._id);
    //   });
    // }
    this.filteredAdjustedTrips.forEach((assign) => {
      assign.isSelected = true;
      if (!this.selectedTrips.includes(assign._id))
        this.selectedTrips.push(assign._id);
    });

    this.isAllTripsSelected =
      this.selectedTrips.length === this.filteredAdjustedTrips.length;
    return this.isAllTripsSelected;
  }

  unSelectAllTrips() {
    const unselectedTrips = [];
    this.filteredAdjustedTrips.forEach((trip) => {
      trip.isSelected = false;
      unselectedTrips.push(trip._id);
    });
    this.selectedTrips = this.selectedTrips.filter(
      (x) => !unselectedTrips.includes(x)
    );
    this.isAllTripsSelected = false;
  }

  filterTripsByModel() {
    this.filteredAdjustedTrips = this.dataList;

    this.filteredAdjustedTrips = new FilterPipe().transform(
      this.filteredAdjustedTrips,
      this.search
    );

    this.filteredAdjustedTrips = new FilterByKeyPipe().transform(
      this.filteredAdjustedTrips,
      "assign.priorityClient.displayName",
      this.selectedMember
    );

    // this.filteredAdjustedTrips = new FilterByKeyPipe().transform(
    //   this.filteredAdjustedTrips,
    //   "assign.companyType",
    //   this.selectedBroker
    // );

    this.filteredAdjustedTrips = new FilterByKeyPipe().transform(
      this.filteredAdjustedTrips,
      "assign.payor",
      this.selectedCorporatePayor
    );

    this.filteredAdjustedTrips = new FilterByKeyPipe().transform(
      this.filteredAdjustedTrips,
      "assign.driver._id",
      this.selectedDriver
    );
  }

  getSelectedTrips() {
    return this.dataList.filter((trip) => trip.isSelected);
  }

  adjustOdometer() {
    const payload = {
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
      driver: this.selectedDriver,
      fleet: this.fleet._id,
      projectedOdometer: this.projectedOdometer,
      companyType: this.selectedBroker,
    };
    this.assignTripService
      .adjustTripOdometer(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

  // OK
  nextOdometer() {
    const payload = {
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
      driver: this.selectedDriver,
      fleet: this.fleet._id,
      projectedOdometer: this.projectedOdometer,
      companyType: this.selectedBroker,
    };

    this.assignTripService
      .adjustAllTripsOdometer(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

  // OK
  billedtomodivcare() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .billedToModivcare(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.dataList.forEach((assign) => {
            const assignIndex = this.selectedTrips.indexOf(
              assign._id.toString()
            );
            if (assignIndex > -1) {
              assign.bankStatus = "banked";
              assign.isSelected = false;
              this.selectedTrips.splice(assignIndex, 1);
            }
          });
        }
      });
  }

  // OK
  callthecarInvoice() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };
    this.assignTripService
      .donwloadCallTheCarInvoice(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + "excel/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  manifest() {
    // United (Southeastrans)
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadSoutheastransManifest(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  manifestLogistic() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadLogisticareManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  manifestLcp() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadLcpManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  manifestAmera() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadAmeraManifest(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  corporateInvoiceReport() {
    let payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    if (this.dateRange && this.dateRange.length) {
      payload["startDate"] = this.dateRange[0];
      payload["endDate"] = this.dateRange[1];
    }

    this.assignTripService
      .donwloadCorporateInvoiceReport(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  corporateInvoice() {
    // Corporate TRF
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadCorporateInvoice(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  nmnDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadMedtransDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  mtmTRF() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadMtmTrf(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  mtmClaim() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadMtmClaim(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + "excel/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  mtmDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  mtmAllDriverLogs() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadAllDriverLog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  vapremiumDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .vapremiumDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  access2CareDriverLog() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .access2CareDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  veyoDriverLog() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .veyoDriverLog(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  tncarrierClaim() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .donwloadTNCClaim(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + "excel/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  async tripsLog(fileName?) {
    this.isTripLogFile = true;
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .tripsLog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(async (data) => {
        if (data) {
          this.isTripLogFile = false;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + data.pdfName;
          downloadFileAsBlob(url, fileName ? fileName : data.pdfName);
        }
      });
  }

  async memberTripsLog(fileName?) {
    this.isMemberTripsLogDownloading = true;
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .memberTripsLog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(async (data) => {
        if (data) {
          this.isMemberTripsLogDownloading = false;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + data.pdfName;
          downloadFileAsBlob(url, fileName ? fileName : data.pdfName);
        }
      });
  }

  // OK
  logistiCareTriplogs() {
    const payload = {
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .logistiCareTriplogs(this.selectedDriver, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // OK
  signatureUpdate(isSignature) {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
      isSignature: isSignature,
    };

    this.assignTripService
      .signatureUpdate(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Signature Updated Successfully', 'success', 'OK')
          this.getAdjustedTrips();
        }
      });
  }

  // OK
  bankedTrips() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
      bankStatus: "banked",
    };

    this.assignTripService
      .bankedTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Banked the Trip Successfully', 'success', 'OK')
          this.getAdjustedTrips();
        }
      });
  }

  // OK
  claimFile(isPuDo) {
    const payload = {
      isPuDo: isPuDo,
      tempVin: this.tempVin,
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .claimFile(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.excelName;
          let url = urls.REPORT_SERVER_URL + "excel/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  // Adjust Odometer
  showOdometerModal(trip) {
    this.odomterModalObj = trip;
  }

  onSaveOdometer(evt) {
    this.dataList.forEach((item, i) => {
      if (item.job._id === evt._id) {
        this.dataList[i].job.odometerBegin = evt.odometerBegin;
        this.dataList[i].job.odometerStart = evt.odometerStart;
        this.dataList[i].job.odometerStop = evt.odometerStop;
      }
    });
    this.odomterModalObj = null;
  }

  // Signature
  showSignatureModal(trip) {
    this.signatureModalObj = trip;
  }

  onSaveSignature(data) {
    if (data) {
      this.signatureModalObj.job.signatureImage = data + "?" + Math.random();
      // sweetAlert('Success', 'Signature Generated', 'success', 'OK');
      const payload = {
        isEmpty: false,
        dataUrl: data,
      };
      this.assignTripService
        .generateSignature(payload, this.signatureModalObj._id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
          if (data) {
            this.signatureModalObj = null;
          }
        });
    }
  }

  cameraTripsLog() {
    this.isMemberFile = true;
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .cameraTripslog(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.isMemberFile = false;
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "api/download/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  corporateAeroInvoice() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };

    this.assignTripService
      .corporateAeroInvoice(this.selectedCorporateUser, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  rnsCorporateInvoice1Report() {
    const payload = {
      type: this.status,
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
    };
    this.cms1500Service.rnsCorporateInvoice1Report(
      this.selectedCorporateUser,
      payload
    );
    // this.assignTripService
    //   .rnsCorporateInvoice1Report(this.selectedCorporateUser, payload)
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe(data => {
    //     if (data) {
    //       let name = data.pdfName;
    //       let url = urls.REPORT_SERVER_URL + '/pdf/' + name;
    //       downloadFile(url, '', name);
    //     }
    //   });
  }

  getBroker(key) {
    return getBrokersList[key];
  }

  //Old
  tripsClaimFilesDownload() {
    const object = {
      _ids: this.selectedTrips,
      companyImage:
        "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png",
      scheduleTime: this.searchDate,
    };

    this.assignTripService
      .getAssignTripLogsFile(object, "claimfile")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          //    Download File From Here
          let url = urls.REPORT_SERVER_URL.toString();
          let pdfName;
          let fileExtention;
          if (data.pdfName) {
            fileExtention = "pdf";
            url = url + "pdf/" + data.pdfName;
            pdfName = data.pdfName;
          } else {
            fileExtention = "xlsx";
            url = url + "excel/" + data.excelName;
            pdfName = data.excelName;
          }

          downloadFile(url, fileExtention, pdfName);
          // sweetAlert('Success', data.message, 'success', 'OK');
        }
      });
  }

  tripsLogFilesDownload() {
    const object = {
      _ids: this.selectedTrips,
      companyImage:
        "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png",
      type: "adjustedtriplogs",
      scheduleTime: this.searchDate,
    };

    this.assignTripService
      .getAssignTripLogsFile(object, "tripslog")
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          //    Download File From Here
          let url = urls.REPORT_SERVER_URL.toString();
          let pdfName;
          let fileExtention;
          if (data.pdfName) {
            fileExtention = "pdf";
            url = url + "pdf/" + data.pdfName;
            pdfName = data.pdfName;
          } else {
            fileExtention = "xlsx";
            url = url + "excel/" + data.excelName;
            pdfName = data.excelName;
          }

          downloadFile(url, fileExtention, pdfName);
          // sweetAlert('Success', data.message, 'success', 'OK');
        }
      });
  }

  // =============================

  // Banked
  bankedSingleTrip(assign, status) {
    this.popupAssignTrip = JSON.parse(JSON.stringify(assign));
    this.bankedType = status;
    // tslint:disable-next-line: max-line-length
    this.alertMessage =
      assign.bankStatus === "banked"
        ? "Are you sure you want to appeald against this trip ?"
        : "Are you sure you want to bank this trip ?";
    const object = {
      type: "adjustedtriplogs",
      _ids: [this.popupAssignTrip._id],
      scheduleTime: this.searchDate,
      companyImage: this.companyImage,
      bankStatus: this.bankedType,
      statusType: "single",
    };
    this.assignTripService
      .bankedTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', `Trip ${this.bankedType === 'banked' ? 'Banked': 'Appealed'} Successfully`, 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  bankedSingleTripsModalResponse() {
    const object = {
      type: "adjustedtriplogs",
      _ids: [this.popupAssignTrip._id],
      scheduleTime: this.searchDate,
      companyImage:
        "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png",
      bankStatus: this.bankedType,
      statusType: "single",
    };

    this.assignTripService
      .bankedTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Trips Banked', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  bankedMultipalTrips() {
    this.alertMessage = "Are you sure you want to bank these trips ?";
  }

  bankedMultipalTripsModalResponse() {
    const object = {
      type: "adjustedtriplogs",
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage:
        "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png",
      bankStatus: "banked",
    };

    this.assignTripService
      .bankedTrips(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Trips Banked', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  // Signature
  updateTripsSignature(b) {
    const object = {
      type: "adjustedtriplogs",
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage:
        "https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png",
      isSignature: JSON.parse(b),
    };
    this.assignTripService
      .updateAllTripsSignature(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Signatures Updated', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  updateTripSignature(assign) {
    this.signaturePad.clear();
    this.popupAssignTrip = JSON.parse(JSON.stringify(assign));
  }

  generateSignature() {
    const object = {
      isEmpty: false,
      dataUrl: this.signaturePad.toDataURL(),
    };
    this.processSignature(object, this.popupAssignTrip._id);
  }

  removeTripSignature() {
    const object = {
      isEmpty: true,
      dataUrl: this.signaturePad.toDataURL(),
    };
    this.processSignature(object, this.popupAssignTrip._id);
  }

  processSignature(obj, id) {
    this.assignTripService
      .generateSignature(obj, id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', 'Signature Generated', 'success', 'OK');
          const splitArray = data.signatureImage.split("/");
          const splitArrayTwo = splitArray[6].split(".");
          this.dataList.filter((item2) => {
            if (item2.job._id === splitArrayTwo[0]) {
              item2.job.signatureImage = data.signatureImage;
            }
          });
        }
      });
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  btnNagtiveResponse() {
    this.popupAssignTrip = JSON.parse(JSON.stringify({}));
    this.alertMessage = "";
  }
  // Methods will stay
  openbtModal() {
    this.loader.isBeachwoodModal = true;
    this.isShowFilter = false;
  }
  openAeroTransModal() {
    this.aeroModal.isModal = true;
    this.isShowFilter = false;
  }

  closeBTModal(evt) {
    this.loader.isBeachwoodModal = evt;
    this.bwfLoader.isbwfModal = evt;
  }

  closeModal(evt) {
    this.aeroModal.isModal = evt;
  }

  openbtFacilityModal() {
    this.bwfLoader.isbwfModal = true;
    this.isShowFilter = false;
  }

  showFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  navigateToAdjustment(id) {
    const queryParams = { isFromBilling: true };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/trips/${id}/adjust`], { queryParams })
    );
    window.open(url, "_blank");
    // this.router.navigate([`/trips/${id}/adjust`], { queryParams } );
  }

  getCmsReport(trip) {
    let queryParams = {
      companyType: trip.companyType,
      tripIdentity: trip.tripIdentity,
      type: "adjustedtriplogs",
    };
    if (trip.cooperate) {
      queryParams["cooperate"] = trip.cooperate._id;
    }
    this.cms1500Service.getCmsReport(queryParams, this.user);
  }

  async medicaidPackage() {
    this.isMedicaidReport = true;
    for (let trip of this.getSelectedTrips()) {
      if (trip) {
        const pdfArray = [];
        // One
        if (trip.attachment) {
          const pdfTwo = await getArrayBuffer(trip.attachment);
          pdfArray.push(pdfTwo);
        }

        // Two
        const pdfOne = await this.cms1500Service.medicaidPackage(
          trip,
          this.user
        );
        pdfArray.push(pdfOne);

        // const data = await this.assignTripService.tripsLog({
        //   type: this.status,
        //   _ids: [tripId],
        //   scheduleTime: this.searchDate,
        //   companyImage: this.companyImage
        // }).toPromise();
        // if (data) {
        //   // Three
        //   let url = urls.REPORT_SERVER_URL + 'api/download/pdf/' + data.pdfName;
        //   const pdfThree = await getArrayBuffer(url);
        //   pdfArray.push(pdfThree);
        // }

        // Merge
        const pdfDoc = await PDFDocument.create();
        for (const pdfBytesData of pdfArray) {
          const sourcePDF = await PDFDocument.load(pdfBytesData);
          const [page] = await pdfDoc.copyPages(sourcePDF, [0]); // Change [0] to select different pages
          pdfDoc.addPage(page);
        }

        // download
        const mergedPdfBytes = await pdfDoc.save();
        if (mergedPdfBytes) {
          const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "medicaid-package-" + trip.tripId + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    }
    this.isMedicaidReport = false;
  }

  async catsInvoice() {
    let payload = {
      totalTrips: 0,
      totalAmount: 0,
      totalMiles: 0,
    };

    for (let trip of this.getSelectedTrips()) {
      if (trip) {
        payload = {
          totalTrips: payload.totalTrips + 1,
          totalAmount: payload.totalAmount + trip.job.jobFareDetail.jobFee,
          totalMiles: payload.totalMiles + trip.milage,
        };
      }
    }

    let date = this.searchDate;

    if (this.jobType === "cooperate") {
      date = this.dateRange;
    }

    await this.cms1500Service.catsInvoice(payload, date, this.user);
  }
  downloadMemberSummary() {
    let sortedTrips = this.getSelectedTrips().sort(
      (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
    );

    let tripsGroupedByDate = {};
    sortedTrips.forEach((trip) => {
      let dateKey = moment(trip.scheduleTime).format("YYYY/MM/DD");

      tripsGroupedByDate[dateKey] = tripsGroupedByDate[dateKey] || {
        trips: [],
        miles: 0,
        days: 0,
      };

      tripsGroupedByDate[dateKey].trips.push(trip);
      tripsGroupedByDate[dateKey].miles += parseFloat(
        trip.milage ? trip.milage : 0
      );
      // if (trip.job && trip.job.jobFareDetail && trip.job.jobFareDetail.jobFee) {
      tripsGroupedByDate[dateKey].jobFee = this.calculateFare(
        parseFloat(trip.milage ? trip.milage : 0),
        tripsGroupedByDate[dateKey].miles,
        tripsGroupedByDate[dateKey].jobFee
      );
      // }
      tripsGroupedByDate[dateKey].days += 1;
    });
    this.catService.getMemberSummary(
      tripsGroupedByDate,
      this.user,
      this.dateRange
    );
  }

  calculateFare(miles, prevMiles, prevTotal) {
    let total = 0;
    if (!prevMiles) {
      if (miles <= 20) {
        total = 50;
      }

      if (miles > 20) {
        let extraMiles = miles - 20;
        total += 50 + extraMiles * 1.5;
      }
    } else {
      if (miles <= 20) {
        total = 50;
      }

      if (miles > 20) {
        let extraMiles = miles - 20;
        total += 50 + extraMiles * 1.5;
      }
    }

    return prevTotal ? prevTotal + total : total;
  }

  tripsSummary() {
    let trips = {};

    for (let trip of this.getSelectedTrips()) {
      if (trips[trip.priorityClient.displayName]) {
        trips[trip.priorityClient.displayName] = {
          miles: trips[trip.priorityClient.displayName].miles + trip.milage,
          legs: trips[trip.priorityClient.displayName].legs + 1,
          total:
            trips[trip.priorityClient.displayName].total +
            trip.job.jobFareDetail.jobFee,
        };
      } else {
        trips[trip.priorityClient.displayName] = {
          miles: trip.milage,
          legs: 1,
          total: trip.job.jobFareDetail.jobFee,
        };
      }
    }

    let date = this.searchDate;

    if (this.jobType === "cooperate") {
      date = this.dateRange;
    }

    const table = [
      [
        (this.user.company
          ? this.user.company.displayName
          : this.user.displayName) || "",
        " ",
        " ",
        " ",
        date && typeof date !== "string"
          ? moment(date[0]).tz(this.user.timeZone).format("MM/DD/YY") +
            " - " +
            moment(date[1]).tz(this.user.timeZone).format("MM/DD/YY")
          : moment(date).tz(this.user.timeZone).format("MM/DD/YY"),
        " ",
      ],
      [" ", " ", " ", " ", " ", " "],
      ["First Name", "Last Name", "Miles", "Legs", "$", "Per Trip"],
    ];

    let miles = 0;
    let total = 0;
    let legs = 0;

    for (let key in trips) {
      const t = [
        key.split(" ")[0],
        key.split(" ")[1] ? key.split(" ")[1] : "",
        trips[key].miles.toFixed(2),
        trips[key].legs,
        "$ " + trips[key].total.toFixed(2),
        "$ " + (trips[key].total / trips[key].legs).toFixed(2),
      ];

      miles = miles + trips[key].miles;
      total = total + trips[key].total;
      legs = legs + trips[key].legs;

      table.push(t);
    }
    table.push([" ", " ", " ", " ", " ", " "]);

    table.push([
      " ",
      " ",
      miles.toFixed(2),
      legs,
      "$ " + total.toFixed(2),
      " ",
    ]);

    exportToCsv(`trips-summary-${moment().format("MM-DD-YYYY")}.csv`, table);
  }

  async ahcccsReport() {
    this.isAHCCCSFile = true;
    let trips = {};

    for (let trip of this.getSelectedTrips()) {
      // if (trips[trip.priorityClient.displayName]) {
      //   trips[trip.priorityClient.displayName].push(trip)
      // } else {
      //   trips[trip.priorityClient.displayName] = [trip]
      // }
      const date = moment(trip.scheduleTime)
        .tz(this.user.timeZone)
        .format("MM-DD-YYYY");
      if (trips[date]) {
        if (trips[date][trip.priorityClient.displayName]) {
          trips[date][trip.priorityClient.displayName].push(trip);
        } else {
          trips[date][trip.priorityClient.displayName] = [trip];
        }
      } else {
        trips[date] = { [trip.priorityClient.displayName]: [trip] };
      }
    }

    for (let myDate in trips) {
      for (let trip in trips[myDate]) {
        await this.cms1500Service.ahcccsReport(
          myDate,
          trips[myDate][trip],
          this.user
        );
      }
    }
    this.isAHCCCSFile = false;
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  downloadDriverLog() {
     let sortedTrips = this.getSelectedTrips();
    //.sort(
    //   (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
    // );

    // console.log();
    this.driverLoader.isLoading = true;
    this.driverLogService.dailyDriverLog(
      sortedTrips,
      this.user,
      this.driverLoader
    );
  }

  downloadMemberLog(){
    const selectedCorporate = this.corporateUsers.find(corporate => corporate._id === this.selectedCorporateUser);
    console.log(selectedCorporate, "download");
    this.memberLoader.isLoading = true;
    let sortedTrips = this.getSelectedTrips().sort(
      (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
    );

    let tripsGroupedByDate = {};
    sortedTrips.forEach((trip) => {
      let dateKey = moment(trip.scheduleTime).format("YYYY/MM/DD");

      tripsGroupedByDate[dateKey] = tripsGroupedByDate[dateKey] || {
        trips: [],
      };
      tripsGroupedByDate[dateKey].trips.push(trip);
    });
    this.memberLogService.dailyMemberLog(
      tripsGroupedByDate,
      this.user,
      this.memberLoader,
      selectedCorporate
    );
  }


 async  modivcareReport() {
    this.isModiveCarev2File = true;
    let sortedTrips = this.getSelectedTrips().sort(
      (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
    );

    if (sortedTrips && sortedTrips.length){
      await this.modivcareService.getModivcareReport(sortedTrips, this.user, sortedTrips[0].driver);
    }
    this.isModiveCarev2File = false;
  }

  isShowSpecificMemberLog(){
    const companyId = this.user.company? this.user.company._id : this.user._id;
    return companyId === '641646ca1466bc7c55472d48';
  }

  downloadCompletedReport() {
    let trips;

    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();


    const data = {
      date:
        moment(this.dateRange[0]).format("MM/DD/YYYY") +
        " - " +
        moment(this.dateRange[1]).format("MM/DD/YYYY"),
      trips,
      // corporate: this.selectedCooperate,
      // driver: this.selectedDriver,
      // vehicle: this.selectedVehicle,
      timezone: this.timeZone,
    };
    // console.log("Trip Data",data);
    // return;
    this.csvService.generateCompletedTripReport(data);
  }

  async resubmissionFormReport(){
    let sortedTrips = this.getSelectedTrips().sort(
      (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
    );

    if (sortedTrips && sortedTrips.length){
      await this.modivcareService.getResubmissionReport(sortedTrips, this.user, sortedTrips[0].driver, this.tempVin);
    }
  }

  prodCheck(){
    if (this.isProduction) {
      const companyId = this.user.company ? this.user.company._id : this.user._id
      if(companyId === '6463be83c60e5d659486581f'){
        return true;
      }else {
        return false;
      }

    }
    else{
      return true
    }
  }

  async downloadCoTripReport() {
    this.coReportLoader.isLoading = true;
    let trips;
    let groupedTrips = {}
    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();

      for (let trip of trips) {
        groupedTrips[trip.tripId] = {
          ...trip,
        };
      }

    await this.reportService.getCoTripReport(groupedTrips, this.user, this.searchDate, this.coReportLoader);
  }

  ngOnDestroy(): void {}
}
