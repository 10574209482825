import { Component, OnDestroy, OnInit } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DriversService } from '../../drivers-service/drivers-service';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { map, takeUntil, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { TripPurposesService } from '../../../trip-purposes/trip-purposes.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { merge } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-add-driver-amendments',
  templateUrl: './add-driver-amendments.component.html',
  styleUrls: ['./add-driver-amendments.component.css']
})
export class AddDriverAmendmentsComponent implements OnInit, OnDestroy {
  user;
  timeZone;
  amendmentForm: FormGroup;
  singleBreakFormSubmitted = false;
  submitted = false;
  searchDate;
  drivers;
  selectedDriver = '';
  timeline;

  constructor(
    private fb: FormBuilder,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.amendmentForm = this.fb.group({
      timelineId:[''],
      preCheckin: ['', Validators.required],
      preCheckout: ['', Validators.required],
      checkin: ['', Validators.required],
      checkout: ['', Validators.required],
      reason: [''],
      breaks: this.fb.array([])
    });

    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.getDrivers();
          this.searchDate = moment.tz(this.timeZone).startOf('day').toISOString();
        }
      });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.drivers = data;
      });
  }


  getDriverCurrentTimelines() {
    if (!this.selectedDriver || !this.searchDate) return;

    const queryParams = {
      startDate: moment(this.searchDate).tz(this.timeZone).toISOString(),
      driver: this.selectedDriver
    }

    this.driverService
      .getDriverCurrentTimelines(queryParams)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeline = data;
          // patch top level values
          this.amendmentForm.patchValue({
            ...data,
            timelineId: data._id,
            checkin: data.checkin ? data.checkin:  moment().tz(this.timeZone).startOf('day').toISOString(),
            checkout: data.checkout ? data.checkout: moment().tz(this.timeZone).endOf('day').toISOString(),
            preCheckin: data.checkin ? moment(data.checkin).tz(this.timeZone):  moment().tz(this.timeZone).startOf('day'),
            preCheckout: data.checkout ? moment(data.checkout).tz(this.timeZone): moment().tz(this.timeZone).endOf('day')
          });
          // Add breaks
          for (let myBreak of data.breaks) {
            if (myBreak.status !== 'approved') continue;
            this.breakArrayForm.push(
              this.fb.group({
                _id: [myBreak._id],
                action: [myBreak.action],
                dispatcherAction: [myBreak.dispatcherAction],
                preStart: [moment(myBreak.start).tz(this.timeZone), Validators.required],
                preStop: [moment(myBreak.stop).tz(this.timeZone), Validators.required],
                start: [myBreak.start, Validators.required],
                stop: [myBreak.stop, Validators.required],
                breakId:['']
              })
            );
          }

        }
      });
  }


  onSelectDriver() {
    this.getDriverCurrentTimelines();
  }

  onChangeDate() {
    this.getDriverCurrentTimelines();
  }


  getBreakForm() {
    return this.fb.group({
      // _id: [''],
      action: ['add'],
      dispatcherAction: ['approved'],
      preStart: ['', Validators.required],
      preStop: ['', Validators.required],
      start: ['', Validators.required],
      stop: ['', Validators.required],
    })
  }

  addBreak() {
    this.breakArrayForm.push(this.getBreakForm());
  }

  removeBreak(index) {
    const mealBreak = this.breakArrayForm.at(index).value;
    if (mealBreak._id) {
      this.breakArrayForm.at(index).patchValue({
        action: mealBreak.action === 'remove' ? '' : 'remove',
        breakId:mealBreak._id
      });

      if (mealBreak.action !== 'remove') {
        const b = this.timeline.breaks.find(item => item._id === mealBreak._id);
        if (b) {
          this.breakArrayForm.at(index).patchValue({
            ...b,
            preStart: moment(b.start).tz(this.timeZone),
            preStop: moment(b.stop).tz(this.timeZone),
            action: 'remove'
          })
        }

      this.breakArrayForm.at(index).get('preStart').disable();
      this.breakArrayForm.at(index).get('preStop').disable();
    } else {
      this.breakArrayForm.at(index).get('preStart').enable();
      this.breakArrayForm.at(index).get('preStop').enable();
    }

    } else {
      this.breakArrayForm.removeAt(index);
    }
  }

  onCheckinDateChange(evt) {
    this.amendmentForm.patchValue({
      checkin: moment(evt).tz(this.timeZone, true).toISOString()

    });
  }

  onCheckoutDateChange(evt) {
    this.amendmentForm.patchValue({
      checkout: moment(evt).tz(this.timeZone, true).toISOString()
    });
  }

  onStartBreakChange(evt, index) {
    this.breakArrayForm.at(index).patchValue({
      start: moment(evt).tz(this.timeZone, true).toISOString(),
      action: this.breakArrayForm.at(index).value._id ? 'update': 'add',
      breakId:this.breakArrayForm.at(index).value._id
    });

    console.log( this.breakArrayForm.at(index).value);

  }

  onStopBreakChange(evt, index) {
    this.breakArrayForm.at(index).patchValue({
      stop: moment(evt).tz(this.timeZone, true).toISOString(),
      action: this.breakArrayForm.at(index).value._id ? 'update': 'add',
      breakId:this.breakArrayForm.at(index).value._id
    });
    console.log( this.breakArrayForm.at(index).value);
  }

  get form() {
    return this.amendmentForm.controls;
  }

  get breakArrayForm() {
    return this.amendmentForm.controls.breaks as FormArray;
  }


  onSubmit() {
    console.log(this.amendmentForm)
    console.log(this.amendmentForm.value)
    this.singleBreakFormSubmitted = true;
    if (this.amendmentForm.invalid) return;

    const driver = this.drivers.find(d => d._id === this.selectedDriver);
    const payload = { ...this.amendmentForm.value, currDriver: driver, driver: driver._id};
    payload.breaks= payload.breaks.filter(b=> b.action )
     this.driverService.saveAmendment(payload)
       .pipe(takeUntil(componentDestroyed(this)))
       .subscribe(data => {
         if (data) {
          this.router.navigateByUrl('/timelines/amendments');
         }
       },
         err => {
           sweetAlert('Error', err, 'error', 'OK');
         });

  }


  ngOnDestroy(): void {
  }
}
