import { Component, OnDestroy, OnInit } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { OfferedtripsService } from '../../offered-trips/offered-trips-service/offeredtrips.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { getstandingOrderDaysAsObject } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-offered',
  templateUrl: './offered.component.html',
  styleUrls: ['./offered.component.css']
})
export class OfferedComponent implements OnInit, OnDestroy {
  standingOrderDays = getstandingOrderDaysAsObject();
  tripsList = [];
  ctcTripsList = [];
  assignModalObj;
  status = 'offered';
  subMenu = 'nemt';
  search;
  listView: boolean = false;
  gridView: boolean = true;
  view = 'grid';
  couterData;
  showSlideFilter = false;
  isShowButton = false;

  constructor(
    private offeredTripsService: OfferedtripsService
  ) { }

  ngOnInit() {
    window.addEventListener("keyup", this.initializeTimer);
    this.getTrips();
    this.getCounter();
  }

  getTrips() {
    const payload = {
      status: this.status
    };
    this.offeredTripsService
      .getTripsList(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripsList = data;
        }
      });
  }

  getCTCTrips() {
    this.offeredTripsService
      .getCTCTripsList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.ctcTripsList = data;
          this.ctcTripsList.forEach(trip => {
            if (trip.StandingOrderDays) {
              trip.sDays = this.getStandingOrderDays(trip.StandingOrderDays);
            }
          });
        }
      });
  }

  getStandingOrderDays(sDays) {
    sDays = sDays.split(',');
    let days = '';
    days = sDays.map(d => {
      return this.standingOrderDays[d - 1];
    });
    return days;
  }

  getCounter() {
    this.offeredTripsService
      .getDashboardCount()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.couterData = data;
        }
      });
  }

  setModalContent(tripDetail) {
    this.assignModalObj = tripDetail;
  }

  onAcceptClick(trip) {
    sweetAlert('Alert', 'Are you sure you want to accept?', 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          const payload = {
            ...trip,
            providerStatus: "accepted"
          };
          this.updateTripStatus(payload);
        }
        else if (result.dismiss) {
          close();
        }
      });
  }

  onRejectClick(trip) {
    sweetAlert('Alert', 'Are you sure you want to reject?', 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          const payload = {
            ...trip,
            providerStatus: "rejected"
          };
          this.updateTripStatus(payload);
        }
        else if (result.dismiss) {
          close();
        }
      });
  }

  updateTripStatus(payload) {
    this.offeredTripsService
      .changeProviderStatus(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.getTrips();
        }
      });
  }

  onChangeStatus(statusName) {
    this.status = statusName;
    this.tripsList = [];
    this.getTrips();
  }


  showFilter() {
    this.showSlideFilter = !this.showSlideFilter
  }

  onSelectSubMenu(menu) {
    this.subMenu = menu;
    if (this.subMenu === 'ctc') {
      this.getCTCTrips();
    }
  }

  claimTable(trip, index) {
    if (!trip.StandingOrderDays) {
      sweetAlert(`<div class= 'text-main-danger'>Warning</div>`, 'If you reroute these trips once you have claimed them, your ability to use this feature may be suspended. Are you sure you want to claim these trips?', 'warning', 'Yes', 'No')
        .then((result: any) => {
          if (result.value) {
            this.ctcRequest(trip, index);
          }
        })
    }
    else {
      sweetAlert(`<div> <div class= 'text-main-danger'> You are claiming a standing order trip. By doing so, you are agreeing to take the standing order on all scheduled days which are: ${trip.sDays} </div> <div class= 'text-main'> Leg A: ${trip.PickupTime} - ${trip.PickupAddress}  Leg B: ${trip.PickupTime} - ${trip.DropoffAddress} </div> </div>`, `If you reroute these trips once you have claimed them, your ability to use this feature may be suspended. Are you sure you want to claim these trips?`, 'warning', 'Yes', 'No')
        .then((result: any) => {
          if (result.value) {
            this.ctcRequest(trip, index);
          }
        })
    }
  }

  ctcRequest(trip, index) {
    const payload = {
      TriplegId: trip.TripLegId,
    }
    this.offeredTripsService
      .putCTCTripClaim(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (data.message === 'Created'){
            console.log("index check");
            this.ctcTripsList.splice(index, 1);
          }
        }
      });
  }

  closeFilter() {
    this.showSlideFilter = false;
  }

  initializeTimer = (e) => {
    console.log(e);
    if (
      e.altKey &&
      (e.key.toLowerCase() === "v" || e.key.toLowerCase() === "√")
    ) {
      this.isShowButton = !this.isShowButton;
    }
  };
  ngOnDestroy(): void {
  }
}
