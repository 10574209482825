import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-cancel-trip-modal',
  templateUrl: './cancel-trip-modal.component.html',
  styleUrls: ['./cancel-trip-modal.component.css']
})
export class CancelTripModalComponent implements OnInit, OnDestroy {
  @Input() tripData;
  @Input() componentType;
  @Output() cancelTripData = new EventEmitter<any>();
  @Output() onCloseModal = new EventEmitter<string>();
  multipleCancel = {}
  cancelReason;
  componentLoad = false;

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  setReason(reason) {
    this.cancelReason = reason;
    // if (this.componentType === 'Multiple Assign') {
    //   this.multipleCancel['changeReason'] = reason;
    // } else {
    //   this.tripData['changeReason'] = reason;
    // }
  }

  cancelJob() {
    if (this.componentType === 'Multiple Assign') {
      this.multipleCancel = {
        selectedTrips: this.tripData,
        status: 'cancelled',
        changeReason: this.cancelReason
      }
      if (this.tripData.jobType === 'cooperate') {
        this.multipleCancel = { ...this.multipleCancel, viewStatus: 'returned' }
      }

      if (this.tripData.isFinishedCalled) {
        this.multipleCancel = { ...this.multipleCancel, isFinishedCancelled: true }
      }

      this.componentLoad = true;
      this.assignTripService
        .assignMultipleTrip(this.multipleCancel)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            // sweetAlert('Success', "Trip Cancelled Successfully", 'success', 'OK')
            this.cancelTripData.emit(this.tripData);
          }
        });
    } else {
      this.tripData['changeReason'] = this.cancelReason;
      this.tripData['status'] = "cancelled";
      this.tripData['jobStatus'] = "cancelled";
      if (this.tripData.jobType === 'cooperate') {
        this.tripData['viewStatus'] = "returned";
      }
      if (this.tripData.isFinishedCalled) {
        this.tripData['isFinishedCancelled'] = true
      }
      this.componentLoad = true;
      this.assignTripService
        .removeTrip(this.tripData, this.tripData._id)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            // sweetAlert('Success', "Trip Cancelled Successfully", 'success', 'OK')
            this.cancelTripData.emit(this.tripData);
          }
        })
    }
  }

  closeCancelModal() {
    this.cancelTripData.emit();
  }

  ngOnDestroy(): void {
  }
}
